import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class ToolboxService {

  constructor(private restRequest: RestRequest) { }

  createToolbox(obj) {
    return this.restRequest.addAuthorization().post('users/addToolText', obj);
  }
  getTemplate(query) {
    return this.restRequest.addAuthorization().get('users/templateList',query);
  }
  updateToolboxData(obj) {
    return this.restRequest.addAuthorization().post('users/editTool', obj);
  }
  getToolboxDataById(obj) {
    return this.restRequest.addAuthorization().post('users/getToolById', obj);
  }
  getToolboxList() {
    return this.restRequest.addAuthorization().get('users/displayToolDataV1');
  }
  getToolboxListV2(query) {
    return this.restRequest.addAuthorization().get('users/displayToolData',query);
  }
  deleteToolboxData(obj) {
    return this.restRequest.addAuthorization().post('users/deleteTool', obj);
  }
}
