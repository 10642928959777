import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardService } from './services/dashboard.service';
import { UtilityModule } from './../utility/utility.module';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './../../layouts/layouts.module';


/*import { HeaderComponent } from './../../layouts/header/header.component';
import { FooterComponent } from './../../layouts/footer/footer.component';
import { SidebarComponent } from './../../layouts/sidebar/sidebar.component';
import { BreadcrumbComponent } from './../../layouts/breadcrumb/breadcrumb.component';
*/
@NgModule({
  imports: [
    CommonModule,
    UtilityModule,
    RouterModule,
    LayoutsModule
  ],
  exports: [
    DashboardComponent
  ],
  providers: [
    DashboardService
  ],
  declarations: [DashboardComponent]
})
export class DashboardModule { }
