import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { QuotesStatusServices } from "./quotes-status.service";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";
import { EmailModelComponent } from "../email-model/email-model.component";
import { error } from "console";

@Component({
  selector: "app-quotes-status",
  templateUrl: "./quotes-status.component.html",
  styleUrls: ["./quotes-status.component.sass"],
})
export class QuotesStatusComponent implements OnInit {
  switchClasses: { [key: string]: string } = {};
  classMap: { [key: string]: string } = {};

  // bsModalRef: BsModalRef;
  // isToggleChecked;
  search = "";
  routeParams = {
    followed_up: {
      title: "To Be Followed-Up",
    },
    followed_completed: {
      title: "Follow-Up Complete",
    }, // getList() {
    //   if (!this.status) {
    //     return;
    //   }
    //   this.loader = true;
    //   this.api
    //     .getQuotestList(this.status, this.currentPageNo, this.search)
    //     .subscribe(
    //       (result: any) => {
    //         const response = result.data;
    //         console.log(result.data, ">>>>>>>>>>>>>>>>>>>>>>>>");

    //         // Store the items
    //         this.items = response.items;
    //         this.totalRecords = response.count;
    //         this.totalPages = response.totalPages;
    //         this.currentPageNo = response.currentPage;

    //         // Extract the date and store it in quoteDate
    //         // console.log(this.quoteDate, "Quote Dates");

    //         // this.quoteDate = this.items.length > 0 ? this.items.date : null; // Stores the first date or null if no items
    //         console.log(this.quoteDate, "Quote Dates -");

    //         this.loader = false;
    //       },
    //       (error: any) => {
    //         this.commonService.toast(error.error.message, "Error");
    //         this.loader = false;
    //       }
    //     );
    // }
    call_back: {
      title: "Assesor to Call Back",
    },
    ready_yet: {
      title: "Client Not Ready Yet",
    },
    declined: {
      title: "Declined",
    },
    accepted: {
      title: "Accepted",
    },
  };
  auto_reminder = true;
  reminderCount: number;
  quoteStatus: any;
  // mailStatus: any;
  modalRef: BsModalRef;
  status = "";
  quoteDate: any;

  switchColor = "red";

  daysUntilMailSent: any;
  id: string;
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  loader = false;
  // switchColors: any;
  switchColors: { [key: string]: string } = {}; // Initialize the object to hold colors

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: QuotesStatusServices,
    private commonService: CommonService,
    private router: Router
  ) {}

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.search = inputElement.value;
    this.getList();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      this.title = this.routeParams[this.status].title;
      this.getList();
      // this.getMasterSwitchData();
    });
  }

  handleGetMasterMail(auto_reminder: any) {
    this.auto_reminder = auto_reminder;
    this.updateMasterEmail();
  }

  handleMasterSwitch(daysUntilMailSent: any) {
    this.daysUntilMailSent = daysUntilMailSent;
    this.updateMasterEmail();
  }

  // getMasterSwitchData() {
  //   try {
  //     this.api.getMasterMail().subscribe(
  //       (result: any) => {
  //         this.auto_reminder = result.data.auto_reminder;
  //         this.daysUntilMailSent = result.data.daysUntilMailSent;
  //         this.id = result.data._id;
  //       },
  //       (error: any) => {
  //         this.commonService.toast(error.error.message, "Error");
  //       }
  //     );
  //   } catch (error) {}
  // }

  updateMasterEmail() {
    if (!this.id) {
      console.error("ID is undefined, cannot update.");
      return;
    }
    this.api
      .updateMasterEmail(this.id, this.auto_reminder, this.daysUntilMailSent)
      .subscribe(
        (response) => {
          this.commonService.toast("Master Switch Updated", "Success");
        },
        (error) => {
          console.error("Error updating master email:", error);
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }
  formatDate(dateString: string): string {
    const [day, month, year] = dateString.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthIndex = parseInt(month) - 1; // Convert to zero-based index
    return `${day}-${monthNames[monthIndex]}-${year}`;
  }
  getList() {
    if (!this.status) {
      return;
    }
    this.loader = true;
    this.api
      .getQuotestList(this.status, this.currentPageNo, this.search)
      .subscribe(
        (result: any) => {
          const response = result.data;

          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          // this.quoteDate = this.items.map((item) => item.date); // This will store an array of dates
          this.quoteDate = this.items.map((item) => this.formatDate(item.date));

          this.quoteStatus = this.items.map((item) => {
            // console.log("item-----------", item.reminderCount);
            return {
              quoteStatus: item.quoteStatus,
              _id: item._id,
              reminderCount: item.reminderCount,
              autoReminder: item.auto_reminder,
            };
          });
          console.log("<<<<", response, this.quoteStatus, this.reminderCount);

          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleUpdateStatus(id: any, event: any) {
    const data = {
      workFlowStatus: event.target.value,
    };
    this.loader = true;
    this.api.updateQuotesStatus(id, data).subscribe(
      (data) => {
        this.getList();
      },
      (errro) => {
        this.commonService.toast(errro.error.message, "Error");
        this.loader = false;
      }
    );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "quote",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleModel(mailType: any) {
    const initialState = {
      mailType: mailType,
    };
    this.modalRef = this.modalService.show(EmailModelComponent, {
      initialState,
      class: "modal-xl",
    });
  }

  SendManulEmail(
    email: any,
    quote_link: any,
    quote_code: any,
    quote_title: any,
    name: any
  ) {
    this.loader = true;
    this.api
      .sendManualEmail({ email, quote_link, quote_title, quote_code, name })
      .subscribe(
        (response: any) => {
          this.commonService.toast("Mail Send Successfully", "Success");

          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  // handleToggleSwitch(event: Event, id: any) {
  //   this.api.updateSingleMasterMail(id, event).subscribe(
  //     (response) => {
  //       // console.log(response, event, id, ">>>");

  //  this.getList();
  //       this.commonService.toast("Status Update Successfully", "Success");
  //     },
  //     (error) => {
  //       console.error("Error updating master email:", error);
  //       this.commonService.toast(error.error.message, "Error");
  //     }
  //   );
  // }

  handleToggleSwitch(event: Event, id: any) {
    this.api.updateSingleMasterMail(id, event).subscribe(
      (response) => {
        const idExists = this.quoteStatus.find(
          (quote: { _id: string }) => quote._id === id
        );
        console.log("idExists", idExists);
        this.getList();
        this.commonService.toast("Status Update Successfully", "Success");
      },
      (error) => {
        console.error("Error updating master email:", error);
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  getSwitchClass(item: any): string {
    if (item.quoteStatus === "declined") {
      return "orange";
    } else if (!item.autoReminder && item.reminderCount === 0) {
      return (this.switchColor = "red");
    } else if (item.autoReminder && item.quoteStatus !== "declined") {
      return (this.switchColor = "green");
    } else if (
      item.quoteStatus !== "declined" &&
      !item.autoReminder &&
      item.reminderCount > 4
    ) {
      return (this.switchColor = "blue");
    }
    return (this.switchColor = "red");
  }
}
