import { Component, OnInit } from '@angular/core';
import { BookinglinkService } from '../bookinglink.service';
import { CommonService } from '../../../services/common.service';
import jspdf from 'jspdf'
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { NotesmodalComponent } from '../notesmodal/notesmodal.component';
import { BookingimageComponent } from '../bookingimage/bookingimage.component'
import {PdppopupviewComponent} from '../../pdfpersection/pdppopupview-modal/pdppopupview.component'
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-search-booking",
    templateUrl: "./search-booking.component.html",
    styleUrls: ["./search-booking.component.sass"],
})
export class SearchBookingComponent implements OnInit {
    loader = true;
    items: any;
    pdfdata: any;
    pdfviewer: any;
    listData: any;
    p = 1;
    nodata = false;
    iswarranty: any;
    deleteid: any;
    router: any;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    recordsPerPage: number;
    totalRecords = 0;

    constructor(
        private booking: BookinglinkService,
        private modalService: BsModalService,
        private commonService: CommonService
    ) {
        this.recordsPerPage = environment.defaultPageSize;
    }

    private searchTerms = new Subject<string>();

    ngOnInit() {
        this.getbookinglist("Profile Preparation");
        this.getpdfpopup("Profile Preparation");
        this.pdfviewer = false;
        this.listData = [
            "Profile Preparation",
            "Dates Sent",
            "Job Underway",
            "Job On Hold",
            "Job Cancelled",
            "To be Invoiced",
            "Awaiting Payment",
            "Dispute or On Hold",
            "Paid in full",
        ];
         this.searchTerms
             .pipe(debounceTime(500), distinctUntilChanged())
             .subscribe((value) => {
                 this.query.search = value;
                 this.pageChanged({ page: 1 });
                 // this.engineerlist();
             });
    }

    getValue(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.searchTerms.next(inputElement.value);
        // return (event.target as HTMLInputElement).value;
    }

    getpdfpopup(obj) {
        this.booking.getpdfforsectionbyname(obj).subscribe(
            (result: any) => {
                const items = result.data;
                this.pdfdata = items;
                console.log(this.pdfdata);
            },
            (error: any) => {
                // this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    getbookinglist(obj) {
        this.query.search = this.searchText;
        this.loader = true;
        this.booking.getAllBookingList(this.query).subscribe(
            (result: any) => {
                const items = result.data.data;
                this.totalRecords = result.data.totalCount;
                this.items = items;
                this.loader = false;
                this.nodata = false;
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
                // this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    pageChanged(event: any): void {
        this.p = event.page;
        this.query.page = this.p;
        this.getbookinglist("Profile Preparation");
    }

    uploadfile(userid) {
        const initialState = {
            userid: userid,
        };
        this.modalService.show(BookingimageComponent, {
            initialState,
            class: "modal-lg",
        });
        this.modalService.onHide.subscribe(() => {});
    }
    pdfshow() {
        const initialState = {
            pdf: this.pdfdata.PDF,
        };
        this.modalService.show(PdppopupviewComponent, {
            initialState,
            class: "modal-lg",
        });
        this.modalService.onHide.subscribe(() => {});
    }

    notesModal(userid) {
        //console.log(userid);
        const initialState = {
            userid: userid,
        };
        this.modalService.show(NotesmodalComponent, {
            initialState,
            class: "modal-lg",
        });
        this.modalService.onHide.subscribe(() => {});
    }

    deltedata() {
        const Id = this.deleteid;
        this.booking.deleteData(Id).subscribe(
            (result: any) => {
                this.loader = false;
                if (result.success === true) {
                    this.commonService.toast(result.message, "Success");
                } else {
                    this.commonService.toast(result.message, "Error");
                }
                this.getbookinglist("Profile Preparation");
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    changeStatus(_id, event) {
        const status = event.target.value;
        this.booking.updateStatus({ _id, status: status }).subscribe(
            (result: any) => {
                this.commonService.toast(
                    `Booking status updated successfully.`,
                    "Success"
                );
                this.getbookinglist("Profile Preparation");
            },
            (error) => {
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    async downloadpdf(base64String, firsttime) {
        if (firsttime == false) {
            this.loader = true;
            var imgWidth = 210;
            var pageHeight = 280;
            var imgHeight = 530; //canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var pdf = new jspdf("p", "mm");
            var position = 0;

            pdf.addImage(
                base64String,
                "PNG",
                0,
                position,
                imgWidth,
                imgHeight + 25
            );
            heightLeft -= pageHeight;

            // while (heightLeft >= 0) {
            position = heightLeft - imgHeight - 5;
            pdf.addPage();
            pdf.addImage(base64String, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            //}
            await pdf.save("PDF/Buildfix_Booking_Details.pdf");
            this.loader = false;
        } else if (firsttime == true) {
            this.loader = true;
            var imgWidth = 210;
            var pageHeight = 280;
            var imgHeight = 530; //canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            var pdf = new jspdf("p", "mm");
            var position = 0;

            pdf.addImage(
                base64String,
                "PNG",
                0,
                position,
                imgWidth,
                imgHeight + 5
            );
            heightLeft -= pageHeight;

            //while (heightLeft >= 0) {
            position = heightLeft - imgHeight - 13;
            pdf.addPage();
            pdf.addImage(base64String, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            //}
            await pdf.save("PDF/Buildfix_Booking_Details.pdf");
            this.loader = false;
        }
    }

    saveid(Id) {
        this.deleteid = Id;
    }
}
