import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";

@Injectable({
  providedIn: "root",
})
export class PortalService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}
  

  getPortalList(id:any) {
    return this.restRequest
      .addAuthorization()
      .get(
        `/landing-pages?categoryId=${id}`
      );
  }

 
  editTarget(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`sales-performance/target/${id}`, data);
  }

  addButton (data:any) {
    return this.restRequest
      .addAuthorization()
     .post(`landing-page`, data);
  }

  editButton (data:any , id:any) {
    return this.restRequest
    .addAuthorization()
    .put(`/landing-page/${id}`,data)
  }

  deleteButton (id:any) {
    return this.restRequest
    .addAuthorization()
    .delete(`/landing-page/${id}`)
  }

  getButtonDetail (id:any) {
    return this.restRequest
    .addAuthorization()
    .get(`/landing-page/${id}`)
  }

  getButtonList () {
    return this.restRequest
    .addAuthorization()
    .get(`/landing-pages`)
  }

  getCategory (data:any) {
    return this.restRequest
    .addAuthorization()
    .get(`landing-page-categories?search=${data.search}&type=${data.type}`)

  }
  addCategory (data:any) {
    return this.restRequest
    .addAuthorization()
    .post('landing-page-category',data)
  }
  editCategory (id:any,data:any) {
    return this.restRequest
    .addAuthorization()
    .put(`landing-page-category/${id}`,data)
  }
  deleteCategory (id:any) {
    return this.restRequest
    .addAuthorization()
    .delete(`/landing-page-category/${id}`)
  }
 
  getLandingPage (type:any) {
    return this.restRequest
    .addAuthorization()
    .get(`portal-landing-pages?type=${type}`)
  }

  getCategoryById (id:any) {
    return this.restRequest
    .addAuthorization()
    .get(`landing-page-category/${id}`)
  }
  
  addEditPassword(data:any) {
    return this.restRequest
    .addAuthorization()
    .put('landing-page-category-password',data)
  }

}
