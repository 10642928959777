import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class RepairService {

  constructor(private restRequest: RestRequest) { }

  createRepair(obj) { 
    return this.restRequest.addAuthorization().post('users/addRepair', obj); 
  }

  createQuote(obj) {
    return this.restRequest.addAuthorization().post('users/postTemplatePdf', obj); 
  }
  addQuote(obj) {
    return this.restRequest.addAuthorization().post('users/addTemplatePdf', obj); 
  }
  getRepairList() {
    return this.restRequest.addAuthorization().get('users/getTemplateRepairDataV1');
  }
  getRepairListV2(query) {
    return this.restRequest.addAuthorization().get('users/getTemplateRepairData',query);
  }
  getRepairDataById(id) {
    return this.restRequest.addAuthorization().get('users/getRepairDataById/'+id);
  }
  updateRepairData(obj) {
    return this.restRequest.addAuthorization().post('users/editRepairMethod',obj);
  }
  updateQuote(obj) {
    return this.restRequest.addAuthorization().put('users/updateTemplatePdf',obj);
  }
  deleteRepairData(obj) {
    return this.restRequest.addAuthorization().post('users/deleteRepair',obj);
  }
  deletePdf(obj) {
    return this.restRequest.addAuthorization().post('users/deleteTemplatePdf',obj);
  }
  getRepairListById(obj) {
    return this.restRequest.addAuthorization().post('users/getTemplateRepairList',obj);
  }

  getRepairById(obj) {
    return this.restRequest.addAuthorization().get(`users/getTemplatePdf?temp_id=${obj.tempId}`);
  }

  
}
