import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlumbingListComponent } from './plumbing-list/plumbing-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddPlumbingComponent } from './add-plumbing/add-plumbing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [PlumbingListComponent, AddPlumbingComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule
  ]
})
export class PlumbingModule { }
