import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

interface SortEmit {
    field: String;
    sortOrder: String;
}
@Component({
  selector: 'app-sorter',
  templateUrl: './sorter.component.html',
  styleUrls: ['./sorter.component.css']
})
export class SorterComponent implements OnInit, OnChanges {
  @Input() field: String;
  @Input() sorted: Boolean;
  @Input() sortField: String;
  @Input() sortOrder: String;
  @Output() selectSort = new EventEmitter<SortEmit>();

  constructor() { }

  ngOnInit() {
    /*console.log('------------------');
    console.log(this.sorted);
    console.log(typeof this.sorted);

    console.log(this.sortOrder);
    console.log(typeof this.sortOrder);*/
  }
  ngOnChanges() {
    /*console.log('=====================');
    console.log(this.sorted);
    console.log(typeof this.sorted);

    console.log(this.sortOrder);
    console.log(typeof this.sortOrder);*/
    if(this.sortField == this.field) {
      this.sorted = true;
    } else {
      this.sorted = false;
    }
  }

  changeSort($event) {
    // console.log($event);
    if($event == '') {
      $event = 'asc';
    }
    let sortOrder = null;
    if(this.field === this.sortField) {
      if (this.sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    this.selectSort.emit({ field: this.field, sortOrder: sortOrder });
  }

}
