import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopPropagate]'
})
export class StopPropagateDirective {

  constructor() { }

  @HostListener('click', ['$event'])
  public onClick(event: any): void
  {
      event.stopPropagation();
  }

}
