import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../services/page-title.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { BreadcrumbLink } from '../../services/breadcrumb';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  pageTitle = '';
  breadcrumbLinks: any[];
  constructor(private pageTitleService: PageTitleService, private breadcrumbService: BreadcrumbService ) {

  }

  ngOnInit() {
    this.pageTitleService.currentMessage.subscribe(pageTitle => this.pageTitle = pageTitle);
    this.breadcrumbService.currentMessage.subscribe(breadcrumbLinks => this.breadcrumbLinks = breadcrumbLinks);
  }

}
