import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CauseListComponent } from './cause-list/cause-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddCauseComponent } from './add-cause/add-cause.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [CauseListComponent, AddCauseComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
  ]
})
export class CausesModule { }
