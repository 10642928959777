import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { InventoryService } from "../inventory.service";
import { CommonService } from "src/app/services/common.service";
import { BsModalRef } from "ngx-bootstrap";

@Component({
  selector: "app-edit-inventory",
  templateUrl: "./edit-inventory.component.html",
  styleUrls: ["./edit-inventory.component.sass"],
})
export class EditInventoryComponent implements OnInit {
  isAuthorized = sessionStorage.getItem("ivu");
  isLoading = false;
  category = "";
  showCategory = true;
  categories = [];
  isValid = false;
  submitted = false;
  inventoryId = "";
  addInventoryForm: FormGroup;

  private inventoryData = {
    _id: "",
    itemName: "",
    categoryId: "",
    supplier: "",
    name: "",
    supplierSku: "",
    workHouseLocation: "",
    quotePrice: null,
    stockQut: null,
  };

  constructor(
    private bsModalRef: BsModalRef,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: InventoryService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    if(['core_products','raw_materials','equipment_parts','other','consumables'].includes(this.category)) this.showCategory = false
    this.route.params.subscribe(() => {
      // this.category = this.route.snapshot.params.category;
      this.getCategories();
      this.inventoryId = this.inventoryData._id;

      this.addInventoryForm = this._fb.group({
        itemName: [
          this.inventoryData.itemName,
          [Validators.required, Validators.minLength(2)],
        ],
        categoryId: [this.inventoryData.categoryId, Validators.required],
        supplier: [
          this.inventoryData.supplier,
          [Validators.required, Validators.minLength(2)],
        ],
        name: [
          this.inventoryData.name,
          [Validators.required, Validators.minLength(2)],
        ],
        supplierSku: [
          this.inventoryData.supplierSku,
          [Validators.required, Validators.minLength(2)],
        ],
        workHouseLocation: [
          this.inventoryData.workHouseLocation,
          [Validators.required, Validators.minLength(2)],
        ],
        quotePrice: [
          this.inventoryData.quotePrice,
          [Validators.required, Validators.min(0)],
        ],
        stockQut: [
          this.inventoryData.stockQut,
          [Validators.required, Validators.min(0)],
        ],
      });
    });
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  get f() {
    return this.addInventoryForm.controls;
  }

  getCategories() {
    this.api.getCategories().subscribe(
      (result: any) => {
        const response = result.data;

        if (response.items) {
         const  temp = response.items;
         this.categories = temp.filter((e) => e.route != 'raw_materials')
          const selectedCategory = response.items.find(
            ({ route }) => route === this.category
          );

          this.addInventoryForm.patchValue({
            categoryId: selectedCategory._id, // Set the default category id here
          });

          this.inventoryData.categoryId = selectedCategory._id;
          // this.selectedCategory = ;
        }
      },
      (error: any) => {
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  handleEditInventory() {
    this.submitted = true;

    if (this.addInventoryForm.valid && this.isLoading === false) {
      const formData = this.addInventoryForm.value;
      this.isLoading = true;

      if (this.inventoryId) {
        this.api.updateInventory(this.inventoryId, formData).subscribe(
          (result: any) => {
            this.submitted = false;
            this.commonService.toast(result.message, "Success");

            const seletedCategory = this.categories.find(
              ({ _id }) => _id === formData.categoryId
            );
            const categoryRoute = this.category || "core_products";
            this.isLoading = true;
            this.router.navigate([`Inventory/list/${this.category}`]);

            this.closeModal();
          },
          (error: any) => {
            this.commonService.toast(error.error.message, "Error");
            this.isLoading = true;

            this.closeModal();
          }
        );
      } else {
        const formData = this.addInventoryForm.value;
        this.api.addNewInventory(formData).subscribe(
          (result: any) => {
            this.submitted = false;
            this.isLoading = true;
            this.commonService.toast(result.message, "Success");

            const seletedCategory = this.categories.find(
              ({ _id }) => _id === formData.categoryId
            );
            const categoryRoute = this.category || "core_products";
            this.router.navigate([`Inventory/list/${this.category}`]);

            this.closeModal();
          },
          (error: any) => {
            this.isLoading = true;
            this.commonService.toast(error.error.message, "Error");
            // this.closeModal();
          }
        );
      }
    }
  }
}
