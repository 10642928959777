import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import * as moment from 'moment';
@Component({
  selector: "app-support-ticket-popup",
  templateUrl: "./support-ticket-popup.component.html",
  styleUrls: ["./support-ticket-popup.component.sass"],
})
export class SupportTicketPopupComponent implements OnInit {
  item: any;
  date :null;
  
  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.item.createdAt = moment(this.item.createdAt).format('DD/MM/YYYY')

    // this.item.note = this.item.inventoriesNote.length
    //   ? this.item.inventoriesNote[0].notes
    //   : "";
  }

  handleCloseModal() {
    this.bsModalRef.hide();
  }
}
