import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlumbingService } from '../plumbing.service';
import { CommonService } from '../../../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plumbing-list',
  templateUrl: './plumbing-list.component.html',
  styleUrls: ['./plumbing-list.component.sass']
})
export class PlumbingListComponent implements OnInit {
  loader;
  nodata = false;
  items;
  p = 1;
  searchText = '';
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1
  };
  recordsPerPage: number;
  totalRecords = 0;

  constructor(private router: Router, private plumbingser: PlumbingService, private commonService: CommonService) { 
    this.recordsPerPage = environment.defaultPageSize;
  }

  ngOnInit() {
    this.loader = true;
    this.getPlumbingList();
  }
  getPlumbingList() {
    this.query.search = this.searchText;
    this.plumbingser.getPlumbingListV2(this.query).subscribe((result: any) => {
      const items = result.data.data;
      this.items = items;
      this.totalRecords = result.data.totalCount;
      this.loader = false;
      this.nodata = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getPlumbingList();
  }

  public getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }
  navigateAddPlumbing() {
    this.router.navigate(['/plumbing/add']);
  }

  deletePlumbing(id) {
    const obj = {
      _id: id
    };
    this.plumbingser.deletePlumbingData(obj).subscribe((result: any) => {
      this.loader = false;
      if (result.success === true) {
        this.commonService.toast(result.message, 'Success');
      } else {
        this.commonService.toast(result.message, 'Error');
      }
      this.getPlumbingList();
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }
}
