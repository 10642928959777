import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import { addTimeSheet } from "./timesheet";

@Injectable({
  providedIn: "root",
})
export class TimeSheetService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  //-----------------------------------------------> Inventory
  getList(data: any) {
    const searchQuery = data.search ? `&search=${data.search}` : "";
    return this.restRequest
      .addAuthorization()
      .get(`time-sheet?status=${data.status}&page=${data.page}${searchQuery}`);
  }

  getDetail(id:any) {
    return this.restRequest
    .addAuthorization()
    .get(`time-sheet/${id}`);
}
  
  getUsers(data:any) {
    const searchQuery = data.search ? `?search=${data.search}` : "";
    return this.restRequest
      .addAuthorization()
      .get(`time-sheet/employee${searchQuery}`);
  }
  
  addData(data: addTimeSheet) {
    return this.restRequest.addAuthorization().post(`time-sheet`, data);
  }

  updateStatus(id: string, data: { status: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`time-sheet-status/${id}`, data);
  }

  deleteData(id: string) {
    return this.restRequest.addAuthorization().delete(`time-sheet/${id}`);
  }
}
