import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private restRequest: RestRequest) { }

  getJobList(query) {
    return this.restRequest.addAuthorization().get('users/getTempSaveData',query);
  }
}
