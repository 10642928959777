import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "../../../services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { BookinglinkService } from "../bookinglink.service";

@Component({
  selector: "app-bookingimage",
  templateUrl: "./bookingimage.component.html",
  styleUrls: ["./bookingimage.component.sass"],
})
export class BookingimageComponent implements OnInit {
  imageForm: FormGroup;
  items;
  loader;
  image;
  index;
  userid: null;
  fileToUpload: File = null;
  fileToUpload1 = null;
  dispimage = [];
  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private booking: BookinglinkService
  ) {}

  ngOnInit() {
    this.imageForm = this.formBuilder.group({
      imagemain: this.formBuilder.array([this.addNote()]),
    });
    this.getbookinglist(this.userid);
    this.dispimage == [];
  }
  addNote() {
    return this.formBuilder.group({
      image: [null, Validators.required],
    });
  }
  userNoteAdd() {
    this.noteArr.push(this.addNote());
  }
  get noteArr() {
    return this.imageForm.get("imagemain") as FormArray;
  }
  editData(data: any) {
    this.imageForm.setControl("imagemain", this.setNoteFun(data.image));
  }
  setNoteFun(genData: any): FormArray {
    const formArray = new FormArray([]);
    this.fileToUpload1 = true;

    this.image = genData;
    if (genData === "" || genData === undefined) {
      this.fileToUpload1 = null;
    } else {
      this.dispimage = genData;
    }
    if (genData) {
      genData.forEach((s) => {
        formArray.controls.push(
          this.formBuilder.group({
            image: s.image,
          })
        );
      });
    }
    return formArray;
  }
  getbookinglist(obj) {
    this.booking.getbookingdatabyid(obj).subscribe(
      (result: any) => {
        const items = result.data.data;
        this.items = items;
        this.loader = false;

        console.log({ thisitems: this.items });

        this.editData(this.items);
      },
      (error: any) => {
        this.loader = false;

        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  editData1(data) {
    this.fileToUpload1 = true;

    this.image = data.image;
    if (data.image === "" || data.image === undefined) {
      this.fileToUpload1 = null;
    } else {
      this.dispimage = data.image;
    }
  }
  saveimage() {
    const formData = { ...this.imageForm.value };
    const obj = {
      image: this.dispimage,
    };
    this.booking.updateimageyid(this.userid, obj).subscribe(
      (result: any) => {
        this.loader = false;
        this.commonService.toast("File saved successfully", "Success");
        // this.decline();
      },
      (error: any) => {
        this.loader = false;

        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }
  decline(): void {
    this.bsModalRef.hide();
  }
  setindex(index) {
    this.index = index;
  }

  handleFileInput(files: FileList, index) {
    this.fileToUpload = files.item(0);
    // this.showLoader = true;
    //this.loader= false;
    //console.log(index)
    if (files.length === 1) {
    }
    this.commonService
      .fileUpload(this.fileToUpload)
      .subscribe((result: any) => {
        this.image = result.data;
        //this.loader = true;
        this.dispimage[this.index] = result.data;
        console.log(this.dispimage);
        this.fileToUpload1 = true;
        // this.imageForm.patchValue({
        //   image: result.data
        // });
      });
  }
  removeNote(index) {
    if (this.noteArr.length === 1) {
      //this.commonService.toast('Atleast one  Image is required.', 'Error');
      this.noteArr.removeAt(index);
      this.dispimage.splice(index, 1);
    } else {
      this.noteArr.removeAt(index);
      this.dispimage.splice(index, 1);
    }
  }
}
