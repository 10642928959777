import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class PlumbingService {

  constructor(private restRequest: RestRequest) { }

  getPlumbingList() {
    return this.restRequest.addAuthorization().get('users/getPlumbDataV1');
  }
  getPlumbingListV2(query) {
    return this.restRequest.addAuthorization().get('users/getPlumbData',query);
  }
  createPlumbing(obj) {
    return this.restRequest.addAuthorization().post('users/addPlumbdata', obj);
  }
  getPlumbingById(obj) {
    return this.restRequest.addAuthorization().get('users/getPlumbById/' + obj);
  }
  updatePlumbingData(obj) {
    return this.restRequest.addAuthorization().post('users/editPlub', obj);
  }
  deletePlumbingData(obj) {
    return this.restRequest.addAuthorization().post('users/plumbDelete', obj);
  }
}
