import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobHistoryComponent } from './job-history/job-history.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [JobHistoryComponent, FeedbackModalComponent],
  imports: [
    CommonModule, NgxPaginationModule,Ng2SearchPipeModule,FormsModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
  ],
  entryComponents : [FeedbackModalComponent]
})
export class JobsModule { }
