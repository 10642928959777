import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.sass']
})
export class ViewTemplateComponent implements OnInit {

  loader;
  dataResult: any;
  repData: any;
  genData: any;
  templateName: string;

  constructor(private templateService: TemplateService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private commonService: CommonService
  ) { }

  ngOnInit() {
    this.loader = true;
    this.loadData();
  }
  loadData() {
    const obj = {
      tempId: this.activatedRoute.snapshot.params.tempId,
      userId: this.activatedRoute.snapshot.params.tempId
    };
    this.templateService.viewTemplate(obj).subscribe((result: any) => {
      const items = result.data;
      console.log(items);
      this.dataResult = items;
      this.repData = items.repairRecommend;
      this.genData = items.generalNote;
      this.templateName = items.templateName;
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

}
