import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortalService } from '../../portal.component.services';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-add-portal-form',
  templateUrl: './add-portal-form.component.html',
  styleUrls: ['./add-portal-form.component.sass']
})
export class AddPortalFormComponent implements OnInit {

  isEdit = false
  formData: FormGroup;
  submitted = false;
  loading = false;
  selectedCategory : any
  category :any;
  categoryName:any;
  private initialData = {
    categoryId:"",
    index: "",
    buttonName: "",
    buttonLink: "",
    category: {},
   
    _id:""

  };
  reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
 numberPattern = /^[0-9]+$/;

  optionsVisibility = {};
  constructor(
    private _fb: FormBuilder,
    private api: PortalService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,

  ) {
    this.handleCategory("")
   }

  ngOnInit() {
    this.formData = this._fb.group({
      categoryId: [this.initialData.categoryId, [Validators.required]],
      index: [this.initialData.index, [Validators.required, Validators.pattern(this.numberPattern)]],
      buttonName: [this.initialData.buttonName, [Validators.required]],
      buttonLink: [this.initialData.buttonLink, [Validators.required, Validators.pattern(this.reg)]],
    });
    this.formData.patchValue({
      categoryId: this.initialData.categoryId,
    });
    this.selectedCategory = this.initialData.category
    console.log(this.selectedCategory ,'this.selectedCategory ')
  }



  get f() {
    return this.formData.controls;
  }

  handleCategorySelect(item: any) {
    this.selectedCategory = item;
    this.formData.patchValue({
      categoryId: item._id,
    });
  }

  handleUpdateButton () {
    this.submitted = true;
    if(!this.formData.valid) {
      return
    }
    this.loading = true
    if (this.isEdit) {      
      this.api.editButton(this.formData.value,this.initialData._id).subscribe((result:any) => {
        this.loading = false;
        this.commonService.toast(result.message,'Success');
        this.submitted = false;
      })
    }
    else {
      this.api.addButton(this.formData.value).subscribe((result:any) => {
        this.loading = false;
        this.commonService.toast(result.message, "Success");
        this.submitted = false;
        this.router.navigate(["/portal/list",this.selectedCategory.name]);
      },(error: any) => {
        this.commonService.toast(error.error.message, "Error");
        this.submitted = false
        this.loading = false;

      }
    )}
    
  }

  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;

    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  handleCategory(event: any) {
    const str = event ? event.target.value : "";
    const data = {
      search: str,
      type:"all"
    };
    this.api.getCategory(data).subscribe(
      (result: any) => {
        const response = result.data;
        if (response.items) {
          this.category = response.items;
          let temp = this.isEdit ? this.category.find(obj => obj._id === this.initialData.categoryId ) :  this.category.find(obj => obj.name === this.activatedRoute.snapshot.params.categoryId )
          console.log(temp,'temp')
          this.selectedCategory = temp || this.category[0]
          this.formData.patchValue({
            categoryId: this.selectedCategory._id,
          });
        }
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );    
  }
}
