import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { EmployeeService } from "../employee.service";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";
import { InventoryDocUploaderComponent } from "../../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { EmployeeFormComponent } from "../employee-form/employee-form.component";
import { EmployeeModalComponent } from "../employee-modal/employee-modal.component";
import { Subscription } from "rxjs";

@Component({
  selector: "app-employee-list",
  templateUrl: "./employee-list.component.html",
  styleUrls: ["./employee-list.component.sass"],
})
export class EmployeeListComponent implements OnInit, OnDestroy {
  isAuthorized: any;
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedInventoryId = "";
  loader = false;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: EmployeeService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    // this.route.params.subscribe((params: Params) => {
    //   this.getList();
    // });
    this.isAuthorized = "false";
    this.handleUserAuthCheck("/Employee/list", "Employee Database");
  }

  handleUserAuthCheck(url: any, name: string) {
    this.items = [];

    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "inventory",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");

      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getList();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  // handlePasswordPopup() {
  //   const initialState = {
  //     component: "employee",
  //   };

  //   this.modalService.show(PasswordCheckerComponent, {
  //     initialState,
  //     class: "modal-lg",
  //   });
  //   this.modalService.onHide.subscribe(() => {
  //     this.getList();
  //     this.isAuthorized = sessionStorage.getItem("ivu");
  //   });
  // }

  handleShowDataModal(item: any) {
    const initialState = {
      item,
    };

    this.modalService.show(EmployeeModalComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  getList(search?: string) {
    this.api
      .getList({
        search,
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          console.log(this.currentPageNo, "currentPageNo");
          this.loader = false;
        },
        (error: any) => {
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.getList(inputElement.value);
  }

  handleNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "employee_database",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleShowEditFormModal(item: any) {
    const initialState = {
      initialData: item,
    };

    this.modalService.show(EmployeeFormComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleDocUpload(orderId: string, images: string[]) {
    const initialState = {
      orderId,
      images,
      type: "employee",
    };
    this.modalService.show(InventoryDocUploaderComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  setSelectedInventoryId(id: string) {
    this.selectedInventoryId = id;
  }

  deltedata() {
    const Id = this.selectedInventoryId;
    this.api.deleteData(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
