import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { TimeSheetService } from "../timesheet.service";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";
import { TimesheetModalComponent } from "../timesheet-modal/timesheet-modal.component";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-timesheet-list",
  templateUrl: "./timesheet-list.component.html",
  styleUrls: ["./timesheet-list.component.sass"],
})
export class TimesheetListComponent implements OnInit, OnDestroy {
  isAuthorized: any;

  routeParams = {
    weekly_time_sheet: {
      title: "Weekly Timesheets",
      status: "Weekly Timesheets",
    },
    director_review: {
      title: "Director Review",
      status: "Director Review",
    },
    time_sheet_archive: {
      title: "Timesheet Archive",
      status: "Timesheet Archive",
    },
  };

  status = "";
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  setSelectedInventoryId = "";
  daysEmum = ["unpaid_leave", "sick_leave", "annual_leave"];
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  routeSubscription: Subscription;
  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: TimeSheetService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.deleteComponent();
    // });

    this.isAuthorized = "false";
    this.items = [];
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      this.title = this.routeParams[this.status].title;

      console.log(this.status, "the status");
      if (this.status == "director_review") {
        this.handleUserAuthCheck(
          "/Timesheet/list/director_review",
          "Director Review"
        );
      } else if (this.status == "time_sheet_archive") {
        this.handleUserAuthCheck(
          "/Timesheet/list/time_sheet_archive",
          "Time Sheet Archive Review"
        );
      } else if (this.status == "weekly_time_sheet") {
        this.handleUserAuthCheck(
          "/Timesheet/list/weekly_time_sheet",
          "Weekly Time Sheet"
        );
      }
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    this.items = [];

    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "inventory",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getList();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleEditSupportTicket(ticketId: string) {
    this.router.navigate([`Support/form/${ticketId}`]);
  }

  getHoursForDay(arr: any, str: string): string {
    const data = arr.find((e) => e.day === str);
    let check = this.daysEmum.some((e) => e == data.hours);
    if (check) {
      if (data.hours === "unpaid_leave") return "UL";
      else if (data.hours === "sick_leave") return "SL";
      else if (data.hours === "annual_leave") return "AL";
    } else return data.hours;
  }

  getList(search?: string) {
    if (!this.status) {
      return;
    }
    this.api
      .getList({
        search,
        status: this.status,
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.getList(inputElement.value);
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "time_sheet",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleShowOrderModal(item: any) {
    const initialState = {
      userInfo: item.employee,
      receivedData: item._id,
    };

    this.modalService.show(TimesheetModalComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  handleEditTicket(initialData: any) {
    const initialState = {
      initialData: {
        ...initialData,
        employeeId: initialData.users._id,
      },
    };
    // this.modalService.show(SupportTicketFormComponent, {
    //   initialState,
    //   class: "modal-lg",
    // });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  setSelectedTicketId(id: string) {
    this.selectedTicketId = id;
  }

  deltedata() {
    const Id = this.selectedTicketId;

    this.api.deleteData(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleUpdateStatus(ticketId: string, event) {
    const status = event.target.value;

    this.api.updateStatus(ticketId, { status }).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.status = "";
    this.items = [];
    this.totalPages = 0;
    this.totalRecords = 0;
    this.currentPageNo = 1;
    this.loader = false;
    this.isAuthorized = null;
  }

  deleteComponent() {
    this.status = "";
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
