import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { TemplateService } from '../../templates/template.service';
import { CommonService } from 'src/app/services/common.service';
import { LegendService } from '../legend.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: "app-add-legend",
    templateUrl: "./add-legend.component.html",
    styleUrls: ["./add-legend.component.sass"],
})
export class AddLegendComponent implements OnInit {
    bigpdf = false;
    notpdf;
    legendForm: FormGroup;
    listData: any;
    fileToUpload: File = null;
    userData = {
        image: "",
    };
    editId = true;
    _id = "";
    loader;
    fileToUpload1 = null;
    submitted = false;
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };

    constructor(
        private _fb: FormBuilder,
        private templateService: TemplateService,
        private commonService: CommonService,
        private leg: LegendService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.legendForm = this._fb.group({
            legendText: [null, [Validators.required, Validators.minLength(2)]],
            tempName: [null, [Validators.required]],
            image: [null],
            hidfile: [null, [Validators.required]],
        });
        this.templateService.getTemplate(this.query).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
        if (this.activatedRoute.snapshot.params.legId !== undefined) {
            this.editId = false;
            this._id = this.activatedRoute.snapshot.params.legId;
            this.loadData(this.activatedRoute.snapshot.params.legId);
        }
    }
    get f() {
        return this.legendForm.controls;
    }
    loadData(repId) {
        this.loader = true;
        this.leg.getLegentById(repId).subscribe(
            (result: any) => {
                const items = result.data;
                this.editLegData(items);
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    editLegData(data) {
        this.fileToUpload1 = true;
        this.userData.image = data.file;
        this.legendForm.patchValue({
            legendText: data.legName,
            tempName: data.tempId,
            hidfile: data.file,
        });
    }

    get legendText() {
        return this.legendForm.get("legendText");
    }
    get tempName() {
        return this.legendForm.get("tempName");
    }
    get legendicon() {
        return this.legendForm.get("legendicon");
    }
    get hidfile() {
        return this.legendForm.get("hidfile");
    }

    handleFileInput(files: FileList) {
        this.fileToUpload1 = null;
        this.bigpdf = false;
        this.notpdf = false;
        this.fileToUpload = files.item(0);
        // this.showLoader = true;
        if (files.length === 1) {
            console.log(this.fileToUpload.type);
        }

        if (this.fileToUpload.size >= 250 * 1024) {
            this.bigpdf = true;
            if (!this.fileToUpload.type.match("application/pdf")) {
                this.notpdf = true;
            }
        } else if (!this.fileToUpload.type.match("application/pdf")) {
            this.notpdf = true;
            if (this.fileToUpload.size >= 250 * 1024) {
                this.bigpdf = true;
            }
        } else {
            this.bigpdf = false;
            this.notpdf = false;
            this.commonService
                .fileUpload(this.fileToUpload)
                .subscribe((result: any) => {
                    this.fileToUpload1 = true;
                    this.userData.image = result.data;
                    this.legendForm.patchValue({
                        hidfile: result.data,
                    });
                });
        }
    }
    submitHandler() {
        if (!this.legendForm.invalid) {
            const obj = {
                tempId: this.legendForm.controls.tempName.value,
                legName: this.legendForm.controls.legendText.value,
                file: this.legendForm.controls.hidfile.value,
            };
            this.leg.sendLegent(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Legend added Successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/legends/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            this.submitted = true;
            // this.commonService.toast('Validation error.', 'Error');
        }
    }

    updateLegend() {
        if (!this.legendForm.invalid) {
            const obj = {
                tempId: this.legendForm.controls.tempName.value,
                legName: this.legendForm.controls.legendText.value,
                file: this.legendForm.controls.hidfile.value,
                _id: this._id,
            };
            this.leg.updateLegent(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Legend has been updated successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/legends/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            this.submitted = true;
            // this.commonService.toast('Validation error.', 'Error');
        }
    }
}
