import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private restRequest: RestRequest, ) { }
  getTemplate(query) {
    return this.restRequest.addAuthorization().get('users/templateList',query);
  }
  addTemplate(data) {
    return this.restRequest.addAuthorization().post('users/addTemplate', data);
  }
  viewTemplate(obj) {
    return this.restRequest.addAuthorization().post('users/getTemplateById', obj);
  }
  updateTemplate(data) {
    return this.restRequest.addAuthorization().post('users/editTemplate', data);
  }
  updateArch(obj) {
    return this.restRequest.addAuthorization().post('users/updateArch', obj);
  }
  updateHighlighter(obj) {
    return this.restRequest.addAuthorization().post('users/updateHighlighter', obj);
  }
  updateTempled(id, obj) {
    return this.restRequest.addAuthorization().put('users/updateTemplate/' + id, obj);
  }

  getCauseCount(id) {
    return this.restRequest.addAuthorization().get(`users/getCauseCount?tempId=${id}`);
  }
}
