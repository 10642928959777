import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { BsModalRef } from "ngx-bootstrap";

@Component({
  selector: "app-purchase-order-modal",
  templateUrl: "./purchase-order-modal.component.html",
  styleUrls: ["./purchase-order-modal.component.sass"],
})
export class PurchaseOrderModalComponent implements OnInit {
  item: any;
  APIEndpoint = environment.APIEndpoint;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.item.createdAt = new Date(this.item.createdAt).toLocaleDateString(
      "en-US"
    );
    console.log({ APIEndpoint: this.APIEndpoint });

    this.item.note = this.item.inventoriesNote.length
      ? this.item.inventoriesNote[0].notes
      : "";
  }

  handleCloseModal() {
    this.bsModalRef.hide();
  }
}
