import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  /*@Input() currentPage: number;
  @Input() totalRecords: number;
  @Input() recordsPerPage: number;
  @Input() totalShownPages: number;*/
  @Output() selectPage = new EventEmitter<Number>();

  prevEnabled = true;
  nextEnabled = true;
  // pages: Array<Number>;

  constructor() {
    // this.pages = [];
  }

  ngOnInit() {
    /*let pageCount: number;
    let pageRem: Number;

    pageCount = this.totalRecords / this.recordsPerPage;
    pageRem = this.totalRecords % this.recordsPerPage;

    if (pageRem !== 0) {
      pageCount = pageCount + 1;
    }
    let prevEnabled = true;
    let nextEnabled = true;
    if (this.page === 1) {
      prevEnabled = false;
      if (pageCount > 1) {
        nextEnabled = true;
      }
    }

    if (this.page === pageCount) {
      nextEnabled = false;
      if (pageCount > 1) {
        prevEnabled = true;
      }
    }

    this.prevEnabled = prevEnabled;
    this.nextEnabled = nextEnabled;

    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(i);
    }
    this.pages = pages ;
    console.log(this.pages); */
  }

  getPage(page) {
    this.selectPage.emit(page);
  }

}
