import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "../../../services/common.service";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { InventoryService } from "../inventory.service";

@Component({
  selector: "app-inventory-doc-uploader",
  templateUrl: "./inventory-doc-uploader.component.html",
  styleUrls: ["./inventory-doc-uploader.component.sass"],
})
export class InventoryDocUploaderComponent implements OnInit {
  imageForm: FormGroup;
  isLoading = false;
  images = [];
  loader = false;
  image;
  index;
  companyOverviewId:null;
  orderId: null;
  fileToUpload: File = null;
  fileToUpload1 = null;
  dispimage = [];
  type = "po";

  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private api: InventoryService
  ) {}

  ngOnInit() {
    this.imageForm = this.formBuilder.group({
      imagemain: this.formBuilder.array([this.addNote("")]),
    });

    this.editData({ image: this.images });
  }

  addNote(value: string) {
    return this.formBuilder.group({
      image: [null, Validators.required],
    });
  }
  userNoteAdd() {
    this.noteArr.push(this.addNote(""));
  }
  get noteArr() {
    return this.imageForm.get("imagemain") as FormArray;
  }
  editData(data: any) {
    this.imageForm.setControl("imagemain", this.setNoteFun(data.image));
  }
  setNoteFun(genData: any): FormArray {
    const formArray = new FormArray([]);
    this.fileToUpload1 = true;

    this.image = genData;
    if (genData === "" || genData === undefined) {
      this.fileToUpload1 = null;
    } else {
      this.dispimage = genData;
    }
    if (genData) {
      genData.forEach((s) => {
        formArray.controls.push(
          this.formBuilder.group({
            image: s.image,
          })
        );
      });
    }
    return formArray;
  }

  editData1(data) {
    this.fileToUpload1 = true;

    this.image = data.image;
    if (data.image === "" || data.image === undefined) {
      this.fileToUpload1 = null;
    } else {
      this.dispimage = data.image;
    }
  }

  saveimage() {
    let data = {}
    if(this.type == "company-overview") {
       data = {
        image: this.dispimage.filter((item) => item !== null),
        companyOverviewId:this.companyOverviewId
      }
    }
    else if (this.type == "sales-performance"){
        data = {
         image: this.dispimage.filter((item) => item !== null),
         salesPerformanceId:this.companyOverviewId
     }
    }
    else {
       data = {
        image: this.dispimage.filter((item) => item !== null),
        orderId: this.orderId,
        employeeId: this.orderId,
        type: this.type,
      };
    }
    this.isLoading = true;
    this.api.uploadInventoryDocs(data, this.type).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.commonService.toast(result.message, "Success");
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
        this.isLoading = false;
      }
    );
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  setindex(index: number) {
    this.index = index;
  }

  handleFileInput(files: FileList, index: number) {
    this.fileToUpload = files.item(0);
    this.isLoading = true;

    if (files.length === 1) {
      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.image = result.data;
          this.dispimage[this.index] = result.data;
          this.fileToUpload1 = true;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }

  removeNote(index: number) {
    if (this.noteArr.length === 1) {
      this.noteArr.removeAt(index);
      this.dispimage.splice(index, 1);
    } else {
      this.noteArr.removeAt(index);
      this.dispimage.splice(index, 1);
    }
  }
}
