import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import {DiscountService} from 'src/app/modules/discount/discount.service'
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.sass']
})
export class DiscountComponent implements OnInit {
  loader;
  _id: string;
  nodata = false;
  items;
  filterByTemp = false;
  p = 1;
  searchText = '';
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1
  };
  recordsPerPage: number;
  totalRecords = 0;
  
  constructor(private commonService: CommonService,
              private activatedRoute: ActivatedRoute,
              private discount : DiscountService,
              private router: Router) { 
                this.recordsPerPage = environment.defaultPageSize;
              }

  ngOnInit() {

    this.loader = true;
    
      this.getDiscountList();
    
  }
  public getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }
  getDiscountList(){
    this.query.search = this.searchText;
    this.discount.getDiscountListV2(this.query).subscribe((result: any) => {
      const items = result.data.data;
      this.items = items;
      this.totalRecords = result.data.totalCount;
      this.loader = false;
      this.nodata = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getDiscountList();
  }

  delteDiscount(Id){
    //const obj = {
      //_id: Id
    //};
    this.discount.deleteDiscountData(Id).subscribe((result: any) => {
      this.loader = false;
      if (result.success === true) {
        this.commonService.toast(result.message, 'Success');
      } else {
        this.commonService.toast(result.message, 'Error');
      }
      this.getDiscountList();
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

  navigateAddDiscount() {
    this.router.navigate(['/Discount/add']);
  }

}
