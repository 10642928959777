import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  private otpVerifiedSubject = new BehaviorSubject<boolean>(false);
  otpVerified$ = this.otpVerifiedSubject.asObservable();

  setOtpVerified(status: boolean): void {
    this.otpVerifiedSubject.next(status);
  }
}
