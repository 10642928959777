import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './../../services/user.service';
import { PageTitleService } from '../../../../services/page-title.service';
import { BreadcrumbService } from './../../../../services/breadcrumb.service';
import { CommonService } from '../../../../services/common.service';
import { GetCountryResponse } from '../../../../services/common.interface';
// import * as AWS from 'aws-sdk';

import { UserProfile, GetProfileResponse, UpdateProfileResponse } from './../../interfaces/user';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    profileForm: FormGroup;
    profile_image_data = {};
    profile: UserProfile;
    submitted = false;
    profile_name: String;
    loader: Boolean;
    profile_image_url: String;
    countries = [];
    image_upload: Boolean;
    profileImageData = null;
    @ViewChild('profileImage') profileImage: ElementRef;

    constructor(private pageTitleService: PageTitleService, private breadcrumbService: BreadcrumbService,
                private formBuilder: FormBuilder, private userService: UserService,
                private commonService: CommonService) {
    }

    ngOnInit() {


        this.pageTitleService.setTitle('User Profile');
        this.breadcrumbService.setLinks([]);
        this.image_upload = false;

        this.profileForm = this.formBuilder.group({
            fname: ['', [Validators.maxLength(20), Validators.pattern(/^[A-Za-z\s]+$/)]],
            phone_number: ['', [Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
            image: [''],
            // gender: [''],
            email: [''],
            // address: ['', [Validators.maxLength(100)]],
            // country: ['']
        });

        // this.commonService.getCountries().subscribe((response: GetCountryResponse) => {
        //     this.countries = response.data.countries.map((country) => {
        //         return {id: country._id, name: country.name};
        //     });

        //     this.countries.unshift({id: '', name: 'Select Country'});
        // });
        const authData = JSON.parse(localStorage.getItem('userInfo'));
        const emailStore = authData.email ? authData.email.toString() : '';

        this.userService.getProfile({ email: emailStore }).subscribe((response: GetProfileResponse) => {

            this.loader = false;
            if (response.success === true) {
                const data = response.data;
                
               
                // this.profileImageData = (data.image !== '') ? data.image : null;

                this.profileImageData = (data.file !== '') ? data.file : null;

                this.profileForm.get('fname').setValue(data.name);
                this.profileForm.get('email').setValue(data.email);
                this.profileForm.get('phone_number').setValue(data.phoneNumber);
                this.profileForm.get('image').setValue(data.file);

                // this.profile_name = data.fname;
                // this.profileForm.get('gender').setValue(data.gender);
                this.profile = data;

            } else {
                this.commonService.toast(response.message.toString(), 'Error');
            }
        }, error => {
            this.loader = false;
            this.commonService.toast(error.error.message, 'Error');
        });

    }

    get f() {
        return this.profileForm.controls;
    }

    passwordValidator(group) {
        const password = group.get('password');
        const confirm_password = group.get('confirm_password');
        if (password.value !== confirm_password.value) {
            return confirm_password.setErrors({ notEquivalent: true });
        } else {
            return confirm_password.setErrors(null);
        }
    }

    triggerFileClick() {
        const el: HTMLElement = this.profileImage.nativeElement as HTMLElement;
        el.click();
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            console.log(event.target.files);
            this.profile_image_data = event.target.files[0];
        }
    }

    saveProfile() {
        this.submitted = true;
        this.loader = true;

        if (!this.profileForm.invalid) {
            const profile_data = this.profileForm.value;
            profile_data.image = '';
            this.userService.updateProfile(profile_data).subscribe((response: UpdateProfileResponse) => {
                this.loader = false;
                if (response.success === true) {
                    this.profile.fname = profile_data.fname;
                    if (profile_data.gender === 'male') {
                        this.profile.gender = 'Male';
                    } else if (profile_data.gender === 'female') {
                        this.profile.gender = 'Female';
                    }
                    const profileData: any = response.data;
                    // const userInfo = JSON.parse(localStorage.getItem('userInfo'));

                    // if (response.data.image) {
                    //     this.profile.image = response.data.image;
                    //     userInfo.profile_image = response.data.image;
                    // }
                    // userInfo.profile_name = profileData.fname;
                    // userInfo.profile_gender = profileData.gender;
                    // localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    this.commonService.toast(`Profile updated successfully.`, 'Success');
                } else {
                    this.commonService.toast(response.message.toString(), 'Error');
                }
            }, error => {
                this.loader = false;
                this.commonService.toast(error.error.message, 'Error');
            });
        } else {
            this.loader = false;
        }
    }

    toggleImageUpload() {
        this.image_upload = !this.image_upload;
    }

}
