import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { InventoryService } from "../inventory.service";

@Component({
  selector: "app-internal-order-form",
  templateUrl: "./internal-order-form.component.html",
  styleUrls: ["./internal-order-form.component.sass"],
})
export class InternalOrderFormComponent implements OnInit {
  optionsVisibility = {};
  category = "";
  categories = [];
  products: [];
  selectedProduct = {};
  isValid = false;
  submitted = false;
  addInventoryForm: FormGroup;

  private inventoryData = {
    categoryId: "",
    inventoryItemId: "",
    name: "",
    deliveryPickupAddress: "",
    note: "",
    // "latitude": "",
    // "longitude": "",
    orderQut: null,
  };

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: InventoryService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(() => {
      // this.category = this.route.snapshot.params.category;
      this.getCategories();

      this.addInventoryForm = this._fb.group({
        categoryId: ["", [Validators.required]],
        inventoryItemId: ["", [Validators.required, Validators.minLength(2)]],
        name: ["", Validators.required],
        deliveryPickupAddress: [
          "",
          [Validators.required, Validators.minLength(2)],
        ],
        orderQut: [null, [Validators.required, Validators.min(0)]],
        note: [""],
      });
    });
  }

  get f() {
    return this.addInventoryForm.controls;
  }

  getCategories() {
    this.api.getCategories().subscribe(
      (result: any) => {
        const response = result.data;

        if (response.items) {
          const  temp = response.items;
          this.categories = temp.filter((e) => e.route != 'raw_materials')
          const selectedCategory = response.items[0];
          this.addInventoryForm.patchValue({
            categoryId: selectedCategory._id, // Set the default category id here
          });
          this.inventoryData.categoryId = selectedCategory._id;

          this.handleProductSearch("");
        }
      },
      (error: any) => {
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;

    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  handleProductSearch(event: any) {
    const str = event ? event.target.value : "";

    const formData = this.addInventoryForm.value;
    const data = {
      search: str,
      categoryId: formData.categoryId,
    };

    this.api.getInventories(data).subscribe(
      (result: any) => {
        this.products = result.data.items;
        // this.commonService.toast(result.message, "Success");
      },
      (error: any) => {
        // this.commonService.toast(error.error.message, "Error");
      }
    );

    this.addInventoryForm.patchValue({
      inventoryItemId: "",
    });
    this.selectedProduct = {};
  }

  handleProductSelect(item: any) {
    this.selectedProduct = item;

    this.addInventoryForm.patchValue({
      inventoryItemId: item._id,
    });
  }

  handleSubmit() {
    this.submitted = true;

    if (this.addInventoryForm.valid) {
      const formData = this.addInventoryForm.value;

      this.api.addInternalOrder(formData).subscribe(
        (result: any) => {
          this.addInventoryForm.patchValue(this.inventoryData);
          this.commonService.toast(result.message, "Success");
          this.submitted = false;
          this.router.navigate(["/Inventory/IO/list/new_io"]);
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
