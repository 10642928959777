import { Component, OnInit } from "@angular/core";
import * as jwt_decode from "jwt-decode";
import { Router } from "@angular/router";
import { AuthService } from "./../../modules/auth/services/auth.service";
import {
  SignoutRequest,
  SignoutResponse,
} from "./../../modules/auth/interfaces/auth";
import { ToastrManager } from "ng6-toastr-notifications";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-header-dashboard",
  templateUrl: "./header-dashboard.component.html",
  styleUrls: ["./header-dashboard.component.css"],
})
export class HeaderDashboardComponent implements OnInit {
  profile_image: String;
  profile_name: String;
  profile_email: String;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrManager,
    private jwtHelperService: JwtHelperService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    const authData = JSON.parse(localStorage.getItem("userInfo"));

    // if (!this.jwtHelperService.isTokenExpired(localStorage.getItem('currentSession'))) {
    /*const authData = jwt_decode(localStorage.getItem('currentSession'));
    console.log(authData);
    this.profile_image = authData.image;
    this.profile_name = authData.name;
    this.profile_email = authData.email;
    */
    // console.log(localStorage.getItem('userInfo'));

    this.profile_image = authData.profileImage
      ? authData.profileImage.toString()
      : "";
    this.profile_name = authData.name ? authData.name.toString() : "";
    this.profile_email = authData.email ? authData.email.toString() : "";

    // }
  }

  logout() {
    localStorage.removeItem("currentSession");
    sessionStorage.removeItem("ivu");
    this.router.navigate(["/login"]);
    this.commonService.toast("Logout successfully", "Success");
  }
}
