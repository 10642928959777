import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EmmailService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getEmailBody(mailType: any) {
    return this.restRequest.addAuthorization().post(`mailTemplate`, mailType);
  }

  updateEmailBody(emailData: any, id: string): Observable<any> {
    return this.restRequest
      .addAuthorization()
      .put(`mailTemplate/${id}`, emailData);
  }
}
