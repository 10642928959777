import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualityassuranceopenComponent } from './qualityassuranceopen/qualityassuranceopen.component';
import { QualityassurancependingComponent } from './qualityassurancepending/qualityassurancepending.component';
import { QualityassurancecheckedComponent } from './qualityassurancechecked/qualityassurancechecked.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { QnotesmodalComponent } from './qnotesmodal/qnotesmodal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ViewmodalComponent } from './viewmodal/viewmodal.component';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [QualityassuranceopenComponent, QualityassurancependingComponent, QualityassurancecheckedComponent, QnotesmodalComponent, ViewmodalComponent],
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    PipesModule,
    ModalModule.forRoot(),
  ],
  entryComponents: [ QnotesmodalComponent, ViewmodalComponent ]
})
export class QualityassuranceModule { }
