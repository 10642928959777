import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from '../template.service';


@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.sass']
})
export class AddTemplateComponent implements OnInit {

  templateForm: FormGroup;
  fileToUpload: File = null;
  userData = {
    image: ''
  };
  submitted = false;
  loader = false;
  fileToUpload1 = null;


  constructor(
    private router: Router,
    private templateService: TemplateService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,

  ) { }


  ngOnInit() {
    this.templateForm = this.formBuilder.group({
      tempName: ['', Validators.required],
      image: [null],
      repMain: this.formBuilder.array([this.addRepairMethod()]),
      noteMain: this.formBuilder.array([this.addNote()]),
      hidfile: ['', Validators.required]
    });
  }
  addRepairMethod() {
    return this.formBuilder.group({
      repairMethod: [null, Validators.required],
    });
  }
  addRep() {
    this.repArray.push(this.addRepairMethod());
  }
  removeRep(index) {
    if ((this.repArray).length === 1) {
      this.commonService.toast('Atleast one Repair Recommendation is required.', 'Error');
    } else {
      this.repArray.removeAt(index);
    }
  }
  get repArray() {
    return this.templateForm.get('repMain') as FormArray;
  }
  get tempName() {
    return this.templateForm.get('tempName');
  }
  addNote() {
    return this.formBuilder.group({
      general: [null, Validators.required],
    });
  }
  userNoteAdd() {
    this.noteArr.push(this.addNote());
  }
  removeNote(index) {
    if ((this.noteArr).length === 1) {
      this.commonService.toast('Atleast one general note is required.', 'Error');
    } else {
      this.noteArr.removeAt(index);
    }
  }
  get noteArr() {
    return this.templateForm.get('noteMain') as FormArray;
  }
  handleFileInput(files: FileList) {
    //this.loader = true;
    this.fileToUpload = files.item(0);
    this.commonService.fileUpload(this.fileToUpload).subscribe((result: any) => {
      this.userData.image = result.data;
      this.fileToUpload1 = true;
      this.templateForm.patchValue({
        hidfile: result.data
      });
      this.loader = false;
    });
  }

   get f() {
    return this.templateForm.controls;
  }


  get hidfile() {
    return this.templateForm.get('hidfile');
  }

  submitHandler() {
    if (this.templateForm.valid) {
      const formData = { ...this.templateForm.value };
      const obj = {
        templateName: formData.tempName,
        repairRecommend: formData.repMain,
        generalNote: formData.noteMain,
        projectAddress: '',
        jobNumber: '',
        date: '',
        defaultRepairMethod: [],
        legends: [],
        defaultCause: [],
        imageUrl: this.templateForm.controls.hidfile.value
      };
      this.templateService.addTemplate(obj).subscribe((res) => {
        this.router.navigate(['/template/list']);
        this.commonService.toast('Template added Successfully.', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    } else {
      this.submitted = true;
      //this.commonService.toast('Please fill the required input.', 'Error');
    }
  }
}
