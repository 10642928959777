import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { HumanResourceService } from "../human-resource.service";
import * as moment from "moment";
import { IncidentPopComponent } from "../incident-pop/incident-pop.component";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";

@Component({
  selector: "app-incident",
  templateUrl: "./incident.component.html",
  styleUrls: ["./incident.component.sass"],
})
export class IncidentComponent implements OnInit {
  items = [];
  status: any;
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  modalRef: BsModalRef;
  viewItem: any;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private modalService: BsModalService,
    private api: HumanResourceService,
    private route: ActivatedRoute
  ) {}

  title = {
    new: "New Incident Report",
    archived: "Archived Incident Report",
  };

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      this.getList();
    });
  }

  setViewItem(item: any) {
    const copiedItem = JSON.parse(JSON.stringify(item));

    const initialState = {
      items: copiedItem,
    };

    this.modalService.show(IncidentPopComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  getDate(date: any) {
    if (date) return moment(date).format("DD/MM/YYYY");
    return "-";
  }
  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "incident_report",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  getList() {
    this.loader = true;
    this.api
      .incidentReport({
        page: this.currentPageNo,
        status: this.status,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handleUpdateStatus(Id: string, event) {
    const status = event.target.value;

    this.api.updateIncidentReport(Id, { status }).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
