import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { UserListComponent } from './user-list/user-list.component';
import { UtilityModule } from '../utility/utility.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TagInputModule } from 'ngx-chips';
import { UserEmployeeViewComponent } from './user-employee-view/user-employee-view.component';
import { UserProjectViewComponent } from './user-project-view/user-project-view.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserViewComponent } from './user-view/user-view.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    UserListComponent,
    UserEmployeeViewComponent,
    UserProjectViewComponent,
    EmployeeDetailsComponent,
    ProjectDetailsComponent,
    UserViewComponent
  ],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UtilityModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    TagInputModule,
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule
  ]
})
export class UserListModule { }
