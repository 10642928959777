import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { EmmailService } from "./emai-modal.service";
import { CommonService } from "src/app/services/common.service";
// import { EmailService } from './email.service';
@Component({
  selector: "app-email-model",
  templateUrl: "./email-model.component.html",
  styleUrls: ["./email-model.component.sass"],
})
export class EmailModelComponent implements OnInit {
  mailType: any;
  emailData: any;
  mailSubject: any;
  selectedEmailID: any;
  loading = true;
  constructor(
    private bsModalRef: BsModalRef,
    private api: EmmailService,
    private commonService: CommonService,
    private emailService: EmmailService
  ) {}

  ngOnInit() {
    console.log(this.mailType, "the email we recerived");
    this.getEmailData();
  }

  onEmailDataChange(newEmail: string) {
    this.emailData = newEmail;
  }

  getEmailData() {
    const dataToSend = { mailType: this.mailType };
    this.api.getEmailBody(dataToSend).subscribe(
      (response: any) => {
        console.log(response, "Email data retrieved successfully");
        console.log(response.data.result.mailBody, "-");
        this.emailData = response.data.result.mailBody;
        this.mailSubject = response.data.result.mailSubject;
        this.selectedEmailID = response.data.result._id;
        this.loading = false;
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  cancelModal() {
    this.bsModalRef.hide();
  }

  handleSubmit() {
    try {
      const sendData = {
        mailBody: this.emailData,
        mailSubject: this.mailSubject,
      };

      this.emailService
        .updateEmailBody(sendData, this.selectedEmailID)
        .subscribe(
          (response) => {
            this.loading = false;
            this.cancelModal();
            this.commonService.toast("Email Update Successfully", "Success");
          },
          (error) => {
            this.commonService.toast(error.error.message, "Error");
          }
        );
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
