import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from '../filter.pipe';
import { SafeHtml } from './safehtml.pipe';

@NgModule({
  declarations: [SafePipe,SafeHtml],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    SafeHtml
  ]
})
export class PipesModule { }
