import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PageTitleService } from '../../../../services/page-title.service';
import { BreadcrumbService } from './../../../../services/breadcrumb.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  constructor(private pageTitleService: PageTitleService, private breadcrumbService: BreadcrumbService ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.pageTitleService.setTitle('Dashboard');
    this.breadcrumbService.setLinks([]);
  }
  

}
