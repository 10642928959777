import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-administrative-modal",
  templateUrl: "./administrative-modal.component.html",
  styleUrls: ["./administrative-modal.component.sass"],
})
export class AdministrativeModalComponent implements OnInit {
  administrativeData: any;
  constructor(
    private bsModalRef: BsModalRef

  ) {}

  ngOnInit() {
    this.administrativeData.uploadDate = moment(this.administrativeData.uploadDate).format('DD-MM-YYYY')

  }

  cancelModal() {
    this.bsModalRef.hide();
  }
}
