import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-thankyou-modal',
  templateUrl: './thankyou-modal.component.html',
  styleUrls: ['./thankyou-modal.component.sass']
})
export class ThankyouModalComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  decline(): void {
    this.bsModalRef.hide();
  }

}
