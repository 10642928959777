import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';
import { PieSeriesService} from '@syncfusion/ej2-angular-charts';
import { ChartsModule } from 'ng2-charts';
import { DatarepresentationComponent } from './datarepresentation/datarepresentation.component';
import { LinebarrepresentationComponent } from './linebarrepresentation/linebarrepresentation.component';
import { MultilineComponent } from './multiline/multiline.component';

@NgModule({
  declarations: [AnalyticsComponent, DatarepresentationComponent, LinebarrepresentationComponent, MultilineComponent],
  imports: [
    CommonModule,
    AccumulationChartModule,
    ChartsModule
  ],
  providers: [PieSeriesService]
})
export class AnalyticsModule { }
