import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { SupportTicketService } from "../support-ticket.service";
// import { InventoryNotesComponent } from "../inventory-notes/inventory-notes.component";
// import { Inventory } from "../Inventory";
// import { InventoryService } from "../inventory.service";
import { CommonService } from "src/app/services/common.service";
import { SupportTicketNotesComponent } from "../support-ticket-notes/support-ticket-notes.component";
import { SupportTicketPopupComponent } from "../support-ticket-popup/support-ticket-popup.component";
import { SupportTicketFormComponent } from "../support-ticket-form/support-ticket-form.component";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
// import { PurchaseOrderModalComponent } from "../purchase-order-modal/purchase-order-modal.component";
// import { InventoryDocUploaderComponent } from "../inventory-doc-uploader/inventory-doc-uploader.component";

@Component({
  selector: "app-support-ticket-list",
  templateUrl: "./support-ticket-list.component.html",
  styleUrls: ["./support-ticket-list.component.sass"],
})
export class SupportTicketListComponent implements OnInit {
  isAuthorized :'true';

  routeParams = {
    new_ticket: {
      title: "New Tickets",
      status: "New",
    },
    in_progress: {
      title: "In Progress",
      status: "In Progress",
    },
    for_analysis: {
      title: "For Analysis",
      status: "Analysis",
    },
    done: {
      title: "Done",
      status: "Done",
    },
  };

  status = "";
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  routeSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: SupportTicketService,
    private commonService: CommonService,
    private router: Router,
    
  ) {}

  ngOnInit() {
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(() => {
    //   this.deleteComponent(); 
    // });
    // this.isAuthorized = 'false'
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      
      // if(this.status == 'new_ticket') {
      //   this.handleUserAuthCheck('/Support/list/new_ticket','New Ticket')
      // }
      // else if(this.status == 'in_progress') {
      //   this.handleUserAuthCheck('/Support/list/in_progress','Progess Ticket')
      // }
      // else if (this.status == 'for_analysis') {
      //   this.handleUserAuthCheck('/Support/list/for_analysis','Analysis Ticket')
      // }
      // else if (this.status == 'done'){
      //   this.handleUserAuthCheck('/Support/list/done','Done Ticket')
      // }
      this.title = this.routeParams[this.status].title;

      this.getList();
    });
  }

  // handleUserAuthCheck(url:any,name:string) {
  //   this.items=[]
    
  //   sessionStorage.setItem("ivu", "false");
  //     const initialState = {
  //       component: "inventory",
  //       url,
  //       name
  //     };
  //     this.modalRef = this.modalService.show(PasswordCheckerComponent, {
  //       initialState,
  //       class: "modal-lg",
  //     });
  //     this.modalSubscription = this.modalService.onHide.subscribe(() => {
  //       this.isAuthorized = sessionStorage.getItem("ivu")
  //       if (this.isAuthorized == 'true') {
  //         this.loader =  true
  //         this.getList();
  //       }else{
  //         this.router.navigate(["users/list"]);
  //       }
  //     });
  // }


  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  handleEditSupportTicket(ticketId: string) {
    this.router.navigate([`Support/form/${ticketId}`]);
  }

  getList(search?: string) {
    if (!this.status) {
      return
    }
    this.loader = true
    this.api
      .getSupportTicketList({
        search,
        status: this.status,
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false
        }
      );
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.getList(inputElement.value);
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      // showAddAndDeleteBtn: false,
    };
    this.modalService.show(SupportTicketNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleShowOrderModal(item: any) {
    const initialState = {
      item,
    };

    this.modalService.show(SupportTicketPopupComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  handleEditTicket(initialData: any) {
    const employeeId = initialData.users ? initialData.users._id : '';

    const initialState = {
      initialData: {
        ...initialData,
        employeeId: employeeId,
      },
    };
    this.modalService.show(SupportTicketFormComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  setSelectedTicketId(id: string) {
    this.selectedTicketId = id;
  }

  deltedata() {
    const Id = this.selectedTicketId;

    this.api.deleteTicket(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleUpdateStatus(ticketId: string, event) {
    const status = event.target.value;

    this.api.updateTicketStatus(ticketId, { status }).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.items = [];
    this.totalPages = 0;
    this.totalRecords = 0;
    this.currentPageNo = 1;
    this.loader = false;
    this.isAuthorized = null;
    this.status = ''
   
  }
  deleteComponent() {  
  
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
