import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { ForgetPasswordResponse } from './../../interfaces/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../services/common.service';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  submitted = false;
  forgetPasswordForm: FormGroup;
  loader: Boolean;
  constructor(private router: Router,
              private authService: AuthService,
              private formBuilder: FormBuilder,
              public commonService: CommonService
  ) { }

  ngOnInit() {

    this.loader = false;
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  get f() {
    return this.forgetPasswordForm.controls;
  }

  sendForgotPasswordDetails() {
    this.submitted = true;
    this.loader = true;

    if (!this.forgetPasswordForm.invalid) {
      this.authService.sendForgetPasswordDetails({
        email: this.forgetPasswordForm.controls.email.value
      }).subscribe((result: any) => {
        this.loader = false;
        this.commonService.toast(`We have sent a reset password link. Please check your mail.`, 'Success');
        this.router.navigate(['/login']);
      }, error => {
        this.loader = false;
        this.commonService.toast(error.error.message, 'Error');
      });
    } else {
      this.loader = false;
    }
  }

}
