import { Injectable } from "@angular/core";
import { RestRequest } from "../../../services/rest-request";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserListService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getUserList(query) {
    return this.restRequest.addAuthorization().get("users/getuserlist", query);
  }
  updateUser(userId, payload) {
    return this.restRequest.addAuthorization().put("user/" + userId, payload);
  }
  getUser(userId) {
    return this.restRequest.addAuthorization().get("users/" + userId);
  }
  sendAccessKey(data) {
    return this.restRequest
      .addAuthorization()
      .post("users/sendAccessKey", data);
  }

  createUser(obj) {
    return this.restRequest.post("users/createUser", obj);
  }
  getUserById(userId) {
    return this.restRequest
      .addAuthorization()
      .get("users/getUserDataById/" + userId);
  }
  updateUserDetails(obj) {
    return this.restRequest.addAuthorization().post("users/editUser", obj);
  }

  updateUserStatus(id, payload) {
    return this.restRequest
      .addAuthorization()
      .put("users/updateUser/" + id, payload);
  }
  updateUserEngineer(id, payload) {
    return this.restRequest
      .addAuthorization()
      .put("users/updateUserEngineer/" + id, payload);
  }

  inActiveUser(id) {
    return this.restRequest
      .addAuthorization()
      .put("users/inactive_user?userId=" + id, { active: false });
  }
}
