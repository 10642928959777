import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { RepairService } from '../repair.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from '../../templates/template.service';



@Component({
    selector: "app-add-repair",
    templateUrl: "./add-repair.component.html",
    styleUrls: ["./add-repair.component.sass"],
})
export class AddRepairComponent implements OnInit {
    listData: any;
    repForm: FormGroup;
    submitted: boolean;
    editId = true;
    _id = "";
    userData = {
        image: "",
    };
    fileToUpload: File = null;
    notpdf;
    loader;
    fileToUpload1 = null;
    dropVal = null;
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };

    constructor(
        private router: Router,
        private repairService: RepairService,
        public commonService: CommonService,
        private _fb: FormBuilder,
        private templateService: TemplateService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.repForm = this._fb.group({
            repSolution: [null, [Validators.required, Validators.minLength(2)]],
            //  repairImage: [null, [Validators.required]],
            image: [null],
            hidfile: [null, [Validators.required]],
            tempName: [null, [Validators.required]],
        });
        this.templateService.getTemplate(this.query).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
        if (this.activatedRoute.snapshot.params.repId !== undefined) {
            this.editId = false;
            this._id = this.activatedRoute.snapshot.params.repId;
            this.loadData(this.activatedRoute.snapshot.params.repId);
        }
    }

    get tempName() {
        const temp = this.repForm.controls.tempInfo as FormGroup;
        return temp.controls.tempName;
    }

    get hidfile() {
        return this.repForm.get("hidfile");
    }
    get repSolution() {
        return this.repForm.get("repSolution");
    }

    loadData(repId) {
        this.loader = true;
        this.repairService.getRepairDataById(repId).subscribe(
            (result: any) => {
                const items = result.data;
                this.editRepData(items);
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    handleFileInput(files: FileList) {
        this.fileToUpload1 = null;
        this.notpdf = false;
        this.fileToUpload = files.item(0);
        if (!this.fileToUpload.type.match("application/pdf")) {
            this.notpdf = true;
        } else {
            this.notpdf = false;
            this.commonService
                .fileUpload(this.fileToUpload)
                .subscribe((result: any) => {
                    this.fileToUpload1 = true;
                    this.userData.image = result.data;
                    this.repForm.patchValue({
                        hidfile: result.data,
                    });
                });
        }
    }

    editRepData(data) {
        this.fileToUpload1 = true;
        this.userData.image = data.repairImage;
        this.repForm.patchValue({
            repSolution: data.repairSolution,
            hidfile: data.repairImage,
            tempName: data.tempId,
        });
    }

    submitHandler() {
        if (!this.repForm.invalid) {
            this.submitted = false;
            const obj = {
                repairSolution: this.repForm.controls.repSolution.value,
                repairImage: this.repForm.controls.hidfile.value,
                tempId: this.repForm.controls.tempName.value,
            };

            this.repairService.createRepair(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Repair Method added Successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/repair/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            this.submitted = true;
        }
    }

    get f() {
        return this.repForm.controls;
    }

    updateRepair() {
        this.submitted = true;
        if (!this.repForm.invalid) {
            const obj = {
                repairSolution: this.repForm.controls.repSolution.value,
                repairImage: this.repForm.controls.hidfile.value,
                tempId: this.repForm.controls.tempName.value,
                _id: this._id,
            };
            this.repairService.updateRepairData(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Repair Method has been updated successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/repair/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            console.log("Validation error");
        }
    }
}
