import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { ResetPasswordRequest, ResetPasswordResponse } from './../../interfaces/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../../services/common.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  phone_number: String;
  submitted = false;
  verifyOtpForm: FormGroup;
  loader: Boolean;
  show_password: Boolean;
  showConfirmPassword: Boolean;
  constructor(private router: Router,
              private authService: AuthService,
              private formBuilder: FormBuilder,
              public commonService: CommonService,private activatedRoute: ActivatedRoute) {

    // if (this.authService.isAuthenticated()) {
    //   this.router.navigate(['dashboard']);
    //   this.show_password = false;
    //   this.showConfirmPassword = false;
    // }
  }

  ngOnInit() {
    this.loader = false;
    this.verifyOtpForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/)]],
      confirm_password: ['', [Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/)]],
    });
  }

  get f() {
    return this.verifyOtpForm.controls;
  }
  togglePasswordView() {
    if (this.show_password === true) {
      this.show_password = false;
    } else {
      this.show_password = true;
    }
  }
  toggleConfirmPasswordView() {
    if (this.showConfirmPassword === true) {
      this.showConfirmPassword = false;
    } else {
      this.showConfirmPassword = true;
    }
  }
  verifyOtp() {
    // this.loader = true;
    // console.log('In Auth');
    // if (!this.verifyOtpForm.invalid) {
    //   this.authService.verifyOtp({
    //     phone_number: this.phone_number,
    //     otp: this.verifyOtpForm.controls.otp.value,
    //     device_type: '',
    //     device_token: '',
    //     user_type: 2
    //   }).subscribe((response: VerifyOtpResponse) => {
    //     this.loader = false;
    //     if (response.success === true) {
    //       this.commonService.toast('OTP has been successfully verified.', 'Success');
    //       localStorage.setItem('currentSession', response.data.token.toString());
    //       this.router.navigate(['/profile-setup']);
    //     } else {
    //       if (response.data.valid_otp === false) {
    //         this.commonService.toast('The OTP you have entered is Invalid ', 'Error');
    //       } else {
    //         this.commonService.toast(response.message.toString(), 'Error');
    //       }

    //     }
    //   }, error => {
    //     this.loader = false;
    //     this.commonService.toast(error.error.message, 'Error');
    //   });
    // } else {
    //   this.loader = false;
    // }
    this.submitted = true;
    if (this.submitted) {
      this.loader = true;
      if (!this.verifyOtpForm.invalid) {
        const resetPassword: ResetPasswordRequest = {
          newPassword: this.verifyOtpForm.controls.password.value,
          cPassword: this.verifyOtpForm.controls.password.value,
          token: this.activatedRoute.snapshot.params.id
        }
        this.authService.resetPassword(resetPassword).subscribe((result: any) => {
          const responseData: any = result;
          this.loader = false;
          this.router.navigate(['/login']);
          this.commonService.toast('Password reset successfully', 'Success');
        }, error => {
          const errorData = error.error;
          this.loader = false;
          this.commonService.toast(errorData.message, 'Error');
        });
      } else {
        this.loader = false;
      }
    }
  }



}
