import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddQuoteComponent } from './add-quote/add-quote.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { QuoteListComponent } from './quote-list/quote-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [AddQuoteComponent, QuoteListComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    Ng2SearchPipeModule
  ]
})
export class QuotesModule { }
