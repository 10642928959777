import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { InventoryService } from "../inventory.service";
import { InventoryLinksModalComponent } from "../inventory-links-modal/inventory-links-modal.component";

@Component({
  selector: "app-inventory-links",
  templateUrl: "./inventory-links.component.html",
  styleUrls: ["./inventory-links.component.sass"],
})
export class InventoryLinksComponent implements OnInit {
  inventoryId = "";
  items = [];
  loader = false;
  link = "";
  showAddAndDeleteBtn = true;
  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private modalService: BsModalService,
    private api: InventoryService
  ) {}

  ngOnInit() {
    this.getInvetoryLink();
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  getInvetoryLink() {
    this.api.getLinks(this.link,this.inventoryId).subscribe(
      (result: any) => {
        const items = result.data.links;
        this.items = items;
        this.loader = false;
        this.showAddAndDeleteBtn = this.showAddAndDeleteBtn || items.length < 1;
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleLinkAddorUpdate() {
    const initialState = {
      companyOverviewId: this.inventoryId,
      link:this.link,
    };

    this.modalService.show(InventoryLinksModalComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
    this.modalService.onHidden.subscribe(() => {
      this.getInvetoryLink();
    });
  }
  handleLinkUpdate(index:any,content:any) {
    const initialState = {
      companyOverviewId: this.inventoryId,
      index,
      content,
      isEdit:true,
      link:this.link
    };
    this.modalService.show(InventoryLinksModalComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
    this.modalService.onHidden.subscribe(() => {
      this.getInvetoryLink();
    });


  }

  openLinkInNewTab(link: string) {
    window.open(link, '_blank');
  }

  handleNoteDelete(item: any) {
    let data = { 
      link : item
    }
    this.api.deleteLinks(this.link,this.inventoryId,data).subscribe(
      (result: any) => {
        this.getInvetoryLink();
        this.commonService.toast(result.message || "Note Deleted", "Success");
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
