import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { CompanyOverviewService } from "./company-overview.service";
import * as moment from "moment";
import { CommonService } from "src/app/services/common.service";
import { result } from "lodash";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InventoryLinksComponent } from "../inventory/inventory-links/inventory-links.component";
import { InventoryDocUploaderComponent } from "../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MomentumIndicatorService } from "@syncfusion/ej2-angular-charts";
import { PasswordCheckerComponent } from "../inventory/password-checker/password-checker.component";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "app-company-overview",
  templateUrl: "./company-overview.component.html",
  styleUrls: ["./company-overview.component.sass"],
})
export class CompanyOverviewComponent implements OnInit, OnDestroy {
  @ViewChild("confirmdeleteModal") confirmdeleteModal: ElementRef;
  @ViewChild("closebutton") closebutton;
  @ViewChild("closeProfit") closeProfit;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  constructor(
    private api: CompanyOverviewService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private router: Router
  ) {}
  isAuthorized: any;
  formData: FormGroup;
  profitformData: FormGroup;
  showEditFields = [
    "1 Day",
    "1 Week",
    "1 Month (30 days)",
    "3 Months (90 days)",
    "6 Months (180 days)",
    "1 Year",
  ];
  private initialEditData = {
    target: [
      {
        key: "one_day",
        value: "",
      },
      {
        key: "one_week",
        value: "",
      },
      {
        key: "one_month",
        value: "",
      },
      {
        key: "three_months",
        value: "",
      },
      {
        key: "six_months",
        value: "",
      },
      {
        key: "one_year",
        value: "",
      },
    ],
  };
  loader = false;
  SelectedId = null;
  overallHealthScore: number = 30;
  loading = true;
  overviewItem: any;
  filterField: string = "one_week";
  currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
  currentEndDate = moment().format("YYYY-MM-DD");
  previousStateDate = moment(this.currentStartDate)
    .subtract(1, "weeks")
    .format("YYYY-MM-DD");
  previusEndDate = moment(this.previousStateDate)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  isProfit = false;
  item = [
    {
      label: "Today",
      value: "one_day",
    },
    {
      label: "Week",
      value: "one_week",
    },
    {
      label: "1 Month",
      value: "one_month",
    },
    {
      label: "3 Month",
      value: "three_months",
    },
    {
      label: "6 Month",
      value: "six_months",
    },
    {
      label: "1 year",
      value: "one_year",
    },
  ];
  targetArr = [
    "one_day",
    "one_week",
    "one_month",
    "three_months",
    "six_months",
    "one_year",
  ];

  ngOnInit() {
    this.DeleteIconComponent();
    this.isAuthorized = "false";
    this.initForm();
    this.handleUserAuthCheck("Dashboard/companyoverview", "Company Overview");
  }

  setEditData(array: any) {}
  initForm() {
    this.formData = this._fb.group({
      target: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
    this.profitformData = this._fb.group({
      operatingExpenses: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    this.overviewItem = [];

    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "sales_performance",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getoverviewData();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  createEntryGroup(entry: any): FormGroup {
    return this._fb.group({
      key: entry.key,
      value: [entry.value],
    });
  }

  getoverviewData() {
    this.api
      .getoverview(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.filterField
      )
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.overviewItem = response.items;
          this.overallHealthScore = response.overallHealthScore.toFixed();
          this.loading = false;
          this.updateCSSVariables(this.overallHealthScore);
        },
        (error: any) => {
          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  updateCSSVariables(data: any) {
    const progressBar = document.querySelector(
      'div[role="progressbar"]'
    ) as HTMLElement;
    if (progressBar) {
      progressBar.style.setProperty("--value", `${data}`);
    }
  }
  getTargetValue(arr: any, fieldKey: any) {
    let temp = arr.filter(({ key, value }) => {
      if (key === this.filterField) {
        if (fieldKey == "Sales" || fieldKey == "Employee satisfaction" || fieldKey == "Revenue" || fieldKey == "Profit" )
          return parseFloat(value).toFixed(2);
        else return value;
      }
    });
    if (fieldKey == "Sales" || fieldKey == "Employee satisfaction" || fieldKey == "Revenue" || fieldKey == "Profit")
      return parseFloat(temp[0].value).toFixed(2);
    else return temp[0].value;
  }

  getTrendIcon(current: number, previous: number, key: any): string {
    if (key == "Incomplete jobs" || key == "Complaints")
      return current < previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
    else
      return current > previous
        ? "fas fa-arrow-alt-circle-up text-green"
        : "fas fa-arrow-alt-circle-down text-red";
  }

  handleSelectSearch(e: any, v: any) {
    this.loading = true;
    this.updateCSSVariables(0);
    if (e === "one_week") {
      this.filterField = "one_week";
      this.currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "weeks")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    } else if (e == "one_day") {
      this.filterField = "one_day";
      this.currentStartDate = moment().format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    } else if (e === "one_month") {
      this.filterField = "one_month";
      this.currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (e === "three_months") {
      this.filterField = "three_months";
      let currentDate = moment();
      let currentQuarter = Math.ceil(currentDate.month() / 3);
      this.currentStartDate = moment()
        .quarter(currentQuarter)
        .startOf("quarter")
        .format("YYYY-MM-DD"); // got write date
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(3, "month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    } else if (e === "six_months") {
      this.filterField = "six_months";
      let currentDate = moment();
      let currentSixMonthPeriod = Math.ceil(currentDate.month() / 6);
      // let currentSixMonthPeriod = 2;
      if (currentSixMonthPeriod == 1) {
        let previousYear = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.previousStateDate = moment(previousYear)
          .add(6, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(6, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        this.previousStateDate = moment().startOf("year").format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(5, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
    } else {
      this.filterField = "one_year";
      this.currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    this.getoverviewData();
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "company_overview",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  handleLinksModal(links: any, inventoryId: any) {
    const initialState = {
      items: links,
      inventoryId,
      link: "company-overview",
    };
    this.modalService.show(InventoryLinksComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.loader = true;
      this.getoverviewData();
    });
  }
  handleDocUpload(companyOverviewId: string, images: string[]) {
    const initialState = {
      companyOverviewId,
      images,
      type: "company-overview",
    };
    this.modalService.show(InventoryDocUploaderComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  handleEditTarget() {
    const formData = { target: this.formData.value.target };
    this.api.editTarget(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getoverviewData();
        this.formData.reset();
        this.closebutton.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  handleEditProfitTarget() {
    const formData = {
      operatingExpenses: this.profitformData.value.operatingExpenses,
    };
    this.api.addOperatingExpense(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getoverviewData();
        this.profitformData.reset();
        this.closeProfit.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  setSelectedTicketId(id: any, key: any, array: any ,isOp :boolean) {
    this.SelectedId = id;
    this.isProfit = isOp;
    for (const item of array) {
      const targetItem = this.initialEditData.target.find(
        (obj) => obj.key === item.key
      );
      if (targetItem) {
        targetItem.value = item.value;
      }
    }
    console.log(this.isProfit,'this.isProfit')
    this.isProfit
      ? (this.profitformData = this._fb.group({
          operatingExpenses: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }))
      : (this.formData = this._fb.group({
          target: this._fb.array(
            this.initialEditData.target.map((entry) =>
              this.createEntryGroup(entry)
            )
          ),
        }));
  }

  getTargetValueProfit(data: any) {
    let temp = data.filter(({ key, value }) => {
      if (key === this.filterField) {
        return parseFloat(value).toFixed(2);
      }
    });
    return parseFloat(temp[0].value).toFixed(2);
  }

  closeModal() {
    const modal: any = this.confirmdeleteModal.nativeElement;
  }

  getRsSing(data: any) {
    if (["Revenue", "Profit", "Sales"].includes(data)) return "$";
    else "";
  }
  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
  DeleteIconComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
