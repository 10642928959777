import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";

@Injectable({
  providedIn: "root",
})
export class DocumentServices {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  addAdministrative(data: any) {
    return this.restRequest.addAuthorization().post("document", data);
  }
  editAdministrative(id: any, data: any) {
    return this.restRequest.addAuthorization().put(`document/${id}`, data);
  }
  deleteAdministrative(id: any) {
    return this.restRequest.addAuthorization().delete(`document/${id}`);
  }
  getAdministrative(data: any) {
    return this.restRequest
      .addAuthorization()
      .get(
        `/documents?search=${data.search}&page=${data.page}&documentType=${data.documentType}`
      );
  }
}
