import { Component, OnInit,ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { AnalyticsService } from './../analytics.service'
import { CommonService } from '../../../services/common.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PasswordCheckerComponent } from '../../inventory/password-checker/password-checker.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';



@Component({
  selector: 'app-linebarrepresentation',
  templateUrl: './linebarrepresentation.component.html',
  styleUrls: ['./linebarrepresentation.component.sass']
})
export class LinebarrepresentationComponent implements OnInit,OnDestroy {
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  datalabel: any;
  tbook: any;
  isAuthorized:any
  tbsent: any;
  joh:any;
  joc:any;
  enableSmartLabels: any;
  totalcount: any;
  loader = true;
  items: any;
  chartReady: any;
  act: any;
  nsw: any;
  queens: any;
  vict: any;
  tas: any;
  wa: any;
  sa: any;
  offered: any;
  rejected: any;
  rejectedwa: any;
  rejectedws: any;
  S;
  WC;
  SFP;
  ALF;
  LW;
  RD;
  SC;
  WCFC;
  DP;
  DV;
  SB;
  WCFP;
  other;
  datepie1: any;
  datedata1: any;
  datedata2: any;
  datepie2: any;
  //items: any;
  date1: any;
  date11: any;
  date2: any;
  date22: any;
  currdate1: any;
  currdate2: any;
  currdate3: any;
  data: [];
  datearr = [];
  linecartdataarray1;
  linecartdataarray;
  labelarray;
  labelarray1;
  engineerarr = [];
  datashowarr = [];

  lineChartData: ChartDataSets[] = [];
  lineChartLabels: Label[] = [];
  chartData: Chart.ChartData[] = [];
  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true
  };
  pieChartOptions: ChartOptions = {
    responsive: true
  };
  public pieChartPlugins = [];

  public pieChartOptions1: Color[] = [
    { // all colors in order
      backgroundColor: ['#d13537', '#b000b5', '#coffee', 'rgba(77,83,96,0.2)', 'rgba(255,0,0,0.3)', 'rgb(122, 155, 255)', 'black']
    }
  ]
  public pieChartColors1: Color[] = [
    { // all colors in order
      backgroundColor: ['#FFD700', '#87CEEB', '#800000', '#8a2be2', '#a9a9a9', '#006a4e', '#FF0000', '#cc7722']
    }
  ]
  // Define colors of chart segments
  public pieChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',

    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
    }, { // dark grey
      backgroundColor: 'rgb(122, 155, 255)',

    },
    { // red
      backgroundColor: 'rgb(241, 155, 255)',
    }, { // dark grey
      backgroundColor: 'rgb(241, 217, 255)',

    },
    { // red
      backgroundColor: 'rgb(241, 217, 14)',
    }, { // dark grey
      backgroundColor: 'rgb(241, 217, 168)',

    },

  ]
  lineChartColors: Color[] = [

    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
    }
  ];

  // Set true to show legends
  lineChartLegend = true;

  // Define type of chart
  lineChartType = 'line';

  lineChartPlugins = [];

  // events
  //chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //console.log(event, active);
  //}

  //chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  //console.log(event, active);
  //}


  public pieChartLabels: string[] = ['ACT', 'NSW', 'QLD', 'VIC', 'TAS', 'WA', 'SA'];
  //public pieChartData: number[] = [, this.nsw, this.queens,this.vict, this.tas, this.wa,this.sa];
  public pieChartType: any = 'pie';
  public pieChartData: number[];

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  constructor(private analyticsService: AnalyticsService,
    private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService) { 
      monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    }
  ngOnInit() {
    this.datalabel = { visible: true, name: 'text', position: 'Outside' };
  this.enableSmartLabels = true;
  this.handleUserAuthCheck('/Analytics/linechart','Job Analytics')
  this.isAuthorized = 'false'
  this.date1 = '';
  this.date2 = '';
  this.datepie1 = '';
  this.datepie2 = '';
  this.datedata1 = '';
  this.datedata2 = '';
}

getdatapiedata(arrdata) {
  this.totalcount = arrdata.length;
  this.tbook = arrdata.filter(e => e.status == "New Job").length;
  this.tbsent = arrdata.filter(e => e.status == "Job Underway").length;
  this.joh = arrdata.filter(e => e.status == "Job On Hold").length;
  this.joc = arrdata.filter(e => e.status == "Job Cancelled").length;
  

  this.act = arrdata.filter(e => e.state == "ACT").length;
  this.nsw = arrdata.filter(e => e.state == "NSW").length;
  this.queens = arrdata.filter(e => e.state == "QLD").length;
  this.vict = arrdata.filter(e => e.state == "VIC").length;
  this.tas = arrdata.filter(e => e.state == "TAS").length;
  this.wa = arrdata.filter(e => e.state == "WA").length;
  this.sa = arrdata.filter(e => e.state == "SA").length;
  this.offered = arrdata.filter(e => e.Status == "Accepted" || e.Status == "Confirmed").length;
  this.rejected = arrdata.filter(e => e.Status == "Rejected").length;
  this.rejectedwa = arrdata.filter(e => e.Status == "Rejected").filter(a => a.Reason == "Wrong area").length;
  this.rejectedws = arrdata.filter(e => e.Status == "Rejected").filter(a => a.Reason == "Wrong service").length;
  this.chartReady = true;
  this.S = arrdata.filter(e => e.templatename == "Subsidence").length;
  this.WC = arrdata.filter(e => e.templatename == "Wall Cracks").length;
  this.SFP = arrdata.filter(e => e.templatename == "Sinking Floor Pier").length;
  this.ALF = arrdata.filter(e => e.templatename == "Arch and Lintel").length;
  this.LW = arrdata.filter(e => e.templatename == "Leaning Wall").length;
  this.RD = arrdata.filter(e => e.templatename == "Rising Damp").length;
  this.SC = arrdata.filter(e => e.templatename == "Sunken Concrete").length;
  //this.WCFC = arrdata.filter(e => e.templatename == "Cosmetic Wall Crack (FlexiCoat)").length;
  this.other = arrdata.filter(e => e.templatename == "Other").length;
  //this.DP = arrdata.filter(e => e.templatename == "DampPoint").length;
  //this.DV = arrdata.filter(e => e.templatename == "DampVent").length;
  //this.SB = arrdata.filter(e => e.templatename == "SaltBlock").length;
  //this.WCFP = arrdata.filter(e => e.templatename == "Cosmetic Wall Crack (FlexiPaint)").length;

  this.pieChartData = [this.act, this.nsw, this.queens, this.vict, this.tas, this.wa, this.sa];
  //this.pieChartData = [2,3,4,5,6,7,8]
}

handleUserAuthCheck(url:any,name:string) {
  this.items = []
  
  
  sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "sales_performance",
      url,
      name
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == 'true') {
        this.loader =  true;
        this.getinspectionlist();
      }else{
        this.router.navigate(["users/list"]);
      }
    });
}

datefpie(event) {
  this.datepie1 = new Date(event.target.value)
}
datelpie(event) {
  this.datepie2 = new Date(event.target.value)
}
dateprocessingpie() {
  if ( this.datepie2 < this.datepie1 || this.datepie1 == '' || this.datepie2 == '') {
    if( this.datepie2 < this.datepie1){
      this.commonService.toast('start Date should be less than End date','Error');
    }else{
      this.commonService.toast('Please select Start date and End date to fetch the data', 'Error');
    }
  } else {
    var newdata = this.items.filter(a => {
      var date = new Date(a.createdAt);
      if(new Date(this.datepie1).toLocaleDateString() == new Date(this.datepie2).toLocaleDateString()){
         return (new Date(date).toLocaleDateString() == new Date(this.datepie1).toLocaleDateString())
      }else{
        return (date >= this.datepie1 && date <= this.datepie2 || new Date(date).toLocaleDateString() == new Date(this.datepie1).toLocaleDateString() || new Date(date).toLocaleDateString() == new Date(this.datepie2).toLocaleDateString());
      }
      // var date = new Date(a.createdAt);
      // return (date >= this.datepie1 && date <= this.datepie2);
    });
    console.log(newdata);
    this.getdatapiedata(newdata);
  }
}

datedata(event) {
  this.datedata1 = new Date(event.target.value)
}
dateldata(event) {
  this.datedata2 = new Date(event.target.value)
}
dateprocessingdata() {
  if (this.datedata1 == '' || this.datedata2 == '') {
    this.commonService.toast('Please select Start date and End date to fetch the data', 'Error');
  } else {
    var newdata = this.items.filter(a => {
      var date = new Date(a.createdAt);
      return (date >= this.datedata1 && date <= this.datedata2);
    });
    //console.log(newdata);
    this.getdatapiedata(newdata);
  }
}
datef(event) {
  this.date1 = new Date(event.target.value)
}
datef1(event) {
  this.date11 = new Date(event.target.value)
}
datel(event) {
  this.date2 = new Date(event.target.value)
}
datel1(event) {
  this.date22 = new Date(event.target.value)
}
dateprocessing1() {
  if (this.date11 == '' || this.date22 == '') {
    this.commonService.toast('Please select Start date and End date to fetch the data', 'Error');
  } else {
    console.log(this.date11, this.date22, "testing dates blank issue");
    var datint = this.date11;
    var datint1 = this.date22;
    var Difference_In_Days = (datint1.getTime() - datint.getTime()) / (1000 * 3600 * 24);
    if (Difference_In_Days < 7) {
      this.commonService.toast('Please select Start date and End date minimum date diffrence of 7 days', 'Error');
    } else {
      this.linecartdataarray1 = this.getdatabydatediff(this.items, datint1, datint, Difference_In_Days / 7);
      let removed = this.datearr.splice(0, 7);
      for (var i = 0; i < 7; i++) {
        //removed[i] = String(removed[i]).replace("GMT+0530 (India Standard Time)"," ");
        removed[i] = moment(removed[i]).toISOString().slice(0, 10)
        //  console.log(removed[i]);
      }
      //this.lineChartLabels = removed;
      this.labelarray = removed;
      this.drawlinechart();
    }
  }

}
getdatabydatediff(arrdata, dat1, dat2, diff) {
  this.datearr = [];
  var data1 = [];
  var testdate = new Date(dat2)
  for (var i = 1; i <= 7; i++) {
    var obj: any = {};
    var datee = new Date(testdate.setDate(testdate.getDate() + diff));
    this.datearr.push(datee);
    obj = this.getdat(arrdata, datee, diff)
    data1.push(obj);
  }
  return data1
}
getdat(array, date, diff) {
  var dateabefore = new Date(date);
  console.log("ntest43", dateabefore)
  var dateahead = new Date(date.setDate(date.getDate() + diff));
  //var dateabefore =  new Date(date.setDate(date.getDate()  - 1));

  let data = this.items.filter(a => {
    var createddate = new Date(a.createdAt);
    return (createddate > dateabefore && createddate < dateahead)
      ;
  }).length;
  //console.log(data)
  return data;
}
dateprocessing() {
  if (this.date1 == '' || this.date2 == '') {
    this.commonService.toast('Please select Start date and End date to fetch the data', 'Error');
  } else {
    var Difference_In_Days = (this.date2.getTime() - this.date1.getTime()) / (1000 * 3600 * 24);
    if (Difference_In_Days < 7) {
      this.commonService.toast('Please select Start date and End date minimum date diffrence of 7 days', 'Error');
    } else {
      this.linecartdataarray = this.getdatabydatediff(this.items, this.date2, this.date1, Difference_In_Days / 7);
      console.log(this.linecartdataarray, 'datacheck');
      let removed = this.datearr.splice(0, 7);
      for (var i = 0; i < 7; i++) {
        //removed[i] = String(removed[i]).replace("GMT+0530 (India Standard Time)"," ");
        removed[i] = moment(removed[i]).toISOString().slice(0, 10)
        //  console.log(removed[i]);
      }
      //this.lineChartLabels = removed;
      this.labelarray = removed;
      this.drawlinechart();
    }
  }
}
getdata(arrdata, dat1, dat2, length) {
  console.log(dat2);
  this.datearr = [];
  var data1 = [];
  for (var i = 1; i <= length; i++) {
    var obj: any = {};
    var datee = new Date(dat2.setDate(dat2.getDate() + 1));
    this.datearr.push(datee);
    obj = this.getdatabydate(arrdata, datee)
    data1.push(obj);
    // console.log(data1);
  }
  //this.data = data;
  return data1
}
getdatabydate(array, date) {
  var dateahead = new Date(date.setDate(date.getDate() + 1));
  var dateabefore = new Date(date.setDate(date.getDate() - 1));

  let data = this.items.filter(a => {
    var createddate = new Date(a.createdAt);
    return (createddate > dateabefore && createddate < dateahead)
      ;
  }).length;
  //console.log(data)
  return data;
}

drawlinechart() {
  this.lineChartData = [
    {
      data: this.linecartdataarray, label: 'Current',
      fill: false
    },
    {
      data: this.linecartdataarray1, label: 'Compare with',
      fill: false
    }
  ];
  this.lineChartLabels = this.labelarray
}
getdataforprevious(arrdata, dat1, dat2, length) {
  console.log(dat2);
  
  var data1 = [];
  for (var i = 1; i <= length; i++) {
    var obj: any = {};
    var datee = new Date(dat2.setDate(dat2.getDate() + 1));
    //this.datearr.push(datee);
    obj = this.getdatabydate(arrdata, datee)
    data1.push(obj);
  }
  return data1
}


getinspectionlist() {
  this.analyticsService.getjobanalysisdataV1().subscribe((result: any) => {
    const items = result.data;
    this.items = items;
    this.getdatapiedata(this.items);
    this.getengineerlist(this.items);
    this.currdate1 = new Date();
    var today = new Date();
    this.currdate2 = new Date(this.currdate1.setDate(this.currdate1.getDate() - 7));
    this.linecartdataarray = this.getdata(this.items, today, this.currdate2, 7);
    //this.currdate3 =  new Date(today.setDate(today.getDate() - 14));
    this.linecartdataarray1 = this.getdataforprevious(this.items, today, this.currdate2, 7);
    //console.log(this.datearr)
    let removed = this.datearr.splice(0, 7);
    for (var i = 0; i < 7; i++) {
      // removed[i] = String(removed[i]).replace("GMT+0530 (India Standard Time)"," ");
      //moment(removed[i]).toISOstring().slice(0,10);
      removed[i] = moment(removed[i]).toISOString().slice(0, 10)
    }
    //console.log(removed);
    this.loader = false;
    this.labelarray = removed;
    /* this.lineChartData = [
       { 
         data: this.linecartdataarray , label: 'Current week' 
       },
       { 
         data: this.linecartdataarray1, label: 'Previous week' }
     ];
     this.lineChartLabels = removed
     */
    this.drawlinechart();


  }, (error: any) => {

    this.commonService.toast(error.error.message, 'Error');
  });
}
patchengineerdata(dataset){
  this.datashowarr = [];
  for (const engdata of this.engineerarr){
    var count = this.findengineervalkue(dataset, engdata)
    this.datashowarr.push({engineer :engdata , count: count} )
  }
  //console.log("data aa rha h", this.datashowarr )
}
findengineervalkue(dataset,data){
  return dataset.filter(e => e.user_name == data).length;
}

getengineerlist(dataset){
  this.analyticsService.getengineerlist().subscribe((result: any) => {
    const items = result.data;
    this.engineerarr = items;
    this.patchengineerdata(dataset)
    //console.log(this.engineerarr)
    
  }, (error: any) => {

    this.commonService.toast(error.error.message, 'Error');
  });
  
}
ngOnDestroy() {
  if (this.modalSubscription) {
    this.modalSubscription.unsubscribe();
  }
  if (this.modalRef) {
    this.modalRef.hide();
    this.modalRef = null;
  }
}
}

