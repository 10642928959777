import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PdfpersectionService } from '../pdfpersection.service'

@Component({
  selector: 'app-add-pdfpersection',
  templateUrl: './add-pdfpersection.component.html',
  styleUrls: ['./add-pdfpersection.component.sass']
})
export class AddPdfpersectionComponent implements OnInit {
  loader;
  fileToUpload: File = null;
  addUserForm: FormGroup;
  submitted = false;
  editId = true;
  _id = '';
  dispimage;
  fileToUpload1 = null;
  name
  userData = {
    name: '',
    email: '',
    role: '',
    phone: '',
    image: '',
    hidfile: ''
  };
  constructor(
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private PdfpersectionService: PdfpersectionService,
  ) { }

  ngOnInit() {
    this.addUserForm = new FormGroup({
      name: new FormControl(this.userData.name, [
        Validators.required,
      ]),
      image: new FormControl(this.userData.image),
      hidfile: new FormControl(this.userData.hidfile)
    });
    this.name = ["Profile Preparation", "Dates Sent","Job Underway", "Job On Hold", "Job Cancelled", "To be Invoiced", "Awaiting Payment", "Dispute or On Hold", "Paid in full", "Ipad"]
    if (this.activatedRoute.snapshot.params.sectionid !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.sectionid;
      this.loadData(this.activatedRoute.snapshot.params.sectionid);
    }
  }

  loadData(id) {
    this.PdfpersectionService.getpdfforsection(id).subscribe((result: any) => {

      const items = result.data;
      this.editLegData(items[0]);
      this.loader = false;

    },
      error => {
        this.loader = false;
        this.commonService.toast(error.error.message, 'Error');
      })
  }

  editLegData(data) {
    this.addUserForm.patchValue({
      name: data.sectionname,
    });
    if (data.PDF == '' || data.PDF == undefined) {
      this.fileToUpload1 = null;
    } else {
      this.fileToUpload1 = true;
    }
    this.dispimage = data.PDF
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // this.showLoader = true;
    if (files.length === 1) {
    }
    this.commonService.fileUpload(this.fileToUpload).subscribe((result: any) => {
      this.userData.image = result.data;
      this.fileToUpload1 = true;
      this.dispimage = result.data;
      // this.addUserForm.patchValue({
      //   hidfile: result.data
      // });
    });
  }



  get f() {
    return this.addUserForm.controls;
  }
  updateUser() {
    this.submitted = true;
    const data = this.addUserForm.controls;
    if (this.addUserForm.invalid) {
      console.log('Form is not valid!!');
    } else {
      this.loader = true;

      const obj = {
        sectionname: data.name.value,
        PDF: this.dispimage,
        //_id: this._id
      };
      this.PdfpersectionService.updatepdf(this._id, obj).subscribe((res) => {
        this.router.navigate(['/pdfpersection/list']);
        this.commonService.toast('PDF added Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }
  addUser() {
    this.submitted = true;
    const data = this.addUserForm.controls;
    if (this.addUserForm.invalid) {
      console.log('Form is not valid!!');
    } else {
      this.loader = true;

      const obj = {
        sectionname: data.name.value,
        PDF: this.dispimage,
      };
      this.PdfpersectionService.savepdf(obj).subscribe((res) => {
        this.router.navigate(['/pdfpersection/list']);
        this.commonService.toast('PDF added Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }

}
