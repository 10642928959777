import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import {InspectionService} from './../inspection.service'
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-modal-component',
  templateUrl: './user-modal-component.component.html',
  styleUrls: ['./user-modal-component.component.sass']
})
export class UserModalComponentComponent implements OnInit {
 userid = null;
 item = [];
 imagelength;
 img;
 i=0;
 name;
 lname;
 createdAt;
 address;
 address1;
 City;
 state;
 postcode;
 phonenumber;
 email;
 templatename;
 response;
 issuetype;
 textaboutissue;
  constructor(private bsModalRef: BsModalRef,
              private inspection: InspectionService,
              private commonService: CommonService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.response = false;
    this.getinspectionlist(this.userid);
    //console.log(this.userid);
    //this.imagelength = 0;
  }
  decline(): void {
    this.bsModalRef.hide();
  }
  getinspectionlist(obj){
    
    this.inspection.getinspectionlistbyid(obj).subscribe((result: any) => {
      const items = result.data;
      this.item = items[0];
      if(result.data[0].image.length == 0) {
       this.imagelength = false;
      }
      else {
        this.imagelength = true;
      }
      this.response = true;
      console.log(this.item);
    }, (error: any) => {
      // this.commonService.toast(error.error.message, 'Error');
    });
  }

}
