import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class CauseService {

  constructor(private restRequest: RestRequest) { }

  getCauseList() {
    return this.restRequest.addAuthorization().get('users/getCauseV1');
  }
  getCauseListV2(query) {
    return this.restRequest.addAuthorization().get('users/getCause',query);
  }
  createCause(obj) {
    return this.restRequest.addAuthorization().post('users/causeAdd', obj);
  }
  getCauseById(obj) {
    return this.restRequest.addAuthorization().get('users/getCauseById/' + obj);
  }
  updateCauseData(obj) {
    return this.restRequest.addAuthorization().post('users/editCause', obj);
  }
  deleteCauseData(obj) {
    return this.restRequest.addAuthorization().post('users/deleteCause', obj);
  }
}
