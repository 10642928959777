import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';


@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private restRequest: RestRequest) { }

  createQuote(obj) {
    return this.restRequest.addAuthorization().post('users/quoteAdd', obj);
  }
  getTemplate(query) {
    return this.restRequest.addAuthorization().get('users/templateList',query);
  }
  getQuoteList() {
    return this.restRequest.addAuthorization().get('users/displayQuoateV1');
  }
  getQuoteListV2(query) {
    return this.restRequest.addAuthorization().get('users/displayQuoate',query);
  }
  deleteQuoteData(obj) {
    return this.restRequest.addAuthorization().post('users/quoteDelete', obj);
  }
  updateQuoteData(obj) {
    return this.restRequest.addAuthorization().post('users/quoteEdit', obj);
  }
  getQuoteDataById(obj) {
    return this.restRequest.addAuthorization().post('users/getQuoteById', obj);
  }
  getQuoteByTempId(obj) {
    return this.restRequest.addAuthorization().post('users/getQuoteById', obj);
  }

}
