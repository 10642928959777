import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LegendService } from '../legend.service';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-legend-list',
  templateUrl: './legend-list.component.html',
  styleUrls: ['./legend-list.component.sass']
})
export class LegendListComponent implements OnInit {
  loader = true;
  items: any;
  nodata = false;
  searchText = '';
  p = 1;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1
  };
  recordsPerPage: number;
  totalRecords = 0;
  _id: string;
  constructor(private router: Router, private leg: LegendService, public commonService: CommonService, private activatedRoute: ActivatedRoute) { 
    this.recordsPerPage = environment.defaultPageSize;
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this._id = this.activatedRoute.snapshot.params.id;
      this.getLegendListById(this._id);
    } else {
      this.getLegendList();
    }
  }
  navigateAddLegend() {
    this.router.navigate(['/legends/add']);
  }
  getLegendList() {
    this.query.search = this.searchText;
    this.leg.getLegentV2(this.query).subscribe((result: any) => {
      const items = result.data.data.filter(data=> data.tempActive == true);
      this.items = items;
      this.totalRecords = result.data.totalCount;
      this.loader = false;
      this.nodata = false
    }, (error: any) => {
      this.nodata = true;
      this.loader = false;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getLegendList();
  }


  getLegendListById(id: string) {
    const obj = { tempId: id };
    this.leg.getLegentByTempId(obj).subscribe((result: any) => {
      const items = result.data;
      this.items = items;
      this.loader = false;
    }, (error: any) => {
      this.nodata = true;
      this.loader = false;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }

  getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

  legDelete(id) {
    const obj = {
      _id: id
    };
    this.leg.deleteLegent(obj).subscribe((result: any) => {
      this.loader = false;
      if (result.success === true) {
        this.commonService.toast(result.message, 'Success');
      } else {
        this.commonService.toast(result.message, 'Error');
      }
      if (this.activatedRoute.snapshot.params.id !== undefined) {
        this._id = this.activatedRoute.snapshot.params.id;
        this.getLegendListById(this._id);
      } else {
        this.getLegendList();
      }
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  changeStepper(_id, event) {
    const status = event.target.checked;
    this.leg.updateStepper({ _id, stepper: status }).subscribe((result: any) => {
      this.commonService.toast(`Stepper status updated successfully.`, 'Success');
      this.getLegendList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  resizeStatus(_id, event) {
    const status = event.target.checked;
    this.leg.resizeStatus({ _id, resize: status }).subscribe((result: any) => {
      this.commonService.toast(`Resizable status updated successfully.`, 'Success');
      this.getLegendList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  rotateStatus(_id, event) {
    const status = event.target.checked;
    this.leg.rotateStatus({ _id, rotation: status }).subscribe((result: any) => {
      this.commonService.toast(`Rotation status updated successfully.`, 'Success');
      this.getLegendList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  changeLegend(_id, event) {
    const status = event.target.checked;
    this.leg.changeLegendType({ _id, legendType: status }).subscribe((result: any) => {
      this.commonService.toast(`Legend status updated successfully.`, 'Success');
      // this.getLegendList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });

  }
}
