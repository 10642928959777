import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { RouterModule } from '@angular/router';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { LayoutsModule } from './../../layouts/layouts.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    JwtHelperService,
    AuthService
  ],
  declarations: [LoginComponent, ForgetPasswordComponent,
    VerifyOtpComponent]
})
export class AuthModule { }
