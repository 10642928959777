import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { InventoryNotesComponent } from "../inventory-notes/inventory-notes.component";
import { Inventory } from "../Inventory";
import { InventoryService } from "../inventory.service";
import { CommonService } from "src/app/services/common.service";
import { InternalOrderModalComponent } from "../internal-order-modal/internal-order-modal.component";
import { PasswordCheckerComponent } from "../password-checker/password-checker.component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-internal-order-list",
  templateUrl: "./internal-order-list.component.html",
  styleUrls: ["./internal-order-list.component.sass"],
})
export class InternalOrderListComponent implements OnInit, OnDestroy {
  isAuthorized = "true";
  routeParams = {
    new_io: {
      title: "New Internal Order",
      status: "New",
    },
    io_packed: {
      title: "Packed Internal Order",
      status: "Packed",
    },
    pickup: {
      title: "Picked Up Internal Order",
      status: "Picked",
    },
  };

  status = "";
  title = "";
  items: Inventory[] = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedInventoryId = "";
  loader = false;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  routeSubscription: Subscription;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: InventoryService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.deleteComponent();
      });

    this.isAuthorized = "false";
    this.routeSubscription = this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;

      // if(this.status == 'new_io') {
      //   this.handleUserAuthCheck('/Inventory/IO/list/new_io','New IO')
      // }
      // else if(this.status == 'io_packed') {
      //   this.handleUserAuthCheck('/Inventory/IO/list/io_packed','IP Packed')
      // }
      // else {
      //   this.handleUserAuthCheck('/Inventory/IO/list/pickup','Pick Up')
      // }
      this.title = this.routeParams[this.status].title;
      this.getInventoryList();
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    this.items = [];
    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "inventory",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getInventoryList();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
    // this.modalSubscription.unsubscribe()
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getInventoryList();
  }

  handleShowOrderModal(item: any) {
    const initialState = {
      item,
    };

    this.modalService.show(InternalOrderModalComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  getInventoryList(search?: string) {
    if (!this.status) {
      return;
    }
    this.loader = true;
    this.api
      .getInternalOrders({
        search,
        status: this.status,
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.loader = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.getInventoryList(inputElement.value);
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      showAddAndDeleteBtn: false,
      noteType: "internal_order",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getInventoryList();
    });
  }

  setSelectedInventoryId(id: string) {
    this.selectedInventoryId = id;
  }

  deltedata() {
    const Id = this.selectedInventoryId;
    this.api.deleteInternalOrder(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getInventoryList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleUpdateStatus(orderId: string, event) {
    const status = event.target.value;

    this.api.updateOrderStatus(orderId, { status }).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getInventoryList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  
  ngOnDestroy() {
    console.log("Destroy Component Internal");
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    // Clear component state
    this.items = [];
    this.totalPages = 0;
    this.totalRecords = 0;
    this.currentPageNo = 1;
    this.selectedInventoryId = "";
    this.loader = false;
    this.isAuthorized = null;
    this.status = "";
    this.title = "";
    console.log("Destroy Component Internal");
  }

  deleteComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
