import { Component, OnInit } from '@angular/core';
import { VehicelAddEditComponent } from '../vehicel-add-edit/vehicel-add-edit.component';
import { BsModalService } from 'ngx-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { VehicelService } from '../vehicel-service';
import * as moment from 'moment';

@Component({
  selector: 'app-vehicel-list',
  templateUrl: './vehicel-list.component.html',
  styleUrls: ['./vehicel-list.component.sass']
})
export class VehicelListComponent implements OnInit {
  status = "";
  title = "";
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedId = "";
  loader = false;
  searchText = null;
  isEdit = false;
  selectedInventoryId:any

  constructor(
    private modalService: BsModalService,
    private api: VehicelService,
    private commonService: CommonService,
    private router: Router,
    
  ) {}

  ngOnInit() {
    this.getList()
  }

  handleEditVehicle(initialData:any) {
    const initialState = {
      initialData: {
        ...initialData,
      },
    };
    this.modalService.show(VehicelAddEditComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }
  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  getList() {
    this.loader = true;
    this.api
      .getList({
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  setSelectedTicketId(id: string) {
    this.selectedInventoryId = id;
  }

  getDate(date:string) {
    return moment(date).format('DD/MM/YYYY')
  }

  getSignal(date: string): string {
    const inputDate = moment(date);
    const currentDate = moment();
    const differenceInDays = inputDate.diff(currentDate, 'days');
    return differenceInDays > 30 ? 'green' : 'red';
  }


  deltedata() {
    const Id = this.selectedInventoryId;
    console.log(Id,'the id')
    this.api.deleteData(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

 


}
