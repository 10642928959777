import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortalService } from '../portal.component.services';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from "ngx-bootstrap";

@Component({
  selector: 'app-edit-portal',
  templateUrl: './edit-portal.component.html',
  styleUrls: ['./edit-portal.component.sass']
})
export class EditPortalComponent implements OnInit {

  formData: FormGroup;
  submitted = false;
  loading = false;
  selectedCategory : any
  category :any;
  private initialData = {
    categoryId:"",
    index: "",
    buttonName: "",
    buttonLink: "",
    category: {},
    _id:""

  };
  reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
 numberPattern = /^[0-9]+$/;

  optionsVisibility = {};
  constructor(
    private _fb: FormBuilder,
    private api: PortalService,
    private commonService: CommonService,
    private bsModalRef: BsModalRef,


  ) {
    this.handleCategory("")
   }

  ngOnInit() {
    this.formData = this._fb.group({
      categoryId: [this.initialData.categoryId, [Validators.required]],
      index: [this.initialData.index, [Validators.required, Validators.pattern(this.numberPattern)]],
      buttonName: [this.initialData.buttonName, [Validators.required]],
      buttonLink: [this.initialData.buttonLink, [Validators.required, Validators.pattern(this.reg)]],
    });
    this.formData.patchValue({
      categoryId: this.initialData.categoryId,
    });
    this.selectedCategory = this.initialData.category
  }



  get f() {
    return this.formData.controls;
  }

  handleCategorySelect(item: any) {
    this.selectedCategory = item;
    this.formData.patchValue({
      categoryId: item._id,
    });
  }

  handleUpdateButton () {
    this.submitted = true;
    if(!this.formData.valid) {
      return
    }     
    this.loading = true
      this.api.editButton(this.formData.value,this.initialData._id).subscribe((result:any) => {
        this.loading = false;
        this.commonService.toast(result.message,'Success');
        this.submitted = false;
        this.bsModalRef.hide();
      },(error:any) => {
        this.commonService.toast(error.error.message,'Error');
        this.loading = false;
      }
    
    )
    
  }

  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;

    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  handleCategory(event: any) {
    const str = event ? event.target.value : "";
    const data = {
      search: str,
      type:"all"
    };
    this.api.getCategory(data).subscribe(
      (result: any) => {
        const response = result.data;
        if (response.items) {
          this.category = response.items;
          let temp = this.category.find(obj => obj._id === this.initialData.categoryId ) 
          this.selectedCategory = temp || this.category[0]
          this.formData.patchValue({
            categoryId: this.selectedCategory._id,
          });
        }
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );    
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

}
