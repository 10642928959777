import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToolboxService } from '../toolbox.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../templates/template.service';



@Component({
    selector: "app-add-toolbox",
    templateUrl: "./add-toolbox.component.html",
    styleUrls: ["./add-toolbox.component.sass"],
})
export class AddToolboxComponent implements OnInit {
    toolboxForm: FormGroup;
    submitted: boolean;
    toolboxId: "";
    editId = true;
    dropVal = null;
    listData: any;
    loader;
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };

    constructor(
        private toolboxService: ToolboxService,
        public commonService: CommonService,
        private router: Router,
        private fb: FormBuilder,
        private templateService: TemplateService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.toolboxForm = this.fb.group({
            toolbox: [null, [Validators.required, Validators.minLength(2)]],
            tempName: [null, [Validators.required]],
        });
        this.templateService.getTemplate(this.query).subscribe(
            (result: any) => {
                console.log(result,"result")
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                console.log(this.listData, "this.listData");

                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
        if (this.activatedRoute.snapshot.params.toolboxId !== undefined) {
            this.editId = false;
            this.toolboxId = this.activatedRoute.snapshot.params.toolboxId;
            this.loadData(this.activatedRoute.snapshot.params.toolboxId);
        }
    }
    loadData(toolboxId) {
        const obj = { _id: toolboxId };
        this.loader = true;
        this.toolboxService.getToolboxDataById(obj).subscribe(
            (result: any) => {
                const items = result.data;
                this.editToolboxData(items);
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    editToolboxData(data) {
        this.toolboxForm.patchValue({
            toolbox: data.toolText,
            tempName: data.tempId,
        });
    }

    submitHandler() {
        if (!this.toolboxForm.invalid) {
            this.submitted = false;
            const obj = {
                toolText: this.toolboxForm.controls.toolbox.value,
                // tempId: this.repForm.controls.tempInfo.value.tempName,
                tempId: this.toolboxForm.controls.tempName.value,
            };

            this.toolboxService.createToolbox(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Instruction box added Successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/toolbox/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            this.submitted = true;
        }
    }

    updateToolbox() {
        this.submitted = true;
        if (!this.toolboxForm.invalid) {
            const obj = {
                toolText: this.toolboxForm.controls.toolbox.value,
                tempId: this.toolboxForm.controls.tempName.value,
                _id: this.toolboxId,
            };
            this.toolboxService.updateToolboxData(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Instruction box has been updated successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/toolbox/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            console.log("Validation error");
        }
    }
    get f() {
        return this.toolboxForm.controls;
    }
}
