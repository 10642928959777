import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "../../../services/common.service";
// import { InventoryNoteModalComponent } from '../create-notes/create-notes.component';
import { InventoryService } from "../inventory.service";
import { InventoryNoteModalComponent } from "../inventory-note-modal/inventory-note-modal.component";

@Component({
  selector: "app-inventory-notes",
  templateUrl: "./inventory-notes.component.html",
  styleUrls: ["./inventory-notes.component.sass"],
})
export class InventoryNotesComponent implements OnInit {
  inventoryId = "";
  items = [];
  loader = false;
  showAddAndDeleteBtn = true;
  noteType: string = "inventory_item";

  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private api: InventoryService,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    this.getInventoryNotes();
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  getInventoryNotes() {
    this.api.getInventoryNotes(this.inventoryId).subscribe(
      (result: any) => {
        console.log("list of notes are hereeeeeeeeeeeeeeeeeeeeeee");
        const items = result.data.items;
        this.items = items;
        this.loader = false;

        this.showAddAndDeleteBtn = this.showAddAndDeleteBtn || items.length < 1;
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleNoteAddorUpdate(noteId: string, content: string) {
    const initialState = {
      noteId,
      content,
      noteType: this.noteType,
      referenceId: this.inventoryId,
    };

    this.modalService.show(InventoryNoteModalComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
    this.modalService.onHidden.subscribe(() => {
      this.getInventoryNotes();
    });
  }

  handleNoteDelete(noteId: string) {
    this.api.deleteNote(noteId).subscribe(
      (result: any) => {
        this.getInventoryNotes();
        this.commonService.toast(result.message || "Note Deleted", "Success");
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
