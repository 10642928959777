import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { BookinglinkService } from '../bookinglink.service'
import { CreateNotesComponent } from '../create-notes/create-notes.component';

@Component({
  selector: 'app-notesmodal',
  templateUrl: './notesmodal.component.html',
  styleUrls: ['./notesmodal.component.sass']
})
export class NotesmodalComponent implements OnInit {
  userid = null;
  uId = null;
  notesForm: FormGroup;
  items;
  loader;


  constructor(private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private booking: BookinglinkService,
    private modalService: BsModalService,
    ) { }
  ngOnInit() {
    this.notesForm = this.formBuilder.group({
      noteMain: this.formBuilder.array([this.addNote()]),
    });

    this.getbookinglist(this.userid);
  }

  getbookinglist(obj) {
    this.booking.getbookingdatabyid(obj).subscribe((result: any) => {      
      const items = result.data.data;
      this.items = items;
      this.loader = false;
      this.editData(this.items)
    }, (error: any) => {
      this.loader = false;

      // this.commonService.toast(error.error.message, 'Error');
    });
  }
  updatenotes() {
    if (this.notesForm.valid) {
      const formData = { ...this.notesForm.value };
      const obj = {
        notes: formData.noteMain
      };
      this.booking.createNotes(obj).subscribe((res:any)=>{
        console.log(res);
        this.loader = false;
        this.commonService.toast('Notes saved successfully', 'Success');
        this.decline()
      },err=>{
        console.log(err);
                this.loader = false;
      //   // this.commonService.toast(error.error.message, 'Error');
      })
      // this.booking.updatenotesbyid(this.userid, obj).subscribe((result: any) => {

      //   this.loader = false;
      //   this.commonService.toast('Notes saved successfully', 'Success');
      //   //this.decline();
      // }, (error: any) => {
      //   this.loader = false;

      //   // this.commonService.toast(error.error.message, 'Error');
      // });
    }
  }
  editData(data: any) {    
    this.notesForm.setControl('noteMain', this.setNoteFun(data.notes));
  }
  userNoteAdd() {
    const initialState = {
      userId : this.uId,
      uniqueQuoteId : this.userid
    }
    this.modalService.show(CreateNotesComponent, { initialState, class: 'modal-lg modal-dialog-centered' });
    this.modalService.onHidden.subscribe(res=>{
      this.getbookinglist(this.userid);
    })
        // this.noteArr.push(this.addNote());
    // this.booking.createNotes()
  }
  removeNote(index,item) {
    let body = {
      "Unique_Quote_code" : this.userid,
      "noteId"  : item.value.noteId
    }
      this.commonService.deleteNote(body).subscribe((res)=>{
        console.log(res,'delete');
        this.commonService.toast('Note Deleted.', 'Success');
        this.getbookinglist(this.userid);
      },err=>{
        console.log(err);
      })
    // if ((this.noteArr).length === 1) {
    //   // this.commonService.toast('Atleast one  Note is required.', 'Error');
    //   this.noteArr.removeAt(index);
    // } else {
    //   this.noteArr.removeAt(index);
    // }
  }

  editNote(e,group) {
    console.log(e,group.value);
    const initialState = {
      userId : this.uId,
      uniqueQuoteId : this.userid,
      note : group.value.general,
      noteId : group.value.noteId
      // note : note
    }
    const modal = this.modalService.show(CreateNotesComponent, { initialState, class: 'modal-lg modal-dialog-centered' });
    this.modalService.onHidden.subscribe(res=>{
      this.getbookinglist(this.userid);
    })
  }
  get noteArr() {
    return this.notesForm.get('noteMain') as FormArray;
  }
  addNote() {
    return this.formBuilder.group({
      userId : this.uId,
      Unique_Quote_code : this.userid,
      general: [null, Validators.required],
      noteId : ''
    });
  }
  decline(): void {
    this.bsModalRef.hide();
  }
  setNoteFun(genData: any): FormArray {    
    
    const formArray = new FormArray([]);
    if (genData) {
      genData.forEach(s => {
        formArray.controls.push(this.formBuilder.group({
          general: s.general,
          userId : this.uId,
          Unique_Quote_code : this.userid,
          noteId : s._id
        }));
      });
    }
    return formArray;
  }

}
