import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import {QualityService} from '../quality.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import {QnotesmodalComponent} from '../qnotesmodal/qnotesmodal.component';
import {ViewmodalComponent} from '../viewmodal/viewmodal.component'
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-qualityassuranceopen",
    templateUrl: "./qualityassuranceopen.component.html",
    styleUrls: ["./qualityassuranceopen.component.css"],
})
export class QualityassuranceopenComponent implements OnInit {
    loader = true;
    items: any;
    pdfdata: any;
    pdfviewer: any;
    deleteid;
    any;
    arr_temp_all: [];
    arr_temp: any;
    listData: any;
    p = 1;
    nodata = false;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    totalRecords = 0;
    recordsPerPage: number;

    constructor(
        private quality: QualityService,
        private commonService: CommonService,
        private modalService: BsModalService
    ) {
        this.recordsPerPage = environment.defaultPageSize;
    }

    ngOnInit() {
        this.getqualitylist("open");
        this.arr_temp = [];
    }

    private searchTerms = new Subject<string>();
    getqualitylist(obj) {
        this.query.search = this.searchText;
        this.quality.getqualityListV2(obj, this.query).subscribe(
            (result: any) => {
                const items = result.data.data;
                this.items = items;
                this.totalRecords = result.data.totalCount;
                this.arr_temp_all = this.items.map((result) => result._id);
                this.loader = false;
                this.nodata = false;
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
            }
        );

        this.searchTerms
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.query.search = value;
                this.pageChanged({ page: 1 });
                // this.engineerlist();
            });
    }

    getValue(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.searchTerms.next(inputElement.value);
        // return (event.target as HTMLInputElement).value;
    }
    pageChanged(event: any): void {
        this.p = event.page;
        this.query.page = this.p;
        this.getqualitylist("open");
    }

    userModal(userid) {
        //console.log(userid);
        const initialState = {
            userid: userid,
        };
        this.modalService.show(ViewmodalComponent, {
            initialState,
            class: "modal-lg",
        });
        this.modalService.onHide.subscribe(() => {});
    }
    notesModal(userid) {
        //console.log(userid);
        const initialState = {
            userid: userid,
        };
        this.modalService.show(QnotesmodalComponent, {
            initialState,
            class: "modal-lg",
        });
        this.modalService.onHide.subscribe(() => {});
    }
    Deletefnc() {
        // this.obj = {
        //obj : this.arr_temp
        //}
        if (this.arr_temp.length == 0) {
            this.commonService.toast(
                "Please select an enquiry to delete",
                "Error"
            );
        } else {
            this.quality.deleteDatamultiple(this.arr_temp).subscribe(
                (result: any) => {
                    this.loader = true;
                    this.getqualitylist("open");
                    this.loader = false;
                    this.commonService.toast("Deletion successful", "Success");
                },
                (error: any) => {
                    this.loader = false;
                    //this.nodata = true;
                    // this.commonService.toast(error.error.message, 'Error');
                }
            );
        }
    }
    deleteselection(_id, check) {
        if (check.target.checked == true) {
            this.arr_temp.push(_id);
            //console.log(this.arr_temp);
        } else if (check.target.checked == false) {
            for (let j = 0; j < this.arr_temp.length; j++) {
                if (this.arr_temp[j] === _id) {
                    this.arr_temp.splice(j, 1);
                }
            }
            // console.log(this.arr_temp);
        }
    }
    deleteall(event) {
        const checked = event.target.checked;
        this.items.forEach((item) => (item.selected = checked));
        if (checked == true) {
            this.arr_temp = this.arr_temp_all;
        } else if (checked == false) this.arr_temp = [];
        // console.log(this.arr_temp);
    }
    changeStatus(_id, event) {
        const status = event.target.value;
        this.quality.updateData(_id, { status: status }).subscribe(
            (result: any) => {
                this.commonService.toast(
                    `Booking status updated successfully.`,
                    "Success"
                );
                this.getqualitylist("open");
            },
            (error) => {
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    deltedata() {
        const Id = this.deleteid;
        this.quality.deleteData(Id).subscribe(
            (result: any) => {
                this.loader = false;
                if (result.success === true) {
                    this.commonService.toast(result.message, "Success");
                } else {
                    this.commonService.toast(result.message, "Error");
                }
                this.getqualitylist("open");
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    saveid(Id) {
        this.deleteid = Id;
    }
}
