import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import * as moment from "moment";
import { PasswordCheckerComponent } from "../inventory/password-checker/password-checker.component";
import { InventoryLinksComponent } from "../inventory/inventory-links/inventory-links.component";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";
import { InventoryDocUploaderComponent } from "../inventory/inventory-doc-uploader/inventory-doc-uploader.component";
import { SalesPerformanceService } from "./sales-performance.service";
import { Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-sales-performance",
  templateUrl: "./sales-performance.component.html",
  styleUrls: ["./sales-performance.component.sass"],
})
export class SalesPerformanceComponent implements OnInit, OnDestroy {
  // @ViewChild('confirmdeleteModal') confirmdeleteModal: ElementRef;
  @ViewChild("confirmdeleteModal") confirmdeleteModal: any;
  @ViewChild("editTargetModal") editTargetModal: any;
  @ViewChild("closebutton") closebutton;
  modalSubscription: Subscription;
  modalRef: BsModalRef;

  limit = 10;
  showModal = false;
  totalSales = 0;
  isAuthorized: any;
  loader = false;
  totalRecords = 0;
  recordsPerPage = 10;
  page = 1;
  SelectedId = null;
  overallHealthScore: number = 30;
  formData: FormGroup;
  profitformData: FormGroup;
  overviewItem: any;
  loading = true;
  link = "sales-performance";
  showEditFields = [
    "1 Day",
    "1 Week",
    "1 Month (30 days)",
    "3 Months (90 days)",
    "6 Months (180 days)",
    "1 Year",
  ];
  private initialEditData = {
    target: [
      {
        key: "one_day",
        value: "",
      },
      {
        key: "one_week",
        value: "",
      },
      {
        key: "one_month",
        value: "",
      },
      {
        key: "three_months",
        value: "",
      },
      {
        key: "six_months",
        value: "",
      },
      {
        key: "one_year",
        value: "",
      },
    ],
  };
  filterField: string = "one_week";
  currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
  currentEndDate = moment().format("YYYY-MM-DD");
  previousStateDate = moment(this.currentStartDate)
    .subtract(1, "weeks")
    .format("YYYY-MM-DD");
  previusEndDate = moment(this.previousStateDate)
    .endOf("isoWeek")
    .format("YYYY-MM-DD");
  item = [
    {
      label: "Today",
      value: "one_day",
    },
    {
      label: "Week",
      value: "one_week",
    },
    {
      label: "1 Month",
      value: "one_month",
    },
    {
      label: "3 Month",
      value: "three_months",
    },
    {
      label: "6 Month",
      value: "six_months",
    },
    {
      label: "1 year",
      value: "one_year",
    },
  ];
  targetArr = [
    "one_week",
    "one_month",
    "three_months",
    "six_months",
    "one_year",
  ];
  constructor(
    private commonService: CommonService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    private api: SalesPerformanceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isAuthorized = "false";
    this.formData = this._fb.group({
      target: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
    this.profitformData = this._fb.group({
      operatingExpenses: this._fb.array(
        this.initialEditData.target.map((entry) => this.createEntryGroup(entry))
      ),
    });
    this.handleUserAuthCheck("Dashboard/salesperformance", "Sales Performance");
  }

  handleEditTarget() {
    const formData = { target: this.formData.value.target };
    this.api.editTarget(formData, this.SelectedId).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.SelectedId = null;
        this.getoverviewData();
        this.formData.reset();
        //  this.confirmdeleteModal.nativeElement.closest('.modal').modal('hide');
        this.closebutton.nativeElement.click();
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleSelectSearch(e: any, v: any) {
    this.loading = true;
    this.totalSales = 0;
    this.updateCSSVariables(0);
    if (e === "one_week") {
      this.filterField = "one_week";
      this.currentStartDate = moment().startOf("isoWeek").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "weeks")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    } else if (e == "one_day") {
      this.filterField = "one_day";
      this.currentStartDate = moment().format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    } else if (e === "one_month") {
      this.filterField = "one_month";
      this.currentStartDate = moment().startOf("month").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.previousStateDate)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (e === "three_months") {
      this.filterField = "three_months";
      let currentDate = moment();
      let currentQuarter = Math.ceil(currentDate.month() / 3);
      this.currentStartDate = moment()
        .quarter(currentQuarter)
        .startOf("quarter")
        .format("YYYY-MM-DD"); // got write date
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(3, "month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
    } else if (e === "six_months") {
      this.filterField = "six_months";
      let currentDate = moment();
      let currentSixMonthPeriod = Math.ceil(currentDate.month() / 6);
      // let currentSixMonthPeriod = 2;
      if (currentSixMonthPeriod == 1) {
        let previousYear = moment()
          .subtract(1, "year")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.previousStateDate = moment(previousYear)
          .add(6, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(6, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        this.previousStateDate = moment().startOf("year").format("YYYY-MM-DD");
        this.previusEndDate = moment(this.previousStateDate)
          .add(5, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
        this.currentStartDate = moment(this.previusEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }
    } else {
      this.filterField = "one_year";
      this.currentStartDate = moment().startOf("year").format("YYYY-MM-DD");
      this.previousStateDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .startOf("month")
        .format("YYYY-MM-DD");
      this.previusEndDate = moment(this.currentStartDate)
        .subtract(1, "year")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    this.getoverviewData();
  }

  setSelectedTicketId(id: any, array: any) {
    this.SelectedId = id;
    this.api.getSalesDetail(id).subscribe((result: any) => {
      const response = result.data.target;
      if (response.length) {
        console.log("bbbiiiiiiiiiiiiiiiiiiiiiiii");

        for (const item of response) {
          const targetItem = this.initialEditData.target.find(
            (obj) => obj.key === item.key
          );
          if (targetItem) {
            targetItem.value = item.value;
          }
        }
      } else {
        this.initialEditData = {
          target: [
            {
              key: "one_day",
              value: "",
            },
            {
              key: "one_week",
              value: "",
            },
            {
              key: "one_month",
              value: "",
            },
            {
              key: "three_months",
              value: "",
            },
            {
              key: "six_months",
              value: "",
            },
            {
              key: "one_year",
              value: "",
            },
          ],
        };
      }

      this.formData = this._fb.group({
        target: this._fb.array(
          this.initialEditData.target.map((entry) =>
            this.createEntryGroup(entry)
          )
        ),
      });
    });
    this.showModal = true;
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.getoverviewData();
  }

  createEntryGroup(entry: any): FormGroup {
    return this._fb.group({
      key: entry.key,
      value: [entry.value],
    });
  }

  handleUserAuthCheck(url: any, name: string) {
    this.overviewItem = [];

    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "sales_performance",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getoverviewData();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  handleLinksModal(links: any, inventoryId: any) {
    const initialState = {
      items: links,
      inventoryId,
      noteType: "company_overview",
      link: this.link,
    };
    this.modalService.show(InventoryLinksComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      // this.getoverviewData();
    });
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "sales_performance",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  handleDocUpload(companyOverviewId: string, images: string[]) {
    const initialState = {
      companyOverviewId,
      images,
      type: "sales-performance",
    };
    this.modalService.show(InventoryDocUploaderComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getoverviewData();
    });
  }

  getTargetValue(arr: any) {
    let temp = arr.filter(({ key, value }) => {
      if (key === this.filterField) {
        return { key, value };
      }
    });
    return parseFloat(temp[0].value).toFixed(2);
  }
  getValue(data: any) {
    return parseFloat(data).toFixed(2);
  }

  getoverviewData() {
    this.api
      .getsalesView(
        this.currentStartDate,
        this.currentEndDate,
        this.previousStateDate,
        this.previusEndDate,
        this.filterField
      )
      .subscribe(
        (result: any) => {
          const response = result.data;
          console.log("response=============", response);
          this.overviewItem = response.items;
          this.overallHealthScore = response.overallHealthScore.toFixed();
          this.totalSales = response.totalSales.toFixed(2);
          this.totalRecords = response.count;
          this.loading = false;
          this.updateCSSVariables(this.overallHealthScore);
        },
        (error: any) => {
          this.loading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
  }

  getSalesDetail(id: any) {
    console.log("hiiiiiiiiiiiiiiiiiiiiiiii");
    this.api.getSalesDetail(id).subscribe((result: any) => {
      const response = result.data;
      this.handleDocUpload(response._id, response.images);
    });
  }

  getTrendIcon(current: number, previous: number): string {
    return current > previous
      ? "fas fa-arrow-alt-circle-up text-green"
      : "fas fa-arrow-alt-circle-down text-red";
  }

  updateCSSVariables(data: any) {
    const progressBar = document.querySelector(
      'div[role="progressbar"]'
    ) as HTMLElement;
    if (progressBar) {
      progressBar.style.setProperty("--value", `${data}`);
    }
  }
  ngOnDestroy() {
    // Clean up subscriptions and resources
    console.log("DESTROY SALES");

    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
