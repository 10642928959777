import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { BookinglinkService } from '../bookinglink.service';

@Component({
  selector: 'app-create-notes',
  templateUrl: './create-notes.component.html',
  styleUrls: ['./create-notes.component.sass']
})
export class CreateNotesComponent implements OnInit {

  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private booking: BookinglinkService
  ) { }
  name:any;
  userId: any;
  uniqueQuoteId: any;
  note : any;
  noteId : any;
  ngOnInit() {
    console.log(this.userId);
    console.log(this.uniqueQuoteId);
    console.log(this.noteId)
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  saveClicked() {
    this.bsModalRef.hide();
    if(this.noteId){
      const data = {
        noteId : this.noteId,
        Unique_Quote_code : this.uniqueQuoteId,
        note : this.note,

      }
          this.booking.updateNotes(data).subscribe(res=>{
            console.log(res);
          },err=>{
            console.log(err);
          })
    }
    if(!this.noteId){
      const data = {
        userId : this.userId,
        Unique_Quote_code : this.uniqueQuoteId,
        general : this.note,
      }
          this.booking.createNotes(data).subscribe(res=>{
            console.log(res);
          },err=>{
            console.log(err);
          })
    }
  }

}
