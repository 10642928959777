import { Component, OnInit } from "@angular/core";
import { JobService } from "../job.service";
import { CommonService } from "../../../services/common.service";
import { ExcelService } from "src/app/services/excel.service";
import { BsModalService } from "ngx-bootstrap";
import { FeedbackModalComponent } from "../feedback-modal/feedback-modal.component";
import { environment } from "../../../../environments/environment";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-job-history",
    templateUrl: "./job-history.component.html",
    styleUrls: ["./job-history.component.css"],
})
export class JobHistoryComponent implements OnInit {
    items;
    loader;
    nodata = false;
    recordsPerPage: number;
    isAction = true;
    p = 1;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    totalRecords = 0;

    constructor(
        private job: JobService,
        private commonService: CommonService,
        private exportSheet: ExcelService,
        private modalService: BsModalService
    ) {
        this.recordsPerPage = environment.defaultPageSize;
    }
    private searchTerms = new Subject<string>();
    ngOnInit() {
        this.loader = true;
        this.searchTerms
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.query.search = value;
                 this.pageChanged({ page: 1 });
                // this.getJobList();
            });
        this.getJobList();
    }
    getValue(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.searchTerms.next(inputElement.value);
        // return (event.target as HTMLInputElement).value;
    }
    /**
   * 
   * Unique_Quote_code: "Q47-C-1.0-FC-432289"
date: "04-06-2021"
defaultCause: [{_id: "5e7ad3bcea24052fade20683",…}]
defaultRepairMethod: [{_id: "5de0efa4f1141259fc6d8b40",…}]
detailType: "Cosmetic Wall Crack (FlexiCoat)"
file: "https://buidfixlive.s3.ap-southeast-2.amazonaws.com/file1622781760141.pdf"
jobNumber: "4072"
projectAddress: "95 Alice Street, Newtown 2042"
tempId: "5dce4a753f8fbd05dc3cdb0e"
templateName: "Cosmetic Wall Crack (FlexiCoat)"
userId: "5e83c81c8657b06a2b903da1"
userName: "Miguel Ruiz"
_id: "60b9ae43ddb0740d4a8ce0a2"

defaultCause: [{_id: "5e7ad3bcea24052fade20683",…}]
0: {_id: "5e7ad3bcea24052fade20683",…}
cause_text: "Likely cause of cosmetic damage to the walls is a result of structural movement. This has allowed fretting and cracking of to the finish material."
_id: "5e7ad3bcea24052fade20683"


defaultRepairMethod: [{_id: "5de0efa4f1141259fc6d8b40",…}]
0: {_id: "5de0efa4f1141259fc6d8b40",…}
repairImage: "https://buidfixlive.s3.ap-southeast-2.amazonaws.com/M29%20-%20FC-%2001.pdf"
repairSolution: "M29 - FC- 01"
_id: "5de0efa4f1141259fc6d8b40"
   * 
   */
    getJobList() {
        this.loader = true;
        this.query.search = this.searchText;
        this.job.getJobList(this.query).subscribe(
            (result: any) => {
                const items = result.data.data;
                this.items = items;
                this.totalRecords = result.data.totalCount;
                this.nodata = false;
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
                // this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    downloadJSON2csv() {
        let arrObj = [];
        this.items.forEach((element) => {
            arrObj.push({
                Unique_Quote_code: element.Unique_Quote_code,
                date: element.date,
                detailType: element.detailType,
                file: element.file,
                jobNumber: element.jobNumber,
                projectAddress: element.projectAddress,
                tempId: element.tempId,
                templateName: element.templateName,
                userId: element.userId,
                userName: element.userName,
            });
        });

        console.log(arrObj);
        this.exportSheet.print(
            "csv",
            [
                { label: "User Name", value: "userName" },
                { label: "Unique Quote Code", value: "Unique_Quote_code" },
                { label: "Date", value: "date" },
                { label: "Detail Type", value: "detailType" },
                { label: "File", value: "file" },
                { label: "User Id", value: "userId" },
                { label: "Job Number", value: "jobNumber" },
                { label: "Project Address", value: "projectAddress" },
                { label: "Temp Id", value: "tempId" },
                { label: "Template Name", value: "templateName" },
            ],
            [
                "User Name",
                "Unique Quote Code",
                "Date",
                "Detail Type",
                "File",
                ,
                "User Id",
                "Job Number",
                "Project Address",
                "Temp Id",
                "Template Name",
            ],
            arrObj,
            "All Quote History",
            "Quote History Data",
            "All Quote History Data-sheet"
        );

        // if (e == "CSV") this.csvDownload("csv");
        // if (e == "Excel") this.csvDownload("xlsx");
        // if (e == "Pdf") this.csvDownload("Pdf");
    }

    openFeedbackModal(data) {
        const initialState = {
            data: data,
        };
        if (data && data.feedback && data.feedback.feedback) {
            this.modalService.show(FeedbackModalComponent, {
                initialState,
                class: "modal-md",
            });
        } else {
            this.commonService.toast("No Feedback available", "Error");
        }
        this.modalService.onHide.subscribe(() => {});
    }

    pageChanged(event: any): void {
        this.items;
        this.p = event.page;
        this.query.page = this.p;
        this.getJobList();
    }
}
