import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  newUserType: any = 1;

  constructor() { }

  ngOnInit() {
    if(localStorage.getItem('newUserType') == 'enquiryAdmin'){
      // this.userType = 2;
      this.newUserType = 0;
    }
  }

}
