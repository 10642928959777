import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { SupportTicketService } from "../support-ticket.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-add-support-ticket-form",
  templateUrl: "./add-support-ticket-form.component.html",
  styleUrls: ["./add-support-ticket-form.component.sass"],
})
export class AddSupportTicketFormComponent implements OnInit {
  optionsVisibility = {};
  category = "";
  isLoading = false;
  users = [];
  images = [""];
  video = "";
  selectedUser = {};
  isValid = false;
  submitted = false;
  formData: FormGroup;
  fileToUpload: File = null;

  private initialData = {
    employeeId: "",
    customerName: "",
    phoneNumber: "",
    quoteCode: "",
    address: "",
    name: "",
    date: "",
    video: "",
    typeOfIssue: "",
    descriptionIssue: "",
  };

  constructor(
    private _fb: FormBuilder,
    private api: SupportTicketService,
    private commonService: CommonService,
    private router: Router
  ) {}

  
  get today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  ngOnInit() {
    this.formData = this._fb.group({
      employeeId: [this.initialData.employeeId],
      customerName: [this.initialData.customerName, [Validators.required]],
      phoneNumber: [this.initialData.phoneNumber, [Validators.required]],
      quoteCode: [this.initialData.quoteCode, [Validators.required]],
      address: [this.initialData.address, [Validators.required]],
      name: [this.initialData.name, [Validators.required]],
      date: [this.initialData.date, [Validators.required]],
      typeOfIssue: [this.initialData.typeOfIssue, [Validators.required]],
      descriptionIssue: [
        this.initialData.descriptionIssue,
        [Validators.required],
      ],
    });

    this.handleUserSearch("");
  }

  get f() {
    console.log(this.formData.controls,'the controles')
    return this.formData.controls;
  }

  addImages() {
    if (this.isLoading) {
      return this.commonService.toast("uploading Doc", "Info");
    }
    this.images.push("");
  }

  removeimage(index: number) {
    if (this.images.length > 1) {
      this.images.splice(index, 1);
    } else {
      this.images[0] = "";
    }
  }

  removeVideo() {
    this.video = "";
  }

  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;
    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  handleUserSearch(event: any) {
    const str = event ? event.target.value : "";

    const data = {
      search: str,
    };

    this.api.getUsers(data).subscribe(
      (result: any) => {
        const response = result.data;

        if (response.items) {
          this.users = response.items;
        }
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );

    this.selectedUser = {};
    this.formData.patchValue({
      employeeId: "",
    });
  }

  handleUserSelect(item: any) {
    this.selectedUser = item;

    this.formData.patchValue({
      employeeId: item._id,
    });
  }

  handleFileInput(files: FileList, type = "image", index: number) {
    this.fileToUpload = files.item(0);

    if (files.length === 1) {
      this.isLoading = true;

      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.isLoading = false;

          if (type === "image") {
            this.images.pop();
            this.images.push(result.data);
          } else {
            this.video = result.data;
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  handleSubmit() {
    this.submitted = true;

    if (this.formData.valid && this.isLoading === false) {
      const formData = this.formData.value;
      this.isLoading = true;

      this.api
        .addSupportTicket({
          ...formData,
          video: this.video,
          images: this.images.filter((data) => data !== ""),
        })
        .subscribe(
          (result: any) => {
            // this.formData.patchValue(this.initialData);
            // this.selectedUser = {};
            // this.images = [""];
            // this.video = "";
            // this.isLoading = false;

            this.router.navigate(["/Support/list/new_ticket"]);
            this.commonService.toast(result.message, "Success");
            this.submitted = false;
          },
          (error: any) => {
            this.isLoading = false;
            this.commonService.toast(error.error.message, "Error");
          }
        );
    }
  }
}
