import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
// import { addTimeSheet } from "./timesheet";

@Injectable({
    providedIn: "root",
  })

  export class VehicelService {

    constructor(private restRequest: RestRequest, private http: HttpClient) {}

    getList(data: any) {
      return this.restRequest
        .addAuthorization()
        .get(`vehicles?&page=${data.page}`);
    }

    addData(data: any) {
       return this.restRequest.addAuthorization().post(`vehicle`, data);
    }
  
    updateVehicle(id: string, data: any) {
      return this.restRequest
        .addAuthorization()
        .put(`vehicle/${id}`, data);
    }
  
    deleteData(id: string) {
      return this.restRequest.addAuthorization().delete(`vehicle/${id}`);
    }

  }