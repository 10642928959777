import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { DocumentServices } from "../document.service";

@Component({
  selector: "app-administrative-form",
  templateUrl: "./administrative-form.component.html",
  styleUrls: ["./administrative-form.component.sass"],
})
export class AdministrativeFormComponent implements OnInit {
  formData: FormGroup;
  submitted = false;
  loading = false;
  isLoading = false;
  fileToUpload: File = null;
  image = "";
  isEdit = false;
  isChange = true;
  title = "";
  private initialData = {
    documentName: "",
    uploadDate: "",
    description: "",
    submittedBy: "",
    document: "",
    _id: "",
    documentType: "",
  };

  constructor(
    private _fb: FormBuilder,
    private api: DocumentServices,
    private commonService: CommonService,
    private bsModalRef: BsModalRef
  ) {}

  get today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  getCurrentDate(date: Date): string {
    // Format date as YYYY-MM-DD
    return date.toISOString().split("T")[0];
  }
  ngOnInit() {
    console.log(this.initialData, "this.initialData");
    this.isEdit
      ? (this.formData = this._fb.group({
          documentName: [this.initialData.documentName, [Validators.required]],
          description: [this.initialData.description],
          submittedBy: [this.initialData.submittedBy],
          document: [this.initialData.document],
          documentType: [this.initialData.documentType],
          uploadDate: [
            this.getCurrentDate(new Date(this.initialData.uploadDate)),
          ],
        }))
      : (this.formData = this._fb.group({
          documentName: [this.initialData.documentName, [Validators.required]],
          description: [this.initialData.description],
          submittedBy: [this.initialData.submittedBy],
          document: [this.initialData.document],
          uploadDate: [this.initialData.uploadDate],
          documentType: [this.initialData.documentType],
        }));
  }

  handleFileInput(files: FileList, type = "image", index: number) {
    this.fileToUpload = files.item(0);

    if (files.length === 1) {
      this.isLoading = true;
      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.isLoading = false;
          if (type === "image") {
            this.formData.patchValue({ document: result.data });
            this.isChange = false;
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  get f() {
    return this.formData.controls;
  }

  handleAddUpdateButton() {
    this.submitted = true;
    console.log(this.formData.valid, "this.formData.valid");
    if (!this.formData.valid) {
      return;
    }
    console.log(this.formData.value);
    this.loading = true;
    if (this.isEdit) {
      this.api
        .editAdministrative(this.initialData._id, this.formData.value)
        .subscribe(
          (data: any) => {
            this.loading = false;
            this.commonService.toast(data.message, "Success");
            this.submitted = false;
            this.bsModalRef.hide();
          },
          (err) => {
            this.commonService.toast(err.error.message, "Error");
            this.loading = false;
          }
        );
    } else {
      this.api.addAdministrative(this.formData.value).subscribe(
        (data: any) => {
          this.loading = false;
          this.commonService.toast(data.message, "Success");
          this.submitted = false;
          this.bsModalRef.hide();
        },
        (err) => {
          this.commonService.toast(err.error.message, "Error");
          this.loading = false;
        }
      );
    }
  }

  cancelModal() {
    this.bsModalRef.hide();
  }
}
