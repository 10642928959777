import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class SalesPerformanceService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getsalesView(
    startDate: any,
    endDate: any,
    previousStartDate: any,
    previusEndDate: any,
    filterField: any
  ) {
    return this.restRequest
      .addAuthorization()
      .get(
        `sales-performance?startDate=${startDate}&endDate=${endDate}&previousStartDate=${previousStartDate}&previousEndDate=${previusEndDate}&target=${filterField}`
      );
  }

  editTarget(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`sales-performance/target/${id}`, data);
  }

  addOperatingExpense(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`sales-performance/target/${id}`, data);
  }

  getSalesDetail(id: any) {
    console.log("heyyyyyyyyyyy");
    return this.restRequest.addAuthorization().get(`sales-performance/${id}`);
  }
}
