import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import * as moment from "moment-timezone";
@Component({
  selector: "app-incident-pop",
  templateUrl: "./incident-pop.component.html",
  styleUrls: ["./incident-pop.component.sass"],
})
export class IncidentPopComponent implements OnInit {
  items: any;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.items.incidentOccurred = moment(this.items.incidentOccurred).format(
      "DD/MM/YYYY"
    );
  }
  handleCloseModal() {
    this.bsModalRef.hide();
  }
}
