import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class CompanyOverviewService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}
  

  getoverview(startDate:any,endDate:any,previousStartDate:any,previusEndDate:any,filterField:any) {
    return this.restRequest
      .addAuthorization()
      .get(
        `company-overview?startDate=${startDate}&endDate=${endDate}&previousStartDate=${previousStartDate}&previousEndDate=${previusEndDate}&target=${filterField}`
      );
  }

 
  editTarget(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`company-overview/target/${id}`, data);
  }

  addOperatingExpense (data:any , id:any) {
    return this.restRequest
      .addAuthorization()
      .put(`company-overview/operating-expense/${id}`, data);
  }

  addDoc (data:any ) {
    return this.restRequest
    .addAuthorization()
    .put(`company-overview/doc`,data)
  }

  addNote (data:any) {
    return this.restRequest
    .addAuthorization()
    .post(`company-overview/note`,data)
  }

  

}
