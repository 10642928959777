import { Injectable } from '@angular/core';
import { RestRequest } from '../../../services/rest-request';

@Injectable()
export class DashboardService {
  APIEndpoint = '';
  constructor(private restRequest: RestRequest) {}

  // recentUsers(): Observable<RecentUsersResponse> {
  recentUsers() {
    return this.restRequest.addAuthorization().get('users/recent');
  }

  recentDrivers() {
    return this.restRequest.addAuthorization().get('drivers/recent');
  }

  recentTasks() {
    return this.restRequest.addAuthorization().get('jobs/recent');
  }

  totalUsers(filter) {
    return this.restRequest.addAuthorization().get('users/total?filter=' + filter);
  }

  totalDrivers(filter) {
    return this.restRequest.addAuthorization().get('drivers/total?filter=' + filter);
  }

  totalTasks(filter) {
    return this.restRequest.addAuthorization().get('jobs/total?filter=' + filter);
  }

  totalEarnings(filter) {
    return this.restRequest.addAuthorization().get('earning/total?filter=' + filter);
  }
}
