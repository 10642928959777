import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { CommonService } from '../../../services/common.service';

@Component({
  selector: 'app-pdppopupview',
  templateUrl: './pdppopupview.component.html',
  styleUrls: ['./pdppopupview.component.sass']
})
export class PdppopupviewComponent implements OnInit {
  userid = null;
  pdf = null;


  constructor(private bsModalRef: BsModalRef,
    private commonService: CommonService,) { }

  ngOnInit() {
    console.log(this.pdf)
  }
  decline(): void {
    this.bsModalRef.hide();
  }

}
