import { Component, DoCheck, OnInit } from "@angular/core";
import { BsModalService } from "ngx-bootstrap";

import { PageTitleService } from "../../services/page-title.service";
import { PasswordCheckerComponent } from "src/app/modules/inventory/password-checker/password-checker.component";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  isAuthorized = sessionStorage.getItem("ivu");
  public isCollapsed = false;

  selectedPage = "Dashboard";
  userType: any;
  newUserType: any = 1;
  constructor(
    private pageTitleService: PageTitleService,
    private modalService: BsModalService
  ) {
    if (localStorage.getItem("userType") == "admin") {
      this.userType = 1;
    }
    if (localStorage.getItem("userType") == "superAdmin") {
      this.userType = 0;
    }
    if (localStorage.getItem("newUserType") == "enquiryAdmin") {
      // this.userType = 2;
      this.newUserType = 0;
    }
  }

  ngOnInit() {
    this.pageTitleService.currentMessage.subscribe(
      (pageTitle) => (this.selectedPage = pageTitle)
    );
  }

  handleUserAuthCheck(url:any,name:string) {
    if (!this.isAuthorized) {
      const initialState = {
        component: "inventory",
        url,
        name
      };
      this.modalService.show(PasswordCheckerComponent, {
        initialState,
        class: "modal-lg",
      });

      this.modalService.onHide.subscribe(() => {
        this.isAuthorized = sessionStorage.getItem("ivu");
      });
    }
  }

}
