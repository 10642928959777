import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { RepairService } from '../../repair/repair.service';
import { TemplateService } from '../../templates/template.service';



@Component({
  selector: 'app-repair-method-add',
  templateUrl: './repair-method-add.component.html',
  styleUrls: ['./repair-method-add.component.sass']
})
export class RepairMethodAddComponent implements OnInit {

  listData: any;
  repForm: FormGroup;
  submitted: boolean;
  editId = true;
  _id = '';
  userData = {
    image: ''
  };
  fileToUpload: File = null;
  notpdf;
  loader;
  fileToUpload1 = null;
  dropVal = null;
  version: any;
  firstPdf: boolean = true;
  pdfId: any;

  constructor(
    private router: Router,
    private repairService: RepairService,
    public commonService: CommonService,
    private _fb: FormBuilder,
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.templateService.getTemplate(null).subscribe((result: any) => {
      this.listData = [];
      if (result.success === true) {
        this.listData = result.data;
      }
    }, (error: any) => {
      // this.commonService.toast('Something went wrong', 'Error');
    });
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this._id = this.activatedRoute.snapshot.params.id;
      // this.version = this.activatedRoute.snapshot.queryParams.version
      if(this.activatedRoute.snapshot.queryParams.quote !== undefined){
        this.editId = false;
        this.pdfId = this.activatedRoute.snapshot.queryParams.quote;
        // this.loadData(this.activatedRoute.snapshot.queryParams.quote);
        
      }
    }
    this.repairService.getRepairById({tempId: this._id}).subscribe((result: any) => {
      let allData : any = [];
       allData = result && result.data;
      if(result && result.data.length) {
        this.firstPdf = false;
      }else{
        this.firstPdf = true;
      }
      if(!this.editId){
        allData[0].pdf.forEach(element => {
          if(this.pdfId == element._id){
            this.editRepData(element);
          }
        });
        
      }
    }, (error: any) => {
      this.firstPdf = true;
      // this.commonService.toast('Something went wrong', 'Error');
    });
    this.repForm = this._fb.group({
      repSolution: [null, [Validators.required, Validators.minLength(2)]],
      //  repairImage: [null, [Validators.required]],
      image: [null],
      hidfile: [null, [Validators.required]],
      tempName: [this._id]
    });
  }

  get tempName() {
    const temp = this.repForm.controls.tempInfo as FormGroup;
    return temp.controls.tempName;
  }

  get hidfile() {
    return this.repForm.get('hidfile');
  }
  get repSolution() {
    return this.repForm.get('repSolution');
  }

  loadData(repId) {
    this.loader = true;
    this.repairService.getRepairById({tempId : repId}).subscribe((result: any) => {
      const items = result.data;
      this.editRepData(items);
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  handleFileInput(files: FileList) {
    this.fileToUpload1 = null;
    this.notpdf = false;
    this.fileToUpload = files.item(0);
    if (!this.fileToUpload.type.match('application/pdf')) {
      this.notpdf = true;
    } else {
      this.notpdf = false;
      this.commonService.fileUpload(this.fileToUpload).subscribe((result: any) => {
        this.fileToUpload1 = true;
        this.userData.image = result.data;
        this.repForm.patchValue({
          hidfile: result.data
        });
      });
    }
  }

  editRepData(data) {
    this.fileToUpload1 = true;
    this.userData.image = data.file;
    this.repForm.patchValue({
      repSolution: data && data.name ? data.name : '',
      hidfile: data.file,
      tempName: this._id
    });
  }

  submitHandler() {

    if (!this.repForm.invalid) {
      this.submitted = false;
      const obj = {
        // repairSolution: this.repForm.controls.repSolution.value,
        // repairImage: this.repForm.controls.hidfile.value,
        temp_id: this.repForm.controls.tempName.value,
        pdf : {
          name : this.repForm.controls.repSolution.value,
          file :  this.repForm.controls.hidfile.value,
        }
      };

      if(this.firstPdf){
        this.repairService.createQuote(obj).subscribe((response: any) => {
          if (response.success === true) {
            this.commonService.toast(`Quote PDF added Successfully.`, 'Success');
            this.router.navigate([`/ipad/repair/${this._id}`]);
          } else {
            if (response.success === false) {
              this.commonService.toast(response.message, 'Error');
            } else {
              this.commonService.toast(response.message.toString(), 'Error');
            }
          }
        }, error => {
          this.commonService.toast(error.error.message, 'Error');
        });
      }else{
        this.repairService.addQuote(obj).subscribe((response: any) => {
          if (response.success === true) {
            this.commonService.toast(`Quote PDF added Successfully.`, 'Success');
            this.router.navigate([`/ipad/repair/${this._id}`]);
          } else {
            if (response.success === false) {
              this.commonService.toast(response.message, 'Error');
            } else {
              this.commonService.toast(response.message.toString(), 'Error');
            }
          }
        }, error => {
          this.commonService.toast(error.error.message, 'Error');
        });
      }
    } else {
      this.submitted = true;
    }
  }

  get f() {
    return this.repForm.controls;
  }

  updateRepair() {
    this.submitted = true;
    if (!this.repForm.invalid) {
      const obj = {
        // repairSolution: this.repForm.controls.repSolution.value,
        // repairImage: this.repForm.controls.hidfile.value,
        temp_id: this.repForm.controls.tempName.value,
        pdf_id: this.pdfId,
        pdf : {
          name : this.repForm.controls.repSolution.value,
          file :  this.repForm.controls.hidfile.value,
        }
        // _id: this._id
      };
      this.repairService.updateQuote(obj).subscribe((response: any) => {
        if (response.success === true) {
          this.commonService.toast(`Quote PDF has been updated successfully.`, 'Success');
          this.router.navigate([`/ipad/repair/${this._id}`]);
        } else {
          if (response.success === false) {
            this.commonService.toast(response.message, 'Error');
          } else {
            this.commonService.toast(response.message.toString(), 'Error');
          }
        }
      }, error => {
        this.commonService.toast(error.error.message, 'Error');
      });
    } else {
      console.log('Validation error');
    }
  }


}
