import { Routes } from '@angular/router';
import { InspectionComponent } from './inspection/inspection.component';
import {InspectionListComponent} from './inspection-list/inspection-list.component';
import {InspectionListPendingComponent} from './inspection-list-pending/inspection-list-pending.component';
import {InspectionListConfirmedComponent} from './inspection-list-confirmed/inspection-list-confirmed.component';
import {InspectionListAcceptedComponent} from './inspection-list-accepted/inspection-list-accepted.component'
import {InspectionListRejectedComponent} from './inspection-list-rejected/inspection-list-rejected.component'
import {AuthGuardService} from '../../modules/auth/services/auth-guard.service';
import {AdminLayoutComponent} from '../../layouts/admin-layout/admin-layout.component'

export const InspectionRoutes: Routes = [
  // { path: 'Request-a-free-inspection', component: InspectionComponent },
  // {
  //   path: '', 
  //   //component: AdminLayoutComponent,
  //    children: [
  //     { path: 'list/open', component: InspectionListComponent, canActivate: [AuthGuardService] },
  //     { path: 'list/pending', component: InspectionListPendingComponent, canActivate: [AuthGuardService] },
  //     { path: 'list/confirmed', component: InspectionListConfirmedComponent, canActivate: [AuthGuardService] },
  //     { path: 'list/accepted', component: InspectionListAcceptedComponent, canActivate: [AuthGuardService] },
  //     { path: 'list/rejected', component: InspectionListRejectedComponent, canActivate: [AuthGuardService] }
  //   ]
  // },
]