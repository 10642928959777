import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ToolboxService } from '../toolbox.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: "app-toolbox-list",
    templateUrl: "./toolbox-list.component.html",
    styleUrls: ["./toolbox-list.component.sass"],
})
export class ToolboxListComponent implements OnInit {
    toolboxId: "";
    nodata = false;
    loader;
    items;
    p = 1;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    tempquery: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };
    recordsPerPage: number;
    totalRecords = 0;
    selectedCause: string;
    listData: any = [];
    causeList: any;

    constructor(
        private router: Router,
        public commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private toolboxService: ToolboxService
    ) {
        this.recordsPerPage = environment.defaultPageSize;
    }

    ngOnInit() {
        this.loader = true;
        this.query.search = this.searchText;
        this.toolboxService.getTemplate(this.tempquery).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                    if (this.activatedRoute.snapshot.params.id !== undefined) {
                        this.toolboxId = this.activatedRoute.snapshot.params.id;
                        this.getToolboxListById(this.toolboxId);
                    } else {
                        this.getToolboxList();
                    }
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
    }
    getToolboxList() {
        this.query.search = this.searchText;
        this.toolboxService.getToolboxListV2(this.query).subscribe(
            (result: any) => {
                const items = result.data.data;
                this.items = items;
                this.totalRecords = result.data.totalCount;
                this.query.tempId = "all";
                this.loader = false;
                this.nodata = false;
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
                this.query.tempId = "all";
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    pageChanged(event: any): void {
        this.p = event.page;
        this.query.page = this.p;
        this.getToolboxList();
    }
    dropdownValueChange(e) {
        console.log(e.target.value);
        e.target.value == "all" ? null : (this.query.tempId = e.target.value);
        this.getToolboxList();
    }

    getCauseList2(id) {
        // this.causeser.getCauseList().subscribe((result: any) => {
        //   const items = result.data;
        if (id !== "all") {
            let userData = [];
            userData = this.causeList.filter((data) => data.tempId == id);
            this.items = userData.sort((a, b) => a.cause_index - b.cause_index);
        }

        if (id == "all") {
            this.items = this.causeList;
        }
        //   this.loader = false;
        // }, (error: any) => {
        //   this.loader = false;
        //   this.nodata = true;
        //   //      this.commonService.toast(error.error.message, 'Error');
        // });
    }

    getRoute(url, id) {
        const myurl = `${url}/${id}`;
        this.router.navigateByUrl(myurl).then((e) => {
            if (e) {
                // console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }

    getToolboxListById(idVal: string) {
        // const obj = { tempId: idVal };
        this.toolboxService.getToolboxList().subscribe(
            (result: any) => {
                const items = result.data;
                this.items = items.filter((item) => item.tempId === idVal);
                this.loader = false;
                if (this.items.length === 0) {
                    this.nodata = true;
                }
            },
            (error: any) => {
                this.loader = false;
                //      this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    deleteToolbox(id) {
        const obj = {
            _id: id,
        };
        this.toolboxService.deleteToolboxData(obj).subscribe(
            (result: any) => {
                this.loader = false;
                if (result.success === true) {
                    this.commonService.toast(result.message, "Success");
                } else {
                    this.commonService.toast(result.message, "Error");
                }
                if (this.activatedRoute.snapshot.params.id !== undefined) {
                    this.toolboxId = this.activatedRoute.snapshot.params.id;
                    this.getToolboxListById(this.toolboxId);
                } else {
                    this.getToolboxList();
                }
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    navigateAddToolbox() {
        this.router.navigate(["/toolbox/add"]);
    }
}
