import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import {QualityService} from '../quality.service';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import jsPDF from "jspdf";
// declare let jsPDF;
// declare const require: any;
// const jsPDF = require('jspdf');
// require('jspdf-autotable');
@Component({
  selector: 'app-viewmodal',
  templateUrl: './viewmodal.component.html',
  styleUrls: ['./viewmodal.component.sass']
})
export class ViewmodalComponent implements OnInit {
  userid = null;
  item = [];
  imagelength;
  videolength;
  img;
  i=0;
  name;
  lname;
  createdAt;
  address;
  address1;
  City;
  state;
  postcode;
  phonenumber;
  email;
  templatename;
  response;
  issuetype;
  textaboutissue;
  checkboxList = [
    {name : 'Very Dissatisfied',value : 'very_dissatisfied', selected : false},
    {name : 'Somewhat Dissatisfied', value : 'somewhat_dissatisfied', selected : false},
    {name : 'Average Satisfied',value : 'average_satisfied', selected : false},
    {name : 'Somewhat Satisfied',value : 'somewhat_satisfied', selected : false},
    {name : 'Very Satisfied',value : 'very_satisfied', selected : false}
  ];
  QualityResponse: any;
   constructor(private bsModalRef: BsModalRef,
               private quality: QualityService,
               private commonService: CommonService,
               private router: Router,
               private activatedRoute: ActivatedRoute) { }
  ngOnInit() {
    this.response = false;
    this.getinspectionlist(this.userid);
  }
  decline(): void {
    this.bsModalRef.hide();
  }
  getinspectionlist(obj){
    
    this.quality.getqualitydatabyid(obj).subscribe((result: any) => {
      const items = result.data;
      this.item = items;
      this.QualityResponse = result.data;

      if(result.data.videoarray.length == 0) {
        this.videolength = false;
       }
       else {
         this.videolength = true;
       }

      if(result.data.imagearray.length == 0) {
       this.imagelength = false;
      }
      else {
        this.imagelength = true;
      }
      this.response = true;
      console.log(this.item);
    }, (error: any) => {
      // this.commonService.toast(error.error.message, 'Error');
    });
  }

  downloadSignOff(data2) {
    console.log('pdf clicked');
      // const data = res.result;
      var doc = new jsPDF("p", "pt", "a4");
      // const x = 30;
      // let y = 90;
      // let xx = (doc.internal.pageSize.width / 2) + 10;
      // let top = 18, lineNumber = 1;;
      // const line = 17;
      doc.text('',0,0).setFontSize(22).setFont(undefined, 'bold')
      let x = doc.text('BuildFix Job Completion and Sign-Off Form',20, 20,{align:'justify'}).setFont(undefined, 'normal');
      x.setFontSize(12);
        doc.text( 'I agree the job is complete and was carried out as per quote and detail*.',20, 50,{},2);
        // doc.text( 'Yes',20, 70,{},1);
        doc.text(this.QualityResponse.signOff.carriedPerQuote,20, 75,{},1);
        doc.text('Name',20, 120,{},1)
        doc.addImage(this.QualityResponse.signOff.ownerName, "JPEG", 20, 150, 600, 80)

        doc.text('Signature',20, 250,{},1)
        doc.addImage(this.QualityResponse.signOff.ownerSignature, "JPEG", 20, 280, 600, 80)

        doc.text('Feedback',20, 400,{},1)
        let arr = [];
        this.checkboxList.forEach(element => {
          this.QualityResponse.signOff.feedback.forEach(item=>{
            if(element.value == item){
              arr.push(element.name);
            }
          })
        });
        for(let i=0;i<arr.length;i++){
          let x = i + 1;
          doc.text(`${x}) ${arr[i]}`,20,430+i*20,{},1);
        }
        // doc.addPage();
      // var img = new Image();
      
      // var src = "assets/images/check.png";
      // img.src = src;
     
      // Get page width
      // var pageWidth = doc.internal.pageSize.width
      // var txtWidth = doc.getStringUnitWidth('BuildFix Job Completion ') * 25 / doc.internal.scaleFactor;
      // var center1 = (pageWidth - txtWidth) / 2;
      // var txtWidth1 = doc.getStringUnitWidth('CLIENT INFORMATION') * 22 / doc.internal.scaleFactor;
      // var center2 = (pageWidth - txtWidth1) / 2;

    //   if (data.location && data.location[0] && data.location[0].company && data.location[0].company.logo_url) {
    //     // var logo = new Image();
    //     // var src1 = data.location[0].company.logo;
    //     // logo.src = src1;
    //     doc.addImage(data.location[0].company.logo_url, "JPEG", center1 + 20, 20, 30, 30);
    //   }
    //   doc.fromHTML('<div style="font-size: 25px;"><strong>' + data.location[0].company.businessName + '</strong></div>', center1 + 60, 20)
    //   doc.fromHTML('<div style="font-size: 22px;letter-spacing: 16px;"><strong>CLIENT  INFORMATION</strong></div>', center2 + 20, 50)
    //   // doc.fromHTML('<div style="font-size: 14px;color: darkgoldenrod"><strong>' + data.location[0].company.businessName + ' is a professional massage business.</strong></div>', center2, 80)
    //   // doc.fromHTML('<div style="font-size: 14px; color: darkgoldenrod"><strong>Please do not ask our therapist to sexual service..</strong></div>', center2 + 40, 90)
    //   // doc.rect(center2 + 60, 110, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 14px;color: darkgoldenrod"><strong>tick here if you agree</strong></div>', center2 + 80, 105)
    //   doc.setFillColor(0, 0, 0);
    //   doc.rect(x, y - 10, 130, 20, 'F');
    //   doc.triangle(160, y - 10, 160, y + 10, 170, y, 'FD');
    //   doc.fromHTML('<div style="font-size: 17px; color: white;"><strong>Personal Information</strong> </div>', x + 5, y - 10)

    //   doc.fromHTML('<div style="font-size: 17px;">First name: </div>', x, y + (lineNumber * top))
    //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.firstName + '</div>', x + 80, y + (lineNumber * top))
    //   doc.line(x + 250, y + (lineNumber * top) + line, x + 80, y + (lineNumber * top) + line);
    //   doc.fromHTML('<div style="font-size: 17px;">Last name: </div>', xx, y + (lineNumber * top))
    //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.lastName + '</div>', xx + 80, y + (lineNumber * top))
    //   doc.line(xx + 250, y + (lineNumber * top) + line, xx + 80, y + (lineNumber * top) + line);

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 17px;">Email: </div>', x, y + (lineNumber  *  top))
    //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.email + '</div>', x + 80, y + (lineNumber  *  top))
    //   doc.line(x + 250, y + (lineNumber  *  top) + line, x + 80, y + (lineNumber  *  top) + line);
    //   doc.fromHTML('<div style="font-size: 17px;">Mobile: </div>', xx, y + (lineNumber  *  top))
    //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.phone1 + '</div>', xx + 80, y + (lineNumber  *  top))
    //   doc.line(xx + 250, y + (lineNumber  *  top) + line, xx + 80, y + (lineNumber  *  top) + line);

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 17px;">Gender: </div>', x, y + (lineNumber  *  top))

    //   if (data.client_info.personal.gender == "Male") {
    //     doc.addImage(img, "PNG", x + 70, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.client_info.personal.gender == "Female") {
    //     doc.addImage(img, "PNG", x + 130, y + (lineNumber  *  top) + 4, 10, 10);
    //   }
      
    //   doc.rect(x + 70, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Male </div>', x + 90, y + (lineNumber  *  top))
    //   doc.rect(x + 130, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Female </div>', x + 145, y + (lineNumber  *  top))


    //   doc.fromHTML('<div style="font-size: 17px;">Date of Birth: </div>', xx, y + (lineNumber  *  top))
    //   if (data.client_info.personal && data.client_info.personal.dob && data.client_info.personal.dob != null) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.dob ? moment(data.client_info.personal.dob).format('DD/MM/YYYY') : "" + '</div>', xx + 80, y + (lineNumber  *  top) - 3)
    //   }
    //   doc.line(xx + 250, y + (lineNumber  *  top) + line, xx + 80, y + (lineNumber  *  top) + line);



    //   // doc.fromHTML('<div style="font-size: 17px;">Address: </div>', x, y + (4 * top))
    //   // if (data.client_info.personal && data.client_info.personal.address && data.client_info.personal.address.area) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.address.area + '</div>', x + 80, y + (4 * top))
    //   // }
    //   // doc.line(x + 250, y + (4 * top) + line, x + 80, y + (4 * top) + line);

    //   // doc.fromHTML('<div style="font-size: 17px;">City: </div>', xx, y + (4 * top))
    //   // if (data.client_info.personal && data.client_info.personal.address && data.client_info.personal.address.city) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.address.city + '</div>', xx + 50, y + (4 * top))
    //   // }
    //   // doc.line(xx + 120, y + (4 * top) + line, xx + 50, y + (4 * top) + line);
    //   // doc.fromHTML('<div style="font-size: 17px;">State: </div>', xx + 125, y + (4 * top))
    //   // if (data.client_info.personal && data.client_info.personal.address && data.client_info.personal.address.state) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.personal.address.state + '</div>', xx + 160, y + (4 * top))
    //   // }
    //   // doc.line(xx + 250, y + (4 * top) + line, xx + 160, y + (4 * top) + line);

    //   // doc.fromHTML('<div style="font-size: 17px;">Emergency Contact: </div>', x, y + (5 * top))
    //   // if (data.client_info && data.client_info.emergencyContactName) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.emergencyContactName + '</div>', x + 120, y + (5 * top))
    //   // }
    //   // doc.line(x + 250, y + (5 * top) + line, x + 120, y + (5 * top) + line);

    //   // doc.fromHTML('<div style="font-size: 17px;">Relation: </div>', xx, y + (5 * top))
    //   // if (data.client_info && data.client_info.emergencyContactRelation) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.emergencyContactRelation + '</div>', xx + 50, y + (5 * top))
    //   // }
    //   // doc.line(xx + 120, y + (5 * top) + line, xx + 50, y + (5 * top) + line);


    //   // doc.fromHTML('<div style="font-size: 17px;">Phone: </div>', xx + 125, y + (5 * top))
    //   // if (data.client_info && data.client_info.emergencyContactNo) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.client_info.emergencyContactNo + '</div>', xx + 170, y + (5 * top))
    //   // }
    //   // doc.line(xx + 250, y + (5 * top) + line, xx + 170, y + (5 * top) + line);

    //   // doc.fromHTML('<div style="font-size: 17px;">How did you hear about us : </div>', x, y + (5 * top))
    //   // doc.rect(x + 160, y + (5 * top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Facebook </div>', x + 180, y + (5 * top))
    //   // doc.rect(xx, y + (5 * top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Google Map </div>', xx + 15, y + (5 * top))
    //   // doc.rect(xx + 100, y + (5 * top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Friend </div>', xx + 115, y + (5 * top))
    //   // doc.rect(xx + 200, y + (5 * top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Youtube </div>', xx + 215, y + (5 * top))

    //   // doc.rect(x, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Drive Pass </div>', x + 15, y + (lineNumber  *  top))
    //   // doc.rect(x + 80, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Walk Pass </div>', x + 95, y + (lineNumber  *  top))
    //   // doc.rect(x + 160, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Trip Advisor </div>', x + 180, y + (lineNumber  *  top))
    //   // doc.rect(xx, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Radio </div>', xx + 15, y + (lineNumber  *  top))
    //   // doc.rect(xx + 100, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">Newpapre </div>', xx + 115, y + (lineNumber  *  top))
    //   // doc.rect(xx + 200, y + (lineNumber  *  top) + 4, 10, 10);
    //   // doc.fromHTML('<div style="font-size: 17px;">TV </div>', xx + 215, y + (lineNumber  *  top))


    //   // doc.fromHTML('<div style="font-size: 17px;">Friends, if yes his/her name: </div>', x, y + (lineNumber  *  top))
    //   // doc.line(x + 250, y + (lineNumber  *  top) + line, x + 160, y + (lineNumber  *  top) + line);
    //   // doc.fromHTML('<div style="font-size: 17px;"> Other, if yes, what is that: </div>', xx, y + (lineNumber  *  top))
    //   // doc.line(xx + 250, y + (lineNumber  *  top) + line, xx + 150, y + (lineNumber  *  top) + line);
    //   //Medical Information

    //   /*---------------------- 2 lines----------------------------- */
    //   lineNumber = lineNumber + 1;
    //   doc.line(x, y + (lineNumber  *  top) + line - 10, xx + 250, y + (lineNumber  *  top) + line - 10);

    //   /*---------------------- vertical lines----------------------------- */
    //   doc.line(x + 260, y + (lineNumber  *  top) + line - 10, x + 260, y + (lineNumber  *  top) + line + 445);

    //   lineNumber = lineNumber + 1;
    //   doc.setFillColor(0, 0, 0);
    //   doc.rect(x, y + (lineNumber  *  top), 140, 20, 'F');
    //   doc.triangle(170, y + (lineNumber  *  top), 170, y + (lineNumber  *  top) + 20, 180, y + (lineNumber  *  top) + 10, 'FD');
    //   doc.fromHTML('<div style="font-size: 17px; color: white;"><strong>Medical Information</strong> </div>', x + 5, y + (lineNumber  *  top))

    //    doc.setFillColor(0, 0, 0);
    //   doc.rect(xx, y + (lineNumber  *  top), 140, 20, 'F');
    //   doc.triangle(xx + 140, y + (lineNumber  *  top), xx + 140, y + (lineNumber  *  top) + 20, xx + 150, y + (lineNumber  *  top) + 10, 'FD');
    //   doc.fromHTML('<div style="font-size: 16px; color: white;"><strong>Message Information</strong> </div>', xx + 5, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1.5;
    //   doc.fromHTML('<div style="font-size: 16px;">Are you taking any medications? </div>', x, y + (lineNumber  *  top))
    //   doc.rect(x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', x + 180, y + (lineNumber  *  top))
    //   doc.rect(x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', x + 225, y + (lineNumber  *  top))

    //   if (data.medical_info && data.medical_info.medicationStatus == 'yes') {
    //     doc.addImage(img, "PNG", x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.medical_info && data.medical_info.medicationStatus == 'no') {
    //     doc.addImage(img, "PNG", x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   doc.fromHTML('<div style="font-size: 16px;"> Would you mind MALE therapist to give you massage? </div>', xx, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">If yes, please name and use: </div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 160, y + (lineNumber  *  top) + line);

       
    //   doc.rect(xx, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">I cannot accept MALE therapist </div>', xx + 15, y + (lineNumber  *  top))

    //   if (data.massage_info && (data.massage_info.therapistGender == 'Female' || data.massage_info.therapistGender == 'female')) {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top) + 4, 10, 10); 
        
    //   } 
      
    //   lineNumber = lineNumber + 1;
    //   if (data.massage_info && data.massage_info.therapistGender == 'Male' || data.massage_info.therapistGender == 'male') {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top) + 4, 10, 10);
    //   }
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.medicationDetails) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.medicationDetails + '</div>', x, y + (lineNumber  *  top))
    //   }

    //   doc.rect(xx, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">I am fine with MALE therpaist </div>', xx + 15, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">Are you currently pregnant? </div>', x, y + (lineNumber  *  top))
    //   doc.rect(x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', x + 180, y + (lineNumber  *  top))
    //   doc.rect(x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', x + 225, y + (lineNumber  *  top))

    //   if (data.medical_info && data.medical_info.pregnancyStatus == 'yes') {
    //     doc.addImage(img, "PNG", x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.medical_info && data.medical_info.pregnancyStatus == 'no') {
    //     doc.addImage(img, "PNG", x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   doc.fromHTML('<div style="font-size: 16px;">Have you had a professional massage before? </div>', xx, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">If yes, how far along ?</div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 140, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.pregnancyDuration) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.pregnancyDuration + '</div>', x + 140, y + (lineNumber  *  top))
    //   }

    //   doc.rect(xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', xx + 190, y + (lineNumber  *  top))
    //   doc.rect(xx + 230, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', xx + 245, y + (lineNumber  *  top))
    //   if (data.massage_info && data.massage_info.priorMassage == 'yes') {
    //     doc.addImage(img, "PNG", xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.priorMassage == 'no') {
    //     doc.addImage(img, "PNG", xx + 230, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">Any high risk factors ?</div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 140, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.pregnancyRiskFactor) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.pregnancyRiskFactor + '</div>', x + 140, y + (lineNumber  *  top))
    //   }

    //   doc.fromHTML('<div style="font-size: 16px;">What is the pressure do you prefer? </div>', xx, y + (lineNumber  *  top))
      
    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">Do you suffer from chronic pain ?</div>', x, y + (lineNumber  *  top))
    //   doc.rect(x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', x + 180, y + (lineNumber  *  top))
    //   doc.rect(x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', x + 225, y + (lineNumber  *  top))

    //   if (data.medical_info && data.medical_info.chronicPainStatus == 'yes') {
    //     doc.addImage(img, "PNG", x + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.medical_info && data.medical_info.chronicPainStatus == 'no') {
    //     doc.addImage(img, "PNG", x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   doc.rect(xx + 5, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">Soft </div>', xx + 20, y + (lineNumber  *  top))
    //   doc.rect(xx + 50, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">Medium </div>', xx + 65, y + (lineNumber  *  top))
    //   doc.rect(xx + 115, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">Hard </div>', xx + 130, y + (lineNumber  *  top))
    //   doc.rect(xx + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">Extremely Hard </div>', xx + 180, y + (lineNumber  *  top))
    //   if (data.massage_info && data.massage_info.pressure == 'Soft' || data.massage_info && data.massage_info.pressure == 'soft') {
    //     doc.addImage(img, "PNG", xx + 5, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.pressure == 'Medium' || data.massage_info && data.massage_info.pressure == 'medium') {
    //     doc.addImage(img, "PNG", xx + 50, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.pressure == 'Hard' || data.massage_info && data.massage_info.pressure == 'hard') {
    //     doc.addImage(img, "PNG", xx + 115, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.pressure == 'extremely hard') {
    //     doc.addImage(img, "PNG", xx + 165, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">If yes, please explain</div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 140, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.chronicPainDetails) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.chronicPainDetails + '</div>', x + 140, y + (lineNumber  *  top))
    //   }

      
    //   doc.fromHTML('<div style="font-size: 16px;">Do you have any allergies or sensitivities</div>', xx, y + (lineNumber  *  top))
      

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">What makes it better ? </div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 140, y + (lineNumber  *  top) + line);

    //   doc.fromHTML('<div style="font-size: 16px;">(essential oil)?</div>', xx, y + (lineNumber  *  top))
    //   doc.rect(xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', xx + 190, y + (lineNumber  *  top))
    //   doc.rect(xx + 230, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', xx + 245, y + (lineNumber  *  top))
    //   if (data.massage_info && data.massage_info.allergiesStatus == 'yes') {
    //     doc.addImage(img, "PNG", xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.allergiesStatus == 'no') {
    //     doc.addImage(img, "PNG", xx + 230, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.chronicPainMakesBetter) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.chronicPainMakesBetter + '</div>', x, y + (lineNumber  *  top))
    //   }

    //   doc.fromHTML('<div style="font-size: 16px;"> Please explain:</div>', xx + 20, y + (lineNumber  *  top))
    //   doc.line(xx + 250, y + (lineNumber  *  top) + line, xx + 100, y + (lineNumber  *  top) + line);
    //   if (data.massage_info && data.massage_info.allergiesDetails) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.massage_info.allergiesDetails + '</div>', xx + 100, y + (lineNumber  *  top))
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">What makes it worse ? </div>', x + 20, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 140, y + (lineNumber  *  top) + line);

    //   doc.fromHTML('<div style="font-size: 16px;">There are any area that you do NOT want to massaged?</div>', xx, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.chronicPainMakesBetter) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.chronicPainMakesBetter + '</div>', x, y + (lineNumber  *  top))
    //   }

    //   doc.rect(xx, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Head </div>', xx + 15, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Head') >= 0) {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(xx + 100, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Neck </div>', xx + 115, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Neck') >= 0) {
    //     doc.addImage(img, "PNG", xx + 100, y + (lineNumber  *  top), 10, 10);
    //   }

    //   doc.rect(xx + 200, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Thighs </div>', xx + 215, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Thighs') >= 0) {
    //     doc.addImage(img, "PNG",  xx + 200, y + (lineNumber  *  top), 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 14px;">Have you had any orthopedic injuries?</div>', x, y + (lineNumber  *  top))
    //   doc.rect(x + 170, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">Yes </div>', x + 185, y + (lineNumber  *  top))
    //   doc.rect(x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 17px;">No </div>', x + 225, y + (lineNumber  *  top))

    //   if (data.medical_info && data.medical_info.orthoInjuriesStatus == 'yes') {
    //     doc.addImage(img, "PNG", x + 170, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.medical_info && data.medical_info.orthoInjuriesStatus == 'no') {
    //     doc.addImage(img, "PNG", x + 210, y + (lineNumber  *  top) + 4, 10, 10);
    //   }

    //   doc.rect(xx, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Face </div>', xx + 15, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Face') >= 0) {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(xx + 100, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Shoulder </div>', xx + 115, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Shoulder') >= 0) {
    //     doc.addImage(img, "PNG", xx + 100, y + (lineNumber  *  top), 10, 10);
    //   }

    //   doc.rect(xx + 200, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Inner Thighs </div>', xx + 215, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Inner Thighs') >= 0) {
    //     doc.addImage(img, "PNG", xx + 200, y + (lineNumber  *  top), 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">If yes, please List:</div>', x, y + (lineNumber  *  top))
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x + 120, y + (lineNumber  *  top) + line);
    //   if (data.medical_info && data.medical_info.orthoInjuriesDetails) {
    //     doc.fromHTML('<div style="font-size: 16px;">' + data.medical_info.orthoInjuriesDetails + '</div>', x + 160, y + (lineNumber  *  top))
    //   }

    //   doc.rect(xx, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Chest </div>', xx + 15, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Chest') >= 0) {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(xx + 100, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Arms </div>', xx + 115, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Arms') >= 0) {
    //     doc.addImage(img, "PNG", xx + 100, y + (lineNumber  *  top), 10, 10);
    //   }

    //    doc.rect(xx + 200, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Back </div>', xx + 215, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Back') >= 0) {
    //     doc.addImage(img, "PNG", xx + 200, y + (lineNumber  *  top), 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 16px;">Please indicate any of the following that apply you </div>', x, y + (lineNumber  *  top))

    //   doc.rect(xx, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Abdomen </div>', xx + 15, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Abdomen') >= 0) {
    //     doc.addImage(img, "PNG", xx, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(xx + 100, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Hands </div>', xx + 115, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Hands') >= 0) {
    //     doc.addImage(img, "PNG", xx + 100, y + (lineNumber  *  top), 10, 10);
    //   }
      
    //   doc.rect(xx + 200, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Hips </div>', xx + 215, y + (lineNumber  *  top) - 3);
    //   if (data.massage_info && data.massage_info.not_massage && data.massage_info.not_massage.indexOf('Hips') >= 0) {
    //     doc.addImage(img, "PNG", xx + 200, y + (lineNumber  *  top), 10, 10);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Cancer </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Cancer') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Fibromyalgia </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Fibromyalgia') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.fromHTML('<div style="font-size: 16px;"> Do you need your therapist to be quiet? </div>', xx, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Headaches/Migraines </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Headaches/Migraines') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Stroke </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Stroke') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }
    //    doc.rect(xx + 120, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">Quite </div>', xx + 135, y + (lineNumber  *  top))
    //   doc.rect(xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   doc.fromHTML('<div style="font-size: 16px;">I do not mind </div>', xx + 190, y + (lineNumber  *  top))
    //   if (data.massage_info && data.massage_info.therapist == 'Quite') {
    //     doc.addImage(img, "PNG", xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   } else if (data.massage_info && data.massage_info.therapist == 'I do not mind') {
    //     doc.addImage(img, "PNG", xx + 175, y + (lineNumber  *  top) + 4, 10, 10);
    //   }


    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Arthritis </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Arthritis') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Heart Attack </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Heart Attack') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }

    //   doc.fromHTML('<div style="font-size: 14px;">Please circle any areas you would like your therapist to concentrate</div>', xx, y + (lineNumber  *  top))


    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Diabetes </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Diabetes') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Kidney Dysfunction </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Kidney Dysfunction') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }

    //  var img = new Image();
    //   var src = "assets/images/bodypost/bodyposture.jpg";
    //   img.src = src;
    //   doc.addImage(img, "JPEG", xx, y + (lineNumber  *  top) - 1, 200, 100);
    //   if (data.massage_info.discomfortArea && data.massage_info.discomfortArea != 'data:image/png;base64,null') {
    //     doc.addImage(data.massage_info.discomfortArea, "PNG", xx, y + (lineNumber  *  top), 200, 100);
    //   }

    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Joint Replacement </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Joint Replacement') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Blood Clots </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Blood Clots') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }

     

    //   lineNumber = lineNumber + 1;
    //   doc.rect(x, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">High/Low Blood Pressure </div>', x + 15, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('High/Low Blood Pressure') >= 0) {
    //     doc.addImage(img, "PNG", x, y + (lineNumber  *  top), 10, 10);
    //   }
    //   doc.rect(165, y + (lineNumber  *  top), 10, 10);
    //   doc.fromHTML('<div style="font-size: 15px;">Numbness </div>', 180, y + (lineNumber  *  top) - 3);
    //   if (data.medical_info && data.medical_info.diseases && data.medical_info.diseases.indexOf('Numbness') >= 0) {
    //     doc.addImage(img, "PNG", 165, y + (lineNumber  *  top), 10, 10);
    //   }

      

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 15px;">Explain any conditions you have marked above: </div>', x, y + (lineNumber  *  top))

    //   lineNumber = lineNumber + 1;
    //   doc.line(x + 240, y + (lineNumber  *  top) + line, x, y + (lineNumber  *  top) + line);

    //   if (data.medical_info && data.medical_info.diseasesDescription) {
    //     doc.fromHTML('<div style="font-size: 15px;">' + data.medical_info.diseasesDescription + '</div>', x, y + (lineNumber  *  top))
    //   }


    //   // doc.fromHTML('<div style="font-size: 16px;"> Please explain:</div>', xx + 20, y + (lineNumber  *  top))
    //   // doc.line(xx + 250, y + (lineNumber  *  top) + line, xx + 100, y + (lineNumber  *  top) + line);
    //   // if (data.massage_info && data.massage_info.avoidingAreasDetails) {
    //   //   doc.fromHTML('<div style="font-size: 16px;">' + data.massage_info.avoidingAreasDetails + '</div>', xx + 100, y + (lineNumber  *  top))
    //   // }
   
      
    //   // doc.fromHTML('<div style="font-size: 16px;">therapist to concentrate on during your treatment </div>', xx, y + (lineNumber  *  top))
      
    //   lineNumber = lineNumber + 2;
    //   doc.setFillColor(0, 0, 0);
    //   doc.rect(x, y + (lineNumber  *  top) - 10, 140, 20, 'F');
    //   // doc.triangle(160, y - 20, 160, y, 170, y - 10, 'FD');
    //   doc.triangle(170, y + (lineNumber  *  top) - 10, 170, y + (lineNumber  *  top) + 10, 180, y + (lineNumber  *  top), 'FD');
    //   doc.fromHTML('<div style="font-size: 17px; color: white;"><strong>Authentic Information</strong> </div>', x + 5, y + (lineNumber  *  top) - 10)

    //   lineNumber = lineNumber + 1;
    //   doc.fromHTML('<div style="font-size: 14px;"> By Signing below, you agree to the following: </div>', x, y + (lineNumber  *  top))
    //   doc.fromHTML('<div style="font-size: 14px;">  1. I have completed this form to the best of my ability and knowledge and agree to inform</div>', x, y + (lineNumber  *  top) + 10)
    //   doc.fromHTML('<div style="font-size: 14px;"> my therapist if any of the above information changes at any time that there shall be no liability</div>', x, y + (lineNumber  *  top) + 20)
    //   doc.fromHTML('<div style="font-size: 14px;">  on the therapist s part should I forget to do so.  </div>', x, y + (lineNumber  *  top) + 30)
    //   doc.fromHTML('<div style="font-size: 14px;"> 2. I understand that a massage therapist does not diagnose disease, illness and prescribe any </div>', x, y + (lineNumber  *  top) + 40)
    //   doc.fromHTML('<div style="font-size: 14px;"> treatment and drugs nor do they provide spinal manipulation. </div>', x, y + (lineNumber  *  top) + 50)
    //   doc.fromHTML('<div style="font-size: 14px;"> 3. I agree to inform the therapist of any experience of pain during the session.  if I become uncomfortable</div>', x, y + (lineNumber  *  top) + 60)
    //   doc.fromHTML('<div style="font-size: 14px;"> for any reason that I may ask the therapist to end the massage session, and they will end the session.</div>', x, y + (lineNumber  *  top) + 70)
    //   doc.fromHTML('<div style="font-size: 14px;"> 4. I freely give my permission to be massaged.I understand this does not deter me</div>', x, y + (lineNumber  *  top) + 80)
    //   doc.fromHTML('<div style="font-size: 14px;"> from seeking medical treatment for medical conditions.</div>', x, y + (lineNumber  *  top) + 90)
    //   doc.fromHTML('<div style="font-size: 14px;">5. I understand that draping will be used at all times and no inappropriate comments or conduct</div>', x, y + (lineNumber  *  top) + 100)
    //   doc.fromHTML('<div style="font-size: 14px;"> will be tolerated. Any indication of such behavior will automatically end the session.</div>', x, y + (lineNumber  *  top) + 110)

    //   doc.fromHTML('<div style="font-size: 15px;">Client signature: </div>', x, y + (40 *  top))
    //   if (data.massage_info.client_sign && data.massage_info.client_sign != 'data:image/png;base64,null') {
    //     doc.addImage(data.massage_info.client_sign, "PNG", x + 80, y + (40 *  top)-20, 150, 30);
    //   }
    //   doc.line(x + 200, y + (40 *  top) + line, x + 80, y + (40 *  top) + line);

    //   doc.fromHTML('<div style="font-size: 15px;">Therapist signature: </div>', xx - 60, y + (40 *  top))
    //   if (data.massage_info.therapist_sign && data.massage_info.therapist_sign != 'data:image/png;base64,null') {
    //     doc.addImage(data.massage_info.therapist_sign, "PNG", xx + 30, y + (40 *  top)-20, 150, 30);
    //   }
    //   doc.line(xx + 150, y + (40 *  top) + line, xx + 30, y + (40 *  top) + line);

    //   doc.fromHTML('<div style="font-size: 15px;">Date: </div>', xx + 170, y + (40 * top))
    //   doc.fromHTML('<div style="font-size: 15px;">' + moment(data.updatedAt).format('DD/MM/YYYY') + ' </div>', xx + 200, y + (40 * top))
    //   //updatedAt
    //   doc.line(xx + 270, y + (40 * top) + line, xx + 200, y + (40 * top) + line);



    //   let name = ''
    //   if (data.client_info.personal.firstName) {
    //     name = data.client_info.personal.firstName
    //   }
    //   if (data.client_info.personal.lastName) {
    //     name = name + '-' + data.client_info.personal.lastName
    //   }
    //   if (data.updatedAt) {
    //     name = name + '-' + moment(data.updatedAt).format('MMDDYYYY')
    //   }
      doc.save('job_completion' + '.pdf');


  }


}
