import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { VehicelService } from "../vehicel-service";
import { image } from "html2canvas/dist/types/css/types/image";

@Component({
  selector: "app-vehicel-add-edit",
  templateUrl: "./vehicel-add-edit.component.html",
  styleUrls: ["./vehicel-add-edit.component.sass"],
})
export class VehicelAddEditComponent implements OnInit {
  isValid = false;
  submitted = false;
  formData: FormGroup;
  disableSubmit = false;
  showMessage = false;
  isLoading = false;
  images = [""];
  fileToUpload: File = null;

  private initialData = {
    _id: "",
    driverName: "",
    purchaseDate: null,
    vehicleDescription: "",
    located: "",
    currentRego: "",
    stateRegistered: "",
    regoExpiry: null,
    fullyCompExpiry: null,
    ctpExpiry: null,
    nextService: null,
    ctpInsurer: "",
    fullyCompInsurer: "",
    fuelCardNo: "",
    tollTagNo: "",
    repairsMaintenanceLog: "",
    toDoList: "",
    image: "",
  };

  constructor(
    private _fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private api: VehicelService
  ) {}

  getCurrentDate(date: Date | null): string {
    return date ? date.toISOString().split("T")[0] : "";
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.formData = this._fb.group({
      driverName: [this.initialData.driverName, [Validators.required]],
      purchaseDate: [
        this.getCurrentDate(new Date(this.initialData.purchaseDate)),
        [Validators.required],
      ],
      vehicleDescription: [
        this.initialData.vehicleDescription,
        [Validators.required],
      ],
      located: [this.initialData.located, [Validators.required]],
      currentRego: [this.initialData.currentRego, [Validators.required]],
      stateRegistered: [
        this.initialData.stateRegistered,
        [Validators.required],
      ],
      regoExpiry: [
        this.getCurrentDate(new Date(this.initialData.regoExpiry)),
        [Validators.required],
      ],
      fullyCompExpiry: [
        this.getCurrentDate(new Date(this.initialData.fullyCompExpiry)),
        [Validators.required],
      ],
      ctpExpiry: [
        this.getCurrentDate(new Date(this.initialData.ctpExpiry)),
        [Validators.required],
      ],
      nextService: [
        this.getCurrentDate(new Date(this.initialData.nextService)),
        [Validators.required],
      ],
      fullyCompInsurer: [
        this.initialData.fullyCompInsurer,
        [Validators.required],
      ],
      fuelCardNo: [this.initialData.fuelCardNo],
      ctpInsurer: [this.initialData.ctpInsurer, [Validators.required]],
      tollTagNo: [this.initialData.tollTagNo],
      repairsMaintenanceLog: [this.initialData.repairsMaintenanceLog],
      toDoList: [this.initialData.toDoList],
      image: [this.initialData.image],
    });
  }

  get f() {
    return this.formData.controls;
  }

  handleFileInput(files: FileList, type = "image", index: number) {
    this.fileToUpload = files.item(0);

    if (files.length === 1) {
      this.isLoading = true;

      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.isLoading = false;

          if (type === "image") {
            this.formData.patchValue({
              image: result.data,
            });
          } else {
            // this.video = result.data;
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  handleSubmit() {
    this.submitted = true;
    if (this.formData.invalid) {
      return;
    }
    this.isLoading = true;
    this.api.updateVehicle(this.initialData._id, this.formData.value).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.bsModalRef.hide();
      },
      (err) => {
        this.commonService.toast(err.error.message, "Error");
      }
    );
  }
}
