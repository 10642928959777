import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-layout',
  templateUrl: './unauth-layout.component.html',
  styleUrls: ['./unauth-layout.component.css']
})
export class UnauthLayoutComponent implements OnInit {
  show_terms: Boolean;
  show_privacy: Boolean;
  current_year: Number;
  constructor() {
    this.show_terms = false;
    this.show_privacy = false;
    this.current_year = (new Date()).getFullYear();
  }

  ngOnInit() {
  }
}
