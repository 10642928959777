import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { QuoteService } from "../../quotes/quote.service";
import { CommonService } from "src/app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TemplateService } from "../../templates/template.service";

@Component({
    selector: "app-add-quote",
    templateUrl: "./add-quote.component.html",
    styleUrls: ["./add-quote.component.sass"],
})
export class AddQuoteComponent implements OnInit {
    constructor(
        private _fb: FormBuilder,
        private quoteService: QuoteService,
        public commonService: CommonService,
        private router: Router,
        private templateService: TemplateService,
        private activatedRoute: ActivatedRoute
    ) {}
    quoteForm: FormGroup;
    submitted: boolean;
    editId = true;
    listData: any;
    num1: number;
    num2: number;
    dropVal = null;
    _id = "";
    loader;
    get;
    pVal = 0;
    gstVal = 0;
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };

    ngOnInit() {
        this.quoteForm = this._fb.group({
            quoteCode: [null, [Validators.required, Validators.minLength(2)]],
            repTime: [],
            price: [null, [Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)]],
            gst: [null, [Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)]],
            link: [],
            tempName: [null, [Validators.required]],
        });

        this.templateService.getTemplate(this.query).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
        if (this.activatedRoute.snapshot.params.quoteId !== undefined) {
            this.editId = false;
            this._id = this.activatedRoute.snapshot.params.quoteId;
            this.loadData(this.activatedRoute.snapshot.params.quoteId);
        }
    }

    submitHandler() {
        if (!this.quoteForm.invalid) {
            this.submitted = false;
            const quoteCode = this.quoteForm.controls.quoteCode.value;
            let repTime = this.quoteForm.controls.repTime.value;
            const tempId = this.quoteForm.controls.tempName.value;
            let price = this.quoteForm.controls.price.value;
            let link = this.quoteForm.controls.link.value;
            let gst = this.quoteForm.controls.gst.value;

            repTime = repTime !== null ? repTime : "";
            repTime = repTime !== "" ? repTime : "";
            price = price !== "" ? price : 0;
            link = link !== null ? link : "";
            gst = gst !== "" ? gst : "";

            const obj = {
                quoteCode,
                repTime,
                tempId,
                price,
                link,
                gst,
            };

            this.quoteService.createQuote(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Quote Reference added Successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/quote/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            this.submitted = true;
        }
    }
    loadData(quoteId) {
        const obj = { _id: quoteId };
        this.loader = true;
        this.quoteService.getQuoteDataById(obj).subscribe(
            (result: any) => {
                const items = result.data;
                this.editQuoteData(items);
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }
    editQuoteData(data) {
        this.pVal = data.price;
        this.gstVal = data.gst;
        this.quoteForm.patchValue({
            quoteCode: data.quoteCode,
            repTime: data.repTime,
            price: data.price,
            link: data.link,
            gst: data.gst,
            tempName: data.tempId,
        });
    }
    updateQuote() {
        this.submitted = true;
        if (!this.quoteForm.invalid) {
            const obj = {
                quoteCode: this.quoteForm.controls.quoteCode.value,
                repTime: this.quoteForm.controls.repTime.value,
                tempId: this.quoteForm.controls.tempName.value,
                price: this.quoteForm.controls.price.value,
                link: this.quoteForm.controls.link.value,
                gst: this.quoteForm.controls.gst.value,
                _id: this._id,
            };
            this.quoteService.updateQuoteData(obj).subscribe(
                (response: any) => {
                    if (response.success === true) {
                        this.commonService.toast(
                            `Quote Reference has been updated successfully.`,
                            "Success"
                        );
                        this.router.navigate(["/quote/list"]);
                    } else {
                        if (response.success === false) {
                            this.commonService.toast(response.message, "Error");
                        } else {
                            this.commonService.toast(
                                response.message.toString(),
                                "Error"
                            );
                        }
                    }
                },
                (error) => {
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        } else {
            console.log("Validation error");
        }
    }
    get f() {
        return this.quoteForm.controls;
    }
}
