import { Component, OnInit, TemplateRef } from "@angular/core";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "../../../services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { UserModalComponentComponent } from "./../user-modal-component/user-modal-component.component";
import { ExcelService } from "./../../../services/excel.service";
import * as moment from "moment";
import { environment } from "../../../../environments/environment";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-inspection-list-dump",
  templateUrl: "./inspection-list-dump.component.html",
  styleUrls: ["./inspection-list-dump.component.sass"],
})
export class InspectionListDumpComponent implements OnInit {
  loader = true;
  items: any;
  items1: any;
  listData: any;
  status: any;
  reason: any;
  email;
  email1;
  p = 1;
  nodata = false;
  searchText = "";
  selecttype;
  arr_temp = [];
  arr_temp_all = [];
  modalRef: BsModalRef;
  check: boolean;
  listtype_both: any;
  listtype_Accepted: any;
  listtype_Rejected: any;
  listtype_Deleted: any;
  obj: any;
  excel = [];
  td;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;
  selectedItem: any;

  constructor(
    private inspection: InspectionService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private excelService: ExcelService
  ) {
    this.recordsPerPage = environment.defaultPageSize;
  }

  private searchTerms = new Subject<string>();

  ngOnInit() {
    this.getInspectionListByStatus("Dump");
    this.status = [
      "Open",
      "Pending",
      "Qualified",
      "Accepted",
      "Rejected",
      "Confirmed",
      "Deleted",
    ];
    this.reason = ["Wrong service", "Wrong area"];
    this.arr_temp = [];
    var dateString = moment("2020-11-03").format("DD-MM-YYYY");
    console.log(dateString, "dateformat");
    this.searchTerms
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.query.search = value;
        this.pageChanged({ page: 1 });
        // this.engineerlist();
      });
  }

  getValue(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerms.next(inputElement.value);
    // return (event.target as HTMLInputElement).value;
  }
  getInspectionListByStatus(obj) {
    console.log("getInspectionListByStatus=======getInspectionListByStatus");
    this.inspection.getinspectionlistbystatusV2(obj, this.query).subscribe(
      (result: any) => {
        console.log("getInspectionListByStatus result =====>>>>>>>>>>", result);
        const items = result.data.data;
        this.items = items;
        this.totalRecords = result.data.totalCount;
        this.loader = false;
        this.excel = [];
        //this.excel.push('Image', 'Inspection Mail Status', 'Confirmation Mail Status', '_id', 'Issue Type', 'Template Name', 'Descriptive Text', 'Name', 'Email', 'Address', 'City', 'State', 'Postcode', 'Phonenumber', 'Status', 'Reason', 'Date of enquiry', '_v','Inspection date','Inspection time', 'Confirmation date', 'Confirmation time', )
        let curVal = result.data;
        if (curVal.length > 0) {
          curVal.forEach((row) => {
            //delete objNew['_id'];
            console.log("row============================", row);
            this.excel.push(row);
          });
        }

        this.arr_temp_all = this.items.map((result) => result._id);
        console.log("this.arr_temp_all====================", this.arr_temp_all);
        if (obj == "Accepted") {
          console.log("inside accepted");
          this.listtype_both = false;
          this.listtype_Accepted = true;
          this.listtype_Deleted = false;

          this.listtype_Rejected = false;
        } else if (obj == "Rejected") {
          console.log("inside rejected");
          this.listtype_both = false;
          this.listtype_Accepted = false;
          this.listtype_Deleted = false;
          this.listtype_Rejected = true;
        } else if (obj == "Deleted") {
          console.log("inside deleted");
          this.listtype_both = false;
          this.listtype_Accepted = false;
          this.listtype_Rejected = false;
          this.listtype_Deleted = true;
        }
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }
  getinspectionlist() {
    this.query.search = this.searchText;
    this.inspection.getinspectionlistbystatusV2("Dump", this.query).subscribe(
      (result: any) => {
        console.log("getinspectionlist result ", result);

        const items = result.data.data;
        this.items = items;
        this.totalRecords = result.data.totalCount;
        this.excel = [];
        //this.excel.push()
        let curVal = result.data;
        if (curVal.length > 0) {
          curVal.forEach((row) => {
            //delete objNew['_id'];
            this.excel.push(row);
          });
        }
        this.arr_temp_all = this.items.map((result) => result._id);
        // console.log(this.arr_temp_all);
        this.loader = false;
        this.nodata = false;
        this.listtype_both = true;
        this.listtype_Accepted = false;
        this.listtype_Rejected = false;
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        // this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getinspectionlist();
  }

  copydata(index) {
    var doc = "";
    var table = document.getElementById("myTable") as HTMLTableElement;
    //var row,header,cell1, cell2;
    for (var i = 0; i < 11; i++) {
      //var date = table.rows[index+1].cells[i].innerText
      //doc.push(table.rows[index + 1].cells[i].innerText);
      //console.log(table.rows[index+1].cells[i].innerText);
      doc = doc + table.rows[index + 1].cells[i].innerText + "\t";
    }
    //console.log(doc);
    //doc.splice(9, 6);
    //doc.splice(0, 1);
    //doc.join('\r\n');
    //doc.join("\n")
    // console.log(doc)
    // var str = doc.toString();
    //str.replace(",", "<br />");
    //this.copyStringToClipboard(table.rows[index+1].innerText)
    this.copyStringToClipboard(doc);
  }
  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    //el.style = {position: 'absolute', left: '-9999px'};

    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  Deletefnc() {
    // this.obj = {
    //obj : this.arr_temp
    //}
    if (this.arr_temp.length == 0) {
      this.commonService.toast("Please select an enquiry to restore", "Error");
    } else {
      this.inspection.restore(this.arr_temp).subscribe(
        (result: any) => {
          this.loader = true;
          this.getInspectionListByStatus("Dump");
          this.loader = false;
          this.commonService.toast("restore successful", "Success");
        },
        (error: any) => {
          this.loader = false;
          //this.nodata = true;
          this.commonService.toast("restore failed", "Error");
        }
      );
    }
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.excel, "User");
  }

  pagecall() {
    //  if (this.listtype_both == true) {
    this.getinspectionlist();
    // } else if (this.listtype_Accepted == true) {
    // this.getInspectionListByStatus("Accepted");
    //} else if (this.listtype_Rejected == true) {
    //this.getInspectionListByStatus("Rejected");
    //}
  }
  userModal(userid) {
    //console.log(userid);
    const initialState = {
      userid: userid,
    };
    this.modalService.show(UserModalComponentComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getInspectionListByStatus("Dump");
    });
  }
  sendeinspectionmail(emailid, date, time, _id, refnum) {
    let obj1 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
      refnum: refnum,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendeinspectionmail(obj1).subscribe(
        (result: any) => {
          this.commonService.toast(
            `Inspection email sent successfully.`,
            "Success"
          );
          this.getInspectionListByStatus("Confirmed");
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  sendconfirmationemail(emailid, date, time, _id, refnum) {
    let obj2 = {
      email: emailid,
      date: date,
      time: time,
      _id: _id,
      refnum: refnum,
    };
    if (date == "" || date == undefined || time == "" || time == undefined) {
      this.commonService.toast("Please select the date and time", "Error");
    } else {
      this.inspection.sendconfirmationemail(obj2).subscribe(
        (result: any) => {
          this.commonService.toast(
            `Confirmation email  sent successfully.`,
            "Success"
          );
          this.getInspectionListByStatus("Confirmed");
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  // ORIGINAL
  changeStatus(_id, event) {
    const status = event.target.value;
    console.log("changeStatus=======changeStatus", _id, event, status);
    this.inspection.updateStatus({ _id, Status: status }).subscribe(
      (result: any) => {
        this.commonService.toast(`Status updated successfully.`, "Success");
        this.getInspectionListByStatus("Dump");
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  // changeStatus(_id, event) {
  //   const status = event.target.value;
  //   console.log("changeStatus=======changeStatus", _id, event, status);

  //   this.inspection.updateStatus({ _id, Status: status }).subscribe(
  //     (result: any) => {
  //       this.commonService.toast(`Status updated successfully.`, "Success");

  //       // Update the local items list with the new status without calling getInspectionListByStatus again
  //       const updatedItem = this.items.find((item) => item._id === _id);
  //       if (updatedItem) {
  //         updatedItem.Status = status;
  //       }

  //       // You can also filter or update any specific folders
  //       // For example:
  //       if (status === "Accepted") {
  //         this.listtype_Accepted = true;
  //         this.listtype_Rejected = false;
  //         this.listtype_Deleted = false;
  //       }

  //       // Manually re-render the updated data in the list if necessary
  //       this.loader = false;
  //     },
  //     (error) => {
  //       this.commonService.toast(error.error.message, "Error");
  //     }
  //   );
  // }

  changereason(_id, event) {
    const reason = event.target.value;

    if (this.status == "") {
      this.commonService.toast(
        "Please select the status before selecting the reason",
        "Error"
      );
      this.getInspectionListByStatus("Confirmed");
    } else if (this.status == "Accepted") {
      this.commonService.toast("The enquiry is in accepted state", "Error");
      this.getInspectionListByStatus("Confirmed");
    } else {
      this.inspection.updatereason({ _id, Reason: reason }).subscribe(
        (result: any) => {
          this.commonService.toast(`Reason updated successfully.`, "Success");
          this.getInspectionListByStatus("Confirmed");
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
  deleteselection(_id, check) {
    if (check.target.checked == true) {
      this.arr_temp.push({ id: _id });
      console.log(this.arr_temp);
    } else if (check.target.checked == false) {
      for (let j = 0; j < this.arr_temp.length; j++) {
        if (this.arr_temp[j].id === _id) {
          this.arr_temp.splice(j, 1);
        }
      }
      console.log(this.arr_temp);
    }
  }
  deleteall(event) {
    const checked = event.target.checked;
    this.items.forEach((item) => (item.selected = checked));
    if (checked == true) {
      this.arr_temp = JSON.parse(JSON.stringify(this.arr_temp_all));
      let data = [];
      this.arr_temp.forEach((item) => {
        data.push({ id: item });
      });
      this.arr_temp = data;
    } else if (checked == false) this.arr_temp = [];
    console.log(this.arr_temp);
  }
  listchange(event) {
    const listval = event.target.value;
    if (listval == "Both") {
      this.getinspectionlist();
    } else if (listval == "Accepted") {
      this.getInspectionListByStatus("Accepted");
    } else if (listval == "Rejected") {
      this.getInspectionListByStatus("Rejected");
    }
  }
  selectinspectiondate(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate({ _id, idate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection date updated successfully.`,
          "Success"
        );
        this.getInspectionListByStatus("Confirmed");
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  selectinspectiondate1(_id, event) {
    const bdate = event.target.value;
    this.inspection.selectinspectiondate1({ _id, cdate: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Confirmed date updated successfully.`,
          "Success"
        );
        this.getInspectionListByStatus("Confirmed");
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  timeset(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time1")).value;
    this.inspection.selectinspectiontime1({ _id, itime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.getInspectionListByStatus("Confirmed");
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  timeset1(_id) {
    //const bdate = .target.value;
    //var bdate = document.getElementById("time1").value;
    var bdate = (<HTMLInputElement>document.getElementById("time2")).value;
    this.inspection.selectinspectiontime2({ _id, ctime: bdate }).subscribe(
      (result: any) => {
        this.commonService.toast(
          `Inspection time updated successfully.`,
          "Success"
        );
        this.getInspectionListByStatus("Confirmed");
      },
      (error) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  openModal(template: TemplateRef<any>) {
    console.log("inside open modal");
    // this.selectedItem = item;
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  confirm() {
    console.log("inside confirm modal");

    this.Deletefnc();
    this.modalRef.hide();
  }
  decline() {
    console.log("inside decline modal");

    // this.message = 'Declined!';
    this.modalRef.hide();
  }
  restore(i, item) {
    let data = [];
    data.push({ id: item._id });
    this.inspection.restore(data).subscribe(
      (res) => {
        this.commonService.toast(
          `Inspection item restored successfully.`,
          "Success"
        );
        this.loader = true;
        this.getInspectionListByStatus("Dump");
        this.loader = false;
      },
      (err) => {
        this.commonService.toast(`Inspection item restore failed.`, "Error");
      }
    );
  }
}
