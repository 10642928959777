import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  @Input() rating?: number;
  @Input() show_count?: Boolean;
  stars = [];
  v_rating: Number;
  constructor() { }

  ngOnInit() {
    if (this.rating === null) {
      this.rating = 0;
    }

    this.v_rating = this.rating;
    console.log(this.rating);
    this.v_rating = Math.floor(Number(this.v_rating));

    for (let i = 1; i <= this.v_rating; i++) {
      this.stars.push(i);
    }


  }

}
