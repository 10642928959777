import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverType'
})
export class DriverTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log('In Driver Pipe');
    const driver_types = {
      '0': 'Part Time',
      '1': 'Full Time'
    };
    return driver_types[value];
  }

}
