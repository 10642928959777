import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { BookinglinkService } from '../bookinglink.service'
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-warrantylink',
  templateUrl: './warrantylink.component.html',
  styleUrls: ['./warrantylink.component.sass']
})
export class WarrantylinkComponent implements OnInit {
  signinForm: FormGroup;
  submitted;
  today: Date;
  DateTimeFormatter: any;
  detailtype;
  quote;
  quotedata;
  repTime;
  totalPrice;
  bdate: Date;
  bdate1: Date;
  firsttime: any;
  //Blanksignature: any;
  sign: any;
  obj: any;
  loader;


  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.2,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  bookingform = {
    name: '',
    email: '',
    address: '',
    City: '',
    State: '',
    Postalcode: '',
    Phonenumber: '',
    Country: '',
    Date: '',
    Tnc: '',
    TodayDate: '',
    signature: ''
  }

  current_date: Number;

  constructor(
    private formBuilder: FormBuilder,
    private bookinglinkService: BookinglinkService,
    private router: Router,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    //this.current_date = (new Date()).getDate();
    this.today = new Date();

  }

  ngOnInit() {
    //this.Blanksignature = true;
    this.signinForm = this.formBuilder.group({

      email: ['', Validators.required],
      name: ['', Validators.required],
      address: ['', Validators.required],
      City: [],
      State: [],
      Postalcode: [],
      Phonenumber: ['', Validators.required],
      Country: [],
      Date: ['', Validators.required],
      Tnc: [],
      TodayDate: [],
      signature: []
    })
    this.getJobdetailsbyid(this.activatedRoute.snapshot.params.jobid);

  }

  getbookingdatabyid(jobid) {
    this.loader = true;
    this.bookinglinkService.getbookingdatabyid(jobid).subscribe((result: any) => {
      const items1 = result.data;
      this.firsttime = true;
      this.loader = false;
      this.jobData1(items1);
    }, (error: any) => {
      this.loader = false;
    });
  }
  jobData1(items1) {
    this.bdate1 = items1.Date.substring(0, 10);
    //this.signaturePad = items1.signature
    this.sign = items1.signature
    this.signinForm.patchValue({
      name: items1.name,
      email: items1.email,
      address: items1.address,
      City: items1.City,
      State: items1.State,
      Postalcode: items1.Postalcode,
      Country: items1.Country,
      Date: this.bdate1,
      //Date: items1.date ,
      Phonenumber: items1.Phonenumber,
      Tnc: true,
      //TodayDate: items1.TodayDate,
      // signature: items1.signature
    });
  }

  getJobdetailsbyid(jobid) {
    this.loader = true;
    this.bookinglinkService.getJobdetailsbyid(jobid).subscribe((result: any) => {
      const items = result.data;
      this.firsttime = false;
      // console.log(items);
      this.jobData(items);
      //this.items = items;
      this.loader = false;
      this.getbookingdatabyid(this.activatedRoute.snapshot.params.jobid);
    }, (error: any) => {
      this.loader = false;
    });
  }

  jobData(items) {
    this.bdate = items.date.substring(0, 10);
    console.log(this.bdate);
    this.detailtype = items.detailType;
    if (items.quoteData.length > 1) {
      for (const arrTemp of items.quoteData) {
        if (arrTemp.link == "" || arrTemp.link == '' || arrTemp.link == "      " || arrTemp.link == undefined) {
          continue
        }
        else {
          this.quotedata = arrTemp;
          break;
        }
      }
    } else {
      this.quotedata = items.quoteData[0];
    }
    this.quote = this.quotedata.quoteCode;
    this.repTime = this.quotedata.repTime;
    this.totalPrice = this.quotedata.totalPrice;
    this.signinForm.patchValue({
      name: items.clientName,
      email: items.clientEmailid,
      address: items.projectAddress,
      City: items.City,
      State: items.State,
      Postalcode: items.Postalcode,
      Country: items.Country,
      Date: this.bdate,
      //Date: items.date ,
      Phonenumber: items.clientphonenumber,
      signature: items.signature
    });
  }
  booking() {
    this.submitted = true;
    const data = this.signinForm.controls;
    if (data.Date == null || data.Date == null) {
      this.commonService.toast('Please select quote date.', 'Error');
    }
    if (this.signinForm.invalid) {
      this.commonService.toast('Please fill in all the details ', 'Error');
      console.log('User form is not valid!!')
    } else if (data.Tnc.value == false || data.Tnc.value === null) {
      this.commonService.toast('Please agree to the terms of service', 'Error');
    }
    else if (this.firsttime == false) {
      if (this.signaturePad.isEmpty()) {
        this.commonService.toast('Please sign to submit your job', 'Error');
      } else {
        this.obj = {
          name: data.name.value,
          email: data.email.value,
          address: data.address.value,
          City: data.City.value,
          State: data.State.value,
          Postalcode: data.Postalcode.value,
          Country: data.Country.value,
          Date: data.Date.value,
          Phonenumber: data.Phonenumber.value,
          Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
          TodayDate: data.TodayDate.value,
          signature: this.signaturePad.toDataURL(),
          firsttime: true,
          type: "Warranty",
          detailtype: this.detailtype,
          repTime: this.repTime,
          price: this.totalPrice,
          
        };
        this.loader = true;
        this.bookinglinkService.createbookingwithouturl(this.obj).subscribe((res) => { 
          this.loader = false;
          this.router.navigate(['/Thank-You-For-Booking-With-Us']);
          this.commonService.toast('Congrats! Your job is booked successfully.', 'Success');
        },
          error => {
            this.loader = false;
            this.commonService.toast(error.error.message, 'Error');
          });
      }
    } else if (this.firsttime == true) {
      this.obj = {
        name: data.name.value,
        email: data.email.value,
        address: data.address.value,
        City: data.City.value,
        State: data.State.value,
        Postalcode: data.Postalcode.value,
        Country: data.Country.value,
        Date: data.Date.value,
        Phonenumber: data.Phonenumber.value,
        Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
        TodayDate: data.TodayDate.value,
        // signature: this.signaturePad.toDataURL(),
        firsttime: true,
        type: "Warranty",
        detailtype: this.detailtype,
          repTime: this.repTime,
          price: this.totalPrice
      }; this.loader = true;
      this.bookinglinkService.createbookingwithouturl(this.obj).subscribe((res) => { 
        this.loader = false;
        this.router.navigate(['/Thank-You-For-Booking-With-Us']);
        this.commonService.toast('Congrats! Your job is booked successfully.', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }


  }

  get f() {
    return this.signinForm.controls;
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    if (this.firsttime == false) {
      this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
      //this.Blanksignature = true;
    }

  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    console.log(this.signaturePad);
    //this.Blanksignature = false;
    //this.bookingform.signature = this.signaturePad.toDataURL()
  }

  clearSign() {
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    //this.Blanksignature = true;
  }

  drawStart() {
    //this.Blanksignature = false;

    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

}
