import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./components/profile/profile.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { UserService } from "./services/user.service";
import { ReactiveFormsModule } from "@angular/forms";
import { JwtHelperService } from "@auth0/angular-jwt";
import { RouterModule } from "@angular/router";
import { LayoutsModule } from "./../../layouts/layouts.module";
import { OtpSentModalComponent } from "./components/otp-sent-modal/otp-sent-modal.component";
import { BrowserModule } from "@angular/platform-browser";
import { ModalModule } from "ngx-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutsModule,
    ReactiveFormsModule,
    BrowserModule,
    ModalModule.forRoot(),
  ],
  providers: [JwtHelperService, UserService],
  declarations: [
    ProfileComponent,
    ChangePasswordComponent,
    OtpSentModalComponent,
  ],
  exports: [
    OtpSentModalComponent, // export it to make it available elsewhere
  ],
})
export class UserModule {}
