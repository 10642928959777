import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { UtilityModule } from '../utility/utility.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TagInputModule } from 'ngx-chips';
import { NgxSpinnerModule } from 'ngx-spinner';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { IpadTemplateListComponent } from './ipad-template-list/ipad-template-list.component';
import { RepairMethodListComponent } from './repair-method-list/repair-method-list.component';
import { RepairMethodAddComponent } from './repair-method-add/repair-method-add.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PaginationModule } from 'ngx-bootstrap';
@NgModule({
  declarations: [
    IpadTemplateListComponent,
    RepairMethodListComponent,
    RepairMethodAddComponent
  ],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UtilityModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    TagInputModule,
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule,
    PipesModule
  ]
})
export class IpadModule { }
