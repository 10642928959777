import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "../../../services/common.service";
import { SupportTicketService } from "../support-ticket.service";
import { SupportTicketNotesModalComponent } from "../support-ticket-notes-modal/support-ticket-notes-modal.component";

@Component({
  selector: "app-support-ticket-notes",
  templateUrl: "./support-ticket-notes.component.html",
  styleUrls: ["./support-ticket-notes.component.sass"],
})
export class SupportTicketNotesComponent implements OnInit {
  inventoryId = "";
  items = [];
  loader = false;
  showAddAndDeleteBtn = true;
  noteType: string = "support_ticket";

  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private api: SupportTicketService,
    private modalService: BsModalService
  ) {}
  ngOnInit() {
    this.getInventoryNotes();
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  getInventoryNotes() {
    this.api.getSupportTicketNotes(this.inventoryId).subscribe(
      (result: any) => {
        const items = result.data.items;
        this.items = items;
        this.loader = false;

        this.showAddAndDeleteBtn = this.showAddAndDeleteBtn || items.length < 1;
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleNoteAddorUpdate(noteId: string, content: string) {
    const initialState = {
      noteId,
      content,
      noteType: this.noteType,
      referenceId: this.inventoryId,
    };

    this.modalService.show(SupportTicketNotesModalComponent, {
      initialState,
      class: "modal-lg modal-dialog-centered",
    });
    this.modalService.onHidden.subscribe(() => {
      this.getInventoryNotes();
    });
  }

  handleNoteDelete(noteId: string) {
    this.api.deleteSupportTicketNote(noteId).subscribe(
      (result: any) => {
        this.getInventoryNotes();
        this.commonService.toast(result.message || "Note Deleted", "Success");
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
