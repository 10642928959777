import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfpersectionComponent } from './pdfpersection/pdfpersection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddPdfpersectionComponent } from './add-pdfpersection/add-pdfpersection.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PdppopupviewComponent } from './pdppopupview-modal/pdppopupview.component';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [PdfpersectionComponent, AddPdfpersectionComponent, PdppopupviewComponent],
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
  ]
})
export class PdfpersectionModule { }
