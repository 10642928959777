import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { RepairService } from '../../repair/repair.service';

@Component({
  selector: 'app-repair-method-list',
  templateUrl: './repair-method-list.component.html',
  styleUrls: ['./repair-method-list.component.sass']
})
export class RepairMethodListComponent implements OnInit {
  items;
  loader;
  nodata = false;
  p = 1;
  _id: string;
  searchText;

  constructor(
    private repairService: RepairService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.loader = true;
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this._id = this.activatedRoute.snapshot.params.id;
      this.getRepairListById(this._id);
    } else {
      this.getRepairList();
    }
  }
  getRepairList() {
    this.repairService.getRepairList().subscribe((result: any) => {
      const items = result.data;
      this.items = items;
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  getRepairListById(idVal: string) {
    const obj = { tempId: idVal };
    this.repairService.getRepairById(obj).subscribe((result: any) => {
      let data = result.data && result.data[0];
      let items = result.data && result.data[0].pdf;
      if(items.length) {
        items.forEach(element => {
          element.templateName = data.templateName
          element.temp_id = data.temp_id
        });
      }
      this.items = items;
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }
  navigateAddRepair() {
     this.router.navigate([`/ipad/add/${this._id}`]); 
    }

  getRoute(url, id) {
    const myurl = `${url}/${this._id}`;
    this.router.navigate([myurl],{queryParams:{quote : id}}).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

  delteRepair(item) {
    const obj = {
      temp_id: this._id,
      pdf_id : item._id
    };
    this.repairService.deletePdf(obj).subscribe((result: any) => {
      this.loader = false;
      if (result.success === true) {
        this.commonService.toast(result.message, 'Success');
      } else {
        this.commonService.toast(result.message, 'Error');
      }
      if (this.activatedRoute.snapshot.params.id !== undefined) {
        this._id = this.activatedRoute.snapshot.params.id;
        this.getRepairListById(this._id);
      } else {
        this.getRepairList();
      }
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }


}
