import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
// import { AuthService } from './auth.service';
@Injectable()
export class UserAuthService implements CanActivate {
    constructor( public router: Router) { }
    canActivate(): boolean {
        let userType = localStorage.getItem('userType'); 
        if ( userType == 'superAdmin') {
            return true;
          }
          else{
              this.router.navigate(['/login']);
          }
        // if (!this.auth.isAuthenticated()) {
        //     this.router.navigate(['login']);
        //     return false;
        // } else {
        //     /*if(localStorage.getItem('signUpInfo')) {
        //       this.router.navigate(['profile-setup']);
        //       return false;
        //     }*/
        // }
        // return true;
    }
}
