import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.sass']
})
export class EditTemplateComponent implements OnInit {

  templateForm: FormGroup;
  fileToUpload: File = null;
  dataResult: any;
  userData = {
    image: ''
  };
  submitted = false;
  loader = true;
  _id = null;
  fileToUpload1 = null;
  

  constructor(
    private router: Router,
    private templateService: TemplateService,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.templateForm = this.formBuilder.group({
      tempName: ['', Validators.required],
      // image: [null, [Validators.required]],
      image: [''],
      repMain: this.formBuilder.array([this.addRepairMethod()]),
      noteMain: this.formBuilder.array([this.addNote()]),
      hidfile: [null]
    });

    this.loadData();

  }

  loadData() {
    this._id = this.activatedRoute.snapshot.params.tempId;
    const obj = {
      tempId: this.activatedRoute.snapshot.params.tempId,
      userId: this.activatedRoute.snapshot.params.tempId
    };
    this.templateService.viewTemplate(obj).subscribe((result: any) => {
      const items = result.data;
      this.editTemplateData(items);
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

  get hidfile() {
    return this.templateForm.get('hidfile');
  }

  get f() {
    return this.templateForm.controls;
  }

  editTemplateData(data: any) {
    this.fileToUpload1 = true;
    this.userData.image = data.imageUrl;
    if (data.imageUrl == undefined) {
      this.fileToUpload1 = null;
    }
    this.templateForm.patchValue({
      tempName: data.templateName,
      hidfile: data.imageUrl
    });
    this.templateForm.setControl('repMain', this.setRepMain(data.repairRecommend));
    this.templateForm.setControl('noteMain', this.setNoteFun(data.generalNote));

  }

  setRepMain(repData: any): FormArray {
    const formArray = new FormArray([]);
    repData.forEach(s => {
      formArray.controls.push(this.formBuilder.group({
        repairMethod: s.repairMethod
      }));
    });
    return formArray;
  }

  setNoteFun(genData: any): FormArray {
    const formArray = new FormArray([]);
    genData.forEach(s => {
      formArray.controls.push(this.formBuilder.group({
        general: s.general
      }));
    });
    return formArray;
  }

  addRepairMethod() {
    return this.formBuilder.group({
      repairMethod: [null, Validators.required],
    });
  }
  addRep() {
    this.repArray.push(this.addRepairMethod());
  }
  removeRep(index) {
    if ((this.repArray).length === 1) {
      this.commonService.toast('Atleast one Repair Recommendation is required.', 'Error');
    } else {
      this.repArray.removeAt(index);
    }
  }
  get repArray() {
    return this.templateForm.get('repMain') as FormArray;
  }
  get tempName() {
    return this.templateForm.get('tempName');
  }
  addNote() {
    return this.formBuilder.group({
      general: [null, Validators.required],
    });
  }
  userNoteAdd() {
    this.noteArr.push(this.addNote());
  }
  removeNote(index) {
    if ((this.noteArr).length === 1) {
      this.commonService.toast('Atleast one general note is required.', 'Error');
    } else {
      this.noteArr.removeAt(index);
    }
  }
  get noteArr() {
    return this.templateForm.get('noteMain') as FormArray;
  }
  handleFileInput(files: FileList) {
    //this.loader = true;
    this.fileToUpload = files.item(0);
    this.commonService.fileUpload(this.fileToUpload).subscribe((result: any) => {
      this.userData.image = result.data;
      this.fileToUpload1 = true;
      this.templateForm.patchValue({
        hidfile: result.data
      });
      this.loader = false;
    });
  }

  submitHandler() {
   // const genInfo =  (this.templateForm.get('noteMain') as FormArray).controls[0];
   //const temp= <FormGroup>genInfo.value;
    //console.log(temp);

    
    const repLen = (this.templateForm.get('noteMain') as FormArray).controls;
    const genArr = [];
    if (repLen.length > 0) {
      for (let i = 0; i < repLen.length; i++) {
        genArr.push(<FormGroup>repLen[i].value);
      }
    }
    const recInfo =  (this.templateForm.get('repMain') as FormArray).controls;
    const recArr = [];
    if (recInfo.length > 0) {
      for (let j = 0; j < recInfo.length; j++) {
        recArr.push(<FormGroup>recInfo[j].value);
      }
    }
    if (this.templateForm.valid) {
      const formData = { ...this.templateForm.value };
      const obj = {
        templateName: formData.tempName,
        repairRecommend: recArr,
        generalNote: genArr,
        _id: this._id,
        imageUrl: this.templateForm.controls.hidfile.value
      };
      this.templateService.updateTemplate(obj).subscribe((res) => {
        this.router.navigate(['/template/list']);
        this.commonService.toast('Template updated successfully.', 'Success');
      },
        error => {
          this.submitted = true;
          this.loader = false;
          //this.commonService.toast(error.error.message, 'Error');
        });
    } else {
      this.submitted = true;
      //this.commonService.toast('Validation Error.', 'Error');
    }
  }


}
