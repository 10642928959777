import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import {
  AddSupportTicketNoteParams,
  GetSupportTicketListParams,
} from "./support-ticket";

@Injectable({
  providedIn: "root",
})
export class SupportTicketService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  //-----------------------------------------------> users
  getUsers(data: { search: string }) {
    const searchQuery = data.search ? `search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(`support/ticket-users/?${searchQuery}`);
  }

  //-----------------------------------------------> Tickets
  getSupportTicketList(data: GetSupportTicketListParams) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(
        `support/tickets/?status=${data.status}&page=${data.page}${searchQuery}`
      );
  }

  updateTicketStatus(id: string, data: { status: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`support/ticket-status/${id}`, data);
  }

  addSupportTicket(data: any) {
    return this.restRequest.addAuthorization().post(`support/ticket`, data);
  }

  updateTicketData(id: string, data: any) {
    return this.restRequest
      .addAuthorization()
      .put(`support/ticket/${id}`, data);
  }

  deleteTicket(id: string) {
    return this.restRequest.addAuthorization().delete(`support/ticket/${id}`);
  }

  //-----------------------------------------------> notes
  getSupportTicketNotes(referenceId: string) {
    return this.restRequest
      .addAuthorization()
      .get(`inventory/notes/?referenceId=${referenceId}`);
  }

  addSupportTicketNote(data: AddSupportTicketNoteParams) {
    return this.restRequest.addAuthorization().post(`inventory/note`, data);
  }

  updateSupportTicketNotes(noteId: string, data: { notes: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`inventory/note/${noteId}`, data);
  }

  deleteSupportTicketNote(noteId: string) {
    return this.restRequest
      .addAuthorization()
      .delete(`inventory/note/${noteId}`);
  }
}
