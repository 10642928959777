import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CauseService } from '../cause.service';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from '../../templates/template.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: "app-cause-list",
    templateUrl: "./cause-list.component.html",
    styleUrls: ["./cause-list.component.sass"],
})
export class CauseListComponent implements OnInit {
    loader;
    _id: string;
    nodata = false;
    items;
    filterByTemp = false;
    p = 1;
    listData: any = [];
    causeList: any = [];
    selectedCause: string;
    searchText = "";
    query: any = {
        page: 1,
        limit: environment.defaultPageSize,
        userType: 1,
    };
    Tempquery: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };
    totalRecords = 0;
    recordsPerPage: number;

    constructor(
        private router: Router,
        private causeser: CauseService,
        private commonService: CommonService,
        private activatedRoute: ActivatedRoute,
        private templateService: TemplateService
    ) {}

    ngOnInit() {
        this.loader = true;
        this.query.search = this.searchText;
        this.templateService.getTemplate(this.Tempquery).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                    if (this.activatedRoute.snapshot.params.id !== undefined) {
                        this._id = this.activatedRoute.snapshot.params.id;
                        this.getCauseListById(this._id);
                    } else {
                        this.getCauseList();
                    }
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );
    }
    getCauseList() {
        this.query.search = this.searchText;
        this.causeser.getCauseListV2(this.query).subscribe(
            (result: any) => {
                this.causeList = result.data.data;
                const items = result.data.data;
                this.items = items;
                this.nodata = false;
                this.totalRecords = result.data.totalCount;

                // if(selectedCause){
                //   let causeData = this.items.filter(data=>data.tempId == selectedCause)
                //   this.items = causeData.sort((a,b)=> a.cause_index - b.cause_index);
                //   this.selectedCause = selectedCause;
                // }
                this.loader = false;
                this.query.tempId = "all";
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
                this.query.tempId = "all";
                //      this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    pageChanged(event: any): void {
        this.p = event.page;
        this.query.page = this.p;
        this.getCauseList();
    }

    dropdownValueChange(e) {
        console.log(e.target.value);
        e.target.value == "all" ? null : (this.query.tempId = e.target.value);
        this.getCauseList();
        // localStorage.setItem('selectedCause',e.target.value);
        // this.getCauseList2(e.target.value);
    }

    getCauseList2(id) {
        // this.causeser.getCauseList().subscribe((result: any) => {
        //   const items = result.data;
        if (id !== "all") {
            let userData = [];
            userData = this.causeList.filter((data) => data.tempId == id);
            this.items = userData.sort((a, b) => a.cause_index - b.cause_index);
        }

        if (id == "all") {
            this.items = this.causeList;
        }
        //   this.loader = false;
        // }, (error: any) => {
        //   this.loader = false;
        //   this.nodata = true;
        //   //      this.commonService.toast(error.error.message, 'Error');
        // });
    }

    getCauseListById(idVal: string) {
        // const obj = { tempId: idVal };
        this.causeser.getCauseList().subscribe(
            (result: any) => {
                const items = result.data;
                this.items = items.filter((item) => item.tempId === idVal);
                this.loader = false;
                this.filterByTemp = true;
            },
            (error: any) => {
                this.loader = false;
                this.nodata = true;
                //      this.commonService.toast(error.error.message, 'Error');
            }
        );
    }

    public getRoute(url, id) {
        const myurl = `${url}/${id}`;
        this.router.navigateByUrl(myurl).then((e) => {
            if (e) {
                // console.log("Navigation is successful!");
            } else {
                console.log("Navigation has failed!");
            }
        });
    }
    navigateAddCause() {
        this.router.navigate(["/causes/add"]);
    }

    delteCause(id) {
        const obj = {
            _id: id,
        };
        this.causeser.deleteCauseData(obj).subscribe(
            (result: any) => {
                this.loader = false;
                if (result.success === true) {
                    this.commonService.toast(result.message, "Success");
                } else {
                    this.commonService.toast(result.message, "Error");
                }
                this.getCauseList();
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    // ngOnDestroy() {
    //   localStorage.removeItem('selectedCause');
    // }
}
