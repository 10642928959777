import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddToolboxComponent } from './add-toolbox/add-toolbox.component';
import { ToolboxListComponent } from './toolbox-list/toolbox-list.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [AddToolboxComponent, ToolboxListComponent],
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    FormsModule,
    Ng2SearchPipeModule
  ]
})
export class ToolboxesModule { }
