import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PortalService } from './portal.component.services';
import { CommonService } from "src/app/services/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { AddPortalFormComponent } from './add-portal/add-portal-form/add-portal-form.component';
import { result } from 'lodash';
import { CustomValidators } from './customeValidation';
import { PasswordCheckerComponent } from '../inventory/password-checker/password-checker.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EditPortalComponent } from './edit-portal/edit-portal.component';
@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.sass']
})
export class PortalComponent implements OnInit {
  @ViewChild('closeCategory') closeCategory;
  @ViewChild('closePassword') closePassword;
  items = []
  isAuthorized :any;
  isCategoryEdit = false
  submittedEdit = false
  formData: FormGroup;
  categoryData : FormGroup;
  editPassword: FormGroup;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  routeSubscription: Subscription;
  submitted = false;
  loading = false;
  selectedCategory = {
    _id:""
  };
  editCategoryId = ""
  deleteCategoryId = ""
  editCategory = {
    name:"",
    index:""
  }
  editPasswordValue = {
    password:"",
    confirm_password:"",
    old_password:""
  }
  categoryId = "";
  portalId  = ""
  optionsVisibility = {};
  category :any;
  private initialData = {
    categoryId:"",
    index: "",
    buttonName: "",
    buttonLink: "",
  };
  submittedEditPassword = false
  params:any
  constructor(
    private _fb: FormBuilder,
    private api: PortalService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,

  ) { 
  }

  ngOnInit() {
 this.params = this.activatedRoute.snapshot.params
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.deleteComponent(); 
    });

    this.formData = this._fb.group({
      categoryId: [this.initialData.categoryId, [Validators.required]],
      index: [this.initialData.index,[Validators.required,Validators.pattern('^[0-9]+$')]],
      buttonName: [this.initialData.buttonName, [Validators.required]],
      buttonLink: [this.initialData.buttonLink, [Validators.required]],
    });
    this.categoryData = this._fb.group({
      name: [this.editCategory.name, [Validators.required]],
      index:[this.editCategory.index,[Validators.required,Validators.pattern('^[0-9]+$')]]
    });
    this.editPassword = this._fb.group({
        password: [this.editPasswordValue.password,[Validators.required ]],
        old_password:[this.editPasswordValue.old_password,[Validators.required]],
        confirm_password: [this.editPasswordValue.confirm_password,[Validators.required]]
    })
   
    this.formData.patchValue({
      categoryId: this.initialData.categoryId,
    });

    if(this.params && 'category' in this.params){
      let link = `portal/list/${this.params.category}`
      this.handleUserAuthCheck(link,"Portal Management")
    }else {
      this.handleUserAuthCheck("portal/list","Portal Management")

    }
  }


  handleUserAuthCheck(url:any,name:string) {
    this.items=[]
    sessionStorage.setItem("ivu", "false");
      const initialState = {
        component: "inventory",
        url,
        name
      };
      this.modalRef =  this.modalService.show(PasswordCheckerComponent, {
        initialState,
        class: "modal-lg",
      });
      this.modalSubscription =  this.modalService.onHide.subscribe(() => {
        this.isAuthorized = sessionStorage.getItem("ivu")
        if (this.isAuthorized == 'true') {
          this.loading =  true
          this.handleCategory("")
        }
        else{
          this.router.navigate(["users/list"]);
        }
      });
  }


  handleCategorySelect(item: any) {
    console.log(item,'the item')
    this.selectedCategory = item;
    this.formData.patchValue({
      categoryId: item._id,
    });
    this.getList(item._id)
  }

  handlePageChange(evnet:any) {

  }
  handleOptionVisibility(event: any, isShow: boolean) {
    const name = event.target.name;

    setTimeout(() => {
      this.optionsVisibility[name] = isShow;
    }, 500);
  }

  get f() {
    return this.formData.controls;
  }

  get g() {
    return this.categoryData.controls
  }

  get p() {
    return this.editPassword.controls
  }

  handleUpdateButton () {
    this.submitted = true;
    if(!this.formData.valid) {
      return
    }
    this.api.addButton(this.formData.value).subscribe((result:any) => {
      this.loading = false;
      this.commonService.toast(result.message, "Success");
      this.submitted = false;

    })
    
  }

  handleEdit (item:any) {
    const initialState = {
      initialData: {
        ...item,
      },
    };
    this.modalService.show(EditPortalComponent, {
      initialState,
      class: "modal-lg",
      ignoreBackdropClick: true
    });
    this.modalService.onHide.subscribe(() => {
      this.getList(this.selectedCategory._id)
    });
  }

  getList (id:any) {
    this.loading = true;
    if(!id) return 
    this.api.getPortalList(id).subscribe(
      (result:any) => {
        const response  = result.data;
        this.items = response.items
        this.loading = false
      },
      (error:any) =>{
        console.log('the error',error)
      }
    )
  }

  handleCategory(event: any) {
    const str = event ? event.target.value : "";
    const data = {
      search: str,
      type:"all"
    };
    this.api.getCategory(data).subscribe(
      (result: any) => {
        const response = result.data;
        if (response.items) {
          this.category = response.items;
          let temp = this.category.find(obj => obj.name === (this.params.category ? this.params.category : "Calendars"));
          this.selectedCategory = temp || this.category[0]
          this.getList(this.selectedCategory._id)
        }
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );

    this.selectedCategory = {
      _id:""
    };
    this.formData.patchValue({
      employeeId: "",
    });
  }

  setSelectedPortalId(id: string,categoryId:string) {
    this.portalId = id;
    this.categoryId = categoryId
  }

  deltedata() {
    const Id = this.portalId;
    this.api.deleteButton(Id).subscribe(
      (result: any) => {
        this.loading = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList( this.categoryId);
      },
      (error: any) => {
        this.loading = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleEditCategory(editCategory:any,editCategoryId:any) {
    this.editCategory = editCategory
    this.editCategoryId = editCategoryId;
    this.isCategoryEdit  = true
    this.categoryData = this._fb.group({
      name: [this.editCategory.name, [Validators.required]],
      index:[this.editCategory.index,[Validators.required,Validators.pattern('^[0-9]+$')]]
    });
  }

  handlesubmitPassword() {
    this.submittedEditPassword = true
    if(!this.editPassword.valid) {
      return
    }
    this.api.addEditPassword(this.editPassword.value).subscribe((result:any) => {
      if (result.success === true) {
        this.commonService.toast(result.message, "Success");
        this.editPassword.reset()
      } else {
        this.commonService.toast(result.message, "Error");
      }
      this.closePassword.nativeElement.click();
      this.submittedEditPassword = false

    },
    (error) => {
      this.commonService.toast(error.error.message, "Error");

    }
  )
  }

  deleteCategory(categoryId:any) {
    this.deleteCategoryId = categoryId
  } 

  deleteCategoryData() {
    const Id = this.deleteCategoryId;
    this.api.deleteCategory(Id).subscribe(
      (result: any) => {
        this.loading = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }
        this.handleCategory("")
      },
      (error: any) => {
        this.loading = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );

  }

  handleEditUpdateCategory () {
    this.submittedEdit = true
    if(!this.categoryData.valid) {
      return
    }
    if (this.isCategoryEdit) {
      this.api.editCategory(this.editCategoryId,this.categoryData.value).subscribe(
        (result:any) => {
          this.isCategoryEdit = false
          this.editCategoryId = ""
          this.commonService.toast(result.message,'Success')
          this.categoryData.reset();
          this.handleCategory("")
          this.closeCategory.nativeElement.click();
          this.submittedEdit = false
        },
        (error : any) => {
          this.commonService.toast(error.error.message, "Error");
          this.submittedEdit = false
        }
      )
    }else {
      this.api.addCategory(this.categoryData.value).subscribe(
        (result: any) => {
          this.commonService.toast(result.message,'Success')
          this.handleCategory("")
          this.categoryData.reset();
          this.closeCategory.nativeElement.click();
          this.submittedEdit = false
          this.isCategoryEdit = false
         
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.submittedEdit = false
        }
      );
  
    }
  }

  removeCategory() {
    this.categoryData.reset();
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.items = [];
    // this.params =""
   
  }

  deleteComponent() {  
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
