import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SupportTicketService } from "../support-ticket.service";

@Component({
  selector: "app-support-ticket-notes-modal",
  templateUrl: "./support-ticket-notes-modal.component.html",
  styleUrls: ["./support-ticket-notes-modal.component.sass"],
})
export class SupportTicketNotesModalComponent implements OnInit {
  noteId: string;
  referenceId: string;
  content: string;
  notesForm: FormGroup;
  noteType: string = "inventory_item";

  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    private bsModalRef: BsModalRef,
    private api: SupportTicketService
  ) {}

  ngOnInit() {
    this.notesForm = this._fb.group({
      content: [this.content, [Validators.required, Validators.minLength(2)]],
    });
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  saveClicked() {
    this.bsModalRef.hide();

    if (this.noteId) {
      const data = {
        notes: this.content,
      };

      this.api.updateSupportTicketNotes(this.noteId, data).subscribe(
        (result: any) => {
          this.commonService.toast(result.message, "Success");
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    } else {
      const data = {
        referenceId: this.referenceId,
        noteType: this.noteType,
        notes: this.content,
      };

      this.api.addSupportTicketNote(data).subscribe(
        (result: any) => {
          this.commonService.toast(result.message, "Success");
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
