import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import { AddData } from "./employee";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  //-----------------------------------------------> Inventory
  getList(data: any) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(`employee/?page=${data.page}${searchQuery}`);
  }

  addData(data: AddData) {
    return this.restRequest.addAuthorization().post(`employee`, data);
  }

  updateData(id: string, data: AddData) {
    return this.restRequest.addAuthorization().put(`employee/${id}`, data);
  }

  deleteData(id: string) {
    return this.restRequest.addAuthorization().delete(`employee/${id}`);
  }
  //-----------------------------------------------> docs
  uploadInventoryDocs(data: {}) {
    return this.restRequest.addAuthorization().post(`po/inventory-doc`, data);
  }

  deleteInventoryDoc(data: {}) {
    return this.restRequest
      .addAuthorization()
      .put(`po/inventory-doc-delete`, data);
  }
}
