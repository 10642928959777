import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-project-view',
  templateUrl: './user-project-view.component.html',
  styleUrls: ['./user-project-view.component.sass']
})
export class UserProjectViewComponent implements OnInit {
  userId = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params.id;
  }
  viewProject() {
    this.router.navigate(['users/' + this.userId + '/projects/view']);
  }
}
