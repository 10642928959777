import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent implements OnInit, OnChanges {
  @Input() image_url: string;
  @Input() thumb_url: string;
  @Input() attributes: any;
  model_name: string;

  images = [];
  constructor() {
    this.model_name = 'imageview-' + Math.floor((Math.random() * 1000));

  }

  ngOnInit() {
    if (!this.thumb_url) {
      this.thumb_url = this.image_url;
    }
    this.images = [this.image_url];
  }
  ngOnChanges() {
      if (!this.thumb_url) {
        this.thumb_url = this.image_url;
      }
      this.images = [this.image_url];
  }
  ngOnChanges1() {
    if (!this.thumb_url) {
      this.thumb_url = this.image_url;
    }
    this.images = [this.image_url];
  }
}

/*interface Attributes {
  class: String;
}*/
