import { Injectable } from '@angular/core';
import { RestRequest } from '../../services/rest-request';
import { HttpClient } from '@angular/common/http';
import { query } from '@angular/animations';


@Injectable({
  providedIn: 'root'
})
export class BookinglinkService {

  constructor(private restRequest: RestRequest, private http: HttpClient) { }

  createbooking(obj) {
    return this.restRequest.post('users/bookingform', obj);
  }
  getJobdetailsbyid(obj) {
    return this.restRequest.get('users/jobdatabyid/' + obj);
  }
  getJobdetailsbyidwithsize(obj) {
    return this.restRequest.get('users/jobdatabyidwithsize/' + obj);
  }
  getpdfforsectionbyname(obj){
    return this.restRequest.get('pdf/getpdfforsectionbyname/' + obj)
  }
  getbookingList(obj) {
    return this.restRequest.addAuthorization().get('users/bookinglistV1/' + obj);
  }
  getbookingListV2(obj,query) {
    return this.restRequest.addAuthorization().get('users/bookinglist/' + obj,query);
  }
  getAllBookingList(query) {
    return this.restRequest.addAuthorization().get('users/allbookinglist',query);
  }
  getbookingdatabyid(obj) {
    return this.restRequest.addAuthorization().get('users/bookingdata/' + obj);
  }
  updatenotesbyid(id, obj) {
    return this.restRequest.addAuthorization().put('users/updatenotesbyid/' + id, obj);
  }
  updateimageyid(id, obj) {
    return this.restRequest.addAuthorization().put('users/updateimageyid/' + id, obj);
  }
  updateStatus(obj) {
    return this.restRequest.addAuthorization().put('users/updatebookingdetails', obj);
  }
  selectbookingdate(obj) {
    return this.restRequest.addAuthorization().put('users/updatebookingdate', obj);
  }
  pdfurl(obj) {
    return this.restRequest.post('users/bookingform/pdfurl', obj);
  }

  createbookingwithouturl(obj) {
    return this.restRequest.post('users/bookingformwithoutpdfurl', obj);
  }
  deleteData(obj) {
    return this.restRequest.addAuthorization().delete('users/deletebooking/' + obj);
  }

  createNotes(data) {
    return this.restRequest.addAuthorization().post('users/saveNotes',data);
  }

  updateNotes(data) {
    return this.restRequest.addAuthorization().put('users/updateNote',data);
  }

}
