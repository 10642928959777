import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isActive'
})
export class IsActivePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // return null;
    if (value === '1') {
      return 'Active';
    } else {
      return 'In Active';
    }
  }

}
