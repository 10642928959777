import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbLink } from './breadcrumb';
@Injectable()
export class BreadcrumbService {

  private messageSource = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  setLinks(links: any[]) {
    this.messageSource.next(links);
  }

}
