import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import { TimeSheetService } from "../timesheet.service";
import { CommonService } from "src/app/services/common.service";
import * as moment from 'moment-timezone';

@Component({
  selector: "app-timesheet-modal",
  templateUrl: "./timesheet-modal.component.html",
  styleUrls: ["./timesheet-modal.component.sass"],
})
export class TimesheetModalComponent implements OnInit {
  item: any;
  receivedData: any;
  userInfo: any;
  isLoading: boolean;
  test = ["1", "2", "3", "4", "5", "6", "7", "8","9","10","11","12","13","14","15","16","17","18","19","20"];
  daysEmum = ['unpaid_leave','sick_leave','annual_leave']

  constructor(
    private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private api: TimeSheetService
  ) {
    this.isLoading = true;
  }

  ngOnInit() {
    this.getList(this.receivedData);
  }

  getList(id: any) {
    this.api.getDetail(id).subscribe(
      (result: any) => {
        this.item = result.data;
        this.item.startDate = moment(this.item.createdAt).tz('Australia/Sydney').format('MM/DD/YYYY');

        this.isLoading = false;
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  returnHour(str: any)  {
    if(this.test.includes(str)) return "Hours"
  }

  handleCloseModal() {
    this.bsModalRef.hide();
  }

  getHoursForDay(arr: any, str: string): string {
    if(arr.day === str) {
      let check = this.daysEmum.some((e) => e == arr.hours ) 
      if (check) {
        if(arr.hours === 'unpaid_leave') return 'UL'
        else if (arr.hours === 'sick_leave') return 'SL'
        else if (arr.hours === 'annual_leave') return "AL"
      }
      else {
        return arr.hours
      }
    }
  }
}
