import { Injectable } from '@angular/core';
import { RestRequest } from '../../services/rest-request';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LegendService {

  constructor(private restRequest: RestRequest, private http: HttpClient) { }

  sendLegent(data) {
    return this.restRequest.addAuthorization().post('users/addLegend', data);
  }
  getLegent() {
    return this.restRequest.addAuthorization().get('users/getLegendDataV1');
  }
  getLegentV2(query) {
    return this.restRequest.addAuthorization().get('users/getLegendData',query);
  }
  getLegentById(id) {
    return this.restRequest.addAuthorization().get('users/getLegendDataById/' + id);
  }
  updateLegent(obj) {
    return this.restRequest.addAuthorization().post('users/editLegend', obj);
  }
  updateStepper(obj) {
    return this.restRequest.addAuthorization().post('users/updateStepper', obj);
  }
  resizeStatus(obj) {
    return this.restRequest.addAuthorization().post('users/updateResize', obj);
  }
  rotateStatus(obj) {
    return this.restRequest.addAuthorization().post('users/updateRotation', obj);
  }
  deleteLegent(obj) {
    return this.restRequest.addAuthorization().post('users/deleteLegend', obj);
  }
  getLegentByTempId(obj) {
    return this.restRequest.addAuthorization().post('users/getLegend', obj);
  }
  changeLegendType(obj) {
    return this.restRequest.addAuthorization().post('users/updateLegendType', obj);
  }

}
