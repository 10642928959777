import { Component, OnInit } from '@angular/core';
import { RepairService } from '../repair.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-repair-list',
  templateUrl: './repair-list.component.html',
  styleUrls: ['./repair-list.component.sass']
})
export class RepairListComponent implements OnInit {
  items;
  loader;
  nodata = false;
  p = 1;
  _id: string;
  searchText = '';
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1
  };
  recordsPerPage: number;
  totalRecords = 0;

  constructor(
    private repairService: RepairService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public commonService: CommonService
  ) { 
    this.recordsPerPage = environment.defaultPageSize;
  }

  ngOnInit() {
    this.loader = true;
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this._id = this.activatedRoute.snapshot.params.id;
      this.getRepairListById(this._id);
    } else {
      this.getRepairList();
    }
  }
  getRepairList() {
    this.query.search = this.searchText
    this.repairService.getRepairListV2(this.query).subscribe((result: any) => {
      const items = result.data.data.filter(data=> data.templateActive == true);;
      this.items = items;
      this.totalRecords = result.data.totalCount;
      this.loader = false;
      this.nodata = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getRepairList();
  }

  getRepairListById(idVal: string) {
    const obj = { tempId: idVal };
    this.repairService.getRepairListById(obj).subscribe((result: any) => {
      const items = result.data;
      this.items = items;
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }
  navigateAddRepair() { this.router.navigate(['/repair/add']); }

  getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

  delteRepair(id) {
    const obj = {
      _id: id
    };
    this.repairService.deleteRepairData(obj).subscribe((result: any) => {
      this.loader = false;
      if (result.success === true) {
        this.commonService.toast(result.message, 'Success');
      } else {
        this.commonService.toast(result.message, 'Error');
      }
      if (this.activatedRoute.snapshot.params.id !== undefined) {
        this._id = this.activatedRoute.snapshot.params.id;
        this.getRepairListById(this._id);
      } else {
        this.getRepairList();
      }
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }


}
