import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { EmployeeService } from "../employee.service";

@Component({
  selector: "app-add-employee-form",
  templateUrl: "./add-employee-form.component.html",
  styleUrls: ["./add-employee-form.component.sass"],
})
export class AddEmployeeFormComponent implements OnInit {
  isAuthorized = sessionStorage.getItem("ivu");
  isLoading = false;

  category = "";
  categories = [];
  isValid = false;
  submitted = false;
  inventoryId = "";
  startDateInputType = "";
  addInventoryForm: FormGroup;

  private initialData = {
    name: "",
    address: "",
    phoneNumber: null,
    employeeNo: null,
    startDate: null,
    email: "",
    position: "",
    roleDescription: "",
    hourlyRate: null,
    commissionPercentage: "",
    customerExperienceBonus: "",
    overnightAllowance: "",
    targetOncentives: "",
    verbalWarnings: "",
    writtenWarnings: "",
  };

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private api: EmployeeService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(() => {
      this.addInventoryForm = this._fb.group({
        name: [
          this.initialData.name,
          [Validators.required, Validators.minLength(2)],
        ],
        address: [
          this.initialData.address,
          [Validators.required, Validators.minLength(2)],
        ],
        phoneNumber: [this.initialData.phoneNumber, [Validators.required]],
        // phoneNumber: [this.initialData.phoneNumber, [Validators.required]],
        employeeNo: [this.initialData.employeeNo, [Validators.required]],
        startDate: [this.initialData.startDate, [Validators.required]],
        email: [
          this.initialData.email,
          [Validators.required, Validators.minLength(2)],
        ],
        position: [
          this.initialData.position,
          [Validators.required, Validators.minLength(2)],
        ],
        roleDescription: [
          this.initialData.roleDescription,
          [Validators.required, Validators.minLength(2)],
        ],
        hourlyRate: [this.initialData.hourlyRate, [Validators.required]],
        commissionPercentage: [this.initialData.commissionPercentage],
        customerExperienceBonus: [this.initialData.customerExperienceBonus],
        overnightAllowance: [this.initialData.overnightAllowance],
        targetOncentives: [this.initialData.targetOncentives],
        verbalWarnings: [this.initialData.verbalWarnings],
        writtenWarnings: [this.initialData.writtenWarnings],
      });
    });
  }

  get f() {
    return this.addInventoryForm.controls;
  }

  handleStartDateFocus() {
    this.startDateInputType = "date";
  }

  handleEditInventory() {
    this.submitted = true;

    if (this.addInventoryForm.valid && this.isLoading === false) {
      const formData = this.addInventoryForm.value;
      this.isLoading = true;

      this.api.addData(formData).subscribe(
        (result: any) => {
          this.submitted = false;
          this.commonService.toast(result.message, "Success");

          this.isLoading = false;
          this.router.navigate([`Employee/list`]);
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.isLoading = false;
        }
      );
    }
  }
}
