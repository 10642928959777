import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BookinglinkService } from '../bookinglink.service'
//import { DomSanitizer } from '@angular/platform-browser'
//import { Observable } from 'rxjs';
//import { HttpClient } from '@angular/common/http';
//import { HttpHeaders } from '@angular/common/http';
//import { FileMimeType } from '@taldor-ltd/angular-file-viewer'
//import * as pdfjsLib from 'pdfjs-dist';
//import * as remote from 'remote-file-size'


@Component({
  selector: 'app-pdfview',
  templateUrl: './pdfview.component.html',
  styleUrls: ['./pdfview.component.sass']
})
export class PdfviewComponent implements OnInit {
  quoteref: any;
  price: any;
  size = true;
  src;
  link: any;
  fileURL: any;
  loader;
  item;
  quote;
  warranty;
  quotedata;
  pdfurl: any;
  response: any;
  //type: FileMimeType.PDF;


  constructor(private activatedRoute: ActivatedRoute,
    private bookinglinkService: BookinglinkService,
    //private sanitizer: DomSanitizer,
    //private http: HttpClient
  ) { }

  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
  //     'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': 'http://localhost:4200'
  //   })
  // };

  ngOnInit() {
    //this.item.file ;
    this.response = false;
    this.quoteref = this.activatedRoute.snapshot.params.jobid;
    this.getJobdetailsbyid(this.activatedRoute.snapshot.params.jobid);
    this.quote = false;
    this.warranty = false;
  }

  getJobdetailsbyid(jobid) {
    
    //this.bookinglinkService.getJobdetailsbyidwithsize(jobid).subscribe((result: any) => {
      this.bookinglinkService.getJobdetailsbyid(jobid).subscribe((result: any) => {
      this.item = result.data;
      this.src = this.item.file;
      console.log(this.item.file);
      this.loader = true;
      // const sizee = this.item.size;
      // if (sizee < 20) {
      //   this.size = false
      // } else {
      //   this.size = true
      // }

      this.response = true;
      this.price = this.checktype();
      if (this.price != 'N/A') {
        var testp = this.item.grand_total != undefined ? this.item.grand_total : this.price;
        this.price = new Number(testp).toLocaleString("hi-IN", { style: "currency", currency: "USD" }).substring(1);
      }
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
    });
  }



  checktype() {

    if (this.item.quoteData.length > 1) {
      for (const arrTemp of this.item.quoteData) {
        if (arrTemp.link == "" || arrTemp.link == '' || arrTemp.link == "      " || arrTemp.link == undefined) {
          continue
        }
        else {
          this.quotedata = arrTemp;
          break;
        }
      }
    } else {
      this.quotedata = this.item.quoteData[0];
    }

    if (this.quotedata.price != 0) {
      this.quote = true;
      return this.quotedata.price
    } else {
      this.warranty = true;
      return 'N/A'
    }

  }

}
