import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import {DiscountService} from 'src/app/modules/discount/discount.service'


@Component({
  selector: 'app-adddiscount',
  templateUrl: './adddiscount.component.html',
  styleUrls: ['./adddiscount.component.sass']
})
export class AdddiscountComponent implements OnInit {
  addDiscountForm: FormGroup;
  submitted;
  loader;
  dropVal = null;
  discountperc: '';
  code: '';
  //tempName: '';

  listData: any;
  editId = true;
  _id = '';
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private commonService: CommonService,
              private discount: DiscountService
  ) { }

  ngOnInit() {

    this.addDiscountForm = new FormGroup({
      discountperc: new FormControl(this.discountperc, [Validators.required, Validators.pattern('^[0-9]*$')]),
      code: new FormControl(this.code, [Validators.required]),
     // tempName: new FormControl(this.tempName, [Validators.required])
    });

    if (this.activatedRoute.snapshot.params.Id !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.Id;
      this.loadData(this.activatedRoute.snapshot.params.Id);
    }
  }
  loadData(Id) {
    this.loader = true;
    this.discount.getDiscounyById(Id).subscribe((result: any) => {
      const items = result.data;
      this.editDiscountData(items);
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  editDiscountData(data) {
    this.addDiscountForm.patchValue({
      discountperc: data.discount_percentage,
      code: data.discount_code,
    });
  }

  get f() {
    return this.addDiscountForm.controls;
  }
  addDiscount(){
    this.submitted = true;
    const data = this.addDiscountForm.controls;
    if (this.addDiscountForm.invalid) {
      console.log('Invalid form');
    } else {
      const obj = {
        discount_code: data.code.value,
        discount_percentage: data.discountperc.value,
        //tempId: data.tempName.value,
      };
      this.loader = true;
      this.discount.createDiscount(obj).subscribe((res) => {
        this.router.navigate(['/Discount/list']);
        this.commonService.toast('Discount details added Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }

  updateDiscount(){
    this.submitted = true;
    const data = this.addDiscountForm.controls;
    if (this.addDiscountForm.invalid) {
      console.log('Invalid form');
    } else {
      const obj = {
        _id : this._id,
        discount_code: data.code.value,
        discount_percentage: data.discountperc.value,
        //tempId: data.tempName.value,
      };
      this.loader = true;
      this.discount.createDiscount(obj).subscribe((res) => {
        this.router.navigate(['/Discount/list']);
        this.commonService.toast('Discount details updated Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }
}
