import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { InventoryService } from "../inventory.service";
import { Inventory } from "../Inventory";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { EditInventoryComponent } from "../edit-inventory/edit-inventory.component";
import { InventoryNotesComponent } from "../inventory-notes/inventory-notes.component";
import { PasswordCheckerComponent } from "../password-checker/password-checker.component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-inventorylist",
  templateUrl: "./inventorylist.component.html",
  styleUrls: ["./inventorylist.component.sass"],
})
export class InventorylistComponent implements OnInit, OnDestroy {
  isAuthorized: any;
  category = "";
  items: Inventory[] = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedInventoryId = "";
  loader = false;
  modalSubscription: Subscription;
  modalRef: BsModalRef;
  routeSubscription: Subscription;
  heading : any;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private api: InventoryService,
    private commonService: CommonService,
    private router: Router
  ) {}

  titles = [
    {
      key:'core_products',
      title:'Core Products'
    },
    {
      key:'raw_materials',
      title:'Raw Materials'
    },
    {
      key:'equipment_parts',
      title:'Equipment Parts'
    },
    {
      key:'other',
      title:'Other'
    }
  ]

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.deleteComponent();
      });

    this.isAuthorized = "false";
    this.route.params.subscribe((params: Params) => {
      this.category = this.route.snapshot.params.category;
      let temp = this.titles
      .filter(({ key }) => key === this.category)
      .map(({ title }) => title);
      this.heading = temp[0]
      console.log(this.heading,'the heading')
          // if(this.category == 'consumables') {
      //   this.handleUserAuthCheck('/Inventory/list/consumables','Consumable Order')
      // }
      // else if(this.category == 'core_products') {
      //   this.handleUserAuthCheck('/Inventory/list/core_products','Core Product Order')
      // }
      if (this.category == "raw_materials") {
        this.handleUserAuthCheck(
          "/Inventory/list/raw_materials",
          "Raw Materials Order"
        );
      } else {
        this.isAuthorized = "true";
        this.getInventoryList();
      }
      // else if (this.category == 'equipment_parts') {
      //   this.handleUserAuthCheck('/Inventory/list/equipment_parts','Equipment Order')
      // }
      // else {
      //   this.handleUserAuthCheck('/Inventory/list/other','Other Order')
      // }
    });
  }
  handleUserAuthCheck(url: any, name: string) {
    this.items = [];
    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "inventory",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");

      if (this.isAuthorized == "true") {
        this.loader = true;
        this.getInventoryList();
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getInventoryList();
  }

  getInventoryList(search?: string) {
    if (!this.category) {
      return;
    }
    this.loader = true;
    this.api
      .getInventoryList({
        search,
        route: this.category,
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          // this.commonService.toast(error.error.message, 'Error');
        }
      );
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.getInventoryList(inputElement.value);
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getInventoryList();
    });
  }

  setSelectedInventoryId(id: string) {
    this.selectedInventoryId = id;
  }

  deltedata() {
    const Id = this.selectedInventoryId;
    this.api.deleteInventoryItem(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getInventoryList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleEditInventory(data: {} = {}) {
    const initialState = {
      inventoryData: data,
      category: this.category,
    };

    this.modalService.show(EditInventoryComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getInventoryList();
    });
  }

  ngOnDestroy() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.items = [];
    this.totalPages = 0;
    this.totalRecords = 0;
    this.currentPageNo = 1;
    this.selectedInventoryId = "";
    this.loader = false;
    this.isAuthorized = null;
  }

  deleteComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
