import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddLegendComponent } from './add-legend/add-legend.component';
import { LegendListComponent } from './legend-list/legend-list.component';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PaginationModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [AddLegendComponent, LegendListComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule, NgxPaginationModule, FormsModule, ReactiveFormsModule, Ng2SearchPipeModule, PipesModule
  ]
})
export class LegendsModule { }
