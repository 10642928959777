import { Component, OnInit, Renderer2 } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { InspectionService } from "./../inspection.service";
import { CommonService } from "src/app/services/common.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { ThankyouModalComponent } from "./../thankyou-modal/thankyou-modal.component";
import { CustomValidators } from "ng2-validation";

@Component({
  selector: "app-editinspection",
  templateUrl: "./editinspection.component.html",
  styleUrls: ["./editinspection.component.sass"],
})
export class EditinspectionComponent implements OnInit {
  issuetype: any;
  state: any;
  loading = false;
  placeholder2 = "*What type of problem are you experiencing?";
  placeholder1 = "*I'd like help with -";
  templatename: any;
  loader;
  textaboutissue;
  submitted = true;
  submitted1 = true;
  submitted2 = true;
  submitted3 = true;
  clientProperty = false;
  prePurchase = false;
  risingDubp = false;
  leaningWall = false;
  finalSubmit = false;
  clientbuildingerror;
  prepurchaseerror;
  lwerror;
  rderror;
  nextscren = true;
  nextscren1 = true;
  nextscren2 = true;
  fileToUpload: File = null;
  fileToUpload1 = null;
  inspectionForm: FormGroup;
  obj: any;
  isOld = true;
  preference: any;
  userData = {
    issuetype: "",
    name: "",
    preference: "",
    link: "",
    lname: "",
    templatename: "",
    textaboutissue: "",
    email: "",
    email2: "",
    phonenumber: "",
    address: "",
    address1: "",
    town: "",
    postcode: "",
    state: "",
    image: [],
    hidfile: "",
    sendemailcustomer: "",
    is_remote: false,
  };
  query: any = {
    page: 1,
    limit: 100,
    userType: 1,
  };
  arr_dummy: any = [];

  templateName = {
    strata: [
      {
        name: "Subsidence",
        label: "Foundations are moving or sinking.",
      },
      {
        name: "Wall Cracks",
        label: "Visible cracks on walls.",
      },
      {
        name: "Sinking Floor Piers",
        label: "Floor is sloping, bouncy, or uneven.",
      },
      {
        name: "Rising Damp",
        label: "Flaking paint, powdery surfaces, or damp patches and odour.",
      },
      {
        name: "Sunken Concrete",
        label: "Uneven interior concrete floor or shifting driveway/path/pool.",
      },
      {
        name: "Leaning Walls",
        label: "Walls are leaning or bowing outwards.",
      },
      {
        name: "Other",
        label: "None of the above",
      },
    ],
    homeowner: [
      {
        name: "Subsidence",
        label: "Foundations are moving or sinking.",
      },
      {
        name: "Wall Cracks",
        label: "Visible cracks on walls.",
      },
      {
        name: "Sinking Floor Piers",
        label: "Floor is sloping, bouncy, or uneven.",
      },
      {
        name: "Rising Damp",
        label: "Flaking paint, powdery surfaces, or damp patches and odour.",
      },
      {
        name: "Sunken Concrete",
        label: "Uneven interior concrete floor or shifting driveway/path/pool.",
      },
      {
        name: "Leaning Walls",
        label: "Walls are leaning or bowing outwards.",
      },
      {
        name: "Other",
        label: "None of the above",
      },
    ],
    commercial: [
      {
        name: "Subsidence",
        label: "Foundations are moving or sinking.",
      },
      {
        name: "Wall Cracks",
        label: "Visible cracks on walls.",
      },
      {
        name: "Sinking Floor Piers",
        label: "Floor is sloping, bouncy, or uneven.",
      },
      {
        name: "Rising Damp",
        label: "Flaking paint, powdery surfaces, or damp patches and odour.",
      },
      {
        name: "Sunken Concrete",
        label: "Uneven interior concrete floor or shifting driveway/path/pool.",
      },
      {
        name: "Leaning Walls",
        label: "Walls are leaning or bowing outwards.",
      },
      {
        name: "Other",
        label: "None of the above",
      },
    ],
  };
  issueType2 = [
    "My Home",
    "A Managed Property - I’m a Real Estate Agent",
    "A Strata Building or Complex - I’m an Owner",
    "My Investment Property",
    "A Client's Property - I'm a Contractor",
    "A Pre-Purchase Inspection",
    "My Family or Friend's home",
  ];

  constructor(
    private formBuilder: FormBuilder,
    private inspectionService: InspectionService,
    private router: Router,
    public commonService: CommonService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,

  ) {}

  ngOnInit() {
    this.submitted1 = true;
    //this.gettemplatedata();
    this.templatename = [
      {
        name: "Wall Cracks",
        label: "Wall Cracks",
      },
      {
        name: "Subsidence",
        label: "Subsidence",
      },
      {
        name: "Leaning Wall",
        label: "Leaning Wall",
      },
      {
        name: "Sinking Floor Pier",
        label: "Sinking Floor Pier",
      },
      {
        name: "Arch and Lintel",
        label: "Arch and Lintel",
      },
      {
        name: "Rising Damp",
        label: "Rising Damp",
      },
      {
        name: "Sunken Concrete",
        label: "Sunken Concrete",
      },
      {
        name: "Other",
        label: "Other",
      },
    ];
    this.issuetype = [
      "My Home",
      "A Managed Property",
      "My Work Building",
      "A Client's Building- I'm a contractor",
      "A Pre-Purchase Inspection",
      "My Family or Friend's home",
    ];
    //this.state = ["Australian Capital Territory", "New South Wales", "Queensland", "South Australia", "Tasmania", "Victoria", "Western Australia"]
    this.state = ["ACT", "NSW", "QLD", "VIC", "TAS", "WA", "SA"];
    this.inspectionForm = this.formBuilder.group({
      issuetype: new FormControl(this.userData.issuetype, [
        Validators.required,
      ]),
      name: new FormControl(this.userData.name, [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]*$"),
      ]),
      lname: new FormControl(this.userData.lname, [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]*$"),
      ]),
      templatename: new FormControl(this.userData.templatename, [
        Validators.required,
      ]),
      link: new FormControl(this.userData.link),
      preference : new FormControl(this.userData.preference),
      textaboutissue: new FormControl(this.userData.textaboutissue),
      email: new FormControl(this.userData.email, [
        Validators.required,
        //Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        CustomValidators.email,
      ]),
      email2: new FormControl(this.userData.email2, [
        //Validators.required,
        //Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        //CustomValidators.email
      ]),
      phonenumber: new FormControl(this.userData.phonenumber, [
        Validators.required,
        Validators.minLength(10),
        Validators.pattern("^[0-9]*$"),
      ]),
      address: new FormControl(this.userData.address, [Validators.required]),
      town: new FormControl(
        this.userData.town
        // , [Validators.required]
      ),
      address1: new FormControl(this.userData.address1),
      postcode: new FormControl(this.userData.postcode, [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern("^[0-9]*$"),
      ]),
      state: new FormControl(this.userData.state, [Validators.required]),
      // image: new FormControl(this.userData.image),
      repMain: this.formBuilder.array([this.addRepairMethod()]),
      hidfile: new FormControl(this.userData.hidfile),
      sendemailcustomer: new FormControl(this.userData.sendemailcustomer),
    });

    this.clientbuildingerror = false;
    this.prepurchaseerror = false;
    this.nextscren = false;
    this.getInspectiondetailsbyid(
      this.activatedRoute.snapshot.params.inpectionid
    );
  }

  ngAfterViewInit() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const panel = document.querySelector('.ng-dropdown-panel');
          if (panel) {
            this.renderer.addClass(panel, 'custom-ng-dropdown-panel');
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  getInspectiondetailsbyid(id) {
    this.inspectionService
      .getinspectionlistbyid(id)
      .subscribe((result: any) => {
        const items = result.data[0];
        //this.item = items[0];
        console.log(items);
        this.patchData(items);
      });
  }
  patchData(items) {
    this.inspectionForm.patchValue({
      issuetype: items.issuetype,
      state: items.state,
      templatename: items.templatename,
      name: items.name,
      lname: items.lname,
      textaboutissue: items.textaboutissue,
      email: items.email,
      email2: items.email2,
      phonenumber: items.phonenumber,
      address: items.address,
      address1: items.address1,
      town: items.City,
      postcode: items.postcode,
      preference: items.preference || "",
      link: items.link || "",
      // repMain: items.image
    });
    console.log(items,'the item')
    if (items.link) {
      this.isOld = false;
      this.preference = this.templateName[items.link];
      this.inspectionForm.addControl('preference', new FormControl(this.userData.preference, [
        Validators.required,
      ]));
    }
    //this.inspectionForm.setControl('repMain', this.setRepMain(items.image));
  }
  setRepMain(repData: any): FormArray {
    const formArray = new FormArray([]);
    repData.forEach((s) => {
      formArray.controls.push(
        this.formBuilder.group({
          repairMethod: s.repairMethod,
        })
      );
    });
    return formArray;
  }
  gettemplatedata() {
    //this.loader = true;
    this.inspectionService.getTemplate(this.query).subscribe(
      (result: any) => {
        var tempdata = result.data;
        var temparr = tempdata.reverse().slice(0, 7);
        temparr.push({ templateName: "Other" });
        this.templatename = temparr;
        this.getInspectiondetailsbyid(
          this.activatedRoute.snapshot.params.inpectionid
        );

        //this.loader = false;
      },
      (error: any) => {
        // this.loader = false;
      }
    );
  }
  groupValueFn = (_: string, children: any[]) =>
    console.log(children, "the childre");

  selectPreference(preference: any) {
    console.log(this.inspectionForm.value, "the prefences");
    this.inspectionForm.patchValue({ preference });

    console.log(this.inspectionForm.value, "the value");
  }

  handleClose(name: any) {
    if (name == "clientProperty") this.clientProperty = false;
    if (name == "prePurchase") this.prePurchase = false;
    if (name == "rderror") this.rderror = false;
    if (name == "lwerror") this.lwerror = false;
  }

  shownotice(event) {
    console.log(event,'event')
    if (event == "A Client's Property - I'm a Contractor") {
      this.prePurchase = false;
      this.clientProperty = true;
    } else if (event == "A Pre-Purchase Inspection") {
      this.clientProperty = false;
      this.prePurchase = true;
    } else {
      this.clientProperty = false;
      this.prePurchase = false;
    }
    if (!event) {
      this.placeholder1 = "*I'd like help with -";
    } else {
      this.placeholder1 = "";
    }
  }
  shownotice1(event) {
    if (event == "Leaning Walls") {
      this.lwerror = true;
      this.rderror = false;
    } else if (event == "Rising Damp") {
      this.rderror = true;
      this.lwerror = false;
    } else {
      this.lwerror = false;
      this.rderror = false;
    }
    if (!event) {
      this.placeholder2 = "*What type of problem are you exeprien";
    } else {
      this.placeholder2 = "";
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    //this.loader = true;
    if (files.length === 1) {
    }
    this.commonService
      .fileUpload(this.fileToUpload)
      .subscribe((result: any) => {
        // this.userData.image = result.data;
        this.arr_dummy.push(result.data);
        this.commonService.toast("Image uploaded successfully", "Success");
        this.fileToUpload1 = true;
        console.log(this.arr_dummy);
        //this.loader = false;
        // this.inspectionForm.patchValue({
        // image: result.data.substring(55)
        //  });
      });
  }
  removeRep(index) {
    if (this.repArray.length === 1) {
      this.arr_dummy.splice(index, 1);
      console.log(this.arr_dummy);
      this.repArray.removeAt(index);
      this.repArray.push(this.addRepairMethod());
      // this.commonService.toast('Atleast one Repair Recommendation is required.', 'Error');
    } else {
      this.repArray.removeAt(index);
      this.arr_dummy.splice(index, 1);
      this.commonService.toast("Image deleted successfully.", "Success");
      console.log(this.arr_dummy);
    }
  }
  addRepairMethod() {
    return this.formBuilder.group({
      image: [this.userData.image],
    });
  }
  addRep() {
    this.repArray.push(this.addRepairMethod());
  }

  get repArray() {
    return this.inspectionForm.get("repMain") as FormArray;
  }
  get f() {
    return this.inspectionForm.controls;
  }
  nextscreen() {
    const data = this.inspectionForm.controls;
    this.submitted1 = true;
    if (data.issuetype.invalid || data.templatename.invalid) {
      this.nextscren = false;
    } else {
      this.nextscren = true;
    }
  }
  nextscreen2() {
    const data = this.inspectionForm.controls;
    this.submitted3 = true;
    if (
      data.address.invalid ||
      data.town.invalid ||
      data.postcode.invalid ||
      data.state.invalid
    ) {
      this.nextscren2 = false;
    } else {
      this.nextscren2 = true;
    }
  }
  nextscreen1() {
    const data = this.inspectionForm.controls;
    this.submitted2 = true;
    if (
      data.email.invalid ||
      data.phonenumber.invalid ||
      data.name.invalid ||
      data.lname.invalid
    ) {
      this.nextscren1 = false;
    } else {
      this.nextscren1 = true;
    }
  }
  submit() {
    this.finalSubmit = true;
    console.log(
      this.inspectionForm.value,
      this.inspectionForm.controls,
      "this.inspectionForm"
    );
    if (this.inspectionForm.invalid) {
      return;
    }
    this.submitted = true;
    const data = this.inspectionForm.controls;
    console.log(data.link.value, "the link");

    if (this.inspectionForm.invalid) {
      this.commonService.toast("Please fill in all the details ", "Error");
      console.log("User form is not valid!!");
    } else {
      console.log(data.link.value, "the link");
      this.obj = {
        issuetype: data.issuetype.value,
        templatename: data.templatename.value,
        textaboutissue: data.textaboutissue.value,
        phonenumber: data.phonenumber.value,
        name: data.name.value,
        lname: data.lname.value,
        email: data.email.value,
        email2: data.email2.value,
        address1: data.address1.value,
        address: data.address.value,
        City: data.town.value,
        state: data.state.value,
        postcode: data.postcode.value,
        image: this.arr_dummy,
        link: data.link.value ,
        preference: data.preference.value ,
        _id: this.activatedRoute.snapshot.params.inpectionid,
        sendemailcustomer: data.sendemailcustomer.value,
      };
      this.inspectionService.updateinspection(this.obj).subscribe(
        (result: any) => {
          this.modalService.show(ThankyouModalComponent, {
            class: "modal-lg",
          });
          this.modalService.onHide.subscribe(() => {
            window.location.href = "https://www.buildfix.com.au/";
          });
        },
        (error) => {
          //this.loader = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
