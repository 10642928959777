import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";

@Component({
  selector: "app-internal-order-modal",
  templateUrl: "./internal-order-modal.component.html",
  styleUrls: ["./internal-order-modal.component.sass"],
})
export class InternalOrderModalComponent implements OnInit {
  item: any;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.item.createdAt = new Date(this.item.createdAt).toLocaleDateString(
      "en-US"
    );

    this.item.note = this.item.inventoriesNote.length
      ? this.item.inventoriesNote[0].notes
      : "";
  }

  handleCloseModal() {
    this.bsModalRef.hide();
  }
}
