import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
interface UserInfo {
    name: String;
    image: String;
}
@Injectable()
export class UserInfoService {

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  setUserInfo(userInfo: UserInfo) {
      console.log('UPDATE');
      console.log(userInfo);
    this.messageSource.next(JSON.stringify(userInfo));
  }

}
