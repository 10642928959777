import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { QuoteService } from "../quote.service";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-quote-list",
  templateUrl: "./quote-list.component.html",
  styleUrls: ["./quote-list.component.sass"],
})
export class QuoteListComponent implements OnInit {
  _id: string;
  nodata = false;
  loader;
  items;
  filterByTemp = false;
  searchText = "";
  p = 1;
  query: any = {
    page: 1,
    limit: 100,
    userType: 1,
  };
  recordsPerPage: number;
  totalRecords = 0;
  selectedCause: string;
  listData: any = [];
  causeList: any;

  constructor(
    private router: Router,
    private quoteService: QuoteService,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    this.recordsPerPage = environment.defaultPageSize;
  }

  ngOnInit() {
    this.loader = true;
    this.query.search = this.searchText;
    this.quoteService.getTemplate(this.query).subscribe(
      (result: any) => {
        this.listData = [];
        if (result.success === true) {
          this.listData = result.data.data.filter(
            (data) => data.active == true
          );
          if (this.activatedRoute.snapshot.params.id !== undefined) {
            this._id = this.activatedRoute.snapshot.params.id;
            this.getQuoteListById(this._id);
          } else {
            this.getQuoteList();
          }
        }
      },
      (error: any) => {
        this.commonService.toast("Something went wrong", "Error");
      }
    );
  }

  ConvertToInt(val) {
    return parseInt(val);
  }

  getQuoteList() {
    this.query.search = this.searchText;
    console.log(this.searchText);
    this.quoteService.getQuoteListV2(this.query).subscribe(
      (result: any) => {
        const items = result.data.data;
        this.items = items;
        this.totalRecords = result.data.totalCount;
        this.loader = false;
        this.nodata = false;
        this.query.tempId = "all";
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        this.query.tempId = "all";
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getQuoteList();
  }

  dropdownValueChange(e) {
    console.log(e.target.value);
    e.target.value == "all" ? null : (this.query.tempId = e.target.value);
    this.getQuoteList();
  }

  getQuoteListById(idVal: string) {
    // const obj = { tempId: idVal };
    this.quoteService.getQuoteList().subscribe(
      (result: any) => {
        const items = result.data;
        this.items = items.filter((item) => item.tempId === idVal);
        this.loader = false;
        this.filterByTemp = true;
      },
      (error: any) => {
        this.loader = false;
        this.nodata = true;
        //      this.commonService.toast(error.error.message, 'Error');
      }
    );
  }

  getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then((e) => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log("Navigation has failed!");
      }
    });
  }

  deleteQuote(id) {
    const obj = {
      _id: id,
    };
    this.quoteService.deleteQuoteData(obj).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }
        if (this.activatedRoute.snapshot.params.id !== undefined) {
          this._id = this.activatedRoute.snapshot.params.id;
          this.getQuoteListById(this._id);
        } else {
          this.getQuoteList();
        }
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  navigateAddQuote() {
    this.router.navigate(["/quote/add"]);
  }
}
