import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap";
import * as moment from 'moment-timezone';

@Component({
  selector: "app-employee-modal",
  templateUrl: "./employee-modal.component.html",
  styleUrls: ["./employee-modal.component.sass"],
})
export class EmployeeModalComponent implements OnInit {
  item: any;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.item.startDate = moment(this.item.startDate).tz('Australia/Sydney').format('MM/DD/YYYY');
    this.item.performance = this.item.performance ? this.item.performance : {};
    this.item.performance.jobSatisfactionAverage =  (this.item.performance.jobSatisfactionAverage)
    
  }

  handleCloseModal() {
    this.bsModalRef.hide();
  }
}
