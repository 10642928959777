import { Injectable } from '@angular/core';
import { RestRequest } from '../../services/rest-request';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class QualityService {
    constructor(private restRequest: RestRequest, private http: HttpClient) { }
      getqualityList(obj) {
        return this.restRequest.addAuthorization().get('quality/getqualityListV1/' + obj);
      }
      getqualityListV2(obj, query) {
        return this.restRequest.addAuthorization().get('quality/getqualityList/' + obj, query);
      }
      updateData(id , obj){
        return this.restRequest.addAuthorization().put('quality/updateData/' + id, obj);
      }
      deleteData(id){
        return this.restRequest.addAuthorization().delete('quality/deleteData/' + id);
      }
      deleteDatamultiple(obj){
        return this.restRequest.addAuthorization().delete('quality/deleteDatamultiple/' + obj);
      }
      getqualitydatabyid(id){
        return this.restRequest.addAuthorization().get('quality/getqualitydatabyid/'+ id)
      }
      updatenotesbyid(id, obj){
        return this.restRequest.addAuthorization().put('quality/updatenotesbyid/' + id, obj);
      }
}