import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { InventoryService } from "../inventory.service";

@Component({
  selector: "app-inventory-links-modal",
  templateUrl: "./inventory-links-modal.component.html",
  styleUrls: ["./inventory-links-modal.component.sass"],
})
export class InventoryLinksModalComponent implements OnInit {
  items = [];
  loader = false;
  index: any;
  companyOverviewId = "";
  content: string;
  showAddAndDeleteBtn = true;
  notesForm: FormGroup;
  link = "";
  isEdit = false;
  reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";

  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    private bsModalRef: BsModalRef,
    private api: InventoryService
  ) {
    this.notesForm = this._fb.group({
      content: [
        this.content,
        [Validators.required, Validators.pattern(this.reg)],
      ],
    });
  }

  ngOnInit() {}
  get contentControl() {
    return this.notesForm.get("content");
  }

  get contentErrorMessage() {
    const contentField = this.notesForm.get("content");
    if (contentField.hasError("required")) {
      return "Link is required.";
    }
    if (contentField.hasError("pattern")) {
      return "Please enter a valid link.";
    }
    return "";
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  saveClicked() {
    if (!this.notesForm.valid) {
      return;
    }
    this.bsModalRef.hide();

    if (this.isEdit) {
      let data = {};
      if (this.link == "sales-performance") {
        data = {
          salesPerformanceId: this.companyOverviewId,
          link: this.content,
          index: this.index,
        };
      } else {
        data = {
          companyOverviewId: this.companyOverviewId,
          link: this.content,
          index: this.index,
        };
      }

      this.api.editLinks(this.link, data).subscribe(
        (result: any) => {
          this.commonService.toast(result.message, "Success");
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    } else {
      let data = {};
      if (this.link == "sales-performance") {
        data = {
          salesPerformanceId: this.companyOverviewId,
          link: this.content,
        };
      } else {
        data = {
          companyOverviewId: this.companyOverviewId,
          link: this.content,
        };
      }
      this.api.addLinks(this.link, data).subscribe(
        (result: any) => {
          console.log(result, "the result");
          this.commonService.toast(result.message, "Success");
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
