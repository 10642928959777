import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { VehicelService } from "../vehicel-service";
import { Router } from "@angular/router";

@Component({
  selector: "app-vehicle-add-page",
  templateUrl: "./vehicle-add-page.component.html",
  styleUrls: ["./vehicle-add-page.component.sass"],
})
export class VehicleAddPageComponent implements OnInit {
  isValid = false;
  submitted = false;
  formData: FormGroup;
  disableSubmit = false;
  showMessage = false;
  isLoading = false;
  images = [""];
  imageSelect = false;
  fileToUpload: File = null;

  private initialData = {
    _id: "",
    driverName: "",
    purchaseDate: "",
    vehicleDescription: "",
    located: "",
    currentRego: "",
    stateRegistered: "",
    regoExpiry: "",
    fullyCompExpiry: "",
    ctpExpiry: "",
    nextService: "",
    ctpInsurer: "",
    fullyCompInsurer: "",
    fuelCardNo: "",
    tollTagNo: "",
    repairsMaintenanceLog: "",
    toDoList: "",
    image: "",
  };

  constructor(
    private _fb: FormBuilder,
    private commonService: CommonService,
    private api: VehicelService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formData = this._fb.group({
      driverName: [this.initialData.driverName, [Validators.required]],
      purchaseDate: [this.initialData.purchaseDate, [Validators.required]],
      vehicleDescription: [
        this.initialData.vehicleDescription,
        [Validators.required],
      ],
      located: [this.initialData.located, [Validators.required]],
      currentRego: [this.initialData.currentRego, [Validators.required]],
      stateRegistered: [
        this.initialData.stateRegistered,
        [Validators.required],
      ],
      regoExpiry: [this.initialData.regoExpiry, [Validators.required]],
      fullyCompExpiry: [
        this.initialData.fullyCompExpiry,
        [Validators.required],
      ],
      ctpExpiry: [this.initialData.ctpExpiry, [Validators.required]],
      nextService: [this.initialData.nextService, [Validators.required]],
      fullyCompInsurer: [
        this.initialData.fullyCompInsurer,
        [Validators.required],
      ],
      fuelCardNo: [this.initialData.fuelCardNo],
      ctpInsurer: [this.initialData.ctpInsurer, [Validators.required]],
      tollTagNo: [this.initialData.tollTagNo],
      repairsMaintenanceLog: [this.initialData.repairsMaintenanceLog],
      toDoList: [this.initialData.toDoList],
      image: [this.initialData.image],
    });
  }

  get f() {
    return this.formData.controls;
  }

  handleFileInput(files: FileList, type = "image", index: number) {
    this.fileToUpload = files.item(0);

    if (files.length === 1) {
      this.isLoading = true;

      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.isLoading = false;

          if (type === "image") {
            this.formData.patchValue({
              image: result.data,
            });
            this.imageSelect = true;
          } else {
            // this.video = result.data;
          }
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  handleSubmit() {
    this.submitted = true;
    if (this.formData.invalid) {
      return;
    }

    this.isLoading = true;
    this.api.addData(this.formData.value).subscribe(
      (result: any) => {
        this.commonService.toast(result.message, "Success");
        this.isLoading = false;
        this.router.navigate(["/vehicle/list"]);
      },
      (err) => {
        this.commonService.toast(err.error.message, "Error");
        this.isLoading = false;
      }
    );
  }
}
