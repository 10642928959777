import { Pipe, PipeTransform } from '@angular/core';
import { TaskType } from './../enum/task-type.enum';
@Pipe({
  name: 'taskType'
})
export class TaskTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return TaskType[Number(value)];
  }
}
