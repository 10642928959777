import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    show_terms: Boolean;
    show_privacy: Boolean;
    current_year: Number;
    constructor() { }

    ngOnInit() {
        this.show_terms = false;
        this.show_privacy = false;
        this.current_year = (new Date()).getFullYear();
    }

    showTerms() {
      this.show_terms = true;
    }

    hideTerms() {
      this.show_terms = false;
    }

    showPrivacy() {
      this.show_privacy = true;
    }

    hidePrivacy() {
      this.show_privacy = false;
    }

}
