import { Injectable } from '@angular/core';
import { RestRequest } from 'src/app/services/rest-request';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private restRequest: RestRequest) { }

  getDiscountList() {
    return this.restRequest.addAuthorization().get('users/getDiscountV1');
  }
  getDiscountListV2(query) {
    return this.restRequest.addAuthorization().get('users/getDiscount',query);
  }
  createDiscount(obj){
    return this.restRequest.addAuthorization().post('users/addDiscount', obj);
  }
  getDiscounyById(obj){
    return this.restRequest.addAuthorization().get('users/getDiscountbyid/'+ obj);
  }
  deleteDiscountData(obj){
    return this.restRequest.addAuthorization().delete('users/deleteDiscountbyid/'+ obj);
  }
}
