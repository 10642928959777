import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TermsAndServicesComponent } from './terms-and-services.component';
import { EditorModule } from "@tinymce/tinymce-angular";

@NgModule({
  declarations: [
      TermsAndServicesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    EditorModule,
    FormsModule,
    PipesModule,
    ModalModule.forRoot(),
  ],
  entryComponents: [ ]
})
export class TermsAndServiceModule { }
