import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}
  getinspectionlist() {
    return this.restRequest.addAuthorization().get("users/inspection_list");
  }
  getinspectionlistV1() {
    return this.restRequest.addAuthorization().get("users/inspection_listV1");
  }
  getengineerlist() {
    return this.restRequest
      .addAuthorization()
      .get("users/getUserList_inspection");
  }
  getinspectiondatabydate(obj) {
    return this.restRequest
      .addAuthorization()
      .post("users/getinspectiondatabydate", obj);
  }
  getbookingdata() {
    return this.restRequest.addAuthorization().get("users/getbookingdata");
  }
  getjobanalysisdata() {
    return this.restRequest.addAuthorization().get("users/getjobanalysisdata");
  }
  getjobanalysisdataV1() {
    return this.restRequest
      .addAuthorization()
      .get("users/getjobanalysisdataV1");
  }
  getinvoiceanalysisdata() {
    return this.restRequest
      .addAuthorization()
      .get("users/getinvoiceanalysisdata");
  }
  getinvoiceanalysisdataV1() {
    return this.restRequest
      .addAuthorization()
      .get("users/getinvoiceanalysisdataV1");
  }
}
