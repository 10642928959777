import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { AdministrativeFormComponent } from "../administrative-form/administrative-form.component";
import { DocumentServices } from "../document.service";
import * as moment from "moment";
import { AdministrativeModalComponent } from "../administrative-modal/administrative-modal.component";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PasswordCheckerComponent } from "../../inventory/password-checker/password-checker.component";

@Component({
  selector: "app-administrative-procedure",
  templateUrl: "./administrative-procedure.component.html",
  styleUrls: ["./administrative-procedure.component.sass"],
})
export class AdministrativeProcedureComponent implements OnInit, OnDestroy {
  status = "";
  title = "";
  items = [];
  routeSubscription: Subscription;
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedId = "";
  loader = false;
  modalRef: BsModalRef;
  searchText = null;
  isEdit = false;
  isAuthorized = "true";
  params: any;
  loading = false;
  modalSubscription: Subscription;
  documentTypeObj = {
    administrative_procedures: "Administrative Procedures",
    business_management: "Business Management",
    service_protocols: "Service Protocols",
    employee_procedures: "Employee Procedures",
    compliance_regulations: "Compliance Regulations",
    marketing_sales: "Marketing Sales",
    finance_accounting: "Finance Accounting",
    it_support: "It Support",
    office_management: "Office Management",
    customer_service: "Customer Service",
    important_files: "Important Files",
  };

  constructor(
    private api: DocumentServices,
    private commonService: CommonService,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.params = this.activatedRoute.snapshot.params.documentType;
        this.getList("");
      }
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.deleteComponent();
      });
    this.getList("");
    // this.handleUserAuthCheck('document/administrative',"Administrative Procedures")
  }

  handleUserAuthCheck(url: any, name: string) {
    this.items = [];
    sessionStorage.setItem("ivu", "false");
    const initialState = {
      component: "inventory",
      url,
      name,
    };
    this.modalRef = this.modalService.show(PasswordCheckerComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalSubscription = this.modalService.onHide.subscribe(() => {
      this.isAuthorized = sessionStorage.getItem("ivu");
      if (this.isAuthorized == "true") {
        this.loading = true;
        this.getList("");
      } else {
        this.router.navigate(["users/list"]);
      }
    });
  }

  handleAdd() {
    const initialState = {
      initialData: {
        // ...item,
        documentType: this.params,
      },
      title: this.documentTypeObj[this.params],
    };
    this.modalService.show(AdministrativeFormComponent, {
      initialState,
      class: "modal-lg",
      // ignoreBackdropClick: true
    });
    this.modalService.onHide.subscribe(() => {
      this.getList("");
      // this.getList(this.selectedCategory._id)
    });
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList("");
  }

  handleSearch(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement, "inputElement");
    this.searchText = inputElement.value;
    this.getList(this.searchText);
  }
  getDate(date: string) {
    return moment(date).format("DD/MM/YYYY");
  }

  getList(search?: string) {
    this.loader = true;
    this.api
      .getAdministrative({
        search,
        page: this.currentPageNo,
        documentType: this.params,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  setSelectedTicketId(id: string) {
    this.selectedId = id;
  }

  handleEditTicket(initialData: any) {
    const initialState = {
      initialData: {
        ...initialData,
      },
      isEdit: true,
    };
    this.modalService.show(AdministrativeFormComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList("");
    });
  }

  deltedata() {
    const Id = this.selectedId;
    this.api.deleteAdministrative(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList("");
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  handleShowOrderModal(item: any) {
    const initialState = {
      administrativeData: item,
    };

    this.modalService.show(AdministrativeModalComponent, {
      initialState,
      class: "modal-lg",
    });

    this.modalService.onHide.subscribe(() => {});
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "document",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList("");
    });
  }

  ngOnDestroy() {
    console.log("Destroy Component Internal");
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    // Clear component state
    this.items = [];
    this.totalPages = 0;
    this.totalRecords = 0;
    this.currentPageNo = 1;
    this.loader = false;
    this.isAuthorized = null;
    this.status = "";
    this.title = "";
    console.log("Destroy Component Internal");
  }

  deleteComponent() {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = null;
    }
  }
}
