import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// import { NgxPaginationModule } from 'ngx-pagination';

import { UnauthLayoutComponent } from './unauth-layout/unauth-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { ProfileLayoutComponent } from './profile-layout/profile-layout.component';

import { HeaderDashboardComponent } from './header-dashboard/header-dashboard.component';

import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';


@NgModule({
  declarations: [
    AdminLayoutComponent,
    UnauthLayoutComponent,
    ProfileLayoutComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    HeaderDashboardComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule
  ],
  exports: [
    AdminLayoutComponent,
    UnauthLayoutComponent,
    ProfileLayoutComponent,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    HeaderDashboardComponent
  ],
  providers: []
})
export class LayoutsModule { }
