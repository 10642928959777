import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { HumanResourceService } from "../human-resource.service";
import * as moment from 'moment';
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";

@Component({
  selector: "app-annual-leave",
  templateUrl: "./annual-leave.component.html",
  styleUrls: ["./annual-leave.component.sass"],
})
export class AnnualLeaveComponent implements OnInit {
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  modalRef: BsModalRef;
  status:any;

  title = {
    new:"New Annual Leave Report",
    archived :"Archived Annual Leave Report"
  }
  constructor(
    private commonService: CommonService,
    private router: Router,
    private modalService: BsModalService,
    private api: HumanResourceService,
    private route: ActivatedRoute,

  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.status = this.route.snapshot.params.status;
      console.log(this.status)
     this.getList();
  })
  }

  getDate(date:any) {
    if (date) return moment(date).format("DD/MM/YYYY")
      return "-"
  }

  getList() {
    this.loader = true;
    this.api
      .annualLeave({
        page: this.currentPageNo,
        status:this.status
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "annual_leave",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handleUpdateStatus(Id: string, event) {
    const status = event.target.value;

    this.api.updateAnnualLeave(Id, { status }).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

  setSelectedTicketId(id: string) {
    this.selectedTicketId = id;
  }

  deltedata() {
    const Id = this.selectedTicketId;

    this.api.deleteAnnualLeave(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
        this.selectedTicketId =""
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }

}
