import { Component, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormControl, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MarketingService } from "../marketing.service";
import { CommonService } from "src/app/services/common.service";
import { result } from "lodash";

@Component({
  selector: "app-ad-control-add-form",
  templateUrl: "./ad-control-add-form.component.html",
  styleUrls: ["./ad-control-add-form.component.sass"],
})
export class AdControlAddFormComponent implements OnInit {
  formGroup: FormGroup;
  editId = true;
  image: "";
  isLoading: Boolean = false;

  isNextReviewDateModified: Boolean = false;
  dailySpentFlag: Boolean = false;

  previousDailySpent;
  previousNewReviewDate = "";
  isCampaignFormDeleted;
  CampaignDetails: Boolean = true;
  disableSubmit: Boolean = false;
  _id = "";
  loader;
  fileToUpload: File = null;
  fileToUpload1 = null;

  submitted = false;
  contactForms: any;
  selectedFormId: string | null = null;
  today: string;
  constructor(
    private router: Router,
    private api: MarketingService,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getContactForms();

    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.id;
      this.loadData(this.activatedRoute.snapshot.params.id);
    }

    const today = new Date();
    this.today = today.toISOString().split("T")[0];
  }
  onFormSelect(formId: string) {
    this.selectedFormId = formId;
    console.log("Selected Form ID:", this.selectedFormId);
  }

  getContactForms() {
    console.log("inside get contact form api");

    const requestData = []; // You can pass any data if required by the API

    this.api.getContactForms(requestData).subscribe(
      (response) => {
        console.log(response, "response>>>>>");
        this.contactForms = (
          response as { data: { _id: string; formNo: number }[] }
        ).data;
        console.log(this.contactForms, "=====>>>>>>?");
      },
      (error) => {
        console.log("Error fetching contact forms:", error);
      }
    );
  }

  // handleFileInput(files: FileList) {
  //   this.loader = true;
  //   this.isLoading = true;

  //   this.fileToUpload = files.item(0);
  //   console.log("image uploaded", this.fileToUpload);
  //   if (this.fileToUpload) {
  //     this.isLoading = true;

  //     this.commonService.fileUpload(this.fileToUpload).subscribe(
  //       (result: any) => {
  //         this.image = result.data; // Save the image URL
  //         this.fileToUpload1 = true; // Trigger a state change for image preview
  //         this.loader = false;
  //         this.isLoading = false;
  //       },
  //       (error) => {
  //         console.log("File upload error", error);
  //         this.isLoading = false;
  //       }
  //     );
  //   }
  // }

  handleFileInput(files: FileList) {
    this.loader = true;
    this.isLoading = true; // Activating loader

    this.fileToUpload = files.item(0);
    console.log("Image uploaded:", this.fileToUpload);

    if (this.fileToUpload) {
      this.isLoading = true;

      // Upload the file
      this.commonService.fileUpload(this.fileToUpload).subscribe(
        (result: any) => {
          this.image = result.data; // Save the image URL
          this.fileToUpload1 = true; // Trigger a state change for image preview
          this.loader = false; // Deactivate loader
          this.isLoading = false; // Deactivate loading state
          this.commonService.toast("Image uploaded successfully", "Success");
        },
        (error) => {
          console.log("File upload error", error);
          this.isLoading = false; // Deactivate loading on error
        }
      );
    }
  }

  loadData(uId) {
    console.log("inside loadData user", uId);

    this.api.getSingleTeamAndLocation(uId).subscribe(
      (result: any) => {
        const items = result.data;
        console.log("Data loaded:", items);

        this.image = items.image;

        this.isCampaignFormDeleted = items.isCampaignFormDeleted;
        console.log("isCampaignFormDeleted", this.isCampaignFormDeleted);

        // Patch the form with the loaded data
        this.editUserData(items);
      },
      (error: any) => {
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
  editUserData(data: any) {
    console.log("Editing user data:", data);

    const formattedNextReview = data.nextReview
      ? new Date(data.nextReview).toISOString().split("T")[0]
      : "";

    // Patch the values into the formGroup to populate the form fields
    this.formGroup.patchValue({
      teamAndLocation: data.teamAndLocation, // Ensure formName is patched with existing data or default to empty string
      dailySpent: data.dailySpent, // Fill formNo with existing data or default to empty string
      nextReview: formattedNextReview, // Fill campaign form URL with existing data or default to empty string
      status: data.status,
      image: this.image,
      campaignFormId: data.campainFormActualNo || "",
    });
    const targetArray = data.target || []; // Default to an empty array if target doesn't exist

    // Create an array of FormGroups for each target item
    const targetFormGroups = targetArray.map((item) =>
      this._fb.group({
        key: item.key || "", // Ensure 'key' is populated, default to empty string if missing
        value: item.value || "", // Ensure 'value' is populated, default to empty string if missing
      })
    );

    // Set the target FormArray with the mapped FormGroups
    this.formGroup.setControl("target", this._fb.array(targetFormGroups));

    this.previousDailySpent = data.dailySpent;
    this.previousNewReviewDate = formattedNextReview;
    console.log(
      " this.previousDailySpent=========",
      this.previousDailySpent,
      this.previousNewReviewDate
    );
  }
  createForm() {
    this.formGroup = new FormGroup({
      teamAndLocation: new FormControl(""),
      campaignFormId: new FormControl(""),
      status: new FormControl(""),
      dailySpent: new FormControl(),
      image: new FormControl(""),
      target: this._fb.array([
        this._fb.group({
          key: ["one_week"],
          value: [""],
        }),
      ]),
      nextReview: new FormControl(""),
      CampaignDetails: new FormArray([
        new FormGroup({
          CampaignName: new FormControl(""),
          CampaignDetail: new FormControl(""),
          monthlyBudget: new FormControl(""),
        }),
      ]),
      description: new FormControl(""),
    });
  }

  // Get the FormArray for CampaignDetails
  get campaignDetails(): FormArray {
    return this.formGroup.get("CampaignDetails") as FormArray;
  }

  // addCampaign() {
  //   console.log("inside add-campaign----------------------");

  //   const control = <FormArray>this.formGroup.controls["CampaignDetails"];
  //   control.push(
  //     new FormGroup({
  //       CampaignName: new FormControl(""),
  //       CampaignDetail: new FormControl(""),
  //       monthlyBudget: new FormControl(""),
  //     })
  //   );
  // }

  addCampaign() {
    const control = this.campaignDetails;
    control.push(
      this._fb.group({
        CampaignName: new FormControl(""),
        CampaignDetail: new FormControl(""),
        monthlyBudget: new FormControl(""),
      })
    );
  }

  removeCampaign(index: number) {
    console.log("inside remove-campaign----------------------");
    const control = <FormArray>this.formGroup.controls["CampaignDetails"];
    control.removeAt(index);
  }

  // removeCampaign(index: number) {
  //   const control = this.campaignDetails;
  //   if (control.length > 1) {
  //     control.removeAt(index); // Remove the FormGroup at the specified index
  //   }
  // }

  // updateUser() {
  //   console.log("inside update user");
  //   if (this.formGroup.valid) {
  //     this.isLoading = true;
  //     const formValue = this.formGroup.value;

  //     const dailySpentChanged =
  //       this.previousDailySpent !== formValue.dailySpent;
  //     this.previousNewReviewDate !== formValue.nextReview;

  //     if (dailySpentChanged) {
  //       this.dailySpentFlag = true;
  //     }
  //     const requestData = {
  //       teamAndLocation: formValue.teamAndLocation,
  //       image: this.image,

  //       status: formValue.status,
  //       dailySpent: formValue.dailySpent,
  //       target: formValue.target.map((target: any) => ({
  //         key: "one_week", // Always set to 'one_week'
  //         value: target.value || "", // Capture the form input value, default to '' if empty
  //       })),
  //       nextReview: formValue.nextReview,
  //     };
  //     // Make API call for updating the data
  //     this.api.editAd(requestData, this._id).subscribe(
  //       (result: any) => {
  //         this.isLoading = false;
  //         this.commonService.toast(result.message, "Success");
  //         // this.router.navigate(["Marketing/ad-control-list"]);
  //       },
  //       (error: any) => {
  //         this.isLoading = false;
  //         this.commonService.toast("Something went wrong", "Error");
  //       }
  //     );
  //     this.previousDailySpent = formValue.dailySpent;
  //   } else {
  //     this.isLoading = false;
  //     this.commonService.toast("Please fill all fields", "Error");
  //   }
  // }

  updateUser() {
    console.log("inside update user");

    if (this.formGroup.valid) {
      this.isLoading = true;
      const formValue = this.formGroup.value;

      // Check if dailySpent or nextReview value has changed
      const dailySpentChanged =
        this.previousDailySpent !== formValue.dailySpent;
      const nextReviewDateChanged =
        this.previousNewReviewDate !== formValue.nextReview;

      console.log(this.previousNewReviewDate, formValue.nextReview, "**");
      // console.log(this.previousNewReviewDate.getDate())

      // Prepare the requestData object
      const requestData: any = {
        teamAndLocation: formValue.teamAndLocation,
        image: this.image,
        status: formValue.status,
        dailySpent: formValue.dailySpent,
        target: formValue.target.map((target: any) => ({
          key: "one_week", // Always set to 'one_week'
          value: target.value || "", // Capture the form input value, default to '' if empty
        })),
        nextReview: formValue.nextReview,
      };

      // Add dailySpentFlag and isNextReviewDateModified based on the changes
      requestData.dailySpentFlag = dailySpentChanged ? true : false;
      requestData.isNextReviewDateModified = nextReviewDateChanged
        ? true
        : false;

      // Make API call for updating the data
      this.api.editAd(requestData, this._id).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.commonService.toast(result.message, "Success");
          this.router.navigate(["Marketing/ad-control-list"]);
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast("Something went wrong", "Error");
        }
      );

      // Update previous values after the request is made
      this.previousDailySpent = formValue.dailySpent;
      this.previousNewReviewDate = formValue.nextReview;
    } else {
      this.isLoading = false;
      this.commonService.toast("Please fill all fields", "Error");
    }
  }

  validateNumberInput(event: any) {
    const inputValue = event.target.value;

    // Check if the input is a negative number or contains non-numeric characters
    if (inputValue && (parseFloat(inputValue) < 0 || isNaN(inputValue))) {
      event.target.value = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    }
  }
  // handleSubmit() {
  //   if (this.formGroup.valid) {
  //     this.loader = true;
  //     this.isLoading = true;

  //     const formValue = this.formGroup.value;
  //     console.log(formValue, ">>");

  //     // Here, make sure target values are being captured correctly
  //     const requestData = {
  //       teamAndLocation: formValue.teamAndLocation,
  //       image: this.image,
  //       status: formValue.status,
  //       dailySpent: formValue.dailySpent,
  //       target: formValue.target.map((target: any) => ({
  //         key: "one_week", // Always set to 'one_week'
  //         value: target.value || "", // Capture the form input value, default to '' if empty
  //       })),
  //       nextReview: formValue.nextReview,
  //       CampaignDetails: formValue.CampaignDetails.map((campaign) => ({
  //         CampaignName: campaign.CampaignName,
  //         CampaignDetail: campaign.CampaignDetail,
  //         monthlyBudget: campaign.monthlyBudget,
  //       })),
  //       campaignFormId: this.selectedFormId,
  //       campainFormActualNo: this.contactForms.find(
  //         (form) => form._id === this.selectedFormId
  //       ).formNo,
  //       description: formValue.description,
  //     };

  //     console.log(requestData, "<<<<<requestData");

  //     this.api.addAd(requestData).subscribe(
  //       (result: any) => {
  //         console.log("success in add ad ", result);
  //         this.isLoading = false;
  //         this.commonService.toast(result.message, "Success");
  //         this.loader = false;
  //         this.router.navigate(["Marketing/ad-control-list"]);
  //         this.getContactForms();
  //       },
  //       (error: any) => {
  //         console.log("error in add ad ");
  //         this.isLoading = false;
  //         this.loader = false;
  //         this.commonService.toast("Something went wrong", "Error");
  //       }
  //     );
  //   } else {
  //     console.log("Form is invalid");
  //     this.isLoading = false;
  //     this.commonService.toast("Please fill all fields", "Error");
  //   }
  // }

  handleSubmit() {
    if (this.formGroup.valid) {
      this.isLoading = true;
      const formValue = this.formGroup.value;
      const requestData = {
        teamAndLocation: formValue.teamAndLocation,
        image: this.image,
        status: formValue.status,
        dailySpent: formValue.dailySpent,
        target: formValue.target.map((target: any) => ({
          key: "one_week",
          value: target.value || "",
        })),
        nextReview: formValue.nextReview,
        CampaignDetails: formValue.CampaignDetails.map((campaign) => ({
          CampaignName: campaign.CampaignName,
          CampaignDetail: campaign.CampaignDetail,
          monthlyBudget: campaign.monthlyBudget,
        })),
        campaignFormId: this.formGroup.value.campaignFormId,
        description: formValue.description,
      };

      this.api.addAd(requestData).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.commonService.toast(result.message, "Success");
          this.router.navigate(["Marketing/ad-control-list"]);
        },
        (error: any) => {
          this.isLoading = false;
          this.commonService.toast("Something went wrong", "Error");
        }
      );
    } else {
      this.isLoading = false;
      this.commonService.toast("Please fill all fields", "Error");
    }
  }
}
