import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserListService } from '../services/user-list.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.sass']
})
export class UserAddComponent implements OnInit {
  loader;
  fileToUpload: File = null;
  addUserForm: FormGroup;
  submitted = false;
  editId = true;
  _id = '';
  fileToUpload1 = null;

  userData = {
    name: '',
    email: '',
    role: '',
    phone: '',
    image: '',
    hidfile: ''
  };
  constructor(
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private userListService: UserListService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.addUserForm = new FormGroup({
      name: new FormControl(this.userData.name, [
        Validators.required, Validators.pattern('^[a-zA-Z ]*$')
      ]),
      email: new FormControl(this.userData.email, [
        Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]),
      role: new FormControl(this.userData.role, [
        Validators.required, Validators.pattern('^[a-zA-Z ]*$')
      ]),
      phone: new FormControl(this.userData.phone, [
        Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')
      ]),
      image: new FormControl(this.userData.image),
      hidfile: new FormControl(this.userData.hidfile),
      superAdmin : new FormControl(false)
    });
    if (this.activatedRoute.snapshot.params.id !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.id;
      this.loadData(this.activatedRoute.snapshot.params.id);
    }
  }

  loadData(uId) {
    this.loader = true;
    this.userListService.getUserById(uId).subscribe((result: any) => {
      const items = result.data;
      this.editUserData(items);
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

  editUserData(data) {
    console.log(data);
    this.fileToUpload1 = true;

    this.userData.image = data.file;
    if (data.file === '' || data.file === undefined) {
      this.fileToUpload1 = null;
    }
    this.addUserForm.patchValue({
      name: data.name,
      email: data.email,
      role: data.role,
      phone: data.phoneNumber,
      hidfile: data.file,
      superAdmin : data && data.superAdmin ? data.superAdmin : false
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    // this.showLoader = true;
    if (files.length === 1) {
    }
    this.commonService.fileUpload(this.fileToUpload).subscribe((result: any) => {
      this.userData.image = result.data;
      this.fileToUpload1 = true;
      this.addUserForm.patchValue({
        hidfile: result.data
      });
    });
  }

  get f() {
    return this.addUserForm.controls;
  }

  addUser() {
    this.submitted = true;
    const data = this.addUserForm.controls;
    if (this.addUserForm.invalid) {

      // this.userListService.updateUser({
      //   name: this.addUserForm.controls.name.value,
      // }).subscribe((response: ChangePasswordResponse) => {
      //   this.loader = false;
      //   if (response.success === true) {
      //     this.commonService.toast(`Password changed successfully.`, 'Success');
      //   } else {
      //     if (response.data.old_password_matched === false) {
      //       this.commonService.toast('The password you entered is incorrect', 'Error');
      //     } else {
      //       this.commonService.toast(response.message.toString(), 'Error');
      //     }
      //   }

      // }, error => {
      //   this.loader = false;
      //   this.commonService.toast(error.error.message, 'Error');
      // });
      // } else {
      //   this.loader = false;
      // }
      console.log('User form is not valid!!');
    } else {
      this.loader = true;

      const obj = {
        name: data.name.value,
        lastName: 'test',
        email: data.email.value,
        password: 'test@123',
        userType: '1',
        status: '1',
        file: this.addUserForm.controls.hidfile.value,
        role: data.role.value,
        phoneNumber: data.phone.value,
        superAdmin : data && data.superAdmin && data.superAdmin.value ? data.superAdmin.value : false,
      };
      this.userListService.createUser(obj).subscribe((res) => {
        this.router.navigate(['/users/list']);
        this.commonService.toast('User added Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }

  updateUser() {
    this.submitted = true;
    const data = this.addUserForm.controls;
    if (this.addUserForm.invalid) {
      console.log('User form is not valid!!');
    } else {
      this.loader = true;
      const obj = {
        name: data.name.value,
        lastName: 'test',
        userType: '1',
        status: '1',
        file: this.addUserForm.controls.hidfile.value,
        role: data.role.value,
        phoneNumber: data.phone.value,
        superAdmin : data && data.superAdmin && data.superAdmin.value ? data.superAdmin.value : false,
        _id: this._id
      };
      this.userListService.updateUserDetails(obj).subscribe((res) => {
        this.router.navigate(['/users/list']);
        this.commonService.toast('User updated successfully.', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });

    }
  }
}
