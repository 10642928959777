import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../template.service';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.sass']
})
export class TemplateListComponent implements OnInit {
  items;
  totalRecords = 0;
  loader;
  nodata = false;
  query: any = {
    page: 1,
    limit: environment.defaultPageSize,
    userType: 1
  };
  recordsPerPage: number;
  p = 1;
  constructor(
    private templateService: TemplateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) { 
    this.recordsPerPage = environment.defaultPageSize;
  }

  ngOnInit() {
    this.loader = true;
    this.getTemplateList();
  }

  getTemplateList() {
    this.templateService.getTemplate(this.query).subscribe((result: any) => {
      const items = result.data.data.filter(data=> data.active == true);;
      this.items = items;
      this.totalRecords = result.data.totalCount;
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.nodata = true;
      //      this.commonService.toast(error.error.message, 'Error');
    });
  }

  pageChanged(event: any): void {
    this.p = event.page;
    this.query.page = this.p;
    this.getTemplateList();
  }

  public getRoute(url, id) {
    const myurl = `${url}/${id}`;
    this.router.navigateByUrl(myurl).then(e => {
      if (e) {
        // console.log("Navigation is successful!");
      } else {
        console.log('Navigation has failed!');
      }
    });
  }

  navigateAddTemplate() {
    this.router.navigate(['/template/add']);
  }


  navigateViewTemplate(tempId) {
    this.router.navigate(['/template/view/' + tempId]);
  }
  changeArch(_id, event) {
    const status = event.target.checked;

    this.templateService.updateArch({ _id, archStatus: status }).subscribe((result: any) => {
      this.commonService.toast(`Arch status updated successfully.`, 'Success');
      this.getTemplateList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  changeHighlighter(_id, event) {
    const status = event.target.checked;
    this.templateService.updateHighlighter({ _id, highlighterStatus: status }).subscribe((result: any) => {
      this.commonService.toast(`Highlighter status updated successfully.`, 'Success');
      this.getTemplateList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }
  changeIsMain(id, event) {
    ;
    this.templateService.updateTempled(id, {isMain: event.target.checked}).subscribe((result: any) => {
      this.commonService.toast(`Template status updated successfully.`, 'Success');
      this.getTemplateList();
    }, (error) => {
      this.commonService.toast(error.error.message, 'Error');
    });
  }

}
