import { Injectable } from "@angular/core";
import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import {
  AddInventoryDocParams,
  AddInventoryNoteParams,
  DeleteInventoryDocParams,
  GetInternalOrderListParams,
  GetInventoryListParams,
  Inventory,
} from "./Inventory";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getCategories() {
    return this.restRequest.get("inventory/category");
  }

  //-----------------------------------------------> Inventory
  getInventoryList(data: GetInventoryListParams) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(
        `inventory/items/?route=${data.route}&page=${data.page}${searchQuery}`
      );
  }

  getInventories(data: { search: string; categoryId: string }) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(`inventory/items-all/?categoryId=${data.categoryId}${searchQuery}`);
  }

  addNewInventory(data: Inventory) {
    return this.restRequest.addAuthorization().post(`inventory/item`, data);
  }

  updateInventory(id: string, data: Inventory) {
    return this.restRequest
      .addAuthorization()
      .put(`inventory/item/${id}`, data);
  }

  deleteInventoryItem(id: string) {
    return this.restRequest.addAuthorization().delete(`inventory/item/${id}`);
  }

  //-----------------------------------------------> Internal Order
  getInternalOrders(data: GetInternalOrderListParams) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(
        `io/inventories/?status=${data.status}&page=${data.page}${searchQuery}`
      );
  }

  addInternalOrder(data: Inventory) {
    return this.restRequest.addAuthorization().post(`io/inventory`, data);
  }

  updateOrderStatus(id: string, data: { status: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`io/inventory-status/${id}`, data);
  }

  deleteInternalOrder(id: string) {
    return this.restRequest.addAuthorization().delete(`io/inventory/${id}`);
  }

  //-----------------------------------------------> Purchase Order
  getPurchaseOrders(data: GetInternalOrderListParams) {
    const searchQuery = data.search ? `&search=${data.search}` : "";

    return this.restRequest
      .addAuthorization()
      .get(
        `po/inventories/?status=${data.status}&page=${data.page}${searchQuery}`
      );
  }

  addPurchaseOrder(data: Inventory) {
    return this.restRequest.addAuthorization().post(`po/inventory`, data);
  }

  updatePurchaseOrderStatus(id: string, data: { status: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`po/inventory-status/${id}`, data);
  }

  deletePurchaseOrder(id: string) {
    return this.restRequest.addAuthorization().delete(`po/inventory/${id}`);
  }

  //-----------------------------------------------> notes
  getInventoryNotes(referenceId: string) {
    return this.restRequest
      .addAuthorization()
      .get(`inventory/notes/?referenceId=${referenceId}`);
  }

  addInventoryNote(data: AddInventoryNoteParams) {
    return this.restRequest.addAuthorization().post(`inventory/note`, data);
  }

  updateInventoryNotes(noteId: string, data: { notes: string }) {
    return this.restRequest
      .addAuthorization()
      .put(`inventory/note/${noteId}`, data);
  }

  deleteNote(noteId: string) {
    return this.restRequest
      .addAuthorization()
      .delete(`inventory/note/${noteId}`);
  }

  //-----------------------------------------------> docs
  uploadInventoryDocs(data: any, type = "po") {
    const route = {
      po: "inventory-doc",
      employee: "doc",
      "company-overview" : 'doc',
      "sales-performance":'doc'
    };

    return this.restRequest
      .addAuthorization()
      .post(`${type}/${route[type]}`, data);
  }

  deleteInventoryDoc(data: DeleteInventoryDocParams) {
    return this.restRequest
      .addAuthorization()
      .put(`po/inventory-doc-delete`, data);
  }

  //-------------------------------------------------> Links

  getLinks (link:any,id:any) {
    return this.restRequest
    .addAuthorization()
    .get(`/${link}/${id}`)
  }
  addLinks (link:any,data:any) {
    return this.restRequest
    .addAuthorization()
    .post(`/${link}/link`,data)
  }

  editLinks (link:any,data:any) {
    return this.restRequest
    .addAuthorization()
    .put(`/${link}/link`,data)
  }

  deleteLinks (link:any,id:any,data:any) {
    return this.restRequest
    .addAuthorization()
    .put(`/${link}/link-delete/${id}`,data); 
  }
 
}
