import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { BookingListComponent } from './booking-list/booking-list.component';
//import { NgxPaginationModule } from 'ngx-pagination';
//import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// import {}
//import { BookingListInprogressComponent } from './booking-list-inprogress/booking-list-inprogress.component';
//import { BookingListClosedComponent } from './booking-list-closed/booking-list-closed.component';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
//import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
//import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
//import { MatBadgeModule } from '@angular/material/badge';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { SignaturePadSharedModule } from 'src/app/shared/signaturepad-shared/signaturepad-shared.module';
import { TermsAndConditionComponent } from './terms-and-condition.component';

@NgModule({
  declarations: [ 
    TermsAndConditionComponent
],
  imports: [
    CommonModule,
    SignaturePadModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule.forRoot(),
    // SignaturePadSharedModule,
    //BrowserAnimationsModule,
    //NgxPaginationModule,
    //Ng2SearchPipeModule,
    //NgxExtendedPdfViewerModule,
    FormsModule,
    PipesModule,
    //PdfViewerModule,
    //AngularFileViewerModule
    //MatBadgeModule,
  ],
  exports:[
    TermsAndConditionComponent
],
  providers:[],
  entryComponents : [
    TermsAndConditionComponent
  ]
})
export class TermsAndConditionModule { }
