import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserListService } from '../services/user-list.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent implements OnInit {

  userId = null;
  userAddress = [];
  userData = {
    fname: '',
    email: '',
    registered_name: '',
    gender: '',
    ABN: '',
    business_address: {
      address: '',
      city: '',
      state: '',
      zip_code: ''
    },
    image: '../assets/img/theme/team-4-800x800.jpg',
    totalEmployee: '',
    totalProject: ''
  };
  userarray = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private userListService: UserListService,
    private router: Router
  ) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.getUserDetails();
  }

  getUserDetails() {
    this.userListService.getUser(this.userId).subscribe((result: any) => {
      this.userData = Object.assign(this.userData, result.data);
    });

  }
  viewEmployeeDetails() {
    this.router.navigate(['users/' + this.userId + '/employee']);
  }
  viewProjectDetails(userId) {
    this.router.navigate(['users/' + this.userId + '/projects']);
  }
}
