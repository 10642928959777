import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.sass']
})
export class FeedbackModalComponent implements OnInit {
  data : any;
  checkboxList = [
    {name : 'Price is Too high',value : 'highPrice', selected : false},
    {name : 'Engaged another company', value : 'otherCompany', selected : false},
    {name : 'Time is not right for me',value : 'noTime', selected : false},
    {name : 'I need more time',value : 'moreTime', selected : false}
  ];
  selectedList: any;
  constructor() { }

  ngOnInit() {
    console.log('initial state ==>>',this.data);
    let selected : any = [];
    this.data.feedback.reason.forEach(element => {
      this.checkboxList.forEach(item=>{
        if(element == item.value){
          selected.push(item);
        }
      })
    });
    this.selectedList = selected;
    console.log(selected);
  }

}
