import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PlumbingService } from '../plumbing.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../services/common.service';


@Component({
  selector: 'app-add-plumbing',
  templateUrl: './add-plumbing.component.html',
  styleUrls: ['./add-plumbing.component.sass']
})
export class AddPlumbingComponent implements OnInit {
  addPlumbingForm: FormGroup;
  submitted;
  loader;
  dropVal = null;
  plumbing: '';
  listData: any;
  editId = true;
  _id = '';

  constructor(
    private router: Router,
    private plumbingService: PlumbingService,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.addPlumbingForm = new FormGroup({
      plumbing: new FormControl(this.plumbing, [
        Validators.required
      ]),
    });

    if (this.activatedRoute.snapshot.params.plumbingId !== undefined) {
      this.editId = false;
      this._id = this.activatedRoute.snapshot.params.plumbingId;
      this.loadData(this.activatedRoute.snapshot.params.plumbingId);
    }
  }
  get f() {
    return this.addPlumbingForm.controls;
  }

  loadData(plumbingId) {
    this.loader = true;
    this.plumbingService.getPlumbingById(plumbingId).subscribe((result: any) => {
      const items = result.data;
      this.editplumbingData(items);
      this.loader = false;
    }, (error: any) => {
      this.loader = false;
      this.commonService.toast(error.error.message, 'Error');
    });
  }

  editplumbingData(data) {
    this.addPlumbingForm.patchValue({
      plumbing: data.plumbText,
    });
  }

  addPlumbing() {
    this.submitted = true;
    const data = this.addPlumbingForm.controls;
    if (this.addPlumbingForm.invalid) {
      console.log('Invalid form');
    } else {
      const obj = {
        plumbText: data.plumbing.value,
      };
      this.loader = true;
      this.plumbingService.createPlumbing(obj).subscribe((res) => {
        this.router.navigate(['/plumbing/list']);
        this.commonService.toast('Plumbing requirement added Successfully', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }

  updatePlumbing() {
    this.submitted = true;
    const data = this.addPlumbingForm.controls;
    if (this.addPlumbingForm.invalid) {
      console.log('Invalid form');
    } else {
      const obj = {
        plumbText: data.plumbing.value,
        _id: this._id
      };
      this.loader = true;
      this.plumbingService.updatePlumbingData(obj).subscribe((res) => {
        this.router.navigate(['/plumbing/list']);
        this.commonService.toast(' Updated successfully ', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });
    }
  }

}


