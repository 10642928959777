import { Injectable } from '@angular/core';
import { RestRequest } from '../../../services/rest-request';
import {
    SigninRequest, SignoutRequest,
    SignupRequest, VerifyOtpRequest,
    SetupProfileRequest, ForgetPasswordRequest,
    VerifyPasswordOtpRequest, ResetPasswordRequest
} from './../interfaces/auth';
// import * as jwt_decode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

    constructor(private restRequest: RestRequest, private jwtHelperService: JwtHelperService) { }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('currentSession');
        if (token) {
            return true;
        }
        return false;
        // const signUpInfo = localStorage.getItem('signUpInfo');
        // return !this.jwtHelperService.isTokenExpired(token) && !(signUpInfo !== '' && signUpInfo !== null && typeof signUpInfo !== 'undefined');
    }

    public isProfileSetup(): boolean {
        const token = localStorage.getItem('currentSession');
        const signUpInfo = localStorage.getItem('signUpInfo');
        return !this.jwtHelperService.isTokenExpired(token) && (signUpInfo !== '' && signUpInfo !== null && typeof signUpInfo !== 'undefined');
    }

    signUp(data: SignupRequest) {
        return this.restRequest.post('signup', data);
    }

    verifyOtp(data: VerifyOtpRequest) {
        return this.restRequest.post('reset/password', data);
    }

    signIn(data: SigninRequest) {
        return this.restRequest.post('users/login_admin', data);
    }

    signOut(data: SignoutRequest) {
        return this.restRequest.addAuthorization().put('signOut', data);
    }

    sendForgetPasswordDetails(data: ForgetPasswordRequest) {
        return this.restRequest.post('users/forgotPassword', data);
    }

    verifyPasswordOtp(data: VerifyPasswordOtpRequest) {
        return this.restRequest.put('verifyPasswordOtp', data);
    }

    resetPassword(data: ResetPasswordRequest) {
        return this.restRequest.post('users/parsswordReset', data);
    }

    profileSetup(data: SetupProfileRequest) {
        return this.restRequest.addAuthorization().put('updateSellerProfile', data);
    }


}
