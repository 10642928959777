import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitInLinesPipe } from './pipes/split-in-lines.pipe';
import { StopPropagateDirective } from './directives/stop-propagate.directive';
import { TaskTypePipe } from './pipes/task-type.pipe';
import { IsActivePipe } from './pipes/is-active.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RatingComponent } from './components/rating/rating.component';
import { DriverTypePipe } from './pipes/driver-type.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImageViewerModule } from 'ngx-image-viewer';
import { SorterComponent } from './components/sorter/sorter.component';

@NgModule({
  declarations: [SplitInLinesPipe, StopPropagateDirective, TaskTypePipe, IsActivePipe,
    PaginationComponent, RatingComponent, DriverTypePipe, ImageViewerComponent, SorterComponent],
  exports: [SplitInLinesPipe, TaskTypePipe, IsActivePipe, PaginationComponent, RatingComponent, DriverTypePipe, ImageViewerComponent, SorterComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ImageViewerModule.forRoot()
  ]
})
export class UtilityModule { }
