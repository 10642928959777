import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRepairComponent } from './add-repair/add-repair.component';
import { RepairListComponent } from './repair-list/repair-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { PaginationModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [AddRepairComponent, RepairListComponent],
  imports: [
    PaginationModule.forRoot(),
    CommonModule, ReactiveFormsModule, NgxPaginationModule, Ng2SearchPipeModule, FormsModule, PipesModule,
  ]
})
export class RepairModule { }
