import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import {QualityService} from '../quality.service'
@Component({
  selector: 'app-qnotesmodal',
  templateUrl: './qnotesmodal.component.html',
  styleUrls: ['./qnotesmodal.component.sass']
})
export class QnotesmodalComponent implements OnInit {
  userid = null;
  notesForm: FormGroup;
  items;
  loader;
  constructor(private bsModalRef: BsModalRef,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private quality: QualityService) { }
    ngOnInit() {
      this.notesForm = this.formBuilder.group({
        noteMain: this.formBuilder.array([this.addNote()]),
      });
  
      this.getbookinglist(this.userid);
    }
  
    getbookinglist(obj) {
      this.quality.getqualitydatabyid(obj).subscribe((result: any) => {
        const items = result.data;
        this.items = items;
        this.loader = false;
        this.editData(this.items)
      }, (error: any) => {
        this.loader = false;
  
        // this.commonService.toast(error.error.message, 'Error');
      });
    }
    updatenotes() {
      if (this.notesForm.valid) {
        const formData = { ...this.notesForm.value };
        const obj = {
          notes: formData.noteMain
        };
        this.quality.updatenotesbyid(this.userid, obj).subscribe((result: any) => {
  
          this.loader = false;
          this.commonService.toast('Notes saved successfully', 'Success');
          //this.decline();
        }, (error: any) => {
          this.loader = false;
  
          // this.commonService.toast(error.error.message, 'Error');
        });
      }
    }
    editData(data: any) {
      this.notesForm.setControl('noteMain', this.setNoteFun(data.notes));
    }
    userNoteAdd() {
      this.noteArr.push(this.addNote());
    }
    removeNote(index) {
      if ((this.noteArr).length === 1) {
        // this.commonService.toast('Atleast one  Note is required.', 'Error');
        this.noteArr.removeAt(index);
      } else {
        this.noteArr.removeAt(index);
      }
    }
    get noteArr() {
      return this.notesForm.get('noteMain') as FormArray;
    }
    addNote() {
      return this.formBuilder.group({
        general: [null, Validators.required],
      });
    }
    decline(): void {
      this.bsModalRef.hide();
    }
    setNoteFun(genData: any): FormArray {
      const formArray = new FormArray([]);
      genData.forEach(s => {
        formArray.controls.push(this.formBuilder.group({
          general: s.general
        }));
      });
      return formArray;
    }
  
  }
  