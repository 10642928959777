import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateListComponent } from './template-list/template-list.component';
import { AddTemplateComponent } from './add-template/add-template.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewTemplateComponent } from './view-template/view-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { PaginationModule } from 'ngx-bootstrap';



@NgModule({
  declarations: [TemplateListComponent, AddTemplateComponent, ViewTemplateComponent, EditTemplateComponent],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class TemplatesModule { }
