import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { CommonService } from "src/app/services/common.service";
import { UserService } from "./../../services/user.service";
import { Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { OtpService } from "./otp-sent-modal.service";

@Component({
  selector: "app-otp-sent-modal",
  templateUrl: "./otp-sent-modal.component.html",
  styleUrls: ["./otp-sent-modal.component.sass"],
})
export class OtpSentModalComponent implements OnInit {
  message: string;
  otp: any;
  usersData: any;
  generateOtpResponse: any;
  isOtpSent: Boolean;
  otpModal;
  verified: any;

  constructor(
    public modalRef: BsModalRef,
    private commonService: CommonService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private otpService: OtpService
  ) {}

  ngOnInit(): void {
    console.log("OTP Sent Modal initialized");
    this.generateOtpOnInit();

    // this.otpModal = this.formBuilder.group({
    //   otp: [
    //     "",
    //     [
    //       Validators.required,
    //       Validators.pattern("^[0-9]{4}$"), // Ensure OTP is exactly 4 digits
    //     ],
    //   ],
    // });
    this.otpModal = this.formBuilder.group({
      otp: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{4}$"), // Ensure OTP is exactly 4 digits
        ],
      ],
    });
  }
  cancelModal() {
    this.modalRef.hide();
  }
  cancelOTPModal() {
    this.router.navigate(["/users/list"]);
    this.modalRef.hide();
  }
  generateOtpOnInit() {
    this.cancelModal();

    console.log("getting generate otp api called ");
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("currentSession");
    console.log(token);
    if (userId) {
      const payload = { _id: JSON.parse(userId) };

      this.userService.generateOtp(payload).subscribe(
        (response) => {
          // this.cancelModal();

          this.usersData = response;
          console.log(
            "response==================>>>>",
            response,
            this.usersData
          );
          if (this.usersData.success) {
            // this.cancelModal();
            this.isOtpSent = true;
            this.otp = this.usersData.data.otp;

            console.log(this.otp, "=");
            console.log(this.isOtpSent, ">>");

            this.commonService.toast(
              "OTP sent to your mail successfully.",
              "Success"
            );
          } else {
            this.isOtpSent = false;
            this.commonService.toast(this.usersData.message, "Error");
          }
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }

  proceedWithOtp() {
    console.log("inside proceedWithOtp", this.otp);

    const otpValue = this.otp;
    this.verifyOtp(otpValue);
  }

  verifyOtp(otp: string) {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const payload = { _id: JSON.parse(userId), otp };

      this.userService.verifyOtp(payload).subscribe(
        (response) => {
          this.verified = response;

          if (this.verified.success) {
            this.otpService.setOtpVerified(true);
            this.router.navigate(["/change-password"]);
            this.modalRef.hide();
          } else {
            this.commonService.toast("Invalid OTP", "Error");
          }
        },
        (error) => {
          this.commonService.toast(error.error.message, "Error");
        }
      );
    }
  }
}
// proceedWithOtp() {
//   console.log("inside proceedWithOtp", this.otp);
//   if (this.otp) {
//     console.log(this.otp);
//     this.verifyOtp(this.otp);
//   } else {
//     this.commonService.toast("Please enter a valid OTP", "Error");
//   }
// }
