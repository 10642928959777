import { Injectable } from "@angular/core";
// import { RestRequest } from "../../services/rest-request";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RestRequest } from "src/app/services/rest-request";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class MarketingService {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getoverview(
    startDate: any,
    endDate: any,
    previousStartDate: any,
    previusEndDate: any,
    filterField: any
  ) {
    return this.restRequest
      .addAuthorization()
      .get(
        `company-overview?startDate=${startDate}&endDate=${endDate}&previousStartDate=${previousStartDate}&previousEndDate=${previusEndDate}&target=${filterField}`
      );
  }

  editTarget(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`company-overview/target/${id}`, data);
  }

  addOperatingExpense(data: any, id: any) {
    return this.restRequest
      .addAuthorization()
      .put(`company-overview/operating-expense/${id}`, data);
  }

  addDoc(data: any) {
    return this.restRequest
      .addAuthorization()
      .put(`company-overview/doc`, data);
  }

  addNote(data: any) {
    return this.restRequest
      .addAuthorization()
      .post(`company-overview/note`, data);
  }

  // spent range api's
  getSpendRange(data: any) {
    return this.restRequest.post(`get-range`, {});
  }
  updateSpendRange(data: any, id: any) {
    console.log(data, id, "==>");
    return this.restRequest.put(`edit-range/${id}`, data);
  }

  // team location api's
  getSingleTeamAndLocation(id: any) {
    console.log("getSingleTeamAndLocation", id);
    return this.restRequest.post(`get-marketingTeam/${id}`, {});
  }
  deleteAd(id: any) {
    console.log("deleteAd", id);
    return this.restRequest.delete(`delete-marketingTeam/${id}`);
  }
  editAd(data: any, id: any) {
    console.log("editAd", data, id);
    return this.restRequest.put(`edit-marketingTeam/${id}`, data);
  }
  addAd(data: any) {
    console.log("addAd", data);
    return this.restRequest.post(`add-marketingTeam`, data);
  }
  getContactForms(data: any) {
    console.log("getContactForms", data);
    return this.restRequest.post(`get-campaignForMarketing`, data);
  }

  // contact  api's
  getSingleContactData(id: any) {
    console.log("getSingleTeamAndLocation", id);
    return this.restRequest.post(`get-campaignForm/${id}`, {});
  }
  getContactFormsData(data: any) {
    console.log("getSingleTeamAndLocation", data);
    return this.restRequest.get(`get-campaignForms`, data);
  }
  deleteContactData(id: any) {
    console.log("deleteAd", id);
    return this.restRequest.delete(`delete-campaignForm/${id}`);
  }
  editContactForm(obj: any, id: any) {
    console.log("editAd", obj, id);
    return this.restRequest.put(`edit-campaignForm/${id}`, obj);
  }
  addContactFormData(data: any) {
    console.log("addAd", data);
    return this.restRequest.post(`add-campaignForm`, data);
  }

  // dashboard
  getDashboardData(
    startDate: any,
    endDate: any,
    previousStartDate: any,
    previusEndDate: any,
    durationFilter: any,
    currentPageNo: any
  ) {
    return this.restRequest.get(
      `marketing-analysis?startDate=${startDate}&endDate=${endDate}&previousStartDate=${previousStartDate}&previousEndDate=${previusEndDate}&durationFilter=${durationFilter}&page=${currentPageNo}`,
      {}
    );
  }
  getContactFormDashboardData(
    startDate: any,
    endDate: any,
    previousStartDate: any,
    previusEndDate: any,
    durationFilter: any,
    currentPageNo: any
  ) {
    return this.restRequest.get(
      `contactForm-analysis?startDate=${startDate}&endDate=${endDate}&previousStartDate=${previousStartDate}&previousEndDate=${previusEndDate}&durationFilter=${durationFilter}&page=${currentPageNo}`,
      {}
    );
  }
}
