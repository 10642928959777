import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { VerifyOtpComponent } from "./modules/auth/components/verify-otp/verify-otp.component";
import { ProfileComponent } from "./modules/user/components/profile/profile.component";
import { ChangePasswordComponent } from "./modules/user/components/change-password/change-password.component";
import { LoginComponent } from "./modules/auth/components/login/login.component";
import { ForgetPasswordComponent } from "./modules/auth/components/forget-password/forget-password.component";
import { DashboardComponent } from "./modules/dashboard/components/dashboard/dashboard.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { UnauthLayoutComponent } from "./layouts/unauth-layout/unauth-layout.component";
import { ProfileLayoutComponent } from "./layouts/profile-layout/profile-layout.component";
import { AuthGuardService } from "./modules/auth/services/auth-guard.service";
import { UserListComponent } from "./modules/users/user-list/user-list.component";
import { UserViewComponent } from "./modules/users/user-view/user-view.component";
import { UserAddComponent } from "./modules/users/user-add/user-add.component";
import { UserEmployeeViewComponent } from "./modules/users/user-employee-view/user-employee-view.component";
import { UserProjectViewComponent } from "./modules/users/user-project-view/user-project-view.component";
import { EmployeeDetailsComponent } from "./modules/users/employee-details/employee-details.component";
import { ProjectDetailsComponent } from "./modules/users/project-details/project-details.component";
import { TemplateListComponent } from "./modules/templates/template-list/template-list.component";
import { AddCauseComponent } from "./modules/causes/add-cause/add-cause.component";
import { CauseListComponent } from "./modules/causes/cause-list/cause-list.component";
import { RepairListComponent } from "./modules/repair/repair-list/repair-list.component";
import { AddRepairComponent } from "./modules/repair/add-repair/add-repair.component";
import { AddTemplateComponent } from "./modules/templates/add-template/add-template.component";
import { AddLegendComponent } from "./modules/legends/add-legend/add-legend.component";
import { LegendListComponent } from "./modules/legends/legend-list/legend-list.component";
import { ViewTemplateComponent } from "./modules/templates/view-template/view-template.component";
import { JobHistoryComponent } from "./modules/jobs/job-history/job-history.component";
import { EditTemplateComponent } from "./modules/templates/edit-template/edit-template.component";
import { AddQuoteComponent } from "./modules/quotes/add-quote/add-quote.component";
import { QuoteListComponent } from "./modules/quotes/quote-list/quote-list.component";
import { ToolboxListComponent } from "./modules/toolboxes/toolbox-list/toolbox-list.component";
import { AddToolboxComponent } from "./modules/toolboxes/add-toolbox/add-toolbox.component";
import { PlumbingListComponent } from "./modules/plumbing/plumbing-list/plumbing-list.component";
import { AddPlumbingComponent } from "./modules/plumbing/add-plumbing/add-plumbing.component";
import { BookinglinkComponent } from "./modules/bookinglink/bookinglink/bookinglink.component";
import { WarrantylinkComponent } from "./modules/bookinglink/warrantylink/warrantylink.component";
import { ThankyouComponent } from "./modules/bookinglink/thankyou/thankyou.component";
import { BookingListComponent } from "./modules/bookinglink/booking-list/booking-list.component";
import { BookingListInprogressComponent } from "./modules/bookinglink/booking-list-inprogress/booking-list-inprogress.component";
import { BookingListClosedComponent } from "./modules/bookinglink/booking-list-closed/booking-list-closed.component";
import { InspectionComponent } from "./modules/inspection/inspection/inspection.component";
import { InspectionListComponent } from "./modules/inspection/inspection-list/inspection-list.component";
import { AnalyticsComponent } from "./modules/analytics/analytics/analytics.component";
import { DatarepresentationComponent } from "./modules/analytics/datarepresentation/datarepresentation.component";
import { LinebarrepresentationComponent } from "./modules/analytics/linebarrepresentation/linebarrepresentation.component";
import { InspectionListRejectedComponent } from "./modules/inspection/inspection-list-rejected/inspection-list-rejected.component";
import { InspectionListAcceptedComponent } from "./modules/inspection/inspection-list-accepted/inspection-list-accepted.component";
import { PdfviewComponent } from "./modules/bookinglink/pdfview/pdfview.component";
import { DiscountComponent } from "./modules/discount/discount/discount.component";
import { AdddiscountComponent } from "./modules/discount/adddiscount/adddiscount.component";
import { MultilineComponent } from "./modules/analytics/multiline/multiline.component";
import { InspectionListConfirmedComponent } from "./modules/inspection/inspection-list-confirmed/inspection-list-confirmed.component";
import { InspectionListPendingComponent } from "./modules/inspection/inspection-list-pending/inspection-list-pending.component";
import { EditinspectionComponent } from "./modules/inspection/editinspection/editinspection.component";
import { PdfpersectionComponent } from "./modules/pdfpersection/pdfpersection/pdfpersection.component";
import { AddPdfpersectionComponent } from "./modules/pdfpersection/add-pdfpersection/add-pdfpersection.component";
import { NewjobComponent } from "./modules/bookinglink/newjob/newjob.component";
import { JobonholdComponent } from "./modules/bookinglink/jobonhold/jobonhold.component";
import { JobcancelComponent } from "./modules/bookinglink/jobcancel/jobcancel.component";
import { PaidinfullComponent } from "./modules/bookinglink/paidinfull/paidinfull.component";
import { AwaitingpaymentComponent } from "./modules/bookinglink/awaitingpayment/awaitingpayment.component";
import { DisputeonholdComponent } from "./modules/bookinglink/disputeonhold/disputeonhold.component";
import { TobeinvoiceComponent } from "./modules/bookinglink/tobeinvoice/tobeinvoice.component";
import { InspectionListDumpComponent } from "./modules/inspection/inspection-list-dump/inspection-list-dump.component";
import { AllinspectionComponent } from "./modules/inspection/allinspection/allinspection.component";
import { QualityassurancecheckedComponent } from "./modules/qualityassurance/qualityassurancechecked/qualityassurancechecked.component";
import { QualityassuranceopenComponent } from "./modules/qualityassurance/qualityassuranceopen/qualityassuranceopen.component";
import { QualityassurancependingComponent } from "./modules/qualityassurance/qualityassurancepending/qualityassurancepending.component";
import { IpadTemplateListComponent } from "./modules/ipad-template/ipad-template-list/ipad-template-list.component";
import { RepairMethodListComponent } from "./modules/ipad-template/repair-method-list/repair-method-list.component";
import { RepairMethodAddComponent } from "./modules/ipad-template/repair-method-add/repair-method-add.component";
import { UserAuthService } from "./modules/auth/user-auth.service";
import { AdminAuthService } from "./modules/auth/admin-auth.service";
import { TermsAndServicesComponent } from "./modules/terms-and-services/terms-and-services.component";
import { SearchBookingComponent } from "./modules/bookinglink/search-booking/search-booking.component";
import { FirstReminderComponent } from "./modules/bookinglink/first-reminder/first-reminder.component";
import { ActionRequiredComponent } from "./modules/bookinglink/action-required/action-required.component";
import { SecondReminderComponent } from "./modules/bookinglink/second-reminder/second-reminder.component";
import { LegalActionComponent } from "./modules/bookinglink/legal-action/legal-action.component";
import { ToBeWrittenOffComponent } from "./modules/bookinglink/to-be-written-off/to-be-written-off.component";
import { WrittenOffComponent } from "./modules/bookinglink/written-off/written-off.component";
import { InventorylistComponent } from "./modules/inventory/inventorylist/inventorylist.component";
import { InternalOrderListComponent } from "./modules/inventory/internal-order-list/internal-order-list.component";
import { PurchaseOrderListComponent } from "./modules/inventory/purchase-order-list/purchase-order-list.component";
import { PurchaseOrderFormComponent } from "./modules/inventory/purchase-order-form/purchase-order-form.component";
import { InternalOrderFormComponent } from "./modules/inventory/internal-order-form/internal-order-form.component";
import { SupportTicketListComponent } from "./modules/support-ticket/support-ticket-list/support-ticket-list.component";
import { AddSupportTicketFormComponent } from "./modules/support-ticket/add-support-ticket-form/add-support-ticket-form.component";
import { EmployeeListComponent } from "./modules/employee/employee-list/employee-list.component";
import { AddEmployeeFormComponent } from "./modules/employee/add-employee-form/add-employee-form.component";
import { TimesheetListComponent } from "./modules/timesheet/timesheet-list/timesheet-list.component";
import { TimesheetFormComponent } from "./modules/timesheet/timesheet-form/timesheet-form.component";
import { CompanyOverviewComponent } from "./modules/company-overview/company-overview.component";
import { SalesPerformanceComponent } from "./modules/sales-performance/sales-performance.component";
import { PortalComponent } from "./modules/portal/portal.component";
import { AddPortalFormComponent } from "./modules/portal/add-portal/add-portal-form/add-portal-form.component";
import { QuotesStatusComponent } from "./modules/quotes-status/quotes-status.component";
import { AdministrativeProcedureComponent } from "./modules/document/administrative-procedure/administrative-procedure.component";
import { VehicelListComponent } from "./modules/vehicel-management/vehicel-list/vehicel-list.component";
import { VehicleAddPageComponent } from "./modules/vehicel-management/vehicle-add-page/vehicle-add-page.component";
import { AnnualLeaveComponent } from "./modules/human-resource/annual-leave/annual-leave.component";
import { IncidentComponent } from "./modules/human-resource/incident/incident.component";
import { InspectionQualifiedComponent } from "./modules/inspection/inspection-qualified/inspection-qualified.component";
import { SwmsComponent } from "./modules/human-resource/swms/swms.component";
import { AdControlComponent } from "./modules/marketing/ad-control/ad-control.component";
import { ContactFormComponent } from "./modules/marketing/contact-form/contact-form.component";
import { EnquiryAnalyticsComponent } from "./modules/marketing/enquiry-analytics/enquiry-analytics.component";
import { AdControlAddFormComponent } from "./modules/marketing/ad-control-add-form/ad-control-add-form.component";
import { UndergroundServiceComponent } from "./modules/underground-service/underground-service.component";
import { ContactAddFormComponent } from "./modules/marketing/contact-add-form/contact-add-form.component";
// import { SupportTicketListComponent } from "./modules/support-ticket/support-ticket-list/support-ticket-list.component";
const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "book-my-job/:jobid",
    component: BookinglinkComponent,
    data: {
      title: "Book-my-job",
    },
  },
  {
    path: "quote-details/:jobid",
    component: PdfviewComponent,
    data: {
      title: "Book-my-job",
    },
  },
  {
    path: "Analytics",
    component: AdminLayoutComponent,
    children: [
      {
        path: "data",
        component: AnalyticsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "representation",
        component: DatarepresentationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "linechart",
        component: LinebarrepresentationComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "multilinechart",
        component: MultilineComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },

  // marketing
  // {
  //   path: "Marketing",
  //   component: AdminLayoutComponent,
  //   children: [
  //     {
  //       path: "ad-control-list",
  //       component: AdControlComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "enquiry-analytics",
  //       component: EnquiryAnalyticsComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "contact-form",
  //       component: ContactFormComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "ad-control/add",
  //       component: AdControlAddFormComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "ad-control/edit/:id",
  //       component: AdControlAddFormComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "contact-form/add",
  //       component: ContactAddFormComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //     {
  //       path: "contact-form/edit/:id",
  //       component: ContactAddFormComponent,
  //       canActivate: [AuthGuardService],
  //     },
  //   ],
  // },

  {
    path: "Enquiry",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/open",
        component: InspectionListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/pending",
        component: InspectionListPendingComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/confirmed",
        component: InspectionListConfirmedComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/accepted",
        component: InspectionListAcceptedComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/rejected",
        component: InspectionListRejectedComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/dump",
        component: InspectionListDumpComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/Qualified",
        component: InspectionQualifiedComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/all",
        component: AllinspectionComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "Supervision",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/open",
        component: QualityassuranceopenComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "list/pending",
        component: QualityassurancependingComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "list/checked",
        component: QualityassurancecheckedComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "Inventory",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:category",
        component: InventorylistComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "IO/list/:status",
        component: InternalOrderListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "PO/list/:status",
        component: PurchaseOrderListComponent,
        canActivate: [AuthGuardService],
      },
      { path: "add/IO", component: InternalOrderFormComponent },
      { path: "add/PO", component: PurchaseOrderFormComponent },
      // { path : 'list/checked', component: QualityassurancecheckedComponent, canActivate: [AuthGuardService] },
    ],
  },
  {
    path: "QuotesStatus",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:status",
        component: QuotesStatusComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "document",
    component: AdminLayoutComponent,
    children: [
      {
        path: ":documentType",
        component: AdministrativeProcedureComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "vehicle",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: VehicelListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "add",
        component: VehicleAddPageComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "Support",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:status",
        component: SupportTicketListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "add",
        component: AddSupportTicketFormComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "Employee",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: EmployeeListComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "add",
        component: AddEmployeeFormComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "human-resource",
    component: AdminLayoutComponent,
    children: [
      {
        path: "annual-leave/:status",
        component: AnnualLeaveComponent,
      },
      {
        path: "report-incident/:status",
        component: IncidentComponent,
      },
      {
        path: "swms",
        component: SwmsComponent,
      },
      // {
      //   path: "underground-service-reports",
      //   component: UndergroundServiceComponent,
      // },
    ],
  },
  {
    path: "Timesheet",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:status",
        component: TimesheetListComponent,
        canActivate: [AuthGuardService],
      },

      {
        path: "add",
        component: TimesheetFormComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: "Dashboard",
    component: AdminLayoutComponent,
    children: [
      {
        path: "companyoverview",
        component: CompanyOverviewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: "salesperformance",
        component: SalesPerformanceComponent,
        canActivate: [AuthGuardService],
      },

      // {
      //   path: "add",
      //   component: TimesheetFormComponent,
      //   canActivate: [AuthGuardService],
      // },
    ],
  },
  //{ path: 'Enquiry',  loadChildren: () => import("../app/modules/inspection/inspection.module").then(m => m.InspectionModule) },

  //{ path: 'Request-a-free-inspection', loadChildren: () => import("../app/modules/inspection/inspection.module").then(m => m.InspectionModule) },
  //{ path: 'Inspection', loadChildren: () => import("../app/modules/inspection/inspection.module").then(m => m.InspectionModule) },
  { path: "Request-a-free-inspection", component: InspectionComponent },
  {
    path: "Edit-Request-a-free-inspection/:inpectionid",
    component: EditinspectionComponent,
  },
  { path: "Thank-You-For-Booking-With-Us", component: ThankyouComponent },
  { path: "warranty-claim/:jobid", component: WarrantylinkComponent },
  {
    path: "booking",
    component: AdminLayoutComponent,
    children: [
      {
        path: "history/Profile Preparation",
        component: BookingListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Dates Sent",
        component: BookingListInprogressComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "search",
        component: SearchBookingComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      // { path: 'history/Customer confirmed and underway', component: BookingListClosedComponent, canActivate: [AuthGuardService,AdminAuthService] },
      // { path: 'history/Customer confirmed and underway', component: NewjobComponent, canActivate: [AuthGuardService,AdminAuthService] },
    ],
  },
  {
    path: "Job",
    component: AdminLayoutComponent,
    children: [
      {
        path: "history/Job Cancelled",
        component: JobcancelComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Job On hold",
        component: JobonholdComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Job underway",
        component: BookingListClosedComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/New Job",
        component: NewjobComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "Invoice",
    component: AdminLayoutComponent,
    children: [
      {
        path: "history/To be Invoiced",
        component: TobeinvoiceComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Awaiting Payment",
        component: AwaitingpaymentComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Dispute/ On Hold",
        component: DisputeonholdComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/Paid in full",
        component: PaidinfullComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/first-reminder",
        component: FirstReminderComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/action-required",
        component: ActionRequiredComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/second-reminder",
        component: SecondReminderComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/legal-action",
        component: LegalActionComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/to-be-written-off",
        component: ToBeWrittenOffComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "history/written-off",
        component: WrittenOffComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "pdfpersection",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: PdfpersectionComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AddPdfpersectionComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:sectionid",
        component: AddPdfpersectionComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "",
    component: UnauthLayoutComponent,
    children: [
      { path: "reset-password/:id", component: VerifyOtpComponent },
      { path: "login", component: LoginComponent },
      { path: "forget-password", component: ForgetPasswordComponent },
    ],
  },
  { path: "dashboard", component: DashboardComponent },
  {
    path: "",
    component: ProfileLayoutComponent,
    children: [
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "users",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: UserListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      { path: ":id/view", component: UserViewComponent },
      {
        path: "add",
        component: UserAddComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      { path: ":id/employee", component: UserEmployeeViewComponent },
      { path: ":id/projects", component: UserProjectViewComponent },
      { path: ":id/employee/view", component: EmployeeDetailsComponent },
      { path: ":id/projects/view", component: ProjectDetailsComponent },
      {
        path: "edit/:id",
        component: UserAddComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "portal",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: PortalComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/:category",
        component: PortalComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add/:categoryId",
        component: AddPortalFormComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "ipad",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: IpadTemplateListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "repair/:id",
        component: RepairMethodListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add/:id",
        component: RepairMethodAddComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "template",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: TemplateListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AddTemplateComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "view/:tempId",
        component: ViewTemplateComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:tempId",
        component: EditTemplateComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "Discount",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: DiscountComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AdddiscountComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      // { path: 'view/:tempId', component: ViewTemplateComponent, canActivate: [AuthGuardService,AdminAuthService] },
      {
        path: "edit/:Id",
        component: AdddiscountComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },

  {
    path: "causes",
    component: AdminLayoutComponent,
    children: [
      {
        path: "add",
        component: AddCauseComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list",
        component: CauseListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list/:id",
        component: CauseListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:causeId",
        component: AddCauseComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "repair",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: RepairListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AddRepairComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:repId",
        component: AddRepairComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "quote",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: QuoteListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AddQuoteComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:quoteId",
        component: AddQuoteComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "quotecode-view",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:id",
        component: QuoteListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },

  {
    path: "toolbox",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list",
        component: ToolboxListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "add",
        component: AddToolboxComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:toolboxId",
        component: AddToolboxComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "toolbox-view",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:id",
        component: ToolboxListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "repair-list",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:id",
        component: RepairListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },

  {
    path: "legends",
    component: AdminLayoutComponent,
    children: [
      {
        path: "add",
        component: AddLegendComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list",
        component: LegendListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:legId",
        component: AddLegendComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "legends-view",
    component: AdminLayoutComponent,
    children: [
      {
        path: "list/:id",
        component: LegendListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "plumbing",
    component: AdminLayoutComponent,
    children: [
      {
        path: "add",
        component: AddPlumbingComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "list",
        component: PlumbingListComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
      {
        path: "edit/:plumbingId",
        component: AddPlumbingComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },

  {
    path: "jobs",
    component: AdminLayoutComponent,
    children: [
      {
        path: "history",
        component: JobHistoryComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [{ path: "change-password", component: ChangePasswordComponent }],
  },

  {
    path: "terms",
    component: AdminLayoutComponent,
    children: [
      {
        path: "services",
        component: TermsAndServicesComponent,
        canActivate: [AuthGuardService, AdminAuthService],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
