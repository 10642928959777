import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.sass']
})
export class EmployeeDetailsComponent implements OnInit {
  userData = {
    fname: '',
    email: '',
    gender: '',
    phone: '',
    role: '',
    business_address: {
      address: '',
      city: '',
      state: '',
      zip_code: ''
    },
    image: '../assets/img/theme/team-4-800x800.jpg',
  };
  constructor() { }

  ngOnInit() {

  }

}
