import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SupportTicketListComponent } from "./support-ticket-list/support-ticket-list.component";
import { SupportTicketFormComponent } from "./support-ticket-form/support-ticket-form.component";
import { NgxPaginationModule } from "ngx-pagination";
import { PaginationModule } from "ngx-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SupportTicketNotesComponent } from "./support-ticket-notes/support-ticket-notes.component";
import { SupportTicketNotesModalComponent } from "./support-ticket-notes-modal/support-ticket-notes-modal.component";
import { SupportTicketPopupComponent } from "./support-ticket-popup/support-ticket-popup.component";
import { AddSupportTicketFormComponent } from './add-support-ticket-form/add-support-ticket-form.component';

@NgModule({
  declarations: [
    SupportTicketListComponent,
    SupportTicketFormComponent,
    SupportTicketNotesComponent,
    SupportTicketNotesModalComponent,
    SupportTicketPopupComponent,
    AddSupportTicketFormComponent,
  ],
  imports: [
    PaginationModule.forRoot(),
    NgxPaginationModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
  ],
  entryComponents: [
    SupportTicketPopupComponent,
    SupportTicketNotesComponent,
    SupportTicketNotesModalComponent,
    SupportTicketFormComponent,
  ],
})
export class SupportTicketModule {}
