import { Component, OnInit, VERSION } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-terms-and-services',
  templateUrl: './terms-and-services.component.html',
  styleUrls: ['./terms-and-services.component.sass']
})
export class TermsAndServicesComponent implements OnInit {
  name = "Angular " + VERSION.major;
  html = `Enter text here : <textarea></textarea> <p></p>`;
  termsAndServices : any;
  termsData: any;
  // document: HTMLDocument;
  // /html : any = '';
  constructor(
    private commonService : CommonService,
    private formBuilder : FormBuilder
  ) {
    this.termsAndServices = this.formBuilder.group({
      title : [''],
      description : ['']
    })
   }


  ngOnInit() {
    this.getTermsandServices();
  }

  submitTerms() {
    console.log(this.termsAndServices);
    if(this.termsAndServices.invalid){
      return
    }

    let body : any = {}; 
    body = {
      header : this.termsAndServices.value.title,
      body : this.termsAndServices.value.description
    }
    if(!this.termsData){
      this.commonService.createTerms(body).subscribe(res=>{
        console.log(res);
      },err=>{
        console.log(err);
      })
    }
    if(this.termsData){
      let id = this.termsData._id; 
      this.commonService.edit(id,body).subscribe(res=>{
        console.log(res);
      },err=>{
        console.log(err);
      })
    }

  }

  getTermsandServices() {
    this.commonService.getTerms().subscribe((res:any)=>{
      console.log(res);
      if(res && res.data && res.data.length)
      this.termsData = res.data[res.data.length - 1];
      this.termsAndServices.patchValue({
        title : this.termsData.header,
        description : this.termsData.body
      })
    },err=>{
      console.log(err);
    })
  }

}
