import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { BookinglinkService } from '../bookinglink.service'
import { CommonService } from 'src/app/services/common.service';
import { DateFormatter } from 'ngx-bootstrap/datepicker';
import jspdf from 'jspdf'
import html2canvas from 'html2canvas';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AUTO_STYLE } from '@angular/animations';
import { TermsAndConditionComponent } from '../terms-and-condition/terms-and-condition.component';
import { BsModalService } from 'ngx-bootstrap';


@Component({
  selector: 'app-bookinglink',
  templateUrl: './bookinglink.component.html',
  styleUrls: ['./bookinglink.component.sass']
})
export class BookinglinkComponent implements OnInit {
  signinForm: FormGroup;
  submitted;
  today: Date;
  DateTimeFormatter: any;
  detailtype;
  quote;
  quote1;
  quotedata;
  repTime;
  totalPrice;
  bdate: Date;
  state: any;
  bdate1: Date;
  firsttime: any;
  //Blanksignature: any;
  sign: any;
  obj: any;
  loader;
  PDF: any;
  contentDataURL: any;
  srd: any;
  file: any;
  templatename: any;
  user_name: any;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.2,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  bookingform = {
    name: '',
    email: '',
    address: '',
    City: '',
    state: '',
    Postalcode: '',
    Phonenumber: '',
    Country: '',
    Date: '',
    Tnc: '',
    TodayDate: '',
    signature: '',
    sendemail: ''
  }

  current_date: Number;

  constructor(
    private formBuilder: FormBuilder,
    private bookinglinkService: BookinglinkService,
    private router: Router,
    public commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dialogRef : BsModalService
  ) {
    //this.current_date = (new Date()).getDate();
    this.today = new Date();

  }

  openTermsOfService() {
    this.dialogRef.show(TermsAndConditionComponent,{
      class : 'bg-modal-full modal-lg'
    })
  }

  ngOnInit() {
    //this.Blanksignature = true;
    this.signinForm = this.formBuilder.group({

      email: ['', Validators.required],
      name: ['', Validators.required],
      address: [],
      City: [],
      state: [],
      Postalcode: [],
      Phonenumber: ['', Validators.required],
      Country: [],
      Date: ['', Validators.required],
      Tnc: [],
      TodayDate: [],
      sendemail: [],
      signature: []
    })
    this.getJobdetailsbyid(this.activatedRoute.snapshot.params.jobid);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe(data => {
        console.log(data);
        this.titleService.setTitle(data.title)
      });
    });

  }
  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  getbookingdatabyid(jobid) {
    this.loader = true;
    this.bookinglinkService.getbookingdatabyid(jobid).subscribe((result: any) => {
      const items1 = result.data;
      this.firsttime = true;
      this.loader = false;
      this.jobData1(items1);
    }, (error: any) => {
      this.loader = false;
    });
  }
  jobData1(items1) {
    this.bdate1 = items1.Date.substring(0, 10);
    //this.signaturePad = items1.signature
    this.sign = items1.signature
    this.signinForm.patchValue({
      name: items1.name,
      email: items1.email,
      address: items1.address,
      City: items1.City,
      state: items1.state,
      Postalcode: items1.Postalcode,
      Country: "Australia",
      Date: this.bdate1,
      //Date: items1.date ,
      Phonenumber: items1.Phonenumber,
      Tnc: true,
      //TodayDate: items1.TodayDate,
      // signature: items1.signature
    });
  }

  getJobdetailsbyid(jobid) {
    this.loader = true;
    this.bookinglinkService.getJobdetailsbyid(jobid).subscribe((result: any) => {
      const items = result.data;
      this.firsttime = false;
      this.srd = result.data.srd;
      this.state = result.data.state;
      this.user_name = result.data.user_name
      this.templatename = result.data.templatename;
      this.file = result.data.file
      // console.log(items);
      this.jobData(items);
      //this.items = items;
      this.loader = false;
      this.quote1 = this.activatedRoute.snapshot.params.jobid;
      this.getbookingdatabyid(this.activatedRoute.snapshot.params.jobid);
    }, (error: any) => {
      this.loader = false;
      // this.nodata = true;
      // this.commonService.toast(error.error.message, 'Error');
    });
  }

  jobData(items) {
    this.bdate = items.date.substring(0, 10);
    console.log(this.bdate);
    this.detailtype = items.detailType;
    if (items.quoteData.length > 1) {
      for (const arrTemp of items.quoteData) {
        if (arrTemp.link == "" || arrTemp.link == '' || arrTemp.link == "      " || arrTemp.link == undefined) {
          continue
        }
        else {
          this.quotedata = arrTemp;
          break;
        }
      }
    } else {
      this.quotedata = items.quoteData[0];
    }
    this.quote = this.quotedata.quoteCode;
    this.repTime = this.quotedata.repTime;
    //this.totalPrice = this.quotedata.totalPrice;
    //this.totalPrice = new Number(this.quotedata.totalPrice).toLocaleString("hi-IN", { style: "currency", currency: "USD" }).substring(1);
    var testp = items.grand_total != undefined ? items.grand_total : this.quotedata.totalPrice;
    //this.totalPrice = new Number(items.grand_total).toLocaleString("hi-IN", { style: "currency", currency: "USD" }).substring(1);
    this.totalPrice = new Number(testp).toLocaleString("hi-IN", { style: "currency", currency: "USD" }).substring(1);
    this.signinForm.patchValue({
      name: items.clientName,
      email: items.clientEmailid,
      address: items.projectAddress,
      City: items.City,
      state: items.state,
      Postalcode: items.Postalcode,
      Country: "Australia",
      Date: this.bdate,
      Phonenumber: items.clientphonenumber,
      signature: items.signature
    });
  }
  async booking() {
    this.submitted = true;
    //this.PDF =  await this.exportAsPDF();

    //console.log(this.PDF, 'PDFURL')
    const data = this.signinForm.controls;
    if (data.Date == null || data.Date == null) {
      this.commonService.toast('Please select quote date.', 'Error');
    }
    if (this.signinForm.invalid) {
      this.commonService.toast('Please fill in all the details ', 'Error');
      console.log('User form is not valid!!')
    } else if (data.Tnc.value == false || data.Tnc.value === null) {
      this.commonService.toast('Please agree to the terms of service', 'Error');
    }
    else if (this.firsttime == false) {
      if (this.signaturePad.isEmpty()) {
        this.commonService.toast('Please sign to submit your job', 'Error');
      } else {
        //var id = await this.exportAsPDF();
        this.obj = {
          //_id: id,
          name: data.name.value,
          email: data.email.value,
          address: data.address.value,
          City: data.City.value,
          state: this.state,
          Postalcode: data.Postalcode.value,
          Country: data.Country.value,
          Date: data.Date.value,
          Phonenumber: data.Phonenumber.value,
          Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
          TodayDate: data.TodayDate.value,
          signature: this.signaturePad.toDataURL(),
          firsttime: this.firsttime,
          type: "Quote",
          PDFURL: this.PDF,
          templatename: this.detailtype,
          repTime: this.repTime,
          price: this.totalPrice,
          sendemail: data.sendemail.value,
          file: this.file,
          srd: this.srd,

          user_name: this.user_name,
          //templatename: this.templatename
        };
        this.loader = true;
        this.bookinglinkService.createbookingwithouturl(this.obj).subscribe((res) => {
          this.loader = false;
          this.router.navigate(['/Thank-You-For-Booking-With-Us']);
          this.commonService.toast('Congrats! Your job is booked successfully.', 'Success');
        },
          error => {
            this.loader = false;
            this.commonService.toast(error.error.message, 'Error');
          });
      }
    } else {


      this.obj = {
        //_id: id,
        name: data.name.value,
        email: data.email.value,
        address: data.address.value,
        City: data.City.value,
        state: this.state,
        Postalcode: data.Postalcode.value,
        Country: data.Country.value,
        Date: data.Date.value,
        Phonenumber: data.Phonenumber.value,
        Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
        TodayDate: data.TodayDate.value,
        signature: this.sign,
        firsttime: this.firsttime,
        type: "Quote",
        PDFURL: this.PDF,
        templatename: this.detailtype,
        repTime: this.repTime,
        price: this.totalPrice,
        sendemail: data.sendemail.value,
        file: this.file,
        srd: this.srd,
        user_name: this.user_name,
        //templatename: this.templatename
      };
      this.loader = true;
      this.bookinglinkService.createbookingwithouturl(this.obj).subscribe((res) => {
        this.loader = false;
        this.router.navigate(['/Thank-You-For-Booking-With-Us']);
        this.commonService.toast('Congrats! Your job is booked successfully.', 'Success');
      },
        error => {
          this.loader = false;
          this.commonService.toast(error.error.message, 'Error');
        });

    }
  }

  // async exportAsPDF() {
  //   const data1 = this.signinForm.controls;
  //   let data = await document.getElementById("PDF");

  //   await html2canvas(data, { scrollY: -window.scrollY, windowWidth: window.innerWidth, windowHeight: window.innerHeight }).then(async canvas => {
  //     //console.log(canvas, 'testing canvas');
  //     this.contentDataURL = await canvas.toDataURL('image/png', 0.1);


  //   });
  //   var dataid, sign;
  //   let obj1 = {
  //     Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
  //     PDFURL: this.contentDataURL
  //   }
  //   if (this.firsttime == false) {
  //     sign = this.signaturePad.toDataURL()
  //   }


  //   await this.bookinglinkService.pdfurl(obj1).subscribe((res: any) => {
  //     this.loader = false;
  //     dataid = res.data._id;

  //     this.obj = {
  //       _id: dataid,
  //       name: data1.name.value,
  //       email: data1.email.value,
  //       address: data1.address.value,
  //       City: data1.City.value,
  //       state: data1.state.value,
  //       Postalcode: data1.Postalcode.value,
  //       Country: data1.Country.value,
  //       Date: data1.Date.value,
  //       Phonenumber: data1.Phonenumber.value,
  //       Unique_Quote_code: this.activatedRoute.snapshot.params.jobid,
  //       TodayDate: data1.TodayDate.value,
  //       signature: sign,
  //       firsttime: this.firsttime,
  //       type: "Quote",
  //       //PDFURL: this.PDF
  //     }
  //     this.bookinglinkService.createbookingwithouturl(this.obj).subscribe((res) => {
  //       this.loader = false;
  //       this.router.navigate(['/Thank-You-For-Booking-With-Us']);
  //       this.commonService.toast('Congrats! Your job is booked successfully.', 'Success');
  //     },
  //       error => {
  //         this.loader = false;
  //         this.commonService.toast(error.error.message, 'Error');
  //       });
  //   },
  //     error => {
  //       this.loader = false;
  //       this.commonService.toast(error.error.message, 'Error');
  //     });
  //   //return this.contentDataURL;
  //   return dataid;

  // }
  get f() {
    return this.signinForm.controls;
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    if (this.firsttime == false) {
      this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
      this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
      //this.Blanksignature = true;
    }

  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    console.log(this.signaturePad);
    //this.Blanksignature = false;
    //this.bookingform.signature = this.signaturePad.toDataURL()
  }

  clearSign() {
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    //this.Blanksignature = true;
  }

  drawStart() {
    //this.Blanksignature = false;

    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

}
