import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestRequest } from "../../services/rest-request";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class QuotesStatusServices {
  constructor(private restRequest: RestRequest, private http: HttpClient) {}

  getQuotestList(status: any, page: any, search: any) {
    return this.restRequest
      .addAuthorization()
      .get(`/quotes?workFlowStatus=${status}&page=${page}&search=${search}`);
  }
  updateMasterEmail(id: any, auto_reminder: any, daysUntilMailSent: any) {
    return this.restRequest
      .addAuthorization()
      .put(`updateMasterMail/${id}`, { auto_reminder, daysUntilMailSent });
  }

  updateSingleMasterMail(id: any, auto_reminder: any) {
    return this.restRequest
      .addAuthorization()
      .put(`updateUserMailStatus/${id}`, { auto_reminder });
  }

  // getMasterMail() {
  //   return this.restRequest.addAuthorization().get(`getMasterMail`);
  // }

  updateQuotesStatus(id: any, status: any) {
    return this.restRequest
      .addAuthorization()
      .put(`/quote/work-flow-status/${id}`, status);
  }

  sendManualEmail(data: any) {
    return this.restRequest.addAuthorization().post("/sendManualMail", data);
  }
}
