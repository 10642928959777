import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { HumanResourceService } from "../human-resource/human-resource.service";
import * as moment from "moment";
import { InventoryNotesComponent } from "../inventory/inventory-notes/inventory-notes.component";

@Component({
  selector: "app-underground-service",
  templateUrl: "./underground-service.component.html",
  styleUrls: ["./underground-service.component.sass"],
})
export class UndergroundServiceComponent implements OnInit {
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  modalRef: BsModalRef;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private modalService: BsModalService,
    private api: HumanResourceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getList();
  }

  getDate(date: any) {
    if (date) return moment(date).format("DD/MM/YYYY");
    return "-";
  }

  // getList() {
  //   this.loader = true;
  //   this.api
  //     .getUndergroundReport({
  //       page: this.currentPageNo,
  //     })
  //     .subscribe(
  //       (result: any) => {
  //         const response = result.data;
  //         console.log(response, "============>>>>");
  //         // this.items = response.items;
  //         // this.totalRecords = response.count;
  //         // this.totalPages = response.totalPages;
  //         // this.currentPageNo = response.currentPage;
  //         // this.loader = false;
  //       },
  //       (error: any) => {
  //         console.log("hi");
  //         this.commonService.toast(error.error.message, "Error");
  //         this.loader = false;
  //       }
  //     );
  // }
  getList() {
    this.loader = true;
    this.api
      .getUndergroundReport({
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          console.log(response, "============>>>>");

          // Ensure you're handling the response correctly
          this.items = response.items; // Assuming 'items' contains your list of data
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;

          this.loader = false; // Hide loader once the data is fetched
        },
        (error: any) => {
          console.log("hi");
          this.commonService.toast(error.error.message, "Error");
          this.loader = false; // Hide loader on error
        }
      );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "swms_form",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  setSelectedTicketId(id: string) {
    this.selectedTicketId = id;
  }

  deltedata() {
    const Id = this.selectedTicketId;

    this.api.deleteswms(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
        this.selectedTicketId = "";
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
