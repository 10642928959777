import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { InspectionService } from './../inspection.service'
import { CommonService } from 'src/app/services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ThankyouModalComponent } from './../thankyou-modal/thankyou-modal.component'
import { async } from '@angular/core/testing';
import { CustomValidators } from 'ng2-validation';

@Component({
    selector: "app-inspection",
    templateUrl: "./inspection.component.html",
    styleUrls: ["./inspection.component.sass"],
})
export class InspectionComponent implements OnInit {
    issuetype: any;
    state: any;
    templatename: any;
    loader;
    textaboutissue;
    submitted;
    submitted1;
    submitted2;
    submitted3;
    clientbuildingerror;
    prepurchaseerror;
    lwerror;
    rderror;
    nextscren;
    nextscren1;
    nextscren2;
    fileToUpload: File = null;
    fileToUpload1 = null;
    inspectionForm: FormGroup;
    obj: any;
    userData = {
        issuetype: "",
        name: "",
        lname: "",
        templatename: "",
        textaboutissue: "",
        email: "",
        phonenumber: "",
        address: "",
        address1: "",
        town: "",
        postcode: "",
        state: "",
        image: [],
        hidfile: "",
        email2: "",
    };
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };
    arr_dummy: any = [];
    constructor(
        private formBuilder: FormBuilder,
        private inspectionService: InspectionService,
        private router: Router,
        public commonService: CommonService,
        private modalService: BsModalService
    ) {}

    ngOnInit() {
        this.issuetype = [
            "My Home",
            "A Managed Property",
            "My Work Building",
            "A Client's Building- I'm a contractor",
            "A Pre-Purchase Inspection",
            "My Family or Friend's home",
        ];
        //this.state = ["Australian Capital Territory", "New South Wales", "Queensland", "South Australia", "Tasmania", "Victoria", "Western Australia"]
        this.state = ["ACT", "NSW", "QLD", "VIC", "TAS", "WA", "SA"];
        this.inspectionForm = this.formBuilder.group({
            issuetype: new FormControl(this.userData.issuetype, [
                Validators.required,
            ]),
            name: new FormControl(this.userData.name, [
                Validators.required,
                Validators.pattern("^[a-zA-Z ]*$"),
            ]),
            lname: new FormControl(this.userData.lname, [
                Validators.required,
                Validators.pattern("^[a-zA-Z ]*$"),
            ]),
            templatename: new FormControl(this.userData.templatename, [
                Validators.required,
            ]),
            textaboutissue: new FormControl(this.userData.textaboutissue),
            email: new FormControl(this.userData.email, [
                //Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                Validators.required,
                CustomValidators.email,
            ]),
            email2: new FormControl(this.userData.email2, [
                //Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
                CustomValidators.email,
            ]),
            phonenumber: new FormControl(this.userData.phonenumber, [
                Validators.required,
                Validators.minLength(10),
                Validators.pattern("^[0-9]*$"),
            ]),
            address: new FormControl(this.userData.address, [
                Validators.required,
            ]),
            town: new FormControl(this.userData.town, [Validators.required]),
            address1: new FormControl(this.userData.address1),
            postcode: new FormControl(this.userData.postcode, [
                Validators.required,
                Validators.minLength(4),
                Validators.pattern("^[0-9]*$"),
            ]),
            state: new FormControl(this.userData.state, [Validators.required]),
            // image: new FormControl(this.userData.image),
            repMain: this.formBuilder.array([this.addRepairMethod()]),
            hidfile: new FormControl(this.userData.hidfile),
        });
        this.templatename = [
            "Wall Cracks",
            "Subsidence",
            "Leaning Wall",
            "Sinking Floor Pier",
            "Arch and Lintel",
            "Rising Damp",
            "Sunken Concrete",
            "Other",
        ];
        //this.gettemplatedata();
        this.clientbuildingerror = false;
        this.prepurchaseerror = false;
        this.nextscren = false;
    }

    gettemplatedata() {
        //this.loader = true;
        this.inspectionService.getTemplate(this.query).subscribe(
            (result: any) => {
                var tempdata = result.data;
                var temparr = tempdata.reverse().slice(0, 7);
                temparr.push({ templateName: "Other" });
                this.templatename = temparr;

                //this.loader = false;
            },
            (error: any) => {
                // this.loader = false;
            }
        );
    }
    shownotice(event) {
        if (event == "A Client's Building- I'm a contractor") {
            this.clientbuildingerror = true;
            this.prepurchaseerror = false;
            // console.log(this.clientbuildingerror);
        } else if (event == "A Pre-Purchase Inspection") {
            this.prepurchaseerror = true;
            this.clientbuildingerror = false;
        } else {
            this.clientbuildingerror = false;
            this.prepurchaseerror = false;
        }
    }
    shownotice1(event) {
        if (event == "Leaning Wall") {
            this.lwerror = true;
            this.rderror = false;
            //console.log(this.clientbuildingerror);
        } else if (event == "Rising Damp") {
            this.rderror = true;
            this.lwerror = false;
        } else {
            this.lwerror = false;
            this.rderror = false;
        }
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        //this.loader = true;
        if (files.length === 1) {
        }
        this.commonService
            .fileUpload(this.fileToUpload)
            .subscribe((result: any) => {
                // this.userData.image = result.data;
                this.arr_dummy.push(result.data);
                this.commonService.toast(
                    "Image uploaded successfully",
                    "Success"
                );
                this.fileToUpload1 = true;
                console.log(this.arr_dummy);
                //this.loader = false;
                // this.inspectionForm.patchValue({
                // image: result.data.substring(55)
                //  });
            });
    }

    capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    removeRep(index) {
        if (this.repArray.length === 1) {
            this.arr_dummy.splice(index, 1);
            console.log(this.arr_dummy);
            this.repArray.removeAt(index);
            this.repArray.push(this.addRepairMethod());
            // this.commonService.toast('Atleast one Repair Recommendation is required.', 'Error');
        } else {
            this.repArray.removeAt(index);
            this.arr_dummy.splice(index, 1);
            this.commonService.toast("Image deleted successfully.", "Success");
            console.log(this.arr_dummy);
        }
    }
    addRepairMethod() {
        return this.formBuilder.group({
            image: [this.userData.image],
        });
    }
    addRep() {
        this.repArray.push(this.addRepairMethod());
    }

    get repArray() {
        return this.inspectionForm.get("repMain") as FormArray;
    }
    get f() {
        return this.inspectionForm.controls;
    }
    nextscreen() {
        const data = this.inspectionForm.controls;
        this.submitted1 = true;
        if (data.issuetype.invalid || data.templatename.invalid) {
            this.nextscren = false;
        } else {
            this.nextscren = true;
        }
    }
    nextscreen2() {
        const data = this.inspectionForm.controls;
        this.submitted3 = true;
        if (
            data.address.invalid ||
            data.town.invalid ||
            data.postcode.invalid ||
            data.state.invalid
        ) {
            this.nextscren2 = false;
        } else {
            this.nextscren2 = true;
        }
    }
    nextscreen1() {
        const data = this.inspectionForm.controls;
        this.submitted2 = true;
        if (
            data.email.invalid ||
            data.phonenumber.invalid ||
            data.name.invalid ||
            data.lname.invalid
        ) {
            this.nextscren1 = false;
        } else {
            this.nextscren1 = true;
        }
    }
    submit() {
        this.submitted = true;
        const data = this.inspectionForm.controls;
        if (this.inspectionForm.invalid) {
            this.commonService.toast(
                "Please fill in all the details ",
                "Error"
            );
            console.log("User form is not valid!!");
        } else {
            this.obj = {
                issuetype: data.issuetype.value,
                templatename: data.templatename.value,
                textaboutissue: this.capitalize(data.textaboutissue.value),
                phonenumber: this.capitalize(data.phonenumber.value),
                name: this.capitalize(data.name.value),
                lname: this.capitalize(data.lname.value),
                email: data.email.value,
                email2: data.email2.value,
                address1: this.capitalize(data.address1.value),
                address: this.capitalize(data.address.value),
                City: this.capitalize(data.town.value),
                state: this.capitalize(data.state.value),
                postcode: data.postcode.value,
                image: this.arr_dummy,
            };
            this.inspectionService.inspection(this.obj).subscribe(
                (result: any) => {
                    console.log(result,'the result')
                    // this.commonService.toast('Request for a free inspection has been sent. Thank you', 'Success');
                    //this.router.navigate(['https://www.buildfix.com.au/']);
                    //alert('Request for a free inspection has been sent. Thank you');
                    this.modalService.show(ThankyouModalComponent, {
                        class: "modal-lg",
                    });
                    this.modalService.onHide.subscribe(() => {
                        window.location.href = "https://www.buildfix.com.au/";
                    });
                },
                (error) => {
                    //this.loader = false;
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        }
    }
}
