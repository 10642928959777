import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CauseService } from '../cause.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TemplateService } from '../../templates/template.service';


@Component({
    selector: "app-add-cause",
    templateUrl: "./add-cause.component.html",
    styleUrls: ["./add-cause.component.sass"],
})
export class AddCauseComponent implements OnInit {
    addCauseForm: FormGroup;
    submitted;
    loader;
    dropVal = null;
    cause: "";
    code: "";
    tempName: "";

    listData: any;
    editId = true;
    _id = "";
    query: any = {
        page: 1,
        limit: 100,
        userType: 1,
    };
    count: any;

    constructor(
        private router: Router,
        private causeService: CauseService,
        public commonService: CommonService,
        private templateService: TemplateService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.addCauseForm = new FormGroup({
            cause: new FormControl(this.cause, [Validators.required]),
            code: new FormControl(this.code, [Validators.required]),
            index: new FormControl(),
            tempName: new FormControl(this.tempName, [Validators.required]),
        });
        this.templateService.getTemplate(this.query).subscribe(
            (result: any) => {
                this.listData = [];
                if (result.success === true) {
                    this.listData = result.data.data.filter(
                        (data) => data.active == true
                    );
                    // this.getCauseCount(this.listData[0]._id);//////
                }
            },
            (error: any) => {
                this.commonService.toast("Something went wrong", "Error");
            }
        );

        if (this.activatedRoute.snapshot.params.causeId !== undefined) {
            this.editId = false;
            this._id = this.activatedRoute.snapshot.params.causeId;
            this.loadData(this.activatedRoute.snapshot.params.causeId);
        }
    }
    get f() {
        return this.addCauseForm.controls;
    }
    get hidfile() {
        return this.addCauseForm.get("hidfile");
    }

    getCauseCount(id, type?) {
        this.templateService.getCauseCount(id).subscribe(
            (res: any) => {
                this.count = res.data;
                if (type == "dropdown") {
                    this.addCauseForm.controls.index.setValue(res.data + 1);
                }
                console.log(res, "count");
            },
            (err) => {
                console.log(err);
            }
        );
    }

    loadData(causeId) {
        this.loader = true;
        this.causeService.getCauseById(causeId).subscribe(
            (result: any) => {
                const items = result.data;
                this.getCauseCount(items.tempId);
                this.editCauseData(items);
                this.loader = false;
            },
            (error: any) => {
                this.loader = false;
                this.commonService.toast(error.error.message, "Error");
            }
        );
    }

    editCauseData(data) {
        this.addCauseForm.patchValue({
            cause: data.cause_text,
            tempName: data.tempId,
            code: data.cause_code,
            hidfile: data.cause_image,
            index: data.index,
        });
    }

    dropdownValueChange(e) {
        this.getCauseCount(e.target.value, "dropdown");
    }

    addCause() {
        this.submitted = true;
        const data = this.addCauseForm.controls;
        if (this.addCauseForm.invalid) {
            console.log("Invalid form");
        } else {
            const obj = {
                cause_name: "null",
                cause_code: data.code.value,
                cause_text: data.cause.value,
                tempId: data.tempName.value,
                index: data.index.value,
            };
            this.loader = true;
            this.causeService.createCause(obj).subscribe(
                (res) => {
                    this.router.navigate(["/causes/list"]);
                    this.commonService.toast(
                        "Cause added Successfully",
                        "Success"
                    );
                },
                (error) => {
                    this.loader = false;
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        }
    }

    updateCause() {
        this.submitted = true;
        const data = this.addCauseForm.controls;
        if (this.addCauseForm.invalid) {
            console.log("Invalid form");
        } else {
            const obj = {
                cause_name: "null",
                cause_code: data.code.value,
                cause_text: data.cause.value,
                tempId: data.tempName.value,
                _id: this._id,
                index: data.index.value,
            };
            this.loader = true;
            this.causeService.updateCauseData(obj).subscribe(
                (res) => {
                    this.router.navigate(["/causes/list"]);
                    this.commonService.toast(
                        "Cause updated successfully ",
                        "Success"
                    );
                },
                (error) => {
                    this.loader = false;
                    this.commonService.toast(error.error.message, "Error");
                }
            );
        }
    }
}


