import { Injectable } from '@angular/core';
import { RestRequest } from '../../services/rest-request';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PdfpersectionService {

  constructor(private restRequest: RestRequest, private http: HttpClient) { }
  savepdf(query) {
    return this.restRequest.addAuthorization().post('pdf/savepdf', query);
  }
  deletepdf(id) {
    return this.restRequest.addAuthorization().delete('pdf/deletepdf/' + id);
  }
  updatepdf(id, query) {
    return this.restRequest.addAuthorization().post('pdf/updatepdf/' + id, query);
  }
  getpdf(){
    return this.restRequest.addAuthorization().get('pdf/getpdfpersectionlistV1')
  }
  getpdfV2(query){
    return this.restRequest.addAuthorization().get('pdf/getpdfpersectionlist',query)
  }
  getpdfforsection(id){
    return this.restRequest.addAuthorization().get('pdf/getpdfforsection/' + id)
  }
}
