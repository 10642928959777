import { Injectable } from '@angular/core';
import { RestRequest } from './rest-request';
import { ToastrManager } from 'ng6-toastr-notifications';


@Injectable()
export class CommonService {

    constructor(private restRequest: RestRequest, public toastr: ToastrManager) { }

    getCountries() {
        return this.restRequest.get('countries');
    }

    toast(msg: String, type: String) {
        if (type === 'Success') {
            this.toastr.successToastr(msg.toString(), 'Success!', {
                showCloseButton: true,
                toastTimeout: 6000
            });
        } else if (type === 'Error') {
            this.toastr.errorToastr(msg.toString(), 'Error!', {
                showCloseButton: true,
                toastTimeout: 6000
            });
        }
    }

    getCurrencies(country: String) {
        return this.restRequest.get('currencies?country=' + country);
    }

    getStates(country_id: String) {
        return this.restRequest.get('states?country_id=' + country_id);
    }

    getLanguages() {
        return this.restRequest.get('languages');
    }

    createTerms(body) {
        return this.restRequest.post('users/save_terms',body)
    }

    deleteNote(body) {
        return this.restRequest.deleteNew('users/deleteNote',body);
    }

    edit(id,body) {
        return this.restRequest.put(`users/update_terms?termId=${id}`,body)
    }

    getTerms() {
        return this.restRequest.get('users/get_terms');
    }


    createAttribute(payload) {
        return this.restRequest.post('attribute', payload);
    }

    attributeList(page, limit) {
        return this.restRequest.get('attributes?page=' + page + '&limit=' + limit);
    }

    getAttribute(categoryId) {
        return this.restRequest.get('attribute/' + categoryId);
    }

    updateAttribute(categoryId, payload) {
        return this.restRequest.put('attribute/' + categoryId, payload);
    }

    createJobs(payload) {
        return this.restRequest.post('job', payload);
    }

    fileUpload(file) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.restRequest.post('users/uploadImage', formData);
    } 
   /* pdfupload(pdf){
        const formData: FormData = new FormData();
        formData.append('file', pdf);
        return this.restRequest.post('users/uploadpdf', formData);
    }*/

}
