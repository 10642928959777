import { Component, EventEmitter, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-password-checker",
  templateUrl: "./password-checker.component.html",
  styleUrls: ["./password-checker.component.sass"],
})
export class PasswordCheckerComponent implements OnInit {

  component: string;
  url: any;
  password: string = "";
  name:any;



  constructor(
    private commonService: CommonService,
    private bsModalRef: BsModalRef,
    private router: Router
  ) {}



  ngOnInit() {
    
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  handleInputChange(event: any) {
    if (event.target.value.length > 4) {
      return this.commonService.toast("Max 4 letters allowed", "Error");
    }
    this.password = event.target.value;
  }

  handleSubmit() {
    if(this.name === "Raw Materials Order") {
      if (this.password === "1959") {
        sessionStorage.setItem("ivu", "true");
        this.commonService.toast("Authorized", "Success");
        this.cancelModal();
        const route = {
          inventory: this.url,
          employee: "Employee/list",
        };
        this.router.navigate([route[this.component]]);
        return;
      }
    }
    else{
      if (this.password === "1923") {
        sessionStorage.setItem("ivu", "true");
        this.commonService.toast("Authorized", "Success");
        this.cancelModal();
  
        const route = {
          inventory: this.url,
          employee: "Employee/list",
        };
  
        this.router.navigate([route[this.component]]);
        return;
      }
    }
    if (this.password.length < 4) {
      this.commonService.toast(
        "Password length should be minimum 4 letters",
        "Error"
      );
    } else {
      this.commonService.toast("Invalid Credentials", "Error");
    }
  }
}
