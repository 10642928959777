import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { HumanResourceService } from "../human-resource.service";
import { InventoryNotesComponent } from "../../inventory/inventory-notes/inventory-notes.component";

@Component({
  selector: "app-swms",
  templateUrl: "./swms.component.html",
  styleUrls: ["./swms.component.sass"],
})
export class SwmsComponent implements OnInit {
  items = [];
  totalPages: number;
  totalRecords: number = 0;
  currentPageNo: number = 1;
  selectedTicketId = "";
  loader = false;
  modalRef: BsModalRef;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private modalService: BsModalService,
    private api: HumanResourceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getList();
  }

  getDate(date: any) {
    if (date) return moment(date).format("DD/MM/YYYY");
    return "-";
  }

  getList() {
    this.loader = true;
    this.api
      .getswms({
        page: this.currentPageNo,
      })
      .subscribe(
        (result: any) => {
          const response = result.data;
          this.items = response.items;
          this.totalRecords = response.count;
          this.totalPages = response.totalPages;
          this.currentPageNo = response.currentPage;
          this.loader = false;
        },
        (error: any) => {
          this.commonService.toast(error.error.message, "Error");
          this.loader = false;
        }
      );
  }

  handleInventoryNotesModel(inventoryId: string) {
    const initialState = {
      inventoryId,
      noteType: "swms_form",
    };
    this.modalService.show(InventoryNotesComponent, {
      initialState,
      class: "modal-lg",
    });
    this.modalService.onHide.subscribe(() => {
      this.getList();
    });
  }

  handlePageChange({ page }) {
    this.currentPageNo = page;
    this.getList();
  }

  setSelectedTicketId(id: string) {
    this.selectedTicketId = id;
  }

  deltedata() {
    const Id = this.selectedTicketId;

    this.api.deleteswms(Id).subscribe(
      (result: any) => {
        this.loader = false;
        if (result.success === true) {
          this.commonService.toast(result.message, "Success");
        } else {
          this.commonService.toast(result.message, "Error");
        }

        this.getList();
        this.selectedTicketId = "";
      },
      (error: any) => {
        this.loader = false;
        this.commonService.toast(error.error.message, "Error");
      }
    );
  }
}
