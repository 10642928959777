import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./../../services/auth.service";
import { SigninRequest, SigninResponse } from "./../../interfaces/auth";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "../../../../services/common.service";
import { GetProfileResponse } from "../../../user/interfaces/user";
import { UserService } from "../../../user/services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  submitted = false;
  signinForm: FormGroup;
  loader: Boolean;
  show_password: Boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    public commonService: CommonService
  ) {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(["/"]);
    }
    this.show_password = false;
  }

  ngOnInit() {
    console.log("BUILD UPLOADING ON - 16 JAN 25");
    this.loader = false;
    this.signinForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/
          ),
        ],
      ],
    });
  }

  get f() {
    return this.signinForm.controls;
  }

  authenticate() {
    this.submitted = true;
    this.loader = true;
    if (!this.signinForm.invalid) {
      if (
        this.signinForm.controls.email.value ===
          "supervision@buildfix.com.au" &&
        this.signinForm.controls.password.value === "admin@123"
      ) {
        localStorage.clear();
        this.authService
          .signIn({ email: "admin@gmail.com", password: "Buildfix@2020" })
          .subscribe(
            (res: any) => {
              this.commonService.toast(
                "Super Admin Login Successfull",
                "Success"
              );
              localStorage.setItem("currentSession", res.data.token);
              localStorage.setItem("userId", JSON.stringify(res.data._id));
              localStorage.setItem("userType", "superAdmin");
              this.router.navigate(["/Supervision/list/open"]);
            },
            (error) => {
              this.commonService.toast(error.error.message, "Error");
            }
          );
        // localStorage.setItem('superVision',JSON.stringify(this.signinForm.controls));
      } else if (
        this.signinForm.controls.email.value === "enquiry@buildfix.com.au" &&
        this.signinForm.controls.password.value === "admin@123"
      ) {
        localStorage.clear();
        this.authService
          .signIn({ email: "admin@gmail.com", password: "Buildfix@2020" })
          .subscribe(
            (res: any) => {
              this.commonService.toast(
                "Super Admin Login Successfull",
                "Success"
              );
              localStorage.setItem("currentSession", res.data.token);
              localStorage.setItem("userId", JSON.stringify(res.data._id));
              localStorage.setItem("userType", "superAdmin");
              localStorage.setItem("newUserType", "enquiryAdmin");
              this.router.navigate(["/Analytics/data"]);
            },
            (error) => {
              this.commonService.toast(error.error.message, "Error");
            }
          );
        // localStorage.setItem('superVision',JSON.stringify(this.signinForm.controls));
      } else {
        localStorage.clear();
        this.authService
          .signIn({
            email: this.signinForm.controls.email.value,
            password: this.signinForm.controls.password.value,
          })
          .subscribe(
            (response: SigninResponse) => {
              this.loader = false;
              if (response.success === true) {
                localStorage.removeItem("userInfo");
                // localStorage.setItem('currentSession', response.data.token.toString());
                localStorage.setItem("currentSession", response.data.token);
                localStorage.setItem("userInfo", JSON.stringify(response.data));
                localStorage.setItem(
                  "userId",
                  JSON.stringify(response.data._id)
                );
                this.router.navigate(["/users/list"]);
                localStorage.setItem("userType", "admin");
                this.commonService.toast("Login Successfully", "Success");
              }
            },
            (error) => {
              this.loader = false;
              this.commonService.toast(error.error.message, "Error");
            }
          );
      }
    } else {
      this.loader = false;
    }
  }
  // if (this.signinForm.controls.username.value === 'admin@gmail.com' &&
  //   this.signinForm.controls.password.value === 'admin123') {
  //   this.loader = false;
  //   this.router.navigate(['/dashboard']);
  //   this.commonService.toast('Login successfully', 'Success');
  // } else {
  //   this.loader = false;
  //   this.commonService.toast('Invalid Credentials', 'Error');
  // }
  // this.signinForm.controls.username.value
  //  this.signinForm.controls.password.value
  //   this.authService.signIn({
  //     user_name: this.signinForm.controls.username.value,
  //     password: this.signinForm.controls.password.value,
  //     user_type: 0
  //   }).subscribe( (response: SigninResponse) => {
  //     this.loader = false;
  //     if (response.success === true ) {
  //       localStorage.setItem('currentSession', response.data.token.toString());

  //       this.userService.getProfile().subscribe((profile_response: GetProfileResponse) => {

  //           if (profile_response.success === true) {
  //               const profile_data = profile_response.data;
  //               localStorage.setItem('userInfo', JSON.stringify({
  //                 profile_email: profile_data.email,
  //                 profile_image: profile_data.image,
  //                 profile_name: profile_data.user_name,
  //                 profile_gender: profile_data.gender
  //               }));
  //               console.log(localStorage.getItem('userInfo'));
  //               localStorage.removeItem('signUpInfo');
  //               localStorage.removeItem('forgotPasswordToken');
  //               this.router.navigate(['/']);
  //           }
  //       });

  //     } else {
  //       this.commonService.toast(response.message.toString(), 'Error');
  //     }
  //   }, error => {
  //     this.loader = false;
  //     this.commonService.toast(error.error.message, 'Error');
  //   });

  togglePasswordView() {
    if (this.show_password === true) {
      this.show_password = false;
    } else {
      this.show_password = true;
    }
  }
}
