import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitInLines'
})
export class SplitInLinesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const maxlen = args;
    if (value.length > maxlen) {
      const val_arr = value.split(' ');
      let seg_length = 0;
      let seg_text = '';
      let text = '';
      val_arr.forEach((row) => {
        if (seg_length + row.length > maxlen) {
          text += seg_text + '<br>';
          seg_length = 0;
          seg_text = '';
        } else {
          seg_length += row.length;
          seg_text += row + ' ';
        }
      });

      text += seg_text;
      return text;
    } else {
      return value;
    }
  }

}
