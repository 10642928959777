import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enquiry-analytics',
  templateUrl: './enquiry-analytics.component.html',
  styleUrls: ['./enquiry-analytics.component.sass']
})
export class EnquiryAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
